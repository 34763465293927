import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import '../../Restaurant/OnlineOrder/RestaurantPlateforms.css'
import { useTranslation } from "react-i18next";


const platforms = [
  { id: "zomato", name: "Zomato", logo: "./zomato.png" },
  { id: "swiggy", name: "Swiggy", logo: "./Swiggy.png" },
  { id: "ubereats", name: "UberEats", logo: "ubereates.png" },
  { id: "foodpanda", name: "Food Panda", logo: "Foodpanda.png" },
];

const RestaurantPlatforms = ({ companyId, onClose }) => {
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    apiKey: "",
    secretKey: "",
    restaurantId: "",
  });

  const handlePlatformClick = (platformId) => {
    if (selectedPlatform === platformId) {
      setSelectedPlatform(null);
    } else {
      setSelectedPlatform(platformId);
      setFormData({ apiKey: "", secretKey: "", restaurantId: "" });
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the data to your backend
    console.log("Submitting data for platform:", selectedPlatform, formData);
    // Reset form and close platform selection
    setFormData({ apiKey: "", secretKey: "", restaurantId: "" });
    setSelectedPlatform(null);
  };

  return (
    <div className="restaurant-platforms-overlay">
      <div className="restaurant-platforms-content">
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>{t("Restaurant Platform Integration")}</h2>
        <div className="platform-logos">
          {platforms.map((platform) => (
            <img
              key={platform.id}
              src={platform.logo}
              alt={platform.name}
              className={`platform-logo ${selectedPlatform === platform.id ? "selected" : ""}`}
              onClick={() => handlePlatformClick(platform.id)}
            />
          ))}
        </div>
        {selectedPlatform && (
          <form onSubmit={handleSubmit} className="platform-form">
            <h3>{platforms.find(p => p.id === selectedPlatform).name} {t("Integration")}</h3>
            <input
              type="text"
              name="apiKey"
              value={formData.apiKey}
              onChange={handleInputChange}
              placeholder="API Key"
              required
            />
            <input
              type="text"
              name="secretKey"
              value={formData.secretKey}
              onChange={handleInputChange}
              placeholder="Secret Key"
              required
            />
            <input
              type="text"
              name="restaurantId"
              value={formData.restaurantId}
              onChange={handleInputChange}
              placeholder="Restaurant ID"
              required
            />
            <button type="submit">{t("Save Integration")}</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default RestaurantPlatforms;