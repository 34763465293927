import React, { useState } from "react";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";
import ErrorNotification from "../../Notification/ErrorNotification";
import { useTranslation } from "react-i18next";

const SearchItemForKot = ({handlekotitemoptions}) => {
  const [query, setQuery] = useState("");
   
  const { t } = useTranslation();

  const handleInputChange = async (e) => {
    const { value } = e.target;
    setQuery(value);
    if (value.length >= 2) {
      try{
        const response = await sendRequest('GET', `${BASE_URL}/v1/inventory_managment/items?query=${value}&per_page=100&is_billing=true`);
        const data = response.data;
        const fetchedOptions = data.data;
        handlekotitemoptions(fetchedOptions);
      } catch (error) {
        ErrorNotification(error.data.errors);
      }
    } else {
      handlekotitemoptions([]);
    }
  }

return (
  <>
    <div className="searchable-dropdown">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder={t("Search by Items Name or Items Code")}
        className="form-control"
      />
    </div>
  </>
)
}

export default SearchItemForKot;
