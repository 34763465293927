import React from 'react';
import { format } from 'date-fns';
import "./reports.css"


const MonthListDropdown = ({ selectedMonth, handleMonthChange }) => {
  const fullMonthList = Array.from({ length: 12 }, (_, index) => {
    const monthName = format(new Date(2022, index, 1), 'LLLL');
    return { value: index + 1, label: monthName };
  });
  
  return (
    <select 
      className="form-select report-type text-black"
      aria-label="Month select"
      value={selectedMonth}
      onChange={handleMonthChange}
    >
      {fullMonthList.map(month => (
        <option key={month.value} value={month.label}>
          {month.label}
        </option>
      ))}
    </select>
  );
};

export default MonthListDropdown;

export const getMonthLabel = (month) => {
  const fullMonthList = Array.from({ length: 12 }, (_, index) => {
    const monthName = format(new Date(2022, index, 1), 'LLLL');
    return { value: index, label: monthName };
  });
  return fullMonthList[month - 1].label;
};