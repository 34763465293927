import React, { useEffect, useState } from "react";
import { BsFillTrashFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import './KotItemTable.css';
import ItemModifier from "../../components/Restaurant/ItemModifier/ItemModifier";
import { useTranslation } from "react-i18next";

const KotItemTable = ({ selectedItems, updateItems, subTotal, deletedItems, updateTotalDiscount }) => {
  const [items, setItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isItemModifierPopup, setItemModifierPopup] = useState(false);
  const [selectedModifiers, setSelectedModifiers] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);
  const { t } = useTranslation();

  console.log(selectedItems);

  const openItemModifierPopup = (index) => {
    setItemModifierPopup(true);
    setCurrentItemIndex(index);
    setSelectedModifiers(items[index].item_modifier || []);
  };

  const closeItemModifierPopup = () => {
    setItemModifierPopup(false);
    setSelectedModifiers([]);
  };

  useEffect(() => {
    const total = items.reduce((acc, item) => acc + item.quantity * item.sale_amount - item?.diffrence_discount, 0).toFixed(2);
    const total_discount = items.reduce((acc, item) => {
      return acc + item.quantity * item.discount_amount + item?.diffrence_discount;
    }, 0).toFixed(2);
    setTotalPrice(total);
    updateTotalDiscount(total_discount);
    subTotal(total);
  }, [items]);

  useEffect(() => {
    if (selectedItems) {
      const updatedItems = selectedItems.reduce((acc, item) => {
        const existingItem = acc.find((i) => i.item_code === item.item_code);
        
        if (!existingItem) {
          if (item.quantity !== 1000000 && item.quantity > 0) {
            const totalStockQuantity = item?.stocks?.reduce(
              (sum, stock) => sum + stock?.quantity,
              0
            );
            acc.push({ ...item, quantityUpdated: false, actualQuantity: totalStockQuantity, diffrence_discount: 0, quantity: 1, item_modifier: [], stock_ids: [] });
          } else {
            acc.push({ ...item, quantityUpdated: false, actualQuantity: item.quantity, quantity: 1, diffrence_discount: 0, item_modifier: [] });
          }
        }
        return acc;
      }, [...items]);
      setItems(updatedItems);
    }
  }, [selectedItems]);

  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = [...items];
    const itemActualQuantity = updatedItems[index].actualQuantity;
    const clampedQuantity = Math.max(1, Math.min(newQuantity, itemActualQuantity));
    if (itemActualQuantity !== 1000000 && itemActualQuantity > 0){
      const { salesPrice, saleAmount, diffrenceDiscount, stockIds } = calculateSalesData(updatedItems[index], clampedQuantity);
      updatedItems[index].sales_price = salesPrice;
      updatedItems[index].sale_amount = saleAmount;
      updatedItems[index].diffrence_discount = diffrenceDiscount;
      if (!Array.isArray(updatedItems[index].stock_ids)) {
        updatedItems[index].stock_ids = [];
      }
      updatedItems[index].stock_ids = [...new Set([...updatedItems[index].stock_ids, ...stockIds])];
    }
    updatedItems[index].quantity = clampedQuantity;
    updatedItems[index].quantityUpdated = true;
    setItems(updatedItems);
    updateItems(updatedItems);
  };

  function calculateSalesData(item, requestedQty) {
    let remainingQty = requestedQty;
    let totalPrice = 0;
    let diffrenceDiscount = 0;
    let usedStocks = [];

    const sortedStocks = [...item?.stocks].sort((a, b) => a.sale_price - b.sale_price);

    for (let stock of sortedStocks) {
      if (remainingQty <= 0) break;
      const applicableQty = Math.min(remainingQty, stock.quantity);
      totalPrice += applicableQty * stock.sale_price;
      usedStocks.push({id: stock.id, price: stock.sale_price, qty: applicableQty });

      remainingQty -= applicableQty;
    }
    const stockIds = usedStocks.map(stock => stock.id);
    const salesPrice = usedStocks[usedStocks.length - 1].price;
    diffrenceDiscount = salesPrice * requestedQty - totalPrice;
    // const saleAmount = salesPrice - (item.tax_amount + item.discount_amount)
    const saleAmount = item.is_tax_inclusive ? (salesPrice - (item.tax_amount + item.discount_amount)) : (salesPrice - item.discount_amount);
    return {
      salesPrice,
      saleAmount,
      diffrenceDiscount,
      stockIds
    };
  }

  const handleDeleteItem = (index) => {
    const itemToDelete = items[index];
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
    deletedItems(itemToDelete);
    updateItems(updatedItems);
  };

  const formatPrice = (price) => {
    return price.toFixed(2);
  };

  const handleSelectedModifier = (value) => {
    const updatedItems = [...items];
    if (!Array.isArray(updatedItems[currentItemIndex].item_modifier)) {
      updatedItems[currentItemIndex].item_modifier = [];
    }
    updatedItems[currentItemIndex].item_modifier = [...new Set([...updatedItems[currentItemIndex].item_modifier, ...value])];
    setItems(updatedItems);
    updateItems(updatedItems);
    setSelectedModifiers(updatedItems[currentItemIndex].item_modifier);
  };

  const handleRemoveModifier = (itemIndex, modifierIndex) => {
    const updatedItems = [...items];
    updatedItems[itemIndex].item_modifier.splice(modifierIndex, 1);
    setItems(updatedItems);
    updateItems(updatedItems);
    setSelectedModifiers(updatedItems[itemIndex].item_modifier);
  };

  const renderModifiers = (modifiers, itemIndex) => {
    if (!Array.isArray(modifiers) || modifiers.length === 0) {
      return <span className="item-modifier-each-button">+</span>;
    }
    const uniqueModifiers = [...new Set(modifiers.map(modifier => modifier.modifier))];

    return uniqueModifiers.map((modifier, index) => (
      <span key={index} className="item-modifier-each-button">
        {modifier}
        <span className="item-modifier-option-button" onClick={(e) => {
          e.stopPropagation();
          handleRemoveModifier(itemIndex, index);
        }}>X</span>
      </span>
    ));
  };

  const renderItemData = () => {
    return items.map((item, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td onClick={() => openItemModifierPopup(index)}>
          <span className="item-modifier-selected-button-form">
            {Array.isArray(item.item_modifier) && item.item_modifier.length > 0 ? renderModifiers(item.item_modifier, index) : <FontAwesomeIcon icon={faSquarePlus} />
            }
          </span>
        </td>
        <td>{item.item_name}</td>
        <td className="quantity-container-kot-1">
          <div className="quantity-control">
            <button
              className="quantity-button-kot-1"
              onClick={() => handleQuantityChange(index, item.quantity - 1)}
              disabled={item.quantity <= 1}
            >
              <FontAwesomeIcon icon={faSquareMinus} />
            </button>
            <span className="quantity-number-kot">{item.quantity}</span>
            <button
              className="quantity-button-kot-1"
              onClick={() => handleQuantityChange(index, item.quantity + 1)}
              disabled={item.quantity >= item.actualQuantity}
            >
              <FontAwesomeIcon icon={faSquarePlus} />
            </button>
          </div>
        </td>
        <td>{formatPrice(item.sales_price)}</td>
        <td>{(item.actualQuantity !== 1000000 || item.actualQuantity !== -1 || item.actualQuantity > 0) ? formatPrice(item.quantity * item.sale_amount) : formatPrice((item.quantity * item.sale_amount) - item?.diffrence_discount)}</td>
        <td>
          <a className="deletebtn" onClick={() => handleDeleteItem(index)}>
            <BsFillTrashFill />
          </a>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <div className="table-container-kot">
        <table className="table">
          <thead className="sticky-header">
            <tr>
              <th scope="col" className="centered column-width-kot-1">#</th>
              <th scope="col" className="centered column-width-kot-2">{t("Modifier")}</th>
              <th scope="col" className="centered column-width-kot-3">{t("Item Name")}</th>
              <th scope="col" className="centered column-width-kot-3">{t("Quantity")}</th>
              <th scope="col" className="centered column-width-kot-5">{t("Rate")}</th>
              <th scope="col" className="centered column-width-kot-5">{t("Amount")}</th>
              <th scope="col" className="centered column-width-kot-6">{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {renderItemData()}
          </tbody>
        </table>
      </div>
      <div className="kot-item-quantity">
        <span>
          {t("Total Items")}: {items?.length}
        </span>
        <span>
          {t("Sub Total")}: {totalPrice}
        </span>
      </div>

      {isItemModifierPopup && (
        <div className="kot-table-popup-show">
          <ItemModifier selectedModifiers={selectedModifiers} addModifier={handleSelectedModifier} onClose={closeItemModifierPopup} />
        </div>
      )}
    </>
  );
};

export default KotItemTable;
