import React, { useState } from "react";
import { ItemSettings } from "../../components/ItemSettings/ItemSettings";
import Copyrights from "../../components/Copyrights";

function ItemSetting() {

  const [config, setConfig] = useState({  
    barcode_scan: false,
    stock_maintainance: false,
    show_low_stock_dialog: false,
    item_category: false,
    send_transaction_update_sms: false,
    enable_item: false,
    mrp_enable: false,
    mrp: "",
    imei_enable: false,
    imei: "",
    batch_enable: false,
    batch: "",
    expiry_date_enable: false,
    expiry_date: "",
    mfg_date_enable: false,
    mfg_date: "",
    model_enable: false,
    model: "",
    size_enable: false,
    size: "",
    configurable_type: "",
    configurable_id: "",
  });

  const handleChange = (e) => {
    const { id, type, checked, value } = e.target;
    setConfig((prevState) => ({
      ...prevState,
      // Check if the input type is checkbox, use 'checked' value; otherwise, use 'value'
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  // const handleSave = async () => {
  //   const token = getToken();
  //   if (!token) {
  //     console.error("No token found, unable to make authorized request");
  //     return;
  //   }
  //   try {
  //     const response = await axios.post(
  //       `${BASE_URL}/v1/setting/settings/item_config_create`,
  //       {
  //         item_config: config,
  //       },
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );
  //     console.log("API Response:", response.data);
  //   } catch (error) {
  //     console.error("Error in API call:", error.response || error.message);
  //   }
  // };

  return (
    <>
      <div className="content">
        <ItemSettings config={config} onChange={handleChange} />
        <Copyrights />
      </div>
    </>
  );
}

export default ItemSetting;

