import React, {useState} from "react";
import "./PlatformDashboard.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping, faCartShopping, faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import OnlinePlatformMenu from "./PlatformMenuList/OnlinePlatformMenu";

function PlatformDashboard() {
  const [isOnlineOrderDropdownOpen, setIsOnlineOrderDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOnlineOrderDropdownOpen(true);
  };

  const handleClose = () => {
    setIsOnlineOrderDropdownOpen(false);
  };

  return(
  <>
    <div className="dashboard-platform-main-container">
      <div className="dashboard-platform-header-container">
        <span className="dash-platform-title">Restaurant Dashboard</span>
        {/* <span className="dash-platform-options">&#x22EE</span> */}
        <button
            className="online-order-three-dots-button"
            onClick={toggleDropdown}
          >
            &#x22EE;
          </button>
      </div>
      <div className="dashboard-platform-sub-container">
        <div className="platform-card">
            <div className="platform-card-title-div">
              <span className="platform-card-title">TOTAL SALES</span>
          </div>
          <div className="platform-card-symbol-value-grp">
            <span className="platform-card-symbol">
              <FontAwesomeIcon icon={faIndianRupeeSign} />
            </span>
            <span className="platform-card-value-price">456678.00</span>
          </div>
        </div>
        <div className="platform-card">
          <div className="platform-card-title-div">
            <span className="platform-card-title">TOTAL TAX</span>
          </div>
          <div className="platform-card-symbol-value-grp">
            <span className="platform-card-symbol">
              <FontAwesomeIcon icon={faIndianRupeeSign} />
            </span>
            <span className="platform-card-value-price">456678.00</span>
          </div>
        </div>
        <div className="platform-card">
          <div className="platform-card-title-div">
            <span className="platform-card-title">TOTAL ORDERS</span>
          </div>
          <div className="platform-card-symbol-value-grp">
            <span className="platform-card-symbol">
              <FontAwesomeIcon icon={faCartShopping} />
            </span>
            <span className="platform-card-value">456678.00</span>
          </div>
        </div>
        <div className="platform-card">
          <div className="platform-card-title-div">
            <span className="platform-card-title">CANCELLED ORDERS</span>
          </div>
          <div className="platform-card-symbol-value-grp">
            <span className="platform-card-symbol-cancel">
              <FontAwesomeIcon icon={faCartShopping} />
            </span>
            <span className="platform-card-cacel-value">456678.00</span>
          </div>
        </div>
        <div className="platform-card">
          <div className="platform-card-title-div">
            <img
              src={require("../../../../Assets/Images/swiggy.png")}
              alt="platform-logo"
              className="platform-logo-img"
            />
            <span className="platform-card-title">SWIGGY ORDERS</span>
          </div>
          <div className="platform-card-symbol-value-grp">
            <span className="platform-card-symbol">
              <FontAwesomeIcon icon={faBagShopping} />
            </span>
            <span className="platform-card-value">456678.00</span>
          </div>
        </div>
        <div className="platform-card">
          <div className="platform-card-title-div">
            <span className="platform-card-title">
            <img
              src={require("../../../../Assets/Images/zomato.png")}
              alt="platform-logo"
              className="platform-logo-img"
            />
            ZOMATO ORDERS</span>
          </div>
          <div className="platform-card-symbol-value-grp">
            <span className="platform-card-symbol">
              <FontAwesomeIcon icon={faBagShopping} />
            </span>
            <span className="platform-card-value">456678.00</span>
          </div>
        </div>
        <div className="platform-card">
          <div className="platform-card-title-div">
            <span className="platform-card-title">
            <img
              src={require("../../../../Assets/Images/ubereates.png")}
              alt="platform-logo"
              className="platform-logo-img"
            />
            UBEREATS ORDERS</span>
          </div>
          <div className="platform-card-symbol-value-grp">
            <span className="platform-card-symbol">
              <FontAwesomeIcon icon={faBagShopping} /> 
            </span>
            <span className="platform-card-value">456678.00</span>
          </div>
        </div>
        <div className="platform-card">
          <div className="platform-card-title-div">
            <span className="platform-card-title">
            <img
              src={require("../../../../Assets/Images/pandas.png")}
              alt="platform-logo"
              className="platform-logo-img"
            />
            FOODPANDA ORDERS</span>
          </div>
          <div className="platform-card-symbol-value-grp">
            <span className="platform-card-symbol">
              <FontAwesomeIcon icon={faBagShopping} />
            </span>
            <span className="platform-card-value">456678.00</span>
          </div>
        </div>
      </div>
    </div>

    {isOnlineOrderDropdownOpen && (
      <div className="platform-online-option-list">
        <OnlinePlatformMenu onClose={handleClose}></OnlinePlatformMenu>
      </div>
    )}
  </>
  );
}

export default PlatformDashboard;
