import secureLocalStorage from "react-secure-storage";

// Save token to AsyncStorage
const saveToken = (token) => {
  try {
    secureLocalStorage.setItem('token', token);
  } catch (error) {
    console.error('Error saving token:', error);
  }
};

// Retrieve token from AsyncStorage
const getToken = () => {
  try {
    const token = secureLocalStorage.getItem('token');
    if (token !== null) {
      return token;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error retrieving token:', error);
    return null;
  }
};

// Remove token from AsyncStorage
const removeToken = () => {
  try {
    const token = secureLocalStorage.getItem('token');
    const token1 = secureLocalStorage.removeItem(token);
    saveToken(token1);
  } catch (error) {
    console.error('Error removing token:', error);
  }
};

export {
  saveToken,
  getToken,
  removeToken
};
