import React, { useState, useEffect } from "react";
import Copyrights from "../components/Copyrights";
import { BASE_URL, NO_RECORD_ERROR, WENTWRONG } from "../Url/constants";
import ErrorNotification from "../Notification/ErrorNotification";
import '../components/CreateCustomer.css'
import { BsEye, BsFillTrashFill, BsPencilSquare } from "react-icons/bs";
import CustomTable from "../components/Common/CustomTable";
import { Link, useNavigate } from "react-router-dom";
import ConfirmPopup from "../components/ConfirmPop";
import Search from "../components/Search/Search";
import EntriesPerPageDropdown from "../components/Common/EntriesPerPageDropdown";
import PaginationControls from "../components/Common/PaginationControls";
import { sendRequest } from "../ApiRequest";
import { useTranslation } from "react-i18next";
import { current_user } from "../Authentication/AuthenticationContext";
import successNotification from "../Notification/SuccessNotification";

function Suppliers() {
  const [user, setUser] = useState(null);
  const [getSupplierData, setSupplierData] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setrecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const startIndex = (currentPage - 1) * recordPerPage + 1;
  const endIndex = Math.min(currentPage * recordPerPage, totalRecords);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    fetchSupplier();
  }, [recordPerPage, searchQuery])

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setUser(currentUser);
    };
    fetchUser();
  }, []);

  const fetchSupplier = async (pageNumber = 1) => {
    try {
      let request_url = `${BASE_URL}/v1/onboarding/suppliers?&page=${pageNumber}&per_page=${recordPerPage}`;
      if (searchQuery) {
        request_url += `&query=${searchQuery}`;
      }
      const response = await sendRequest('GET', request_url, null);
      if (response.status === 200) {
        setSupplierData(response.data.data);
        setTotalPages(response.data.total_pages);
        setTotalRecords(response.data.total_count);
      } else {
        ErrorNotification(NO_RECORD_ERROR('supplier'));
        return;
      }
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error))
    }
  };

  const handleUpdateButtonClick = (supplier) => {
    setSelectedSupplier(supplier);
    navigate(`/update-supplier/${supplier.id}`, { state: supplier });
  };

  const handleShowButtonClick = (supplier) => {
    setSelectedSupplier(supplier);
    navigate(`/show-supplier/${supplier.id}`);
  }

  const handleDeleteButtonClick = (supplier) => {
    setSelectedSupplier(supplier)
    setShowPopup(true);
  }

  const handleConfirmDelete = async () => {
    if (selectedSupplier) {
      const response = await sendRequest('DELETE', `${BASE_URL}/v1/onboarding/suppliers/${selectedSupplier.id}`);
      if (response.status === 200) {
        successNotification(response.data.message)
        const updatedSuppliers = getSupplierData?.filter(sup => sup.id !== selectedSupplier.id);
        setSupplierData(updatedSuppliers);
        setShowPopup(false);
        fetchSupplier(currentPage);
      } else {
        ErrorNotification(WENTWRONG);
      }
    }
    setShowPopup(false);
  }

  const handleSearch = (e) => {
    if (e.length >= 2) {
      setSearchQuery(e);
    } else {
      setSearchQuery('');
      setCurrentPage(1);
    }
  }

  const handleCancelDelete = () => {
    setSelectedSupplier(null);
    setShowPopup(false);
  }

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setrecordPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = async (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      await fetchSupplier(pageNumber);
    }
  };

  return (
    <>
      <div className="content customer-create-tab">
        {user?.user_detail?.role?.name !== "staff" && (
          <div className="button-container">
            <Link to="/add-supplier" className="btn btn-primary">
              + {t("Add Supplier")}
            </Link>
          </div>
        )}

        <>
          <div className="row">
            <div className="col-md-12"><h3 className="title">{t("Suppliers List")} ({totalRecords})</h3></div>
            <div className="pagination-dropdown">
              <div className="pagination-controls">
                <EntriesPerPageDropdown recordPerPage={recordPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
              </div>
              <div className="text-right" value={searchQuery} onChange={e => handleSearch(e.target.value)}><Search placeholder={t("Search Suppliers ...")} /></div>
            </div>
          </div>
          <div className="table-view suppliers-table cust-common-table">
            <CustomTable
              col_names={['S. No.', 'First Name', 'Last Name', 'Email ID', 'Phone No', 'Supplier Company', 'Company Address', 'Actions']}
              data={getSupplierData.map((supplier, index) => ({
                'S. No.': startIndex + index,
                'First Name': supplier.first_name,
                'Last Name': supplier.last_name,
                'Email ID': <a href={`mailto:${supplier.email}`}>{supplier.email}</a>,
                'Phone No': supplier.phone_number,
                'Supplier Company': supplier.supp_company,
                'Company Address': supplier.com_address,
                'Actions': <div className="col-md-12">
                  <span>
                    {user?.user_detail?.role?.name !== "staff" && <a className="icon-link" onClick={() => handleUpdateButtonClick(supplier)}><BsPencilSquare /></a>}
                    <a className="show-icon" onClick={() => handleShowButtonClick(supplier)}><BsEye /></a>
                    {user?.user_detail?.role?.name !== "staff" && <a className="deletebtn delete-icon" onClick={() => handleDeleteButtonClick(supplier)}>
                      <BsFillTrashFill />
                    </a>}
                  </span>
                </div>
              }))} />
            {getSupplierData.length === 0 && searchQuery.length > 1 && (
              <div className="no-report-data-message">{t("No match found")}</div>
            )}
            {getSupplierData.length === 0 && searchQuery.length === 0 && (
              <div className="no-report-data-message">{t("No records found")}</div>
            )}
          </div>
          {totalRecords >= recordPerPage && (
            <div>
              <div className="report-pagination-info">
                <div className="report-pagination-container">
                  <PaginationControls
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                    totalRecords={totalRecords}
                    startIndex={startIndex}
                    endIndex={endIndex}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      </div>
      <Copyrights />
      {showPopup && (
        <ConfirmPopup
          title="Confirm Delete"
          message={`Are you sure you want to delete ?`}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </>
  )
}
export default Suppliers;

