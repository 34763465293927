import React from "react";
import './RestourentBillings.css'

const GroupCategory = ({ category, isActive }) => {
  return (
    <div>
      <div className={`card-content-kot ${category.id === isActive ? 'active' : ''}`}>
        <span className="cat-font-size">{category.name}</span>
      </div>
    </div>
  );
};

export default GroupCategory;
