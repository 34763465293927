import React, { useEffect, useRef, useState } from "react";
import "./DebitNote.css"
import NewDebitNote from "./NewDebitNote";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import PaginationControls from "../../Common/PaginationControls";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";

function DebitNote() {

  const [visibleDebitColumns, setVisibleDebitColumns] = useState({
    refNo: true,
    dueDays: true,
    status: true,
    dueAmount: true,
  });
  const [debitNote, setDebitNote] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedDebitNote, setSelectedDebitNote] = useState("");
  const [isShowDebitColumnFilter, setShowDebitColumnFilter] = useState(false);
  const [tempVisibleColumns, setTempVisibleColumns] = useState({ ...visibleDebitColumns });
  const [showDebitIconAction, setShowDebitDownIconAction] = useState(false);
  const [isOpenDebitPopup, setOpenDebitPopup] = useState(false);
  const [saleDebitNoteCopy, setSaleDeditNoteCopy] = useState("");
  const [saleDeditNoteUpdated, setSaleDeditNoteUpdated] = useState(false);
  const [actionDropdownPosition, setActionDropdownPosition] = useState({ top: 0, left: 0 });


  const handleDebitColumnFilter = () => {
    setShowDebitColumnFilter(true);
  }

  const handleCheckboxDebitChange = (column) => {
    setTempVisibleColumns((prevState) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const handleApplyDebitFilter = () => {
    setVisibleDebitColumns(tempVisibleColumns)
    setShowDebitColumnFilter(false);
  }

  const handleDebitDownIconAction = (debitNote, event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setActionDropdownPosition({
      top: rect.bottom + window.scrollY,
    });
    setSelectedDebitNote(debitNote)
    setShowDebitDownIconAction(true);
  }

  const handleDebitNote = () => {
    setOpenDebitPopup(true);
  }

  const closeDebitNotePopup = () => {
    setOpenDebitPopup(false);
    setSaleDeditNoteCopy("");
    setSaleDeditNoteUpdated(false);
  }

  const columnDebitFilterRef = useRef(null);
  const columnDebitActionFilterRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnDebitFilterRef.current &&
        !columnDebitFilterRef.current.contains(event.target)
      ) {
        setShowDebitColumnFilter(false);
      }
    };

    if (isShowDebitColumnFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isShowDebitColumnFilter]);


  useEffect(() => {
    const handleDownClickOutside = (event) => {
      if (
        columnDebitActionFilterRef.current &&
        !columnDebitActionFilterRef.current.contains(event.target)
      ) {
        setShowDebitDownIconAction(false);
      }
    };
    if (showDebitIconAction) {
      document.addEventListener("mousedown", handleDownClickOutside);
    } else {
      document.removeEventListener("mousedown", handleDownClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleDownClickOutside);
    };
  }, [showDebitIconAction]);

  const fetchDebitNote = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/transactions/entries?type=debit_note&page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setDebitNote(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching debit note:", error);
    }
  };

  const handleDebitNoteDelete = async (debitId) => {
    if (debitId) {
      try {
        const response = await sendRequest("DELETE", `${BASE_URL}/v1/transactions/entries/${debitId}?type=debit_note`);
        if (response.status === 200) {
          console.log("Debit Note deleted successfully!");
          fetchDebitNote(currentPage, recordsPerPage);
          setShowDebitDownIconAction(false);
        } else {
          console.log("Failed to delete the debit note. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting the debit note:", error);
      }
    }
  };

  useEffect(() => {
    fetchDebitNote(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const handleSaleDebitNoteCopy = async (saleDebitNoteCopyId, updated) => {
    if (updated) {
      setSaleDeditNoteUpdated(updated);
    }
    if (saleDebitNoteCopyId) {
      try {
        const response = await sendRequest("GET", `${BASE_URL}/v1/transactions/entries/${saleDebitNoteCopyId}?type=debit_note`);
        setSaleDeditNoteCopy(response.data.entry);
        setOpenDebitPopup(true);
      } catch (error) {
        console.error("Error copy the sale credit note:", error);
        console.log("An error occurred while deleting the copy sale credit note.");
      }
    }
  };

  const handleDebitNoteCreated = (value) => {
    setDebitNote(prevDebitNotes => [...prevDebitNotes, ...value]);
    setTotalRecords(prevTotalRecords => prevTotalRecords + 1);
  }

  return (
    <>
      <div className="debit-note-main-container">
        <div className="debit-note-container-first-div">
          <div className="debit-note-first-left">
            <span className="all-debit-note-show">All Debit Note({totalRecords})</span>
          </div>
          <div className="debit-note-first-right">
            <button
              className="debit-note-first-right-btn"
              onClick={handleDebitNote}
            >
              New Debit Note
              {/* <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span> */}
            </button>
          </div>
        </div>

        <div className="debit-note-container-second-div">
          <div className="debit-note-second-part-div">
            <EntriesPerPageDropdown recordPerPage={recordsPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
          </div>
          <div className="debit-note-seond-icon-part">
            {/* <div className="debit-note-second-drop-first"> */}
            {/* <div className="debit-note-turn-icon-grp">
                <span className="debit-note-turn-right-icon">
                  <FontAwesomeIcon icon={faArrowsTurnRight} />
                </span>
              </div> */}
            {/* <span className="debit-note-icon-between"></span> */}
            {/* <div className="debit-note-down-icon-grp" >
                <span className="debit-note-caret-down-icon">
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </div> */}
            {/* </div> */}
            {/* <div className="debit-note-second-filter" >
              <span className="debit-note-filter-icon">
                <FontAwesomeIcon icon={faFilter} />
              </span>
            </div> */}
            <div
              className="debit-note-second-three-dot-group"
              onClick={handleDebitColumnFilter}
            >
              <span className="debit-note-ellips-icon">
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </span>
            </div>
          </div>
        </div>

        <div className="debit-note-table-group">
          <table className="debit-note-table">
            <thead>
              <tr className="debit-note-thead-row">
                <th className="debit-note-th">Date</th>
                <th className="debit-note-th">Debit Note No</th>
                <th className="debit-note-th">Contact</th>
                {visibleDebitColumns.status && (
                  <th className="debit-note-th">Status</th>
                )}
                {visibleDebitColumns.refNo && (
                  <th className="debit-note-th">Ref.No</th>
                )}
                {/* {visibleDebitColumns.dueDays && (
                  <th className="debit-note-th">DueDays</th>
                )} */}
                <th className="debit-note-th">Amount</th>
                <th className="debit-note-th">Action</th>
              </tr>
            </thead>
            <tbody className="debit-note-table-body">
              {debitNote.map((row, index) => (
                <tr key={index} className="debit-note-tbody-row">
                  <td className="debit-note-td">{row.date}</td>
                  <td className="debit-note-td">{row.bill_number}</td>
                  <td className="debit-note-td">{row.customer.customer_name}</td>
                  {visibleDebitColumns.status && (
                    <td className="debit-note-td">{row.status}</td>
                  )}
                  {visibleDebitColumns.refNo && (
                    <td className="debit-note-td">{row.reference_no}</td>
                  )}
                  {/* {visibleDebitColumns.dueDays && (
                    <td className="debit-note-td">{row.due_days}</td>
                  )} */}
                  <td className="debit-note-td">{row.total_amount}</td>
                  <td className="debit-note-td">
                    <FontAwesomeIcon icon={faCaretDown} className="credit-action-down-icon"
                      onClick={(e) => handleDebitDownIconAction(row, e)} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}

        {debitNote.length === 0 && (
          <div className="debit-note-no-data-message">
            No quotations found.
          </div>
        )}

        {isShowDebitColumnFilter && (
          <div className="debit-note-column-filter-popup" ref={columnDebitFilterRef}>
            <form className="debit-note-column-filter-form">
              {Object.keys(tempVisibleColumns).map((column) => (
                <div key={column} className="debit-note--label-input-grp">
                  <label>
                    <input
                      type="checkbox"
                      className="debit-note--visible-column-input"
                      checked={tempVisibleColumns[column]}
                      onChange={() => handleCheckboxDebitChange(column)}
                    />
                    {column.charAt(0).toUpperCase() + column.slice(1)}
                  </label>
                </div>
              ))}
              <button type="button" onClick={handleApplyDebitFilter} className="debit-note-visible-box-btn">
                Apply
              </button>
            </form>
          </div>
        )}

        {showDebitIconAction && (
          <div className="debit-note-down-icon-action"
            ref={columnDebitActionFilterRef}
            style={{
              position: 'absolute',
              top: `${actionDropdownPosition.top}px`,
            }}
          >
            <ul className="debit-note-down-icon-action-ul">
              {/* <li className="debit-note-down-icon-action-li">Edit</li> */}
              <li className="credit-note-down-icon-action-li" onClick={() => handleSaleDebitNoteCopy(selectedDebitNote.id, true)}>Edit</li>
              <li className="debit-note-down-icon-action-li" onClick={() => handleDebitNoteDelete(selectedDebitNote.id)}>
                Delete
              </li>
              {/* <li className="debit-note-down-icon-action-li">Print</li>
              <li className="debit-note-down-icon-action-li">Send</li>
              <li className="debit-note-down-icon-action-li">Send SMS</li>
              <li className="debit-note-down-icon-action-li">Send WhatsApp</li> */}
              <li className="debit-note-down-icon-action-li" onClick={() => handleSaleDebitNoteCopy(selectedDebitNote.id)}>Copy</li>
            </ul>
          </div>
        )}

        {isOpenDebitPopup && (
          <div className="new-debit-note-popup-container">
            <NewDebitNote onClose={closeDebitNotePopup} saleDebitNoteCopy={saleDebitNoteCopy} isUpdated={saleDeditNoteUpdated} debitNoteCreated={handleDebitNoteCreated} />
          </div>
        )}
        <Copyrights />
      </div>
    </>
  );
}

export default DebitNote;
