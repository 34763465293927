import React, { useEffect, useRef, useState } from 'react';
import './DeliveryChallanLetterPad.css';
import { current_user } from '../../../Authentication/AuthenticationContext';
import { useReactToPrint } from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

function DeliveryChallanLetterPad({ saleDeliveryChallanPrint, onClose }) {
  const [isCompanydata, setCompanyData] = useState("");
  const [isBranchData, setBranchData] = useState("");
  const [isUserData, setUserData] = useState("");
  const deliveryPrint = saleDeliveryChallanPrint

  const renderTable = () => (
    <>
      <table className="delivery-letter-pad-invoice-table">
        <thead>
          <tr className='delivery-letter-pad-tab-head-row'>
            <th className='delivery-letter-pad-table-header-attribute'>SNo</th>
            <th className='delivery-letter-pad-table-header-attribute'>Item</th>
            <th className='delivery-letter-pad-table-header-attribute'>HSN</th>
            <th className='delivery-letter-pad-table-header-attribute'>Qty</th>
            <th className='delivery-letter-pad-table-header-attribute'>Unit</th>
            {/* <th className='delivery-letter-pad-table-header-attribute'>Rate</th>
            <th className='delivery-letter-pad-table-header-attribute'>Tax</th>
            <th className='delivery-letter-pad-table-header-attribute'>Amount</th> */}
          </tr>
        </thead>
        <tbody>
          {deliveryPrint.bill_items && deliveryPrint.bill_items.map((item, index) => (
            <tr key={index} className='delivery-letter-pad-table-body-row'>
              <td>{index + 1}</td>
              <td className='delivery-letter-pad-table-body-data'>{item?.name}</td>
              <td className='delivery-letter-pad-table-body-data'>{item?.hsn_number}</td>
              <td className='delivery-letter-pad-table-body-data'>{item?.quantity}</td>
              <td className='delivery-letter-pad-table-body-data'>{item?.unit?.unit_name}</td>
              {/* <td className='delivery-letter-pad-table-body-data'>{item?.sales_price.toFixed(2)}</td>
              <td className='delivery-letter-pad-table-body-data'>
                <div className='delivery-letter-pad-tax-grp'>
                  <span className='delivery-letter-pad-tax-label'>
                    {item?.tax_details?.length > 0
                      ? `${item.tax_details.join(", ")} (${item.tax_inclusive ? "Inc" : "Exc"})`
                      : ""}
                  </span>
                  <span>
                    {item?.item_total_tax.toFixed(2)}
                  </span>
                </div>
              </td>
              <td className='delivery-letter-pad-table-body-data'>{(item?.quantity * item?.sales_price).toFixed(2)}</td> */}
            </tr>
          ))}
          {/* <tr className='delivery-letter-pad-calculation-row'>
            <td colSpan={6} className='delivery-letter-pad-extra-row'>
              <span className='delivery-letter-pad-extra-row-data'>Sub Total</span>
            </td>
            <td></td>
            <td>{deliveryPrint?.sub_total.toFixed(2)}</td>
          </tr> */}
        </tbody>
        {/* <tfoot>
        <tr className='delivery-letter-pad-table-footer-div'>
          <td colSpan={4} rowSpan={5}></td>
          <td colSpan={3} rowSpan={5}>
            <div className="delivery-letter-pad-invoice-footer-data">
              <div className="delivery-footer-data-row">
                <span className="delivery-footer-label">Sub Total:</span>
                <span className="delivery-footer-value">{deliveryPrint?.sub_total.toFixed(2)}</span>
              </div>
              <div className="delivery-footer-data-row">
                <span className="delivery-footer-label">Discount:</span>
                <span className="delivery-footer-value">{deliveryPrint?.total_discount || '0.0'}</span>
              </div>
              {deliveryPrint.is_gst_enable && deliveryPrint.is_igst_enable ? (
                <div className="delivery-footer-data-row">
                  <span className="delivery-footer-label">IGST:</span>
                  <span className="delivery-footer-value">{deliveryPrint?.total_gst.toFixed(2) || '0.0'}</span>
                </div>
              ) : (
                <>  
                  <div className="delivery-footer-data-row">
                    <span className="delivery-footer-label">SGST:</span>
                    <span className="delivery-footer-value">{(deliveryPrint?.total_gst / 2).toFixed(2) || '0.0'}</span>
                  </div>
                  <div className="delivery-footer-data-row">
                    <span className="delivery-footer-label">CGST:</span>
                    <span className="delivery-footer-value">{(deliveryPrint?.total_gst / 2).toFixed(2) || '0.0'}</span>
                  </div>
                </>
              )}
              <div className="delivery-footer-data-row">
                <span className="delivery-footer-label">TOTAL:</span>
                <span className="delivery-footer-value">{deliveryPrint?.total_amount.toFixed(2) || '0.0'}</span>
              </div>
            </div>
          </td>

        </tr>
      </tfoot> */}
      </table>
      {/* <table className="delivery-letter-pad-footer-table">
        <tbody>
          <tr className='delivery-letter-pad-table-footer-div'>
            <td colSpan={3} rowSpan={5} className='delivery-letter-pad-table-footer-border'>
              <div className="delivery-letter-pad-footer-data">
                <div className="delivery-letter-pad-footer-data-row">
                  <span className="delivery-letter-pad-footer-label">Sub Total:</span>
                  <span className="delivery-letter-pad-footer-value">{deliveryPrint?.sub_total.toFixed(2)}</span>
                </div>
                <div className="delivery-letter-pad-footer-data-row">
                  <span className="delivery-letter-pad-footer-label">Discount:</span>
                  <span className="delivery-letter-pad-footer-value">{deliveryPrint?.total_discount || '0.00'}</span>
                </div>
                {deliveryPrint.is_gst_enable && deliveryPrint.is_igst_enable ? (
                  <div className="delivery-letter-pad-footer-data-row">
                    <span className="delivery-letter-pad-footer-label">IGST:</span>
                    <span className="delivery-letter-pad-footer-value">{deliveryPrint?.total_gst.toFixed(2) || '0.00'}</span>
                  </div>
                ) : (
                  <>
                    <div className="delivery-letter-pad-footer-data-row">
                      <span className="delivery-letter-pad-footer-label">SGST:</span>
                      <span className="delivery-letter-pad-footer-value">{(deliveryPrint?.total_gst / 2).toFixed(2) || '0.00'}</span>
                    </div>
                    <div className="delivery-letter-pad-footer-data-row">
                      <span className="delivery-letter-pad-footer-label">CGST:</span>
                      <span className="delivery-letter-pad-footer-value">{(deliveryPrint?.total_gst / 2).toFixed(2) || '0.00'}</span>
                    </div>
                  </>
                )}
                <div className="delivery-letter-pad-footer-data-row">
                  <span className="delivery-letter-pad-footer-label">TOTAL:</span>
                  <span className="delivery-letter-pad-footer-value">{deliveryPrint?.total_amount.toFixed(2) || '0.00'}</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table> */}
    </>
  );

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setCompanyData(currentUser.company);
      setBranchData(currentUser.company.branch_info);
      setUserData(currentUser.company.user_info);
    };
    fetchUser();
  }, []);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
    // documentTitle: 'Delivery Challan',
  });

  return (
    <div className="delivery-letter-pad-invoice-container" ref={componentRef}>
      <div className='delivery-challan-pad-button-group'>
        <button className='delivery-button-icon'><FontAwesomeIcon icon={faPrint} onClick={handlePrint} /></button>
        <button className="delivery-button-icon" onClick={onClose}>X</button>
      </div>
      <div className='delivery-letter-pad-com-info-container'>
        <div className='delivery-letter-img-grp'>
          <img src={isCompanydata?.logo_url} alt="Delivery Logo" className="delivery-letter-company-logo" />
        </div>
        <div className='delivery-letter-com-info-grp'>
          <div className='delivery-letter-com-grp'>
            <span className='delivery-letter-com-name-label'>{isCompanydata?.name}</span>
          </div>
          <div className='delivery-letter-branch-grp'>
            <span className='delivery-letter-branch-label'>{isBranchData?.address}</span>
          </div>
          <div className='delivery-letter-header-com-data-grp'>
            <span className='delivery-letter-com-data-label'>Phone No. : {isUserData?.phone}</span>
            <span className='delivery-letter-com-data-label'>Email : {isUserData?.email}</span>
            <span className='delivery-letter-com-data-label'>GSTIN/UIN : {isCompanydata?.gst_number}</span>
          </div>
        </div>
      </div>
      <div className='delivery-letter-pad-title-grp'>
        <span className='delivery-letter-pad-title'>Delivery Challan</span>
      </div>
      <div className="delivery-horizontal-line"></div>
      <div className='delivery-letter-pad-invoice-billing-address'>
        {/* <div className='delivery-letter-pad-invoice-bill-div'>
          <span>Issued</span>
          <span className='delivery-letter-pad-bill-issued-data'>{isCompanydata.name}</span>
          <span className='delivery-letter-pad-bill-issued-data'>{isBranchData.address}</span>
          <span className='delivery-letter-pad-bill-issued-data'>Phone : {isUserData.phone}</span>
          <span className='delivery-letter-pad-bill-issued-data'>Email : {isUserData.email}</span>
          <span className='delivery-letter-pad-bill-issued-data'>GSTIN : {isCompanydata.gst_number}</span>
          <span className='delivery-letter-pad-bill-issued-data'>POS : Indore</span>
        </div> */}
        <div className='delivery-letter-pad-invoice-bill-div'>
          <span className='delivery-letter-pad-issue-title'>Issued</span>
          <span className='delivery-letter-pad-bill-issued-data'> {deliveryPrint?.customer?.company_name ||
            (deliveryPrint?.customer?.first_name && deliveryPrint?.customer?.last_name
              ? `${deliveryPrint?.customer?.first_name} ${deliveryPrint?.customer?.last_name}`
              : '')
          }</span>
          <span className='delivery-letter-pad-bill-issued-data'>{deliveryPrint?.customer?.billing_address_one ||
            deliveryPrint?.customer?.billing_address_two}</span>
          <span className='delivery-letter-pad-bill-issued-data'>Phone : {deliveryPrint?.customer?.mobile_number}</span>
          {/* <span className='delivery-letter-pad-bill-issued-data'>Email : {deliveryPrint?.customer?.email}</span> */}
          <span className='delivery-letter-pad-bill-issued-data'>GSTIN : {deliveryPrint.customer?.gst_number}</span>
          <span className='delivery-letter-pad-bill-issued-data'>POS : Indore</span>
        </div>
        <div className='delivery-letter-pad-invoice-bill-div'>
          <span className='delivery-letter-pad-bill-issued-data'>Delivey Challan No: {deliveryPrint?.bill_number}</span>
          <span className='delivery-letter-pad-bill-issued-data'>Date: {deliveryPrint?.date
            ? new Date(deliveryPrint.date).toLocaleDateString('en-GB')
            : ''}</span>
          <span className='delivery-letter-pad-bill-issued-data'>Challan Type: {deliveryPrint?.type}</span>
        </div>
      </div>
      <div className="delivery-horizontal-line"></div>
      <div className='delivery-order-grp'>
        <div className='delivery-order-no'>
          <span className='delivery-order-label'>Buyer's Order No#:</span>
          <span className='delivery-order-no-value'>{deliveryPrint?.order_no}</span>
        </div>
        <div className='delivery-order-date'>
          <span className='delivery-order-label'>Buyer's Order Date:</span>
          <span className='delivery-order-date-value'>{deliveryPrint?.order_date}</span>
        </div>
      </div>
      <div className="delivery-horizontal-line"></div>
      {renderTable()}
      <div className="delivery-letter-pad-signature-section">
        <span className='delivery-letter-pad-footer-title'>For {isCompanydata?.name}</span>
        <span className='delivery-letter-pad-footer-title'>Authorized Signature</span>
      </div>
    </div>
  );
};

export default DeliveryChallanLetterPad;
