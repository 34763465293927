import React, { useEffect, useRef } from "react";
import { useState } from "react";
import BillingTableOne from "../components/BillingTableOne";
import GetCustomer from "../components/GetCustomer";
import Copyrights from "../components/Copyrights";
import RecentTransiction from "../components/Billing/BillingAccordion";
import BlankTable from "../components/Billing/BlankTable";
import SearchableDropdown from "../components/ItemSettings/SearchableDropdown";
import { BASE_URL, ERROR_MESSAGE } from "../Url/constants";
import { CloseOutlined } from "@ant-design/icons";
import "./Billing.css";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import { sendRequest } from "../ApiRequest";
import BillingPreview from "./BillingPreview";
import BillingTemplateOne from "../components/TemplateSettings/BillingTemplatePreview/BillingTemplateOne";
import BillingTemplateTwo from "../components/TemplateSettings/BillingTemplatePreview/BillingTemplateTwo";
import BillingTemplateThree from "../components/TemplateSettings/BillingTemplatePreview/BillingTemplateThree";
import BillingTemplateFour from "../components/TemplateSettings/BillingTemplatePreview/BillingTemplateFour";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";
import PrintableInvoice from "../components/PrintableInvoice";
import PartyBilling from "../components/Party/PartyBilling";
import { current_user } from "../Authentication/AuthenticationContext";
import DoctorBilling from "../components/Party/DoctorBilling";
import { FaUserTie } from "react-icons/fa";
import Select from "react-select";

import CommonToggleButton from "../components/Common/CommonToggleButton";

function Billing() {
  const [activeTab, setActiveTab] = useState(1);
  const [billingForms, setBillingForms] = useState([]);
  const [error] = useState(null);
  const [billNumber, setBillNumber] = useState(1);
  const [updateTransactions, setUpdateTransactions] = useState(false);
  const [BillingPreviewData, setBillingPreviewData] = useState([]);
  const [ShowBillingPreview, setShowBillingPreview] = useState(false);
  const [manualDiscount, setManualDiscount] = useState();
  const [currentFromIndex, setcurrentFormIndex] = useState();
  const [isPreviewEnabled, setIspreviewEnabled] = useState(false);
  const [templateId, setTemplateId] = useState();
  const [billSettings, setBillSettings] = useState([]);
  const [enableGST, setEnableGST] = useState(false);
  const { t } = useTranslation();
  const [hiddenForm, setHiddenForm] = useState(0);
  const [autoFocusInput, setAutoFocusInput] = useState(false);
  const [isPartyEnabled, setIsPartyEnabled] = useState(false);
  const [isPharmaceutical, setIsPharmaceutical] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(" ");
  const [discountType, setDiscountType] = useState("fixed");
  const [isChecked, setIsChecked] = useState(false);
  const [selectedSalesRepresentatives, setSelectedSalesRepresentatives] =
    useState([]);
  const selectRef = useRef();
  const [reminderDate, setReminderDate] = useState("");
  const [isBilling, setIsBilling] = useState(false);
  const [showPartialPaymentPopup, setShowPartialPaymentPopup] = useState(false);

  const handleTabClick = (tabIndex) => {
    if (tabIndex === activeTab) {
      setActiveTab(1);
      setIsDisabled(false);
    } else {
      setActiveTab(tabIndex);
    }
    setAutoFocusInput(false);
  };

  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [errors, setErrors] = useState({});
  const [staffList, setStaffList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [setError] = useState(null);

  const handleSalesRepChange = (selectedOptions) => {
    setSelectedSalesRepresentatives(
      selectedOptions.map((option) => option.value)
    );
  };

  const handleToggle = () => {
    if (!isChecked && staffList.length === 0) {
      ErrorNotification("No sales person found!", "Please add staff members")
      return; 
    }
    setIsChecked(!isChecked);
  };

  const handleIconClick = () => {
    setShow((prevShow) => !prevShow);
    if (!show) {
      setSelectedSalesRepresentatives([]);
    }
    setTimeout(() => {
      selectRef.current.focus();
    }, 0);
  };

  useEffect(() => {
    const fetchStaffList = async () => {
      try {
        const response = await sendRequest(
          "get",
          `${BASE_URL}/v1/staff_management/staffs?page=1&per_page=10&devise_type=web`
        );
        setStaffList(
          response.data.data.filter(
            (staff) => staff.role_details?.role_name === "sales_person"
          )
        );
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchStaffList();
  }, []);

  const handleClose = () => {
    setShow(false);
    setSelectedOption([]);
    setErrors({});
  };

  const staffOptions = staffList.map((staff) => ({
    value: staff.id,
    label: `${staff.first_name} ${staff.last_name}`,
  }));

  const handleReminderDateChange = (event) => {
    setReminderDate(event.target.value);
  };

  const fetchUser = async () => {
    try {
      const currentUser = await current_user();
      setIsPharmaceutical(
        currentUser.company.business_type === "Pharmaceutical"
      );
    } catch (error) {
      console.error("Error fetching user:", error);
      setIsPharmaceutical(false);
    }
  };

  const handleDoctorSelect = (doctor, formIndex) => {
    const updatedForms = [...billingForms];
    updatedForms[formIndex] = {
      ...updatedForms[formIndex],
      selectedDoctor: doctor,
    };
    setBillingForms(updatedForms);
  };

  useEffect(() => {
    const initializeUser = async () => {
      await fetchUser();
    };
    initializeUser();
  }, []);

  useEffect(() => {
    // Load isPartyEnabled from localStorage on component mount
    const savedIsPartyEnabled =
      localStorage.getItem("isPartyEnabled") === "true";
    setIsPartyEnabled(savedIsPartyEnabled);
  }, []);

  const handlePartyToggle = () => {
    const newIsPartyEnabled = !isPartyEnabled;
    setIsPartyEnabled(newIsPartyEnabled);
    // Save isPartyEnabled to localStorage
    localStorage.setItem("isPartyEnabled", newIsPartyEnabled.toString());

    // Update all forms with the new isPartyEnabled value
    setBillingForms((prevForms) =>
      prevForms.map((form) => ({
        ...form,
        isPartyEnabled: newIsPartyEnabled,
        selectedItems: form.selectedItems.map((item) => ({
          ...item,
          sales_price: newIsPartyEnabled
            ? item.wholesale_price
            : item.original_sales_price,
        })),
      }))
    );
  };

  const handleDiscountTypeChange = (event) => {
    const newDiscountType = event.target.value;
    setDiscountType(newDiscountType);

    setBillingForms((prevForms) =>
      prevForms.map((form, index) => {
        if (!manualDiscount || manualDiscount <= 0) {
          return {
            ...form,
            totalDiscountAmount: form.totalDiscountAmountCopy,
            totalAmount: form.totalAmountCopy,
          };
        }

        let discountValue = 0;

        if (newDiscountType === "percentage") {
          discountValue = (form.totalAmountCopy * manualDiscount) / 100;
        } else {
          discountValue = Number(manualDiscount);
        }

        if (discountValue > form.totalAmountCopy) {
          setErrorMessage("Discount cannot exceed the total amount");
          return form;
        }

        const totalDiscount = discountValue;
        const totalAmountAfterDiscount = Math.max(
          0,
          form.totalAmountCopy - totalDiscount
        );

        return {
          ...form,
          totalDiscountAmount: totalDiscount + form.totalDiscountAmountCopy,
          totalAmount: totalAmountAfterDiscount,
        };
      })
    );

    setErrorMessage("");
  };

  const handleApplyDiscount = (manualDiscount, formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      const form = updatedForms[formIndex];

      manualDiscount = Number(manualDiscount);
      if (isNaN(manualDiscount) || manualDiscount <= 0) {
        updatedForms[formIndex] = {
          ...form,
          totalDiscountAmount: form.totalDiscountAmountCopy,
          totalAmount: form.totalAmountCopy,
        };
        setErrorMessage("Please enter a valid discount.");
        return updatedForms;
      }

      let discountValue = 0;

      if (discountType === "percentage") {
        discountValue = (form.totalAmountCopy * manualDiscount) / 100;
      } else {
        discountValue = manualDiscount;
      }

      if (discountValue > form.totalAmountCopy) {
        setErrorMessage("Discount cannot exceed the total amount");
        return updatedForms;
      }

      const totalDiscount = discountValue;
      const totalAmountAfterDiscount = Math.max(
        0,
        form.totalAmountCopy - totalDiscount
      );

      updatedForms[formIndex] = {
        ...form,
        totalDiscountAmount: totalDiscount + form.totalDiscountAmountCopy,
        totalAmount: totalAmountAfterDiscount,
      };
      setErrorMessage("");
      return updatedForms;
    });
  };

  const handleDiscountChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) >= 0) {
      setManualDiscount(value);
    } else {
      setErrorMessage();
    }
  };

  const getBillingSettings = () => {
    fetchInitialBillNumber();
    getDefaultTemaplate();
    setIspreviewEnabled(false);
    fetchSettings();
    fetchGSTSetting();
  };

  useEffect(() => {
    const sendToAll = isCheckboxEnabled("send_to_all");
    if (sendToAll) {
      setBillingForms((prevForms) =>
        prevForms.map((form) => ({
          ...form,
          sendSMS: sendToAll,
          sendWhatsapp: sendToAll,
          sendEmail: sendToAll,
        }))
      );
    }
  }, [billSettings, enableGST]);

  const fetchGSTSetting = async () => {
    const url = `${BASE_URL}/v1/setting/settings/get_setting_status?name=GST`;
    try {
      const response = await sendRequest("GET", url);
      if (response.status === 200) {
        const gstSetting = response.data;
        if (gstSetting) {
          setEnableGST(gstSetting.status);
        }
      } else {
        console.error("Error fetching GST setting!", error);
      }
    } catch (error) {
      console.error("Error fetching GST setting!", error);
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/setting/settings/billing_settings`
      );
      setBillSettings(response.data.data);
      const previewSetting = response.data.data.find(
        (setting) => setting.name === "billing_preview"
      );
      if (previewSetting && previewSetting.billing_setting_config?.status) {
        setIspreviewEnabled(true);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const isCheckboxEnabled = (optionKey) => {
    const option = billSettings.find((setting) => setting.name === optionKey);
    return option && option.billing_setting_config
      ? option.billing_setting_config.status
      : false;
  };

  const getDefaultTemaplate = async () => {
    const response = await sendRequest(
      "GET",
      `${BASE_URL}/v1/template/templates/get_default_template`
    );
    if (response.status === 200) {
      setTemplateId(response?.data?.data?.id);
    }
  };

  const fetchInitialBillNumber = async () => {
    const response = await sendRequest(
      "GET",
      `${BASE_URL}/v1/billings/last_invoice_bill_number`
    );
    if (response.status === 200) {
      setBillNumber(response.data);
    }
  };

  const togglePartialPaymentPopup = () => {
    setShowPartialPaymentPopup(true);
  };

  const calculateTotal = (payments) =>
    Object.values(payments).reduce((acc, val) => acc + val, 0);

  const closePartialPaymentPopup = () => {
    setShowPartialPaymentPopup(false);
  };

  const calculateRemainingAmount = (payments, totalAmount) => {
    return Math.max(0, totalAmount - payments);
  };

  const handlePrintInvoice = async (
    formIndex,
    template = templateId ? templateId : 1
  ) => {
    const formData = billingForms[formIndex];
    if (formData) {
      const uniqueItems = [];
      const identifiers = [];
      const itemIds = new Set();

      formData.selectedItems.forEach((item) => {
        if (!item.quantityUpdated) {
          item.quantity = 1;
        }
        if (!itemIds.has(item.id)) {
          itemIds.add(item.id);
          uniqueItems.push({
            item_id: item.id,
            quantity: item.quantity,
            // item_total_discount: item?.discount_amount?.toFixed(2),
            item_total_discount: isPartyEnabled
              ? item?.wholesale_discount_amount
              : item?.discount_amount?.toFixed(2),
            // item_total_tax: item?.tax_amount?.toFixed(2),
            item_total_tax: isPartyEnabled
              ? item?.wholesale_tax_amount?.toFixed(2)
              : item?.tax_amount?.toFixed(2),
            item_total_amount: isPartyEnabled
              ? (
                  item?.editedWholesalePrice - item?.wholesale_discount_amount
                )?.toFixed(2)
              : item?.sale_amount?.toFixed(2),
            stock_diffrence_discount: item.diffrence_discount
              ? item.diffrence_discount.toFixed(2)
              : 0,
            // stock_ids: item.stock_ids,
            sales_person_id: item.selectedStaff,
            stock_ids: item.stockIds,
            stock_ids_jsonb: item.stockUsed,
          });
          if (item?.identifiers && item?.identifiers.length > 0) {
            identifiers.push({
              item_id: item?.id,
              identifier: item?.identifiers
                .map((identifier) => {
                  if (identifier?.selected_identifier) {
                    return `${identifier?.selected_identifier}`;
                  } else if (
                    item?.query &&
                    identifier?.identifier === item?.query &&
                    identifier?.item_id === item?.id
                  ) {
                    return `${identifier?.identifier}`;
                  }
                  return null;
                })
                .filter(Boolean)
                .join(", "),
            });
          }
        }
      });
      // const subTotal = formData.couponDiscount
      //   ? (formData.totalAmount - Number(formData.couponDiscount) - (formData.isDueAmount ? formData.dueAmount : 0)).toFixed(2)
      //   : (formData.totalAmount - (formData.isDueAmount ? formData.dueAmount : 0)).toFixed(2);
      const subTotal = formData.couponDiscount ? (formData?.totalAmount - Number(formData.couponDiscount)).toFixed(2) : formData?.totalAmount?.toFixed(2)
      if (subTotal <= 0 ) {
        ErrorNotification("total should be greater then 0");
        setIsDisabled(false);
        return;
      }
      let totalGst = 0
      let totalTax = 0
      if (enableGST) {
        totalGst = formData?.totalTaxAmount?.toFixed(2);
      } else {
        totalTax = formData?.totalTaxAmount?.toFixed(2);
      }
      const totalDiscount = formData?.couponDiscount
        ? (
            Number(formData?.couponDiscount) + formData?.totalDiscountAmount
          ).toFixed(2)
        : formData?.totalDiscountAmount?.toFixed(2);
      const billing_params = {
        bill: {
          customer_id: formData.selectedCustomer
            ? formData.selectedCustomer.id
            : null,
          // total_amount: (
          //   Number(subTotal) +
          //   Number(totalTax) +
          //   (enableGST ? Number(totalGst) : 0)
          // ).toFixed(2),
          total_amount: (
            Number(subTotal) + 
            (enableGST ? Number(totalGst) : Number(totalTax)) - 
            (formData?.due_amount || 0)
          ).toFixed(2),
          sub_total: subTotal,
          total_discount: totalDiscount,
          total_tax: totalTax,
          total_gst: totalGst,
          is_gst_enable: enableGST,
          sales_person_id: selectedSalesRepresentatives,
          is_igst_enable: isCheckboxEnabled("inter_state_GST") && enableGST,
          bill_number: billNumber + 1,
          serial_number: 1234578,
          status: formData?.isDueAmount ? 3 : 1,
          bill_type: 0,
          send_by_sms: formData.sendSMS,
          send_by_whatsapp: formData.sendWhatsapp,
          send_by_email: formData.sendEmail,
          bill_items_attributes: uniqueItems,
          payment_mode: isCheckboxEnabled("payment_mode")
            ? formData.paymentMode
              ? parseInt(formData.paymentMode)
              : ""
            : 0,
          payment_mode_json: formData?.partialPayment,
          due_amount: formData?.isDueAmount ? formData?.dueAmount : 0,
          print_qr: formData.qrCode,
          print_gst_no: formData.gstNumber,
          print_logo: formData.printLogo,
          template_id: template,
          coupon_id: formData.coupon_id ? formData.coupon_id : "",
          prescribed_by_id: formData.selectedDoctor
            ? formData.selectedDoctor.id
            : null,
          is_pharmaceutical: isPartyEnabled ? true : false,
          reminder_date: reminderDate,
        },
        is_sale: true,
        item_identifiers: identifiers,
      };
      await sendRequest("POST", `${BASE_URL}/v1/billings/bills`, billing_params)
        .then((response) => {
          if (response.status === 200) {
            setBillNumber(billNumber + 1);
            setUpdateTransactions(true);
            handleTabClose(formIndex);
            setIsDisabled(false);
            setIsPartyEnabled(false);
            localStorage.setItem("isPartyEnabled", "false");
            if (ShowBillingPreview) {
              setShowBillingPreview(false);
            }
            // handleBillPrinting(template, billing_params, (Number(formData.couponDiscount) + formData.totalDiscountAmount).toFixed(2))
            if (!isPreviewEnabled) {
              handleBillPrinting(response.data.data);
            }
          }
        })
        .catch((error) => {
          const msg = ERROR_MESSAGE("invoice");
          ErrorNotification(msg);
          setIsDisabled(false);
        });
    } else {
      setIsDisabled(false);
    }
  };

  const handleBillPrinting = async (invoiceData) => {
    try {
      const configResponse = await sendRequest(
        "GET",
        `${BASE_URL}/v1/template/templates/get_default_receipt`
      );
      const templateId = configResponse.data.data.id;
      const templateConfig = configResponse.data.data.template_options;

      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Bill</title>
          </head>
          <body>
            <div id="printable-bill"></div>
          </body>
        </html>
      `);
      printWindow.document.close();

      ReactDOM.render(
        <PrintableInvoice
          invoiceData={invoiceData}
          templateId={templateId}
          templateConfig={templateConfig}
        />,
        printWindow.document.getElementById("printable-bill")
      );

      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 500);
    } catch (error) {
      ErrorNotification("Failed to print bill:", error);
    }
  };

  const getBillingTemplate = (template, formData, discount) => {
    switch (template) {
      case 1:
        return (
          <BillingTemplateOne
            template={1}
            formData={formData}
            discount={discount}
            is_print={true}
          />
        );
      case 2:
        return (
          <BillingTemplateTwo
            template={2}
            formData={formData}
            discount={discount}
            is_print={true}
          />
        );
      case 3:
        return (
          <BillingTemplateThree
            template={3}
            formData={formData}
            discount={discount}
            is_print={true}
          />
        );
      case 4:
        return (
          <BillingTemplateFour
            template={4}
            formData={formData}
            discount={discount}
            is_print={true}
          />
        );
      default:
        return null;
    }
  };

  const addNewForm = () => {
    if (billingForms.length - hiddenForm < 5) {
      const sendToAll = isCheckboxEnabled("send_to_all");
      const newForm = {
        selectedCustomer: null,
        selectedItems: [],
        showBlankForm: true,
        totalAmount: 0,
        totalTax: 0,
        totalGst: 0,
        totalDiscount: 0,
        sendSMS: sendToAll,
        sendWhatsapp: sendToAll,
        sendEmail: sendToAll,
        printLogo: false,
        gstNumber: false,
        qrCode: false,
        paymentMode: null,
        partialPayment: {},
        dueAmount: 0,
        coupon_id: null,
        isPartyEnabled: false,
        totalAmountCopy: 0,
        actualPrice: 0,
        totalDiscountAmountCopy: 0,
        manualDiscount: 0,
        couponDiscount: 0,
        selectedDoctor: null,
        isDueAmount: false,
        couponCode: null,
      };
      setBillingForms([...billingForms, newForm]);
      setActiveTab(billingForms.length + 2);
      setAutoFocusInput(true);
      setIsDisabled(false);
      getBillingSettings();
      setIsPartyEnabled(false);
      localStorage.setItem("isPartyEnabled", "false");
    }
  };

  function calculateSalesData(item, requestedQty, itemPrice) {
    let remainingQty = requestedQty;
    let totalPrice = 0;
    let diffrenceDiscount = 0;
    let stockMap = new Map();
    let usedStocks = [];
  
    if (Array.isArray(item.stocks) && item.stocks.length > 0) {
      const sortedStocks = [...item.stocks].sort((a, b) => {
        return isPharmaceutical
          ? new Date(a.expire_date) - new Date(b.expire_date)
          : a.sale_price - b.sale_price;
      });

      for (let stock of sortedStocks) {
        if (remainingQty <= 0) break;
        const applicableQty = Math.min(remainingQty, stock.quantity);
        if (isPharmaceutical) {
          totalPrice += applicableQty * itemPrice;
        } 
        else {
          totalPrice += applicableQty * stock.sale_price;
        }
        // Aggregate quantities for each stock ID
        if (stockMap.has(stock.id)) {
          stockMap.set(stock.id, stockMap.get(stock.id) + applicableQty);
        } else {
          stockMap.set(stock.id, applicableQty);
        }

        usedStocks.push({id: stock.id, price: stock.sale_price, qty: applicableQty });
  
        remainingQty -= applicableQty;
      }
    } else {
      totalPrice = requestedQty * itemPrice;
    }

    const stockIds = Array.from(stockMap.keys());
    const stockUsed = Array.from(stockMap, ([id, qty]) => ({ id, qty, price: itemPrice }));
    let salesPrice =  isPharmaceutical ? itemPrice : usedStocks[usedStocks.length - 1].price;
    diffrenceDiscount = salesPrice * requestedQty - totalPrice;
    const saleAmount = item.is_tax_inclusive
      ? salesPrice - (item.tax_amount + item.discount_amount)
      : salesPrice - item.discount_amount;

    return { salesPrice, saleAmount, diffrenceDiscount, stockIds, stockUsed };
  }

  const handleSelectOption = (option, formIndex) => {
    const updatedForms = [...billingForms];
    const selectedItems = updatedForms[formIndex].selectedItems;
    const existingItemIndex = selectedItems.findIndex(
      (item) => item.id === option.id
    );
    if (existingItemIndex !== -1) {
      const totalStockQuantity = selectedItems[
        existingItemIndex
      ].stocks?.reduce((sum, stock) => sum + stock.quantity, 0);
      if (selectedItems[existingItemIndex].quantity < totalStockQuantity) {
        // const itemActualQuantity = selectedItems[existingItemIndex].actualQuantity;
        // const clampedQuantity = Math.max(
        //   1,
        //   Math.min(newQuantity, itemActualQuantity)
        // );
        const itemPrice = isPartyEnabled
          ? selectedItems[existingItemIndex].editedWholesalePrice
          : selectedItems[existingItemIndex].sales_price;
        const {
          salesPrice,
          saleAmount,
          diffrenceDiscount,
          stockIds,
          stockUsed,
        } = calculateSalesData(
          selectedItems[existingItemIndex],
          selectedItems[existingItemIndex].quantity + 1,
          itemPrice
        );
        const updatedItem = {
          ...selectedItems[existingItemIndex],
          quantity: selectedItems[existingItemIndex].quantity + 1,
          quantityUpdated: true,
          sales_price: salesPrice,
          sale_amount: saleAmount,
          diffrence_discount: diffrenceDiscount,
          stockIds: stockIds,
          stockUsed: stockUsed,
        };
        selectedItems[existingItemIndex] = updatedItem;
      }
    } else {
      const totalStockQuantity = option?.stocks?.reduce(
        (sum, stock) => sum + stock.quantity,
        0
      );
      const { stockIds, stockUsed } = calculateSalesData(
        option,
        1,
        option.sales_price
      );
      const newItem = {
        ...option,
        quantity: 1,
        actualQuantity: totalStockQuantity,
        stockIds: stockIds,
        stockUsed: stockUsed,
        editedWholesalePrice: option.wholesale_price,
        original_sales_price: option.sales_price,
        sales_price: isPartyEnabled
          ? option.wholesale_price
          : option.sales_price,
        last_bill_amount: option.last_bill_amount,
        diffrence_discount: 0,
      };
      selectedItems.push(newItem);
    }
    updatedForms[formIndex] = {
      ...updatedForms[formIndex],
      selectedItems: [...selectedItems],
      showBlankForm: false,
    };
    setBillingForms(updatedForms);
  };

  const handleUpdateItems = (updatedItems, formIndex) => {
    const updatedForms = [...billingForms];
    updatedForms[formIndex] = {
      ...updatedForms[formIndex],
      selectedItems: updatedItems,
    };
    setBillingForms(updatedForms);
  };

  const handlePartialPaymentChange = (e, mode, formIndex) => {
    const value = parseFloat(e.target.value) || 0;
    const updatedForms = [...billingForms];
    updatedForms[formIndex] = {
      ...updatedForms[formIndex],
      partialPayment: {
        ...updatedForms[formIndex].partialPayment,
        [mode]: value,
      },
    };
    setBillingForms(updatedForms);
  };

  const updateTotalPrice = (price, formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      const form = updatedForms[formIndex];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        totalAmount: price,
        totalAmountCopy: price,
      };
      return updatedForms;
    });
  };

  const updateActualPrice = (price, formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        actualPrice: price,
      };
      return updatedForms;
    });
  };

  const updateTotalTaxPrice = (taxPrice, formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        totalTaxAmount: taxPrice,
      };
      return updatedForms;
    });
  };

  const handleRemaingAmount = (e, formIndex, remainingAmount) => {
    const { checked } = e.target;
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        dueAmount: remainingAmount,
        isDueAmount: checked,
      };
      return updatedForms;
    });
  };

  const updateTotalDiscount = (discountAmount, formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        totalDiscountAmount: discountAmount,
        totalDiscountAmountCopy: discountAmount,
      };
      return updatedForms;
    });
  };

  const addCouponDiscount = (value, formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      let couponDiscount;
      if (
        isNaN(updatedForms[formIndex].totalAmount) ||
        updatedForms[formIndex].totalAmount <= 0
      ) {
        ErrorNotification("Total amount is invalid.");
        return prevForms;
      }
      if (value.coupon_type === "percent") {
        couponDiscount = (
          (updatedForms[formIndex].totalAmount * value?.value) /
          100
        ).toFixed(2);
      } else if (value.coupon_type === "fixed") {
        couponDiscount = value?.value;
      }

      couponDiscount = parseFloat(couponDiscount);

      if (isNaN(couponDiscount)) {
        ErrorNotification("Invalid coupon discount value.");
        return prevForms;
      }

      if (couponDiscount > updatedForms[formIndex].totalAmount) {
        ErrorNotification(
          "Can't apply this coupon because the discount exceeds the total amount."
        );
        return prevForms;
      }

      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        couponDiscount: couponDiscount,
        coupon_id: value.id,
      };

      return updatedForms;
    });
  };

  const handleCouponDiscount = async (code, formIndex) => {
    const couponCode = code;
    const response = await sendRequest(
      "GET",
      `${BASE_URL}/v1/setting/coupons/show_by_code/${couponCode}`
    );
    if (response.status === 200) {
      const coupon = response.data;
      addCouponDiscount(coupon, formIndex);
    }
  };

  const handleCheckboxChange = (event, formIndex) => {
    const { id, checked } = event.target;
    const checkboxType = id.split("-")[0];

    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        [`${checkboxType}`]: checked,
      };
      return updatedForms;
    });
  };

  const handlePaymentModeChange = (event, formIndex) => {
    const { value } = event.target;
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        paymentMode: value,
      };
      return updatedForms;
    });
  };

  const handleCustomerSelect = (customer, formIndex) => {
    const updatedForms = [...billingForms];
    updatedForms[formIndex] = {
      ...updatedForms[formIndex],
      selectedCustomer: customer,
    };
    setBillingForms(updatedForms);
  };

  const handleCouponCodeValue = (e, formIndex) => {
    const updatedForms = [...billingForms];
    updatedForms[formIndex] = {
      ...updatedForms[formIndex],
      couponCode: e?.target?.value,
    };
    setBillingForms(updatedForms);
  }

  const handleTabClose = (formIndex) => {
    setBillingForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        hidden: true,
      };
      const hiddenCount = updatedForms.filter((form) => form.hidden).length;
      setHiddenForm(hiddenCount);
      return updatedForms;
    });

    if (activeTab === formIndex + 2) {
      setActiveTab(1);
    } else {
      const newActiveTabIndex =
        activeTab > formIndex + 2 ? activeTab - 1 : activeTab;
      setActiveTab(newActiveTabIndex);
    }
  };

  const formatBillNumber = (number) => {
    return number.toString().padStart(2, "0");
  };

  const handleBillingPreview = (formIndex) => {
    const currentForm = billingForms[formIndex];
    setBillingPreviewData({
      ...currentForm,
      isPartyEnabled: isPartyEnabled,
      // Make sure to include the editedWholesalePrice in the selectedItems
      selectedItems: currentForm.selectedItems.map((item) => ({
        ...item,
        editedWholesalePrice: item.editedWholesalePrice || item.wholesale_price,
      })),
    });
    setcurrentFormIndex(formIndex);
    setShowBillingPreview(true);
  };

  const handleCancel = () => {
    setBillingPreviewData(null);
    setShowBillingPreview(false);
  };

  const handleUpdateTransactions = (status) => {
    setUpdateTransactions(status);
  };

  const handleNewBillingClick = () => {
    setAutoFocusInput(true);
  };

  const handleClick = (index) => {
    setIsDisabled(true);
    handlePrintInvoice(index);
  };

  return (
    <>
      <div className="tab-wrapper">
        <div className="billing-tabs">
          <div
            className={activeTab === 1 ? "btab active-tab" : "btab"}
            onClick={() => handleTabClick(1)}
          >
            {t("Billing")}
          </div>
          {billingForms.map(
            (form, index) =>
              !form.hidden && (
                <div
                  key={index}
                  className={
                    activeTab === index + 2 ? "btab active-tab" : "btab"
                  }
                  onClick={() => handleTabClick(index + 2)}
                >
                  {t("New Billing")}
                  <span
                    className="close-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTabClose(index);
                    }}
                  >
                    <CloseOutlined />
                  </span>
                </div>
              )
          )}
          <div className="add-form-btn" onClick={addNewForm}>
            <button className="btab">+</button>
          </div>
        </div>
        <div
          className={
            activeTab === 1 ? "tab-content active-content" : "tab-content"
          }
        >
          {/* Content for  main Billing tab */}
          <RecentTransiction
            updateTransactions={updateTransactions}
            setUpdateTransactions={handleUpdateTransactions}
            billSettings={billSettings}
          />
        </div>
        {billingForms.map((form, index) => (
          <div
            key={index}
            className={
              activeTab === index + 2
                ? "tab-content active-content"
                : "tab-content"
            }
          >
            <div className="billing-container">
              <div className="row">
                <div className="col-md-8">
                  <div className="billing-left">
                    <div className="billing-header">
                      <div className="bill-number">
                        <h6>{t("Fill required details and Create Billing")}</h6>
                      </div>
                      {/* <div className="form-header">
                        <strong>Bill Number:</strong> {formatBillNumber(billNumber + 1)}
                      </div> */}
                      {isCheckboxEnabled("invoice_no") && (
                        <div className="form-header">
                          <strong>{t("Bill Number")}:</strong>{" "}
                          {formatBillNumber(billNumber + 1)}
                        </div>
                      )}
                    </div>
                    <SearchableDropdown
                      options={[]}
                      label="item_name"
                      id="id"
                      handleChange={(option) =>
                        handleSelectOption(option, index)
                      }
                      autoFocus={autoFocusInput && activeTab === index + 2}
                    />
                    {form.showBlankForm ? (
                      <BlankTable />
                    ) : (
                      // <BillingTableOne selectedItems={form.selectedItems} updateTotalPrice={updateTotalPrice}/>
                      <BillingTableOne
                        isPartyEnabled={form.isPartyEnabled}
                        selectedItems={form.selectedItems}
                        updateActualPrice={(price) =>
                          updateActualPrice(price, index)
                        }
                        updateTotalPrice={(price) =>
                          updateTotalPrice(price, index)
                        }
                        updateTotalTaxPrice={(taxPrice) =>
                          updateTotalTaxPrice(taxPrice, index)
                        }
                        updateTotalDiscount={(discountAmount) =>
                          updateTotalDiscount(discountAmount, index)
                        }
                        updateItems={(updatedItems) =>
                          handleUpdateItems(updatedItems, index)
                        }
                        billSettings={billSettings}
                        isPharmaceutical={isPharmaceutical}
                        isChecked={isChecked}
                      />
                    )}
                    <div className="billing-checks">
                      <div className="row">
                        <div className="col-md-12 biling-data-form-grp">
                          <form>
                            {isCheckboxEnabled("message") && (
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`sendSMS-${index}`}
                                  checked={form.sendSMS}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`sendSMS-${index}`}
                                >
                                  {t("Send by SMS")}
                                </label>
                              </div>
                            )}

                            {isCheckboxEnabled("whats_app") && (
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`sendWhatsapp-${index}`}
                                  checked={form.sendWhatsapp}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`sendWhatsapp-${index}`}
                                >
                                  {t("Send by WhatsApp")}
                                </label>
                              </div>
                            )}

                            {isCheckboxEnabled("send_by_email") && (
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`sendEmail-${index}`}
                                  checked={form.sendEmail}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, index)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`sendEmail-${index}`}
                                >
                                  {t("Send by Email")}
                                </label>
                              </div>
                            )}
                          </form>
                          {isPharmaceutical && (
                            <div className="reminder-date-container">
                              <label className="reminder-date-label">
                                {t("Reminder Date")}
                              </label>
                              <input
                                type="date"
                                className="form-control reminder-date-box"
                                min={new Date().toISOString().split("T")[0]}
                                value={reminderDate}
                                onChange={handleReminderDateChange}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-20">
                      {/* <button className="btn btn-primary">Print Invoice</button> */}
                      {isPreviewEnabled ? (
                        <button
                          onClick={() => handleBillingPreview(index)}
                          className="btn btn-primary"
                          disabled={form?.selectedItems?.length === 0}
                        >
                          {t("Preview")}
                        </button>
                      ) : (
                        <button
                          onClick={() => handleClick(index)}
                          className="btn btn-primary"
                          disabled={isDisabled}
                        >
                          {t("Print Invoice")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="content mt0 billing-right">
                    <div className="billing-multitoggles">
                      <div>
                      {isPharmaceutical && (
                        <div className="party-wise-toggle-button-group">
                          <label className="party-wise-switch">
                            <input
                              type="checkbox"
                              checked={isPartyEnabled}
                              onChange={handlePartyToggle}
                            />
                            <span className="toggle-slider-bill-party-wise"></span>
                          </label>
                          <h6>Party</h6>
                        </div>
                      )}
                      </div>
                      <div className="billing-multitoggles-second-div">
                        <div>
                          <div className="toggle-button-group">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleToggle}
                              />
                              <span className="toggle-slider-bill"></span>
                            </label>
                            <h6 style={{ marginTop: "20px" }}>
                              Item Wise Representative
                            </h6>
                          </div>
                        </div>
                        <div>
                          {!isChecked && (
                            <div>
                              <FaUserTie
                                style={{ cursor: "pointer" }}
                                size={25}
                                onClick={handleIconClick}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {show && (
                      <div className="form-select-wrapper">
                        <label>{t("")}</label>
                        {loading && <p className="loading">Loading...</p>}
                        {error && (
                          <p className="error">
                            Error loading staff list: {error.message}
                          </p>
                        )}
                        <Select
                          ref={selectRef}
                          className="custom-select"
                          value={selectedSalesRepresentatives.map((optionId) =>
                            staffOptions.find(
                              (staff) => staff.value === optionId
                            )
                          )}
                          onChange={handleSalesRepChange}
                          options={staffOptions}
                          isMulti
                          closeMenuOnSelect={false}
                          placeholder={t("Select Sales Executive")}
                        />
                        {errors.selectedOption && (
                          <div className="tax-group-error">
                            {errors.selectedOption}
                          </div>
                        )}
                      </div>
                    )}

                    {isPartyEnabled ? (
                      <>
                        <h6>
                          <strong>{t("Party Details")}</strong>
                        </h6>
                        <PartyBilling
                          onPartySelect={(party) =>
                            handleCustomerSelect(party, index)
                          }
                        />
                      </>
                    ) : (
                      <>
                        <h6>
                          <strong>{t("Customer Details")}</strong>
                        </h6>
                        <GetCustomer
                          onCustomerSelect={(customer) =>
                            handleCustomerSelect(customer, index)
                          }
                        />
                      </>
                    )}
                    <div className="amount-details">
                      <ul>
                        <li>
                          {t("Sub Total")}
                          <span>
                            {form.actualPrice
                              ? form.actualPrice.toFixed(2)
                              : "0.00"}
                          </span>
                        </li>
                        <>
                          <li>
                            {isCheckboxEnabled("inter_state_GST") &&
                            enableGST ? (
                              <>
                                {t("IGST")}
                                <span>
                                  {form.totalTaxAmount
                                    ? form.totalTaxAmount.toFixed(2)
                                    : "0.00"}
                                </span>
                              </>
                            ) : enableGST ? (
                              <>
                                <div>
                                  {t("CGST")}
                                  <span>
                                    {form.totalTaxAmount
                                      ? (form.totalTaxAmount / 2).toFixed(2)
                                      : "0.00"}
                                  </span>
                                </div>
                                <div>
                                  {t("SGST")}
                                  <span>
                                    {form.totalTaxAmount
                                      ? (form.totalTaxAmount / 2).toFixed(2)
                                      : "0.00"}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                {t("Tax")}
                                <span>
                                  {form.totalTaxAmount
                                    ? form.totalTaxAmount.toFixed(2)
                                    : "0.00"}
                                </span>
                              </>
                            )}
                          </li>
                        </>
                        {(isCheckboxEnabled("auto_discount") ||
                          isCheckboxEnabled("manual_discount")) && (
                          <li>
                            {t("Total Discount")}
                            <span>
                              {/* {form.totalDiscountAmount
                                ? form.couponDiscount
                                  ? `- ${(
                                      Number(form.couponDiscount) +
                                      form.totalDiscountAmount
                                    ).toFixed(2)}`
                                  : `- ${form.totalDiscountAmount.toFixed(2)}`
                                : "0.00"} */}
                              {form.couponDiscount || form.totalDiscountAmount
                                ? `- ${(
                                    Number(form.couponDiscount || 0) +
                                    Number(form.totalDiscountAmount || 0)
                                  ).toFixed(2)}`
                                : "0.00"}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>

                    {/* <div className="total-amount"> */}
                    {/* <h3>Total Amount <span>000.00</span></h3> */}
                    {/* <h3>Total Amount: <span>{
                        form.totalAmount ? (form.totalAmount + form.totalTaxAmount).toFixed(2) : "000.00"}</span></h3>
                    </div> */}

                    {/* <div className="total-amount">
                      <div className="total-bill-amount"><span className="bill-label">Total Amount: </span><span className="amount-total-value">{
                        form.totalAmount
                          ? isCheckboxEnabled("round_of_total")
                            ? Math.round(form.totalAmount + form.totalTaxAmount)
                            : (form.totalAmount + form.totalTaxAmount).toFixed(2)
                          : "000.00"
                      }</span></div>
                    </div> */}

                    <div className="total-amount">
                      <div className="total-bill-amount">
                        <span className="bill-label">
                          {t("Total Amount")}:{" "}
                        </span>
                        <span className="amount-total-value">
                          {form.totalAmount
                            ? (() => {
                                // let taxAmount = 0;
                                // if (
                                //   isCheckboxEnabled("item_wise_tax") &&
                                //   enableGST
                                // ) {
                                //   if (isCheckboxEnabled("inter_state_GST")) {
                                //     taxAmount = form.totalAmount * 0.18;
                                //   } else {
                                //     taxAmount = form.totalAmount * 0.09 * 2;
                                //   }
                                // }
                                const total = form.couponDiscount
                                  ? form.totalAmount -
                                    Number(form.couponDiscount) +
                                    form.totalTaxAmount
                                  : // + taxAmount
                                    form.totalAmount + form.totalTaxAmount;
                                // + taxAmount;
                                return isCheckboxEnabled("round_of_total")
                                  ? Math.round(total)
                                  : total.toFixed(2);
                              })()
                            : "000.00"}
                        </span>
                      </div>
                    </div>
                    <br />
                    {isCheckboxEnabled("manual_discount") && (
                      <div className="billing-discount-group mb-3">
                        {errorMessage && (
                          <p
                            className="text-danger text-start mb-2"
                            style={{ fontSize: "0.875rem" }}
                          >
                            {errorMessage}
                          </p>
                        )}
                        <div className="d-flex justify-content-between align-items-center">
                          <h6 className="billing-discount-label me-3 mb-0">
                            <strong>{t("Discount")}</strong>
                          </h6>
                          <div className="d-flex align-items-center">
                            <select
                              className="form-select me-2"
                              value={discountType}
                              onChange={handleDiscountTypeChange}
                            >
                              <option value="fixed">{t("Fixed")}</option>
                              <option value="percentage">
                                {t("Percentage")}
                              </option>
                            </select>
                            <input
                              className="form-control mb-0 h-75"
                              type="number"
                              value={manualDiscount}
                              placeholder="Enter the Amount"
                              onChange={(e) => {
                                setManualDiscount(e.target.value);
                                handleApplyDiscount(e.target.value, index);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {isPharmaceutical && (
                      <>
                        <div className="billing-doctor-detail-grp">
                          <span className="billing-doctor-details">
                            {t("Doctor Details")}
                          </span>
                        </div>
                        <DoctorBilling
                          onDoctorSelect={(doctor) =>
                            handleDoctorSelect(doctor, index)
                          }
                        />
                      </>
                    )}

                    {isCheckboxEnabled("payment_mode") && (
                      <>
                        <div className="partial_payment_mode">
                          <CommonToggleButton
                            toggleLabel={"Partial Payment"}
                            onToggleChange={togglePartialPaymentPopup}
                            isBilling={showPartialPaymentPopup}
                          />
                        </div>
                        <div className="payment-mode">
                          <h6>
                            <strong>{t("Payment Mode")}</strong>
                          </h6>
                          <select
                            className="form-select payment-mode-option"
                            aria-label="Default select example"
                            value={form.paymentMode || ""}
                            onChange={(e) => handlePaymentModeChange(e, index)}
                          >
                            <option value="" disabled hidden>
                              {t("Select Option")}
                            </option>
                            <option value={0}>{t("cash")}</option>
                            <option value={1}>{t("online")}</option>
                            <option value={2}>{t("card")}</option>
                            <option value={3}>{t("Multi Payment")}</option>
                          </select>
                        </div>

                        {showPartialPaymentPopup && (
                          <div className="custom-partial-payment-popup">
                            <div className="custom-popup-content">
                              <h4>{t("Partial Payment Breakdown")}</h4>
                              <table className="custom-payment-table">
                                <thead>
                                  <tr>
                                    <th>{t("Cash")}</th>
                                    <th>{t("Online")}</th>
                                    <th>{t("Card")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={form.partialPayment?.cash || ""}
                                        onChange={(e) =>
                                          handlePartialPaymentChange(
                                            e,
                                            "cash",
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={
                                          form.partialPayment?.online || ""
                                        }
                                        onChange={(e) =>
                                          handlePartialPaymentChange(
                                            e,
                                            "online",
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={form.partialPayment?.card || ""}
                                        onChange={(e) =>
                                          handlePartialPaymentChange(
                                            e,
                                            "card",
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="custom-total-amount">
                                <strong>{t("Paid Amount")}: </strong>{" "}
                                {calculateTotal(form?.partialPayment)}
                              </div>
                              <div className="custom-remaining-amount">
                                <strong>{t("Remaining Amount")}: </strong>
                                {(() => {
                                  let total = form.couponDiscount
                                    ? form.totalAmount -
                                      Number(form.couponDiscount) +
                                      form.totalTaxAmount
                                    : form.totalAmount + form.totalTaxAmount;
                                  total = isCheckboxEnabled("round_of_total")
                                    ? Math.round(total)
                                    : total.toFixed(2);
                                  const partialTotal = calculateTotal(
                                    form.partialPayment
                                  );
                                  const validTotal = isNaN(total)
                                    ? 0.0
                                    : parseFloat(total);
                                  return calculateRemainingAmount(
                                    partialTotal,
                                    validTotal
                                  );
                                })()}
                              </div>
                              {(() => {
                                let total = form.couponDiscount
                                  ? form.totalAmount -
                                    Number(form.couponDiscount) +
                                    form.totalTaxAmount
                                  : form.totalAmount + form.totalTaxAmount;
                                total = isCheckboxEnabled("round_of_total")
                                  ? Math.round(total)
                                  : total.toFixed(2);
                                const partialTotal = calculateTotal(
                                  form.partialPayment
                                );
                                const validTotal = isNaN(total)
                                  ? 0.0
                                  : parseFloat(total);
                                const remainingAmount =
                                  calculateRemainingAmount(
                                    partialTotal,
                                    validTotal
                                  );
                                return (
                                  remainingAmount > 0 && (
                                    <div className="custom-pay-later">
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={form?.isDueAmount}
                                          onChange={(e) =>
                                            handleRemaingAmount(
                                              e,
                                              index,
                                              remainingAmount
                                            )
                                          }
                                        />
                                        {t("Pay Later")}
                                      </label>
                                    </div>
                                  )
                                );
                              })()}
                              <div className="custom-popup-buttons">
                                <button
                                  onClick={closePartialPaymentPopup}
                                  className="btn btn-primary"
                                >
                                  {t("Save")}
                                </button>
                                <button
                                  onClick={closePartialPaymentPopup}
                                  className="btn btn-primary"
                                >
                                  {t("Close")}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {isCheckboxEnabled("seller_details") && (
                      <div className="row justify-content-between">
                        <h6>
                          <strong>{t("Seller Details")}</strong>
                        </h6>
                        <form className="row">
                          <div className="col-md-4">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id={`qrCode-${index}`}
                                checked={form.qrCode}
                                onChange={(e) => handleCheckboxChange(e, index)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`qrCode-${index}`}
                              >
                                {t("Print QR Code")}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div
                              className="form-check"
                              style={{ marginLeft: "-14px" }}
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id={`gstNumber-${index}`}
                                checked={form.gstNumber}
                                onChange={(e) => handleCheckboxChange(e, index)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`gstNumber-${index}`}
                              >
                                {t("Print GST Number")}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id={`printLogo-${index}`}
                                checked={form.printLogo}
                                onChange={(e) => handleCheckboxChange(e, index)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`printLogo-${index}`}
                              >
                                {t("Print Logo")}
                              </label>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}

                    <div style={{ display: "inline-block" }}>
                      <div className="billing-coupon-group justify-content-between align-items-center">
                        <h6 className="billing-discount-coupon-label">
                          <strong>{t("Discount Coupon")}</strong>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          name="coupon"
                          placeholder="Please Select Coupon Code"
                          onChange={(e) => handleCouponCodeValue(e, index)}
                        />
                        <button
                          className="billling-coupon-apply-btn billing-apply-coupon-main-btn mt-0"
                          onClick={() => {
                            handleCouponDiscount(form.couponCode, index);
                          }}
                        >
                          {t("Apply")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <NotificationContainer />
        {ShowBillingPreview && (
          <BillingPreview
            isPartyEnabled={isPartyEnabled}
            formData={BillingPreviewData}
            currentFrom={currentFromIndex}
            billNumber={billNumber}
            igst_enable={isCheckboxEnabled("inter_state_GST") && enableGST}
            gst_enable={enableGST}
            paymentModeEnabled={isCheckboxEnabled("payment_mode")}
            generateBill={(currentFrom, template) =>
              handlePrintInvoice(currentFrom, template)
            }
            onCancel={handleCancel}
          />
        )}
      </div>
      <Copyrights />
    </>
  );
}

export default Billing;
