import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import Search from "../../components/Search/Search";
import Copyrights from "../../components/Copyrights";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";
import PaginationControls from "../../components/Common/PaginationControls";
import EntriesPerPageDropdown from "../../components/Common/EntriesPerPageDropdown";
import "./RestaurantCoupon.css";
import { useTranslation } from "react-i18next";


const CustomTable = ({ col_names, data }) => {
  return (
    <table className="custom-table">
      <thead>
        <tr>
          {col_names.map((col, index) => (
            <th key={index}>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {Object.values(row).map((cell, cellIndex) => (
              <td key={cellIndex}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

function RestaurantCoupons() {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [recordPerPage, setrecordPerPage] = useState(10);

  const startIndex = (currentPage - 1) * recordPerPage + 1;
  const endIndex = Math.min(currentPage * recordPerPage, totalRecords);
  const { t } = useTranslation();


  useEffect(() => {
    fetchCoupons();
  }, [currentPage, recordPerPage, searchQuery]);

  const fetchCoupons = async (pageNumber = 1) => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/setting/coupons?page=${pageNumber}&per_page=${recordPerPage}&query=${searchQuery}`
      );
      if (response.status === 200) {
        setItems(response.data.coupons);
        setTotalPages(response.data.total_pages);
        setTotalRecords(response.data.total_count);
      }
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }
  };

  const handleSearch = async (query) => {
    if (query.length >= 2) {
      setSearchQuery(query);
    } else {
      setSearchQuery("");
      setCurrentPage(1);
    }
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setrecordPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = async (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      await fetchCoupons(pageNumber);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="col">
          <div className="row mb-2">
            <div className="col d-flex justify-content-between align-items-center">
              <div>
                <h3 className="title">{t("Coupon List")} ({totalRecords})</h3>
              </div>
              <div>
                <Link to="/restaurant-coupon" className="btn btn-primary">
                  + {t("Add Coupon")}
                </Link>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="pagination-dropdown">
              <div className="pagination-controls">
                <EntriesPerPageDropdown
                  recordPerPage={recordPerPage}
                  handleRecordPerPageChange={handleRecordPerPageChange}
                />
              </div>
              <div
                className="text-right mb-0"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
              >
                <Search placeholder={t("Search Coupons ...")} />
              </div>
            </div>
          </div>

          <div className="coupon-table-view">
            <CustomTable
              col_names={[
                "S. No.",
                "Name",
                "Description",
                "Value",
                "Code",
                "Coupon Type",
                "Valid From",
                "Valid Until",
              ]}
              data={items.map((item, index) => ({
                "S. No.": startIndex + index,
                Name: item.name,
                Description: item.description,
                Value: item.value,
                Code: item.code,
                "Coupon Type": item.coupon_type,
                "Valid From": item.valid_from,
                "Valid Until": item.valid_until,
              }))}
            />
            {items.length === 0 && searchQuery.length > 1 && (
              <div className="no-report-data-message">{t("No match found")}</div>
            )}
            {items.length === 0 && searchQuery.length === 0 && (
              <div className="no-report-data-message">{t("No records found")}</div>
            )}
          </div>

          {totalRecords >= recordPerPage && (
            <div className="report-pagination-info">
              <div className="report-pagination-container">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                  totalRecords={totalRecords}
                  startIndex={startIndex}
                  endIndex={endIndex}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Copyrights />
      <NotificationContainer />
    </div>
  );
}

export default RestaurantCoupons;
