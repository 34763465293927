import React, { useEffect, useState } from "react";
import "./NewBillPayment.css";
import SelectGuest from "../../Restaurant/SelectGuest/SelectGuest";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import SaleCommonPopup from "../../CompanySales/SaleCommonPopupHeader/SaleCommonPopupHeader";
import CommonSupplierPopup from "../../Common/CommonSupplierPopup";
import ErrorNotification from "../../../Notification/ErrorNotification";
import successNotification from "../../../Notification/SuccessNotification";

function NewBillPayment(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddSupplier, setShowAddSupplier] = useState(false);
  const [isShowSupplierPopup, setShowSupplierPopup] = useState(false);
  const [supplierBills, setSupplierBills] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [supplierId, setSupplierId] = useState();
  const [date, setDate] = useState();
  const [referenceNo, setReferenceNo] = useState();
  const [paymentMode, setPaymentMode] = useState();

  useEffect(() => {
    const data = props.selectedBillPay;
    if (data) {
      setDate(data.date);
      setPaymentMode(data.payment_mode);
      setTotalAmount(data.amount_paid);
      setPaidAmount(data.amount_paid);
      if (data.supplier) {
        setSupplierId(data.supplier.id);
        setSearchTerm(data.supplier.supplier_name);
        fetchSupplierBills(data.supplier.id);
      }
      setReferenceNo(data.isCopy ? '' : data.reference_no);
    }
  }, [props.selectedBillPay]);

  const fetchSupplierBills = async (supplierId) => {
    try {
      const url = `${BASE_URL}/v1/transactions/payment_transactions/sup_invoice_list?supplier_id=${supplierId}`;
      const response = await sendRequest("get", url);
      if (response.status === 200) {
        setSupplierBills(response.data.invoices);
      }
    } catch (error) {
      ErrorNotification("Unable to fetch supplier bills");
    }
  };

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/suppliers?query=${term}`
        );
        const suppliers = response.data.data;
        if (suppliers.length > 0) {
          setFilteredSuppliers(suppliers);
          setShowDropdown(true);
          setShowAddSupplier(false);
        } else {
          setFilteredSuppliers([]);
          setShowDropdown(true);
          setShowAddSupplier(true);
        }
      } catch (error) {
        setShowDropdown(false);
        setShowAddSupplier(true);
      }
    } else {
      setShowDropdown(false);
      setShowAddSupplier(false);
    }
  };

  const handleSelectSupplier = async (supplier) => {
    setSupplierId(supplier.id);
    setSearchTerm(supplier.first_name || supplier.last_name);
    setShowDropdown(false);
    try {
      const url = `${BASE_URL}/v1/transactions/payment_transactions/sup_invoice_list?supplier_id=${supplier.id}`;
      const response = await sendRequest("get", url);
      if (response.status === 200) {
        setSupplierBills(response.data.invoices);
      }
    } catch (error) {
      ErrorNotification("Unable to fetch supplier bills");
    }
  };

  const handleCreateSupplier = () => {
    setShowSupplierPopup(true);
  };

  const handleAddSupplier = () => {
    setShowSupplierPopup(true);
    setShowDropdown(false);
    setShowAddSupplier(false);
  };

  const closeBillPaySupplierPopup = () => {
    setShowSupplierPopup(false);
  };

  useEffect(() => {
    const totalAmount = supplierBills.reduce(
      (sum, item) => sum + item.amount,
      0
    );
    setTotalAmount(totalAmount);
  }, [supplierBills]);

  const handleOnChange = (e) => {
    const amount = e.target.value;
    setPaidAmount(amount);
  };

  const handleBillPayment = async () => {
    const formData = {
      payment_transaction: {
        supplier_id: supplierId,
        date: date,
        bank_name: "IDFC FIRST LTD",
        reference_no: "434435435",
        payment_mode: paymentMode,
        amount_paid: paidAmount,
        due_amount: totalAmount - paidAmount,
      },
    };

    try {
      const url = `${BASE_URL}/v1/transactions/payment_transactions`;
      const response = await sendRequest("post", url, formData);

      if (response.status === 200) {
        successNotification("Bill payment done.");
        // props.fetchBillPayment();
        props.onClose();
      }
    } catch (error) {
      console.error("Error creating payment transaction:", error);
    }
  };

  const handleChangeReference = (e) => {
    setReferenceNo(e.target.value);
  };

  const handlePaymentModeChange = (e) => {
    setPaymentMode(e.target.value);
  };

  return (
    <>
      <div className="new-bill-payment-main-container">
        <SaleCommonPopup
          title={props.title}
          isUpdated={props.isUpdated}
          onClose={() => props.onClose()}
          onSave={handleBillPayment}
        />
        <div className="new-bill-payment-sub-container">
          <div className="new-bill-payment-second-div gap-1">
            <div className="new-bill-payment-customer-div">
              <span className="new-bill-payment-cust-title">
                Supplier/Vendor
              </span>
              <span className="new-bill-payment-cust-search">
                <input
                  className="form-control h-75 w-75"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search supplier"
                />
                {showDropdown && (
                  <ul className="new-bill-payment-customer-dropdown">
                    {filteredSuppliers.length > 0 ? (
                      filteredSuppliers.map((supplier, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelectSupplier(supplier)}
                          className="new-bill-payment-dropdown-item"
                        >
                          {supplier.first_name || supplier.last_name}
                        </li>
                      ))
                    ) : (
                      <li className="new-bill-payment-cust-dropdown-item">
                        No supplier found
                      </li>
                    )}
                    {showAddSupplier && (
                      <li
                        className="customer-dropdown-item"
                        onClick={handleAddSupplier}
                      >
                        <button
                          className="btn btn-primary"
                          onClick={handleCreateSupplier}
                        >
                          Add Supplier
                        </button>
                      </li>
                    )}
                  </ul>
                )}
              </span>
            </div>
            <div>
              <span className="new-bill-payment-estdate-title">
                Bill Payment Date
              </span>
              <span className="new-bill-payment-estdate-input">
                <input
                  className="form-control new-bill-payment-first-div-input"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </span>
            </div>
          </div>

          <div className="new-purchase-bill-pay-third-group">
            <div className="new-purchase-bill-payment-div">
              <div className="new-purchase-bill-payment-label-grp mt-0">
                <span>Payment Mode</span>
              </div>
              <select
                className="form-select"
                value={paymentMode}
                onChange={handlePaymentModeChange}
              >
                <option value="online">Online</option>
                <option value="cash">Cash</option>
                <option value="cheque">Cheque</option>
              </select>
            </div>
            <div className="new-purchase-bill-payment-div">
              <div className="new-purchase-bill-payment-label-grp">
                <span>Reference Number</span>
              </div>
              <input
                type="text"
                className="form-control h-50"
                placeholder="Reference No"
                value={referenceNo}
                onChange={handleChangeReference}
              />
            </div>
            <div className="new-purchase-bill-payment-div">
              <div className="new-purchase-bill-payment-label-grp">
                <span>Paid Amount</span>
              </div>
              <input
                type="number"
                className="form-control h-50"
                placeholder="Amount"
                value={paidAmount}
                onChange={handleOnChange}
              />
            </div>
          </div>
        </div>

        {supplierBills.length > 0 && (
          <div style={{ marginLeft: "100px", width: "100vh", height: "50vh" }}>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ border: "1px solid white", padding: "8px" }}>
                    Date
                  </th>
                  <th style={{ border: "1px solid white", padding: "8px" }}>
                    Description
                  </th>
                  <th style={{ border: "1px solid white", padding: "8px" }}>
                    Status
                  </th>
                  <th style={{ border: "1px solid white", padding: "8px" }}>
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {supplierBills.map((item) => (
                  <tr key={item.id} style={{ backgroundColor: "lightGray" }}>
                    <td style={{ border: "1px solid white", padding: "8px" }}>
                      {item.created_at}
                    </td>
                    <td style={{ border: "1px solid white", padding: "8px" }}>
                      {item.bill_number} {item.id}
                    </td>
                    <td style={{ border: "1px solid white", padding: "8px" }}>
                      {item.status}
                    </td>
                    <td
                      style={{
                        border: "1px solid white",
                        padding: "8px",
                        alignContent: "right",
                      }}
                    >
                      {item.amount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div style={{ marginTop: "10px", fontWeight: "bold" }}>
              <p>Total Bills: {supplierBills.length}</p>
              <p>Total Amount: ${totalAmount}</p>
              <p>Remaining Balance: ${totalAmount - paidAmount}</p>
            </div>
          </div>
        )}
      </div>

      {isShowSupplierPopup && (
        <div className="new-bill-payment-supplier-popup">
          <CommonSupplierPopup onClose={closeBillPaySupplierPopup} />
        </div>
      )}
    </>
  );
}

export default NewBillPayment;