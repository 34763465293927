import React, { useEffect, useState } from "react";
import "../components/Dashboard/DashComponent.css";
import { DashPurchase } from "../components/Dashboard/DashComponents";
import { Expenses } from "../components/Dashboard/DashComponents";
import { Sale } from "../components/Dashboard/DashComponents";
import { YouRecieve } from "../components/Dashboard/DashComponents";
import { Youpay } from "../components/Dashboard/DashComponents";
import Footer from "../components/Footer";
// import Header from "../components/Header";
import { sendRequest } from "../ApiRequest";
import { BASE_URL } from "../Url/constants";
import ErrorNotification from "../Notification/ErrorNotification";

export const Dashboard = ({ handleLogout }) => {
  const [data, setData] = useState({});

  useEffect(()=> {
    const fetchData = async () => {
      try {
        let dashboardRequestUrl = `${BASE_URL}/v1/reports/fetch_dashboard_data?report_type=dashboard&filter_by=${'today'}&filter_type=${'today'}`;
        const response = await sendRequest("GET", dashboardRequestUrl);
        setData(response.data);
      } catch (error) {
        ErrorNotification(error.errors);
      }
    };

    fetchData();
  },[]);

  useEffect(() => {
    const fetchSelectedLanguage = async () => {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/setting/settings/get_selected_language`,
          null
        );
        if (response.status === 200) {
          localStorage.setItem("selectedLanguage", response.data.value);
        } else {
          ErrorNotification("Failed to fetch selected language");
        }
      } catch (error) {
        ErrorNotification(error.errors);
      }
    };
    fetchSelectedLanguage();
  }, []);

  return (
    <>
      <div className="row">
        <Sale data={data}/>
        <Expenses data={data} />
      </div>
      <div className="row">
        <YouRecieve data={data}/>
        <Youpay data={data}/>
        <DashPurchase data={data} />
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
