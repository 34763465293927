import React, { useState } from 'react';
import './TaxEdit.css';
import { getToken } from '../../SecureStorage/Token';
import { BASE_URL } from '../../../Url/constants';
import { sendRequest } from '../../../ApiRequest';
import { NotificationContainer } from 'react-notifications';
import ErrorNotification from '../../../Notification/ErrorNotification';
import successNotification from '../../../Notification/SuccessNotification';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditTax = ({ tax, handleUpdateFormSubmit, handleCloseModal }) => {
  const token = getToken();
  const [taxData, setTaxData] = useState({
    title: tax.title,
    tax_value: tax.tax_value,
    tax_type: tax.tax_type === "percentage" ? "0" : "1"
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!taxData.title) {
      newErrors.title = "Tax name is required.";
    } else if (!/^[a-zA-Z0-9]{1,16}$/.test(taxData.title)) {
      newErrors.title = "Tax name must be alphanumeric and up to 16 characters.";
    }

    if (!taxData.tax_value) {
      newErrors.tax_value = "Tax value is required.";
    } else if (!/^\d{0,8}(\.\d{0,4})?$/.test(taxData.tax_value)) {
      newErrors.tax_value = "Tax value must be a number with up to 12 digits including decimals.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'title') {
      const updatedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 16);
      setTaxData({ ...taxData, [name]: updatedValue });
    } else if (name === 'tax_value') {
      const isValid = /^\d{0,8}(\.\d{0,4})?$/.test(value);
      if (isValid || value === "") {
        setTaxData({ ...taxData, [name]: value });
      }
    } else {
      setTaxData({ ...taxData, [name]: value });
    }

    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const params = {
      tax: {
        title: taxData.title,
        tax_value: taxData.tax_value,
        tax_type: parseInt(taxData.tax_type)
      }
    };

    const url = `${BASE_URL}/v1/taxes/taxes/${tax.id}`;

    try {
      const response = await sendRequest('put', url, params, token);
      if (response.status === 200) {
        handleUpdateFormSubmit(response.data);
        successNotification('Tax updated successfully');
      } else {
        ErrorNotification("Failed to update tax");
      }
    } catch (error) {
      console.error('Error occurred while updating tax:', error);
      ErrorNotification("Failed to update tax");
    }
  };

  return (
    <div className="edit-tax-container">
      <div className="edit-tax-rate-modal-content">
        <span className="tax-close" onClick={handleCloseModal}>&times;</span>
        <h2>Edit Tax</h2>
        <hr />
        <form className='taxrate-edit-form' onSubmit={handleSubmit}>
          <div>
            <div className="edit-tax-rate-form-group">
              <label className='tax-rate-edit-name'>Tax Name</label>
              <input
                type="text"
                name="title"
                className={`taxrate-fom-input-title taxrate-fom-input input-b-color ${errors.title ? 'input-error' : ''}`}
                value={taxData.title || ''}
                onChange={handleChange}
              />
              {errors.title && <div className="tax-error">{errors.title}</div>}
            </div>
            <div className="edit-tax-rate-form-group">
              <label className='tax-rate-edit-name'>Tax Rate</label>
              <input
                type="text"
                name="tax_value"
                className={`taxrate-fom-input taxrate-fom-input-amount input-b-color ${errors.tax_value ? 'input-error' : ''}`}
                value={taxData.tax_value || ''}
                onChange={handleChange}
              />
              {errors.tax_value && <div className="tax-error">{errors.tax_value}</div>}
            </div>
            <div className="edit-tax-rate-form-group">
              <label className='tax-rate-edit-type'>Tax Type</label>
              <select
                name="tax_type"
                className='taxrate-fom-input raxrate-form-taxvalue input-b-color'
                value={taxData.tax_type || ''}
                onChange={handleChange}
              >
                <option value="0">percentage</option>
                <option value="1">fixed</option>
              </select>
              <div className="tax-edit-drop-icon">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>
          </div>
          <hr />
          <button className="taxedit-submit-button" type="submit">Update</button>
        </form>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default EditTax;
