import React, { useState } from "react";
import "./TransferTable.css";
import { BASE_URL } from "../../Url/constants";
import successNotification from "../../Notification/SuccessNotification";
import ErrorNotification from "../../Notification/ErrorNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { sendRequest } from "../../ApiRequest";
import { useTranslation } from "react-i18next";

function TransferTable({ floors, onClose, onTransferSuccess }) {
  const [selectedBookedTable, setSelectedBookedTable] = useState(null);
  const [selectedAvailableTable, setSelectedAvailableTable] = useState(null);
   
  const { t} = useTranslation();

  const handleTransfer = async () => {
    if (selectedBookedTable && selectedAvailableTable) {
      try {
        const formParams = {
          source_table_id: selectedBookedTable,
          destination_table_id: selectedAvailableTable,
        }

        const response = await sendRequest(
          "PATCH",
          `${BASE_URL}/v1/floors/floors/transfer_table`,
          formParams
        );

        if (response.status === 200) {
          successNotification("Tables Transferred Successfully", "tables transferred");
          onTransferSuccess();
          onClose();
        }
      } catch (error) {
        ErrorNotification("An error occurred while transferring the table");
      }
    } else {
      ErrorNotification("Please select both a booked table and an available table to transfer.");
    }
  };

  return (
    <div className="transfer-table-modal">
      <div className="modal-header">
        <label className="fs-2 text-white">{t("Transfer Table")}</label>
        <button className="merge-close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="modal-body">
        <div className="container-fluid">
          <div className="row gap-2">
            <div className="col">
              <div className="row fs-5">
                <label>{t("Select Table to Transfer")}</label>
              </div>
              <div className="table-list">
                {floors.map((floor) => (
                  <div key={floor.id}>
                    <div className="row bg-secondary text-white mt-2">
                      <label className="fs-4">{floor.name}</label>
                    </div>
                    <div className="row tb-data">
                      {floor.tables
                        .filter((table) => table.status === "running")
                        .map((table) => (
                          <label key={table.id} className="table-option-transfer ms-2">
                            <input
                              className="form-check-input me-2"
                              type="radio"
                              name="booked-table-option"
                              value={table.id}
                              onChange={(e) => setSelectedBookedTable(e.target.value)}
                            />
                            {table.name}
                          </label>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col">
              <div className="row fs-5">
                <label>{t("Select Table to Transfer With")}</label>
              </div>
              <div className="table-list">
                {floors.map((floor) => (
                  <div key={floor.id}>
                    <div className="row bg-secondary text-white mt-2">
                      <label className="fs-4">{floor.name}</label>
                    </div>
                    <div className="row tb-data">
                      {floor.tables
                        .filter((table) => table.status === "available")
                        .map((table) => (
                          <label key={table.id} className="table-option-transfer-with ms-2">
                            <input
                              className="form-check-input me-2"
                              type="radio"
                              name="available-table-option"
                              value={table.id}
                              onChange={(e) => setSelectedAvailableTable(e.target.value)}
                            />
                            {table.name}
                          </label>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="transfer-btn" onClick={handleTransfer}>
          {t("Transfer")} {">>"}
        </button>
      </div>
    </div>
  );
}

export default TransferTable;

