import React from 'react';
import './CommonPaginationStyle.css'

const PaginationControls = ({
    currentPage,
    totalPages,
    paginate,
    totalRecords,
    startIndex,
    endIndex
}) => {
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const visiblePages = 3;
        if (totalPages <= visiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => paginate(i)}
                        className={currentPage === i ? 'active' : ''}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
            const endPage = Math.min(totalPages, currentPage + Math.floor(visiblePages / 2));

            if (startPage > 1) {
                pageNumbers.push(
                    <button
                        key={1}
                        onClick={() => paginate(1)}
                        className={currentPage === 1 ? 'active' : ''}
                    >
                        1
                    </button>
                );
                if (startPage > 2) {
                    pageNumbers.push(<span key="dots1">...</span>);
                }
            }

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => paginate(i)}
                        className={currentPage === i ? 'active' : ''}
                    >
                        {i}
                    </button>
                );
            }

            if (endPage < totalPages) {
                if (endPage < totalPages - 1) {
                    pageNumbers.push(<span key="dots2">...</span>);
                }
                pageNumbers.push(
                    <button
                        key={totalPages}
                        onClick={() => paginate(totalPages)}
                        className={currentPage === totalPages ? 'active' : ''}
                    >
                        {totalPages}
                    </button>
                );
            }
        }

        return pageNumbers;
    };

    return  (
        <>
            <div className="common-pagination-info">
                <h3>Showing {startIndex} to {endIndex} of {totalRecords} entries</h3>
            </div>

            <div className="common-pagination-controls">
                <button disabled={currentPage === 1} onClick={() => paginate(currentPage - 1)}>
                    {/* {'‹'} */}
                    {'<< Previous'}
                </button>
                {renderPageNumbers()}
                <button disabled={currentPage === totalPages} onClick={() => paginate(currentPage + 1)}>
                    {/* {'›'} */}
                    {'Next >>'}
                </button>
            </div>
        </>
    )
};

export default PaginationControls;
