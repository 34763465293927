import React from "react";
import "./NewPurchaseExpenses.css"
import CommonSalePopupTable from "../../CompanySales/CommonSalePopupTable/CommonSalePopupTable";
import SaleCommonPopup from "../../CompanySales/SaleCommonPopupHeader/SaleCommonPopupHeader";

function NewPurchaseExpenses({ onClose }) {

  return (
    <>
      <div className="new-purchase-expenses-main-container">
        <SaleCommonPopup onClose={onClose} />
        <div className="new-purchase-expenses-sub-container">
          <div className="new-purchase-expenses-first-div">
            <div className="new-purchase-expenses-estimate-no-grp">
              <span className="new-purchase-expenses-est-no-title">Payment No</span>
              <span className="new-purchase-expenses-est-no">8</span>
            </div>
            <div className="new-purchase-expenses-estimate-date-grp">
              <span className="new-purchase-expenses-estdate-title">Payment Date</span>
              <span className="new-purchase-expenses-estdate-input">
                <input className="form-control new-purchase-expenses-first-div-input" type="date" />
              </span>
            </div>
          </div>
          <div className="new-purchase-expenses-table">
            <CommonSalePopupTable />
          </div>
        </div>
      </div>
    </>
  );
}

export default NewPurchaseExpenses;
