import React, { useEffect, useState, useRef } from "react";
import "./BillingPreview.css";
import BillingTemplateOne from "../components/TemplateSettings/BillingTemplatePreview/BillingTemplateOne";
import BillingTemplateTwo from "../components/TemplateSettings/BillingTemplatePreview/BillingTemplateTwo";
import BillingTemplateThree from "../components/TemplateSettings/BillingTemplatePreview/BillingTemplateThree";
import BillingTemplateFour from "../components/TemplateSettings/BillingTemplatePreview/BillingTemplateFour";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";


function BillingPreview({ formData, billNumber, igst_enable, gst_enable, paymentModeEnabled, onCancel, generateBill, currentFrom, isPartyEnabled }) {
  const [billingParams, SetBillingParams] = useState({});
  const [SelectedTemplate, setSelectedTemplate] = useState(1);
  const { t } = useTranslation();
  const printableRef = useRef();

  const calculateItemTotal = (item) => {
    const price = isPartyEnabled ? (item.editedWholesalePrice || item.wholesale_price) : item.sales_price;
    return item.quantity * price;
  };


  useEffect(() => {
    if (formData) {
      const uniqueItems = [];
      const itemIds = new Set();

      formData.selectedItems.forEach(item => {
        if (!item.quantityUpdated) {
          item.quantity = 1;
        }
        if (!itemIds.has(item.id)) {
          itemIds.add(item.id);
          uniqueItems.push({
            item_id: item.id,
            quantity: item.quantity,
            item_total: calculateItemTotal(item),
            salesPrice: item.sales_price,
            saleAmount: item.sale_amount,
          });
        }
      }, [formData, billNumber]);
      const subTotal = formData.couponDiscount ? (formData?.totalAmount - Number(formData.couponDiscount)).toFixed(2) : formData?.totalAmount?.toFixed(2)
      // const subTotal = formData.couponDiscount
      //   ? (formData.totalAmount - Number(formData.couponDiscount) - (formData.isDueAmount ? formData.dueAmount : 0)).toFixed(2)
      //   : (formData.totalAmount - (formData.isDueAmount ? formData.dueAmount : 0)).toFixed(2);
      let totalGst = 0
      let totalTax = 0
      if (gst_enable) {
        totalGst = formData?.totalTaxAmount?.toFixed(2);
      } else {
        totalTax = formData?.totalTaxAmount?.toFixed(2);
      }
      const totalDiscount = formData?.couponDiscount
        ? (Number(formData?.couponDiscount) + formData.totalDiscountAmount).toFixed(2)
        : formData.totalDiscountAmount.toFixed(2);      
      SetBillingParams({
        bill: {
          customer_id: formData.selectedCustomer ? formData.selectedCustomer.id : null,
          total_amount: (
            Number(subTotal) + 
            (gst_enable ? Number(totalGst) : Number(totalTax)) - 
            (formData?.due_amount || 0)
          ).toFixed(2),
          sub_total: subTotal,
          total_discount: totalDiscount,
          total_tax: totalTax,
          total_gst: totalGst,
          is_igst_enable: igst_enable,
          is_gst_enable: gst_enable,
          bill_number: billNumber + 1,
          serial_number: 1234578,
          status: 0,
          bill_type: 0,
          send_by_sms: formData.sendSMS,
          send_by_whatsapp: formData.sendWhatsapp,
          send_by_email: formData.sendEmail,
          bill_items_attributes: uniqueItems,
          // payment_mode: formData.paymentMode ? parseInt(formData.paymentMode) : 0,
          payment_mode: paymentModeEnabled ? formData.paymentMode
            ? parseInt(formData.paymentMode)
            : "" : 0,
          payment_mode_json: formData?.partialPayment,
          due_amount: formData?.isDueAmount ? formData?.dueAmount : 0,
          print_qr: formData.qrCode,
          print_gst_no: formData.gstNumber,
          print_logo: formData.printLogo,
          template_id: SelectedTemplate
        },
        is_sale: true
      })
    };
  }, [formData, billNumber, isPartyEnabled, gst_enable, igst_enable]);

  const handleTemplateSelect = (templateId) => {
    setSelectedTemplate(templateId);
  }
  const printInvoiceData = () => {
    generateBill(currentFrom, SelectedTemplate);
  }
  return (
    <div className="billing-preview-modal">
      <div className="billing-preview-content">
        <div className="billing-preview-header">
          <h3>{t("Billing Preview")}</h3>
        </div>
        <div className="billing-preview-body">
          <div className="template-preview">
            <div className="receipt-box-container">
              <div className="receipt-temp-container" >
                <div ref={printableRef}>
                  {SelectedTemplate === 1 && (
                    <BillingTemplateOne template={1} formData={billingParams} isPartyEnabled={isPartyEnabled}/>
                  )}
                  {SelectedTemplate === 2 && (
                    <BillingTemplateTwo template={2} formData={billingParams} isPartyEnabled={isPartyEnabled}/>
                  )}
                  {SelectedTemplate === 3 && (
                    <BillingTemplateThree template={3} formData={billingParams} isPartyEnabled={isPartyEnabled}/>
                  )}
                  {SelectedTemplate === 4 && (
                    <BillingTemplateFour template={4} formData={billingParams} isPartyEnabled={isPartyEnabled}/>
                  )}
                </div> 
              </div>
            </div>
            <div className="receipt-box-sub">
              <div className="image-collection">
                <div className={`box-img-align ${SelectedTemplate === 1 ? 'selected' : ''}`}>
                  <img className="box-image" src="/receipt1.png" alt="Receipt 1" onClick={() => handleTemplateSelect(1)} />
                  {SelectedTemplate === 1 && <FontAwesomeIcon icon={faCheckCircle} className="checkmark" />}
                </div>
                <div className={`box-img-align ${SelectedTemplate === 2 ? 'selected' : ''}`}>
                  <img className="box-image" src="/receipt2.png" alt="Receipt 2" onClick={() => handleTemplateSelect(2)} />
                  {SelectedTemplate === 2 && <FontAwesomeIcon icon={faCheckCircle} className="checkmark" />}
                </div>
                <div className={`box-img-align ${SelectedTemplate === 3 ? 'selected' : ''}`}>
                  <img className="box-image" src="/receipt3.png" alt="Receipt 3" onClick={() => handleTemplateSelect(3)} />
                  {SelectedTemplate === 3 && <FontAwesomeIcon icon={faCheckCircle} className="checkmark" />}
                </div>
                <div className={`box-img-align ${SelectedTemplate === 4 ? 'selected' : ''}`}>
                  <img className="box-image" src="/receipt4.png" alt="Receipt 4" onClick={() => handleTemplateSelect(4)} />
                  {SelectedTemplate === 4 && <FontAwesomeIcon icon={faCheckCircle} className="checkmark" />}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="billing-preview-footer">
          <button className="btn btn-primary billing-btn-cancel" onClick={onCancel}>{t("Cancel")}</button>
          {/* <button className="btn btn-primary billing-btn-confirm" onClick={printInvoiceData}>{t("Print")}</button> */}
          {/* <ReactToPrint
            trigger={() => <button className="btn btn-primary billing-btn-confirm" onClick={printInvoiceData}>{t("Print")}</button>}
            content={() => printableRef.current}
          /> */}
          <ReactToPrint
            trigger={() => (
              <button className="btn btn-primary billing-btn-confirm">
                {t("Print")}
              </button>
            )}
            content={() => printableRef.current}
            onBeforePrint={printInvoiceData}
          />

        </div>
      </div>
    </div>
  );
}

export default BillingPreview;
