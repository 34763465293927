import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Url/constants";
import '../../../src/components/styles/Login/Login.css';
import ErrorNotification from "../../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import { sendRequest } from "../../ApiRequest";

function Login() {
  console.log("test I'm called")
  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");

  function validationForm(mobile) {
    let regEmail = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
    let regMobile = /^\d{10}$/;

    if (!mobile) {
      ErrorNotification("Please enter either an email address or a mobile number");
      return false;
    }

    if (regEmail.test(mobile)) {
      return true;
    }

    if (regMobile.test(mobile)) {
      return true;
    }

    ErrorNotification("Please enter a valid email address or a valid 10-digit mobile number");
    return false;
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onPressSendOTP();
  };

  const onPressSendOTP = async () => {
    const valid = validationForm(mobile);
    try {
      if (valid) {
        const params = {
          user: {
            login: mobile,
            devise_type: 'web'
          },
        }
        const response = await sendRequest('POST', `${BASE_URL}/v1/auth/otps`, params)
        if (response.status === 200) {
          const data = response.data;
          navigate("/loginotp", { state: { otp: data.otp, email: data.email, phone: mobile, company_id: data.company_id } });
        }
      };
    } catch (error) {
      ErrorNotification(error.data.errors);
      if (error.data.errors === 'You have a active session on web, please log out and try again!') {
        navigate("/logout", { state: { user: error.data.user_id}});
      }
    }
  };

  return (
    <div className="login-body">
      <div className="login-box">
        <div className="logo-content">
          <img src={require("../../Assets/Images/logo.jpg")} alt="Billeasy Logo" className="company-unique-logo-image" style={{ width: '85%' }} />
        </div>
        <form onSubmit={handleFormSubmit}>
          <span className="login-title-name">Login</span>
          <div>
            <input
              className="form-control enter-mobile-number-field"
              placeholder="Enter Mobile Number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              type="text"
            />
          </div>
          <div>
            <button
              type="submit"
              className="btn btn-primary login-submit-button"
            >
              Send OTP
            </button>
          </div>
        </form>
      </div>
      <NotificationContainer />
    </div>
  );
}

export default Login;
