import React, { useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CommonCloseButton from "./Restaurant/CommonCloseButton/CommonCloseButton";
import "./QRCodePopup.css";

const QRCodePopup = ({
  show,
  onHide,
  subdomain,
  upiId,
  onGenerateQR,
  onUpdateUPI,
}) => {
  const [localSubdomain, setLocalSubdomain] = useState(subdomain);
  const [localUpiId, setLocalUpiId] = useState(upiId);
  const [subdomainError, setSubdomainError] = useState("");
  const { t } = useTranslation();

  const handleUpdateSubdomain = () => {
    if (localSubdomain.trim() === "") {
      setSubdomainError(t("Subdomain cannot be empty"));
    } else {
      setSubdomainError("");
      onGenerateQR(localSubdomain);
    }
  };

  const handleUpdateUPI = () => {
    if (validateUPI(localUpiId)) {
      onUpdateUPI(localUpiId);
    }
  };

  const validateUPI = (upi) => {
    const upiRegex = /^[\w.-]+@[\w.-]+$/;
    return upiRegex.test(upi);
  };

  const handleClose = () => {
    if (onHide && typeof onHide === 'function') {
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title>{t("Update Settings and Generate QR Code")}</Modal.Title>
        <div onClick={handleClose}>
          <CommonCloseButton />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-4">
            <Form.Label>{t("Subdomain")}</Form.Label>
            <InputGroup>
              <InputGroup.Text className="input-group-text-height">www.</InputGroup.Text>
              <Form.Control
                type="text"
                value={localSubdomain.replace(/^www\./, "")}
                onChange={(e) =>
                  setLocalSubdomain(e.target.value.replace(/^www\./, ""))
                }
                placeholder={t("Enter subdomain")}
                isInvalid={!!subdomainError}
              />
              <Button variant="outline-primary" onClick={handleUpdateSubdomain} className="button-margin-zero">
                {t("Update QR Code")}
              </Button>
              <Form.Control.Feedback type="invalid">
                {subdomainError}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>{t("UPI ID")}</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                value={localUpiId}
                onChange={(e) => setLocalUpiId(e.target.value)}
                placeholder={t("Enter UPI ID")}
                isInvalid={localUpiId && !validateUPI(localUpiId)}
              />
              <Button
                variant="outline-primary"
                onClick={handleUpdateUPI}
                disabled={!validateUPI(localUpiId)}
                className="button-margin-zero"
              >
                {t("Update UPI")}
              </Button>
              <Form.Control.Feedback type="invalid">
                {t("Invalid UPI ID format")}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-success close-button-margin close-button-bg"
          onClick={onHide}
        >
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QRCodePopup;
