import React, { useEffect, useRef, useState } from "react";
import "./PurchaseCreditNote.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewPurchaseCreditNote from "./NewPurchaseCreditNote";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import PaginationControls from "../../Common/PaginationControls";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import successNotification from "../../../Notification/SuccessNotification";

function PurchaseCreditNote() {
  const [openNewPurchaseCredit, setNewPurchaseCredit] = useState();
  const [purchasecreditNote, setPurchaseCreditNote] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showPurCreditIconAction, setShowPurCreditDownIconAction] = useState(false);
  const [selectedPurCreditNote, setSelectedPurCreditNote] = useState("");
  const [purCreditNoteCopy, setPurCreditNoteCopy] = useState("");
  const [purchaseCreditNoteUpdated, setPurCreditNoteUpdated] = useState(false);
  const [actionPurDropPosition, setActionPurDropPosition] = useState({ top: 0, left: 0 });

  const columnPurchaseCreditNote = useRef(null);

  const handleNewCreditNote = () => {
    setNewPurchaseCredit(true);
  }

  const closeNewPurchaseCredit = () => {
    setNewPurchaseCredit(false);
  }

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  useEffect(() => {
    fetchPurchaseCreditNote(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  useEffect(() => {
    const handleDownClickOutside = (event) => {
      if (
        columnPurchaseCreditNote.current &&
        !columnPurchaseCreditNote.current.contains(event.target)
      ) {
        setShowPurCreditDownIconAction(false);
      }
    };
    if (showPurCreditIconAction) {
      document.addEventListener("mousedown", handleDownClickOutside);
    } else {
      document.removeEventListener("mousedown", handleDownClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleDownClickOutside);
    };
  }, [showPurCreditIconAction]);

  const fetchPurchaseCreditNote = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/transactions/entries?type=sup_credit_note&page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setPurchaseCreditNote(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching purchase credit note:", error);
    }
  };

  const handlePurchaseCreditNoteCreated = (value) => {
    if (!value) return;
    setPurchaseCreditNote(prevCreditNotes => [...prevCreditNotes, ...value]);
    setTotalRecords(prevTotalRecords => prevTotalRecords + 1);
  }

  const handlePurCreditCopy = async (PurCreditNoteCopyId, updated) => {
    if (updated) {
      setPurCreditNoteUpdated(updated);
    }
    if (PurCreditNoteCopyId) {
      try {
        const response = await sendRequest("GET", `${BASE_URL}/v1/transactions/entries/${PurCreditNoteCopyId}?type=sup_credit_note`);
        setPurCreditNoteCopy(response.data.entry);
        setNewPurchaseCredit(true);
      } catch (error) {
        console.error("Error copy the purchase credit note:", error);
        console.log("An error occurred while deleting the copy purchase credit note.");
      }
    }
  };

  const handlePurCreditNoteDelete = async (purCreditId) => {
    if (purCreditId) {
      try {
        const response = await sendRequest("DELETE", `${BASE_URL}/v1/transactions/entries/${purCreditId}?type=sup_credit_note`);
        if (response.status === 200) {
          successNotification("Purchase credit note deleted")
          fetchPurchaseCreditNote(currentPage, recordsPerPage);
          setShowPurCreditDownIconAction(false);
        } else {
          console.log("Failed to delete the purchase credit note. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting the purchase credit note:", error);
      }
    }
  }

  const handlePurCreditDownIconAction = (purCreditNote, event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setActionPurDropPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setSelectedPurCreditNote(purCreditNote);
    setShowPurCreditDownIconAction(true);
  };

  return (
    <>
      <div className="purchase-credit-note-main-container">
        <div className="purchase-credit-note-container-first-div">
          <div className="purchase-credit-note-first-left">
            <span className="all-purchase-credit-note-show">All Credit Note({totalRecords})</span>
          </div>
          <div className="purchase-credit-note-first-right">
            <button
              className="purchase-credit-note-first-right-botton"
              onClick={handleNewCreditNote}
            >
              New Credit Note
              {/* <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span> */}
            </button>
          </div>
        </div>

        <div className="purchase-credit-note-container-second-div">
          <EntriesPerPageDropdown recordPerPage={recordsPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
        </div>

        <div className="purchase-credit-note-table-group">
          <table className="purchase-credit-note-table">
            <thead>
              <tr className="purchase-credit-note-thead-row">
                <th className="purchase-credit-note-tab-th">Date</th>
                <th className="purchase-credit-note-tab-th">Trans No</th>
                <th className="purchase-credit-note-tab-th">Contact</th>
                <th className="purchase-credit-note-tab-th">Supplier Credit Note No</th>
                <th className="purchase-credit-note-tab-th">Supplier Credit Note Date</th>
                <th className="purchase-credit-note-tab-th">Ref.No</th>
                <th className="purchase-credit-note-tab-th">Amount</th>
                {/* <th className="purchase-credit-note-tab-th">Due Amount</th> */}
                <th className="purchase-credit-note-tab-th">Action</th>
              </tr>
            </thead>
            <tbody>
              {purchasecreditNote.map((row, index) => (
                <tr key={index}>
                  <td className="purchase-credit-note-tab-td">{row.date}</td>
                  <td className="purchase-credit-note-tab-td">{row.bill_number}</td>
                  <td className="purchase-credit-note-tab-td">{row.supplier.supplier_name}</td>
                  <td className="purchase-credit-note-tab-td">{row.order_no}</td>
                  <td className="purchase-credit-note-tab-td">{row.order_date}</td>
                  <td className="purchase-credit-note-tab-td">{row.reference_no}</td>
                  <td className="purchase-credit-note-tab-td">{row.total_amount}</td>
                  {/* <td className="purchase-credit-note-tab-td"></td> */}
                  <td className="purchase-credit-note-tab-td">
                    <FontAwesomeIcon icon={faCaretDown} className="purchase-credit-note-table-body-td"
                      onClick={(e) => handlePurCreditDownIconAction(row, e)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}

        {purchasecreditNote.length === 0 && (
          <div className="credit-note-no-data-message">
            No purchase credit note found.
          </div>
        )}
      </div>

      {openNewPurchaseCredit && (
        <div className="new-purchase-credit-note-popup-container">
          <NewPurchaseCreditNote onClose={closeNewPurchaseCredit} purchaseCreditNoteCreated={handlePurchaseCreditNoteCreated} purCreditNoteCopy={purCreditNoteCopy} isUpdated={purchaseCreditNoteUpdated}/>
        </div>
      )}

      {
        showPurCreditIconAction && (
          <div className="purchase-credit-note-down-icon-action"
            ref={columnPurchaseCreditNote}
            style={{
              position: 'absolute',
              top: `${actionPurDropPosition.top}px`,
              left: `${actionPurDropPosition.left}px`,
            }}
          >
            <ul className="purchase-credit-note-down-icon-action-ul">
              <li className="purchase-credit-note-down-icon-action-li"
                onClick={() => handlePurCreditCopy(selectedPurCreditNote.id, true)}
              >
                Edit
              </li>
              <li className="purchase-credit-note-down-icon-action-li" 
                onClick={() => handlePurCreditNoteDelete(selectedPurCreditNote.id)}
              >
                Delete
              </li>
              <li className="purchase-credit-note-down-icon-action-li"
                onClick={() => handlePurCreditCopy(selectedPurCreditNote.id)}
              >
                Copy
              </li>
            </ul>
          </div>
        )
      }
      <Copyrights />
    </>
  );
}

export default PurchaseCreditNote;
