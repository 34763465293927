import React from 'react';
import '../Common/EntriesPerPageStyle.css'

const ItemEntriesPerPageDropdown = ({ recordPerPage, handleRecordPerPageChange }) => (
  <div className= 'entry-per-page'>
    <div >
      <select className='form-select' id="entries" value={recordPerPage} onChange={handleRecordPerPageChange}>
				<option value={50}>50</option>
        <option value={100}>100</option>
        <option value={200}>200</option>
        <option value={500}>500</option>
      </select>
    </div>
    <div >
      <label htmlFor="entries" className='label-per-page'>Entries per page</label>
    </div>
  </div>
);

export default ItemEntriesPerPageDropdown;
