import React, { useState } from 'react';
import './CreateTable.css';
import { useTranslation } from "react-i18next";

const CreateTable = ({ onCreate, closeModal }) => {
  const [prefix, setPrefix] = useState('');
  const [quantity, setQuantity] = useState(1);
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();

    const prefixPattern = /^[a-zA-Z0-9]{1,5}$/;
    if (!prefixPattern.test(prefix)) {
      alert('Prefix should be alphanumeric and up to 5 characters long.');
      return;
    }

    if (quantity > 0) {
      onCreate(prefix, quantity);
    } else {
      alert('Please enter a valid quantity.');
    }
  };

  return (
    <div>
      <button className="close-button-btn" onClick={closeModal}>&times;</button>
      <form onSubmit={handleSubmit} className="create-table-form">
        <div className="form-group mt-3">
          <label>{t("Prefix")}</label>
          <input
            type="text"
            placeholder={t('Table Prefix')}
            className="form-control m-0"
            value={prefix}
            onChange={(e) => setPrefix(e.target.value)}
            maxLength={5}
            required
          />
        </div>
        <div className="form-group">
          <label>{t("Number Of Tables")}</label>
          <input
            type="number"
            placeholder={t('Number Of Tables')}
            className="form-control m-0"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            min="1"
            required
          />
        </div>
        <div className="form-group mt-3 d-flex justify-content-between">
          <button type="submit" className="btn btn-primary">{t("Create Tables")}</button>
        </div>
      </form>
    </div>
  );
};

export default CreateTable;
