import React, { useEffect, useState } from "react";
import SelectDesignation from "../../../components/RolesPermissions/SelectDesignation";
import CheckboxPermissions from "../../../components/RolesPermissions/CheckboxPermissions";
import Copyrights from "../../../components/Copyrights";
import "./RolesPermission.css";
import { useTranslation } from "react-i18next";
import { getUser } from "../../../Authentication/AuthenticationContext";

function RolesPermissions() {
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [user, setUser] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    const userData = await getUser();
    if (userData && userData.user_detail && userData.user_detail.role) {
      setUser(userData.user_detail.role.name);
    }
  };

  const handleRoleChange = (permissions) => {
    setSelectedPermissions(permissions);
  };

  if (!user) {
    return null;
  }

  return (
    <>
      {user === "cashier" ? (
        " "
      ) : (
        <div className="role-permissions-container">
          <h3 className="title-content">{t("Roles & Permission")}</h3>
          <SelectDesignation
            onRoleChange={handleRoleChange}
            setRoleId={setRoleId}
          />
          {roleId && <CheckboxPermissions permissions={selectedPermissions} />}
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default RolesPermissions;
