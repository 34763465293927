import React, { useState, useEffect } from "react";
import "./SelectCaptain.css"
import CommonCloseButton from "../CommonCloseButton/CommonCloseButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import { useTranslation } from "react-i18next";

function SelectCaptain({ onClose, onSelectCaptain }) {
  const [CaptainSelected, setCaptainSelected] = useState("")
  const [captain, setCaptain] = useState([]);
  const { t} = useTranslation();

  const handleCaptainChange = (e) => {
    setCaptainSelected(e.target.value)
  }

  useEffect(() => {
    fetchCaptain();
  }, []);  

  const fetchCaptain = async () => {
    try {
      const response = await sendRequest(
      "GET",
      `${BASE_URL}/v1/staff_management/staffs`,
        null
      );

      const filteredCaptains = response.data.data.filter(
        (staff) => staff.role_details.role_name === "staff"
      );
      setCaptain(filteredCaptains);
      // setCaptain(response.data.data);
    } catch (error) {
      console.log("Error fetching staffs:", error);
    }
  };

 

	const handleSave = () => {
    const selectedCaptain = captain.find(c => c.id === parseInt(CaptainSelected));
    if (selectedCaptain) {
    onSelectCaptain(selectedCaptain);
    }
    onClose();
  };

  return (
  <>
  <div className='captain-select-main'>
    <div className='captain-select-title'>
      <span className='selected-captain-title-heading'>{t("Captain Selection")}</span>
      <span onClick={onClose}>
      <CommonCloseButton />
      </span>
    </div>

    <div className="captain-selected-sub">
      <div className="captain-first-label-and-input">
        <label className="captain-select-label-field">{t("Captain")}</label>
        <select className="form-control selected-captain-options"
        value={CaptainSelected} onChange={handleCaptainChange}
        >
          <option value="" selected disabled>
          {t("Select Captain")}
          </option>
          {captain.map((captain) => (
          <option key={captain.id} value={captain.id}>
          {captain.first_name} {captain.last_name}
          </option>
          ))}
        </select>
        <div className="select-captain-drop-icon">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </div>
    </div>

    <div className="captain-select-save-button">
      <button className="btn btn-success select-captain-save" onClick={handleSave}>{t("Save")}</button>
    </div>
  </div>
  </>
  )
}

export default SelectCaptain;
