import React, { useState, useEffect } from "react";
import "./NoCharge.css";
import CommonCloseButton from "../CommonCloseButton/CommonCloseButton";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import { useTranslation } from "react-i18next";

function NoCharge({ selectedNoCharge, onClose }) {
  const [noCharges, setNoCharge] = useState([]);
  const [newChargesReason, setNewChargesReason] = useState("");
  const [chargeError, setChargeError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchNoChargeList();
  }, []);

  const fetchNoChargeList = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/restaurant/nc_accounts/nc_accounts`,
        null
      );
      setNoCharge(response.data);
    } catch (error) {
      console.log("Error fetching item modifier:", error);
    }
  };

  const handleNoChargesChange = (charge) => {
    selectedNoCharge(charge);
    onClose();
  };

  const handleChargesReasonChange = (e) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[0-9]/g, "");
    setNewChargesReason(filteredValue);
  };

  const handleCreateChargesReason = async () => {
    setChargeError(null);

    if (newChargesReason.trim() === "") {
      setChargeError("Charges reason cannot be empty or numeric.");
      return;
    }

    if (newChargesReason.length > 40) {
      setChargeError("Charges reason cannot exceed 40 characters.");
      return;
    }

    const params = {
      nc_account: {
        account_name: newChargesReason
      }
    };

    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/restaurant/nc_accounts/nc_accounts`,
        params
      );
      const res = response.data;
      fetchNoChargeList();
      setNewChargesReason(res);
      selectedNoCharge(res);
      onClose();
    } catch (error) {
      setChargeError(`Account ${error.data.account_name || 'An error occurred'}`);
    }
  };

  return (
    <>
      <div className='res-no-charges-main'>
        <div className='res-no-charges-title'>
          <span className='no-charges-title-heading'>{t("No Charges")}</span>
          <span onClick={onClose}>
            <CommonCloseButton />
          </span>
        </div>
        <div className="res-charges-sub-contain">
          <div className="res-charges-sub-face-2">
            <span className="charges-reason-title">{t("Select NC Account")}</span>
          </div>
          <div className="charges-reason-list-group">
            <div className="charges-reson-list-contain">
              {noCharges.map((noCharge) => (
                <div className='no-charges-button-style' key={noCharge.id}>
                  <button
                    className="no-charges-invoice-input"
                    onClick={() => handleNoChargesChange(noCharge)}
                  >
                    {noCharge.account_name}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="new-nc-account-title-div">
            <span className="new-nc-title">{t("New NC Account")}</span>
            <input
              type="text"
              className='form-control no-charges-reason-input-field'
              placeholder={t('Charges Reason')}
              value={newChargesReason}
              onChange={handleChargesReasonChange}
              maxLength={40}
            />
          </div>
          {chargeError && <div className='charges-error-message'>{chargeError}</div>}
          <div className='collection-no-charges-modify-button'>
            <span className='no-charges-buttons-res-save-select'>
              <button className='btn btn-success no-charges-save-select' onClick={handleCreateChargesReason}>
                {t("Save")}
              </button>
            </span>
            <span className='modifier-buttons-res-close'>
              <button className='btn btn-danger no-charges-close' onClick={onClose}>{t("Close")}</button>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoCharge;
