import React, {useEffect, useState} from "react";
import './UpdateBillingStatus.css';
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";


const UpdateStatusPopup = ({ currentStatus, onClose, onUpdate }) => {
  const [status, setStatus] = useState(currentStatus);
  const { t } = useTranslation();

  useEffect(() => {
    setStatus(currentStatus);
  }, [currentStatus]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleUpdate = () => {
    onUpdate(status);
  };

  return (
<div className="popup-overlay">
  <div className="popup-content">
    <h4>{t("Update Status")}</h4>
    <div className="select-container">
      <select className="form-control" value={status} onChange={handleStatusChange}>
        {/* <option value={0}>{t("Draft")}</option> */}
        <option value={1}>{t("Success")}</option>
        {/* <option value={2}>{t("Failed")}</option> */}
        <option value={3}>{t("Pending")}</option>
        <option value={4}>{t("Cancel")}</option>
      </select>
      <span className="sts-edit-drop">
        <FontAwesomeIcon icon={faChevronDown} />
      </span>
    </div>
    <div className="popup-actions">
      <button className="btn btn-primary" onClick={handleUpdate}>{t("Update")}</button>
      <button className="btn btn-primary" onClick={onClose}>{t("Cancel")}</button>
    </div>
  </div>
</div>
  );
};

export default UpdateStatusPopup;
