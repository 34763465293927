import React, { useEffect, useState } from "react";
import "./NewSaleInvoice.css"
import SaleCommonPopup from "../SaleCommonPopupHeader/SaleCommonPopupHeader";
import CommonSalePopupTable from "../CommonSalePopupTable/CommonSalePopupTable";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { current_user } from "../../../Authentication/AuthenticationContext";
import CommonCustomerPopup from "../../Common/CommonCustomerPopup";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import successNotification from "../../../Notification/SuccessNotification";
import ErrorNotification from "../../../Notification/ErrorNotification";
import Select from 'react-select';
import { BsPencilSquare } from "react-icons/bs";
import CommonToggleButton from "../../Common/CommonToggleButton";

function NewSaleInvoice({ onClose, saleInvoiceCopy, isUpdated, isQuotationInvoice, isSaleOrderInvoice, invoiceCreated, setUpdatedCustoerId }) {

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setBranchId(currentUser.company.branch_info);
    };
    fetchUser();
  }, []);

  const [totalAmount, setTotalAmount] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [gstAmount, setGstAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [branchId, setBranchId] = useState("");
  const [isEstimateDate, setEstimateDate] = useState(
    saleInvoiceCopy?.estimate_date ? saleInvoiceCopy?.estimate_date : "" ||
      isQuotationInvoice?.estimate_date ? isQuotationInvoice?.estimate_date : "" ||
        isSaleOrderInvoice?.estimate_date ? isSaleOrderInvoice?.estimate_date : "");
  const [isValidTillDate, setValidTillDate] = useState(
    saleInvoiceCopy?.valid_date ? saleInvoiceCopy?.valid_date : "" ||
      isQuotationInvoice?.valid_date ? isQuotationInvoice?.valid_date : "" ||
        isSaleOrderInvoice?.valid_date ? isSaleOrderInvoice?.valid_date : "");
  const [isQuoteReference, setQuoteReference] = useState(
    saleInvoiceCopy?.reference_no ? saleInvoiceCopy?.reference_no : "" ||
      isQuotationInvoice?.reference_no ? isQuotationInvoice?.reference_no : "" ||
        isSaleOrderInvoice?.reference_no ? isSaleOrderInvoice?.reference_no : "");
  const [isQuoteBuyerOrderNo, setQuoteBuyerOrderNo] = useState(
    saleInvoiceCopy?.order_no ? saleInvoiceCopy?.order_no : "" ||
      isQuotationInvoice?.order_no ? isQuotationInvoice?.order_no : "" ||
        isSaleOrderInvoice?.order_no ? isSaleOrderInvoice?.order_no : "");
  const [isQuoteBuyerOrderDate, setQuoteBuyerOrderDate] = useState(
    saleInvoiceCopy?.order_date ? saleInvoiceCopy?.order_date : "" ||
      isQuotationInvoice?.order_date ? isQuotationInvoice?.order_date : "" ||
        isSaleOrderInvoice?.order_date ? isSaleOrderInvoice?.order_date : "");
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState(
    saleInvoiceCopy?.customer?.customer_name ? saleInvoiceCopy?.customer?.customer_name : "" ||
      isQuotationInvoice?.customer?.customer_name ? isQuotationInvoice?.customer?.customer_name : "" ||
        isSaleOrderInvoice?.customer?.customer_name ? isSaleOrderInvoice?.customer?.customer_name : "");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [isShowCustomerPopup, setShowCustomerPopup] = useState(false);
  const [customerId, setCustomerId] = useState(
    saleInvoiceCopy?.customer?.id ? saleInvoiceCopy?.customer?.id : "" ||
      isQuotationInvoice?.customer?.id ? isQuotationInvoice?.customer?.id : "" ||
        isSaleOrderInvoice?.customer?.id ? isSaleOrderInvoice?.customer?.id : "");
  const [customerError, setCustomerError] = useState("");
  const [customerDetails, setCustomerDetails] = useState();
  const [itemWiseTax, setItemWiseTax] = useState(true);
  const [packagingCharges, setpackagingCharges] = useState("");
  // const [billNumbers, setBillNumbers] = useState([]);
  const [selectedBillItems, setSelectedBillItems] = useState([]);
  const [BtnDisable, setBtnDisabled] = useState(false);

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/customers/customer_search?query=${term}`
        );
        const customers = response.data;
        if (customers.length > 0) {
          setFilteredCustomers(customers);
          setShowDropdown(true);
          setShowAddCustomer(false);
        } else {
          setFilteredCustomers([]);
          setShowDropdown(true);
          setShowAddCustomer(true);
        }
      } catch (error) {
        setShowDropdown(false);
        setShowAddCustomer(true);
      }
    } else {
      setShowDropdown(false);
      setShowAddCustomer(false);
    }
  };

  const handleSelectCustomer = (customer) => {
    setCustomerDetails(customer);
    fetchCustomerData(customer.id);
    setCustomerId(customer.id);
    setSearchTerm(
      customer.first_name && customer.last_name
        ? `${customer.first_name} ${customer.last_name}`
        : customer.first_name || customer.last_name
    );
    setShowDropdown(false);
  };

  const fetchCustomerData = async (customerId) => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/transactions/entries/invoice_reference?customer_id=${customerId}&type=delivery_challan`
      );

      const formattedData = response?.data?.map(entry => ({
        value: entry.bill_number,
        label: entry.bill_number,
        bill_items: entry.bill_items
      }));

      setChallanOptions(formattedData);
    } catch (error) {
      console.error("No Data Avaialble for this customer");
    }
  };

  const handleCreateCustomer = () => {
    setShowCustomerPopup(true);
  }

  const closeCommonCustomerPopup = () => {
    setShowCustomerPopup(false);
  }

  const handleAddCustomer = () => {
    setShowCustomerPopup(true);
    setShowDropdown(false);
    setShowAddCustomer(false);
  };

  const handleSelectedCustomer = (customer) => {
    setCustomerId(customer?.id);
    setSearchTerm(
      customer.first_name && customer.last_name
        ? `${customer.first_name} ${customer.last_name}`
        : customer.first_name || customer.last_name
    );
  }

  const handleTotalAmount = (amount) => {
    setTotalAmount(amount);
  }

  const handleSubTotal = (amount) => {
    setSubTotal(amount);
  }

  const handleGstValue = (amount) => {
    setGstAmount(amount);
  }

  const handleDiscountValue = (amount) => {
    setDiscount(amount);
  }

  const updatedSelectedItems = (items) => {
    setSelectedItems(items);
  }

  const handleNewSaleInvoice = async () => {
    setBtnDisabled(true);
    if (!customerId) {
      setCustomerError("Please select a customer before saving the Invoice.");
      return;
    }
    setCustomerError("");

    try {
      const uniqueItems = [];
      const itemIds = new Set();
      selectedItems.forEach(item => {
        if (!itemIds.has(item.id)) {
          itemIds.add(item.id);
          uniqueItems.push({
            item_id: item?.item_id,
            quantity: item?.qty,
            item_total_discount: item.discountAmount ? item?.discountAmount?.toFixed(2) : 0,
            item_total_tax: item.tax ? item?.tax?.toFixed(2) : 0,
            item_total_amount: item?.saleAmount?.toFixed(2),
            branch_id: branchId.id
          });
        }
      });

      const formData = {
        bill: {
          customer_id: customerId,
          estimate_date: isEstimateDate,
          valid_date: isValidTillDate,
          reference_no: selectedReferences.map(option => option.value),//Array.isArray(isQuoteReference) ? isQuoteReference : [isQuoteReference],
          order_no: isQuoteBuyerOrderNo,
          order_date: isQuoteBuyerOrderDate,
          bill_items_attributes: uniqueItems,
          total_amount: totalAmount,
          sub_total: subTotal,
          total_gst: gstAmount,
          status: 0,
          total_discount: discount,
          parcel_charges: packagingCharges
        }
      }
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/transactions/entries?type=invoice`,
        formData
      );
      successNotification("Sale Invoice Created!");
      if (invoiceCreated) {
        invoiceCreated(Array.isArray(response.data.entry) ? response.data.entry : [response.data.entry]);
      }
      onClose();
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
    }
  }

  const handleEditSaleInvoice = async (saleInvoiceEditId) => {
    setBtnDisabled(true);
    if (!customerId) {
      setCustomerError("Please select a customer before saving the Invoice.");
      return;
    }
    setCustomerError("");

    if (saleInvoiceEditId) {
      try {
        const uniqueItems = [];
        const itemIds = new Set();

        selectedItems.forEach(item => {
          if (!itemIds.has(item.id)) {
            itemIds.add(item.id);
            uniqueItems.push({
              id: item?.id ? item?.id : null,
              item_id: item?.item_id,
              quantity: item?.qty,
              item_total_discount: item?.discount_amount?.toFixed(2),
              item_total_tax: item?.tax?.toFixed(2),
              item_total_amount: item?.saleAmount?.toFixed(2),
              branch_id: branchId.id
            });
          }
        });

        const formData = {
          bill: {
            customer_id: customerId || 1,
            estimate_date: isEstimateDate,
            valid_date: isValidTillDate,
            reference_no: selectedReferences.map(option => option.value), //Array.isArray(selectedBillItems) ? selectedBillItems : [selectedBillItems],
            order_no: isQuoteBuyerOrderNo,
            order_date: isQuoteBuyerOrderDate,
            bill_items_attributes: uniqueItems,
            total_amount: totalAmount,
            sub_total: subTotal,
            total_gst: gstAmount,
            status: 0,
            total_discount: discount,
            parcel_charges: packagingCharges
          }
        }
        await sendRequest(
          "PUT",
          `${BASE_URL}/v1/transactions/entries/${saleInvoiceEditId}?type=invoice`,
          formData
        );
        successNotification("Sale Invoice Updated!");
        onClose();
      }
      catch (error) {
        error.data.errors.map((error) => ErrorNotification(error));
      }
    }
    else {
      console.log("Failed to update sale invoice.")
    }
  }

  // const [deliveryChallan, setDeliveryChallan] = useState([]);
  const [challanOptions, setChallanOptions] = useState([]);
  const [selectedReferences, setSelectedReferences] = useState([]);
  // const [selectedBillNumbers, setSelectedBillNumbers] = useState([]);
  // const [challanList, setChallanList] = useState([]);

  // const fetchAllDeliveryChallans = async () => {
  //   try {
  //     const request_url = `${BASE_URL}/v1/transactions/entries?type=delivery_challan`;
  //     const response = await sendRequest("GET", request_url);
  //     const data = response.data.data;
  //     setDeliveryChallan(data);
  //     const options = data.map((challan) => ({
  //       value: challan.bill_number,
  //       label: challan.bill_number,
  //     }));
  //     setChallanOptions(options);
  //   } catch (error) {
  //     console.error("Error fetching delivery challans:", error);
  //   }
  // };

  // useEffect(() => {
  //   // fetchAllDeliveryChallans();
  //   fetchCustomerData();
  // }, []);

  useEffect(() => {
    if (customerId) {
      fetchCustomerData(customerId);
    }
  }, [customerId]);

  // const handleSelectChange = (selectedOptions) => {
  //   setBillNumbers(selectedOptions || []);
  //   if (selectedOptions.length > 0) {
  //     const selectedValues = selectedOptions.map((option) => option.value);
  //     setQuoteReference(selectedValues)
  //     const filteredChallans = deliveryChallan.filter((challan) =>
  //       selectedValues.includes(challan.bill_number)
  //     );
  //     setChallanList(filteredChallans);
  //   } else {
  //     setChallanList([]);
  //   }
  // };

  const invoieStyle = {
    menu: (provided) => ({
      ...provided,
      maxHeight: 150,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 150,
    }),
  };

  const handleToggleChange = (value) => {
    setItemWiseTax(value);
  }

  const handlePackagingCharge = (amount) => {
    setpackagingCharges(amount);
  }

  const fetchUpdatedCustInvoieDetails = async (updatedCustId) => {
    if (updatedCustId) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/customers/${updatedCustId}`
        );
        const updateCustomers = response.data.data;
        setCustomerDetails(updateCustomers);
      }
      catch {
        console.log("customer not fetch")
      }
    }
  }

  const handleCustInvoiceUpdate = (updatedCustomer) => {
    fetchUpdatedCustInvoieDetails(updatedCustomer);
  };

  const handleChallanSelect = (selectedOptions) => {
    setSelectedReferences(selectedOptions);
    const selectedItems = selectedOptions.flatMap(option => option.bill_items || []);
    setSelectedBillItems(selectedItems);
  };

  const selectedEditReferences = saleInvoiceCopy?.reference_no?.map(ref => ({
    label: ref,
    value: ref,
  })) || [];


  return (
    <>
      <div className="new-invoice-main-container">
        <SaleCommonPopup onClose={onClose} onSave={handleNewSaleInvoice} isUpdated={isUpdated} onUpdate={() => handleEditSaleInvoice(saleInvoiceCopy.id)}
          BtnDisable={BtnDisable}
        />
        <div className="new-invoice-sub-container">
          <div className="new-invoice-first-div">
            {/* <div className="new-invoice-estimate-no-grp">
              <span className="new-invoice-est-no-title">Invoice No</span>
              <span className="new-invoice-est-no">INV24/22-23</span>
            </div> */}
            <div className="new-invoice-estimate-date-grp">
              <span className="new-invoice-estdate-title">Invoice Date</span>
              <span className="new-invoice-estdate-input">
                <input className="form-control new-invoice-first-div-input"
                  type="date"
                  onChange={(e) => setEstimateDate(e.target.value)}
                  value={isEstimateDate} />
              </span>
            </div>
            <div className="new-invoice-tilldate-grp">
              <span className="new-invoice-est-tilldate-title">Due Date </span>
              <span className="new-invoice-est-tilldate-input">
                <input className="form-control new-invoice-first-div-input"
                  type="date"
                  onChange={(e) => setValidTillDate(e.target.value)}
                  value={isValidTillDate} />
              </span>
            </div>

            <div className="new-invoice-reference-grp">
              <span className="new-invoice-est-reference-title">Reference</span>
              <span className="new-invoice-est-reference-input">
                {
                  (saleInvoiceCopy?.reference_no?.some(ref => ref !== "")) ? (
                    //working for edit case
                    <Select
                      isMulti
                      options={challanOptions}
                      onChange={handleChallanSelect}
                      placeholder="Search by Bill Number"
                      value={selectedEditReferences}
                      styles={invoieStyle}
                    />
                  ) : (
                    //working for create case
                    <Select
                      isMulti
                      options={challanOptions}
                      onChange={handleChallanSelect}
                      placeholder="Search by Bill Number"
                      value={selectedReferences}
                      styles={invoieStyle}
                    />
                  )
                }
              </span>
            </div>

            {/* <div className="new-invoice-type-grp">
              <span className="new-invoice-type-title">Type</span>
              <select className="form-control new-invoice-first-div-input">
                <option>Regular</option>
                <option>Export with Tax Payment</option>
                <option>Export without Tax Payment(bond/LUT)</option>
                <option>SEZ Supplier with Tax Payment</option>
                <option>SEZ Supplier without Tax Payment</option>
                <option>Bill of Supply</option>
                <option>Deemed Export</option>
              </select>
              <div className="new-invoice-select-icon">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div> */}
          </div>

          <div className="new-invoice-second-div">
            <div className="new-invoice-customer-div">
              <span className="new-invoice-cust-title">Customer</span>
              <span className="new-invoice-cust-search">
                <input
                  className="form-control new-invoice-cust-field"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search customer..."
                />
                {showDropdown && (
                  <ul className="new-invoice-customer-dropdown">
                    {filteredCustomers.length > 0 ? (
                      filteredCustomers.map((customer, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelectCustomer(customer)}
                          className="new-invoice-dropdown-item"
                        >
                          {customer.first_name && customer.last_name
                            ? `${customer.first_name} ${customer.last_name}`
                            : customer.first_name || customer.last_name}
                        </li>
                      ))
                    ) : (
                      <li className="dropdown-item">No customer found</li>
                    )}
                    {showAddCustomer && (
                      <li className="customer-dropdown-item" onClick={handleAddCustomer}>
                        <button className="btn btn-primary" onClick={handleCreateCustomer}>Add Customer</button>
                      </li>
                    )}
                  </ul>
                )}
              </span>
              {customerError && (
                <span className="error-message" style={{ color: 'red', marginTop: '5px' }}>
                  {customerError}
                </span>
              )}
            </div>
            {customerId ? (
              <>
                <div className="new-quote-second-billing">
                  <div className="new-quote-billing-first-div">
                    <span className="new-quote-bill-title">Billing Address</span>
                    <BsPencilSquare onClick={handleCreateCustomer} />
                  </div>
                  <span className="new-quote-billing-address">
                    {customerDetails?.addresses && (
                      <>
                        {customerDetails?.addresses
                          .filter(address => address.address_type === 'billing')
                          .map(billingAddress => (
                            <div key={billingAddress.id}>
                              {billingAddress?.address_line_1},
                              {billingAddress?.address_line_2},
                              {billingAddress?.city},
                              {billingAddress?.state},
                              {billingAddress?.country}
                            </div>
                          ))}
                      </>
                    )}
                  </span>
                </div>

                <div className="new-quote-second-shipping">
                  <div className="new-quote-billing-first-div">
                    <span className="new-quote-shipping-title">Shipping Address</span>
                    <BsPencilSquare onClick={handleCreateCustomer} />
                  </div>
                  <span className="new-quote-shipping-address">
                    {customerDetails?.addresses && (
                      <>
                        {customerDetails?.addresses
                          .filter(address => address.address_type === 'shipping')
                          .map(shippingAddress => (
                            <div key={shippingAddress.id}>
                              {shippingAddress?.address_line_1},
                              {shippingAddress?.address_line_2},
                              {shippingAddress?.city},
                              {shippingAddress?.state},
                              {shippingAddress?.country}
                            </div>
                          ))}
                      </>
                    )}
                  </span>
                </div>
              </>
            ) : (
              ''
            )}
          </div>

          <div className="new-sale-invoice-customer-info">
            <div className="new-invoice-buyer-order-grp">
              <span className="new-invoice-buyer-order-title">Buyer's Order</span>
              <input className="form-control new-invoice-buyer-order"
                type="number"
                onChange={(e) => setQuoteBuyerOrderNo(e.target.value)}
                value={isQuoteBuyerOrderNo} />
            </div>
            <div className="new-invoice-buyer-order-date-grp">
              <span className="new-invoice-buyer-order-date-title">Buyer's Order Date</span>
              <input className="form-control new-invoice-buyer-order-date"
                type="date"
                onChange={(e) => setQuoteBuyerOrderDate(e.target.value)}
                value={isQuoteBuyerOrderDate}
              />
            </div>

            <div className="new-invoice-toggle-button-grp">
              <CommonToggleButton onToggleChange={handleToggleChange} />
            </div>
          </div>

          <div className="new-invoice-table">
            <CommonSalePopupTable
              updatedSelectedItems={(items) => updatedSelectedItems(items)}
              totalAmountValue={(totalAmount) => handleTotalAmount(totalAmount)}
              subTotalValue={(subTotal) => handleSubTotal(subTotal)}
              gstValue={(gstValue) => handleGstValue(gstValue)}
              discountValue={(discount) => handleDiscountValue(discount)}
              packagingValue={(packagingCharges) => handlePackagingCharge(packagingCharges)}
              saleInvoiceCopy={saleInvoiceCopy}
              isQuotationInvoice={isQuotationInvoice}
              isSaleOrderInvoice={isSaleOrderInvoice}
              // selectedChallanRecords={challanList}
              isTotalTaxApplied={itemWiseTax}
              selectedBillItems={selectedBillItems}
              challanOptions={challanOptions}
            />
          </div>

          {/* <div className="new-invoice-third-div">
            <div className="new-invoice-note-grp">
              <span className="new-invoice-note-title">Notes</span>
              <span className="new-invoice-note-area">
                <textarea className="new-invoice-note-textarea-input" />
              </span>
            </div>
            <div className="new-invoice-terms-condition-grp">
              <span className="new-invoice-term-title">Terms & Conditions</span>
              <span className="new-invoice-term-textarea">
                <textarea className="new-invoice-term-textarea-input" />
              </span>
            </div>
            <div className="new-invoice-bank-grp">
              <span className="new-invoice-bank-title">Bank</span>
              <span className="new-invoice-bank-input">
                <input className="form-control new-invoice-last-input" />
              </span>
            </div>
            <div className="new-invoice-sales-rep-grp">
              <span className="new-invoice-sale-rep-title">Sales Rep. </span>
              <span className="new-invoice-sale-rep-input">
                <input className="form-control new-invoice-last-input" />
              </span>
            </div>
          </div> */}
        </div>
      </div>
      {isShowCustomerPopup && (
        <div className="new-sale-order-customer-popup">
          <CommonCustomerPopup
            selectedCustomer={(customer) => handleSelectedCustomer(customer)}
            onClose={closeCommonCustomerPopup}
            customerDetails={customerDetails}
            onCustomerUpdate={handleCustInvoiceUpdate}
          />
        </div>
      )}
    </>
  );
}

export default NewSaleInvoice;
