import React, { useState } from "react";
import "../../OnlineOrder/PlatformItem/PlatformItems.css";
import UpdatePlatformItem from "./UpdatePlatformItem/UpdatePlatformItem";
import BackButton from "../../../../BackButton";

function PlatformItems() {
  const [selectedStore, setSelectedStore] = useState("All Store");
  const [selectedCategory, setSelectedCategory] = useState("All Category");
  const [searchTerm, setSearchTerm] = useState("");
  const [isUpdateItemOpen, setIsUpdateItemOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSearch = () => {
    console.log("Searching for:", searchTerm);
  };

  const dummyItems = [
    { id: 1, title: "VEGETABLE SAAGWALA", price: 200.0, active: true },
    { id: 2, title: "VEG ZALFREZI", price: 210.0, active: true },
  ];

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsUpdateItemOpen(true);
  };

  const handleCloseUpdateItem = () => {
    setIsUpdateItemOpen(false);
    setSelectedItem(null);
  };

  return (
    <div className="platform-items-container">
      <div className="platform-items-header">
        <span className="platform-item-back-btn"><BackButton /></span>
        <div className="platform-items-search">
          <select
            value={selectedStore}
            onChange={(e) => setSelectedStore(e.target.value)}
            className="platform-items-dropdown"
          >
            <option>All Store</option>
          </select>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="platform-items-dropdown"
          >
            <option>All Category</option>
          </select>
          <input
            type="text"
            placeholder="Enter Item Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="platform-items-search-input"
          />
          <button onClick={handleSearch} className="platform-items-search-button">
            Search
          </button>
        </div>
      </div>
      <div className="platform-items-actions">
        <span className="platform-items-action-link">Add</span> /
        <span className="platform-items-action-link">Update</span> /
        <span className="platform-items-action-link">Delete</span>
      </div>

      <div className="platform-item-table-grp">
        <div className="platform-item-details-grp">
          <span className="plat-item-details-title">ITEMS DETAIL</span>
        </div>
        <table className="platform-items-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Price</th>
              <th>Active/Inactive</th>
            </tr>
          </thead>
          <tbody>
            {dummyItems.map((item) => (
              <tr key={item.id} onClick={() => handleItemClick(item)}>
                <td>{item.id}</td>
                <td>{item.title}</td>
                <td>₹ {item.price.toFixed(2)}</td>
                <td>{item.active ? "✓" : "✗"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isUpdateItemOpen && (
        <div className="platform-items-update-item-overlay" onClick={handleCloseUpdateItem}>
          <div onClick={(e) => e.stopPropagation()}>
            <UpdatePlatformItem item={selectedItem} onClose={handleCloseUpdateItem} />
          </div>
        </div>
      )}
    </div>
  );
}

export default PlatformItems;
