import React, { useEffect, useState } from "react";
import "./NewPurchaseQuotation.css";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import CommonSalePopupTable from "../../CompanySales/CommonSalePopupTable/CommonSalePopupTable";
import SaleCommonPopup from "../../CompanySales/SaleCommonPopupHeader/SaleCommonPopupHeader";
import CommonSupplierPopup from "../../Common/CommonSupplierPopup";
import successNotification from "../../../Notification/SuccessNotification";
import ErrorNotification from "../../../Notification/ErrorNotification";
import { current_user } from "../../../Authentication/AuthenticationContext";
import CommonItemTable from "../../Common/CommonItemTable";
import { SupplierDetails } from "../../../services/supplierService";

function NewPurchaseQuotation({
  onClose,
  title,
  editedRowData,
  type,
  isCreated,
  isUpdated,
  fetchPurchaseQuotations,
  purchaseQuotationCopy,
  isQuotationUpdated,
}) {
  const [searchTerm, setSearchTerm] = useState(
    purchaseQuotationCopy?.supplier?.supplier_name || ""
  );
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddSupplier, setShowAddSupplier] = useState(false);
  const [isShowSupplierPopup, setShowSupplierPopup] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [gstAmount, setGstAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [selectedItems, setSelectedItems] = useState(
    purchaseQuotationCopy?.bill_items || []
  );
  const [branchId, setBranchId] = useState("");
  const [supplierId, setSupplierId] = useState(
    purchaseQuotationCopy?.supplier?.id || ""
  );
  const [isEstimateDate, setEstimateDate] = useState(
    purchaseQuotationCopy?.estimate_date || ""
  );
  const [isValidTillDate, setValidTillDate] = useState(
    purchaseQuotationCopy?.valid_date || ""
  );
  const [reference, setReference] = useState(
    purchaseQuotationCopy?.bill_number || ""
  );
  const [orderNo, setOrderNo] = useState(purchaseQuotationCopy?.order_no || "");
  const [isOrderDate, setOrderDate] = useState(
    purchaseQuotationCopy?.order_date || ""
  );
  const [selectedSupplier, setSelectedSupplier] = useState(
    purchaseQuotationCopy?.supplier || []
  );
  const [isSaving, setIsSaving] = useState(false);
  const [isSupplierUpdated, setIsSupplierUpdated] = useState();

  useEffect(() => {
    if (isUpdated) {
      setOrderNo(editedRowData.order_no || "");
      setEstimateDate(editedRowData.estimate_date || "");
      setOrderDate(editedRowData.order_date || "");
      setValidTillDate(editedRowData.valid_date || "");
      setReference(editedRowData.bill_number || "");
      setSelectedSupplier(editedRowData.supplier || "");
    } else if (isQuotationUpdated && purchaseQuotationCopy) {
      setOrderNo(purchaseQuotationCopy.order_no || "");
      setEstimateDate(purchaseQuotationCopy.estimate_date || "");
      setOrderDate(purchaseQuotationCopy.order_date || "");
      setValidTillDate(purchaseQuotationCopy.valid_date || "");
      setReference(purchaseQuotationCopy.bill_number || "");
      setSelectedSupplier(purchaseQuotationCopy.supplier || "");
      setSupplierId(purchaseQuotationCopy.supplier?.id || "");
      setSearchTerm(purchaseQuotationCopy.supplier?.supplier_name || "");
    } else if (isCreated) {
      formData();
    }
  }, [
    editedRowData,
    isCreated,
    isUpdated,
    purchaseQuotationCopy,
    isQuotationUpdated,
  ]);

  const formData = () => {
    setEstimateDate("");
    setValidTillDate("");
    setReference("");
    setSelectedSupplier("");
    setOrderNo("");
    setOrderDate("");
    setSelectedItems([]);
  };

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/suppliers?query=${term}`
        );
        const suppliers = response.data.data;
        if (suppliers.length > 0) {
          setFilteredSuppliers(suppliers);
          setShowDropdown(true);
          setShowAddSupplier(false);
        } else {
          setFilteredSuppliers([]);
          setShowDropdown(true);
          setShowAddSupplier(true);
        }
      } catch (error) {
        setShowDropdown(false);
        setShowAddSupplier(true);
      }
    } else {
      setShowDropdown(false);
      setShowAddSupplier(false);
    }
  };

  const handleSelectSupplier = (supplier) => {
    setSelectedSupplier(supplier);
    setSupplierId(supplier.id);
    setSearchTerm(supplier.first_name || supplier.last_name);
    setShowDropdown(false);
  };

  const handleCreateSupplier = () => {
    setIsSupplierUpdated(false);
    setShowSupplierPopup(true);
  };

  const handleEditSupplier = () => {
    setIsSupplierUpdated(true);
    setShowSupplierPopup(true);
  };

  const updatedSelectedItems = (items) => {
    setSelectedItems(items);
  };

  const handleTotalAmount = (amount) => {
    setTotalAmount(amount);
  };

  const handleSubTotal = (amount) => {
    setSubTotal(amount);
  };

  const handleGstValue = (amount) => {
    setGstAmount(amount);
  };

  const handleDiscountValue = (amount) => {
    setDiscount(amount);
  };

  const closeCommonSupplierPopup = () => {
    setShowSupplierPopup(false);
  };

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setBranchId(currentUser.company.branch_info);
    };
    fetchUser();
  }, []);

  const handlePurchaseQuotation = async () => {
    if (isSaving) return;

    if (selectedSupplier.length === 0) {
      ErrorNotification("Please select supplier");
      return;
    }
    if (selectedItems.length === 0) {
      ErrorNotification("Please select items");
      return;
    }
    const isPurchaseQuotation = type === "purchase_quotation";
    try {
      const uniqueItems = [];
      const itemIds = new Set();

      selectedItems.forEach((item) => {
        if (item && !itemIds.has(item.id)) {
          itemIds.add(item.id);

          uniqueItems.push({
            item_id: item?.item_id,
            quantity: item?.qty || 0,
            item_total_discount: item?.discountAmount
              ? parseFloat(item.discountAmount).toFixed(2)
              : "0.00",
            item_total_tax: item?.tax
              ? parseFloat(item.tax).toFixed(2)
              : "0.00",
            item_total_amount: isPurchaseQuotation
              ? parseFloat(item.total_actual_price || 0).toFixed(2)
              : parseFloat(item.saleAmount || 0).toFixed(2),
            branch_id: branchId ? branchId.id : null,
            ...(!isQuotationUpdated && isUpdated
              ? { id: item?.id || null }
              : {}),
          });
        }
      });

      const discount = selectedItems
        .reduce((sum, item) => {
          return (
            sum +
            parseFloat(item?.qty || 0) * parseFloat(item?.discountAmount || 0)
          );
        }, 0)
        .toFixed(2);

      const formData = {
        bill: {
          supplier_id: supplierId || 1,
          estimate_date: isEstimateDate,
          valid_date: isValidTillDate,
          // reference_no: Array.isArray(reference) ? reference : [reference],
          order_no: orderNo,
          order_date: isOrderDate,
          bill_items_attributes: uniqueItems,
          total_amount: totalAmount,
          sub_total: subTotal,
          total_gst: gstAmount,
          total_discount: discount,
          due_days: "",
          due_amount: totalAmount,
        },
      };

      setIsSaving(true);

      let pQId = 0;
      if (isUpdated && !isQuotationUpdated) {
        pQId = editedRowData.id;
      }
      const requestType = isUpdated && !isQuotationUpdated ? "PUT" : "POST";
      const url = `${BASE_URL}/v1/transactions/entries${
        isUpdated && !isQuotationUpdated ? `/${editedRowData.id}` : ""
      }?type=purchase_quotation&devise_type=web`;

      await sendRequest(requestType, url, formData);
      fetchPurchaseQuotations();

      successNotification(
        isUpdated && !isQuotationUpdated
          ? "Purchase quotation Updated!"
          : "Purchase quotation Created!"
      );

      onClose();
    } catch (error) {
      console.error("Error in handlePurchaseQuotation:", error);
      setIsSaving(false);
      if (error.data && error.data.errors) {
        error.data.errors.forEach((errorMessage) =>
          ErrorNotification(errorMessage)
        );
      } else {
        ErrorNotification("An unexpected error occurred.");
      }
    }
  };

  const headers = [
    { key: "id", label: "S. No" },
    { key: "item_name", label: "Item Name" },
    { key: "hsn", label: "HSN" },
    { key: "tax", label: "Tax" },
    { key: "qty", label: "Quantity" },
    { key: "unit", label: "Unit" },
    {
      key: "price",
      label: type === "purchase_quotation" ? "Purchase Price" : "Sales Price",
    },
    { key: "discount", label: "Discount" },
    { key: "amount", label: "Amount" },
    { key: "actions", label: "Action" },
  ];

  const handleClose = () => {
    setIsSaving(false);
    onClose();
  };

  return (
    <>
      <div className="new-purchase-quote-main-container">
        <SaleCommonPopup
          isSaving={isSaving}
          title={title}
          isUpdated={isQuotationUpdated ? false : isUpdated}
          onClose={handleClose}
          onUpdate={isQuotationUpdated ? null : () => handlePurchaseQuotation()}
          onSave={() => handlePurchaseQuotation()}
        />
        <div className="container-fluid">
          <div className="row mb-3 mt-3">
            <div className="pq_table ms-2">
              <div className="pq_table_row pq_table_header">
                {isUpdated ? (
                  <div className="pq_table_th">Purchase Quote No</div>
                ) : (
                  ""
                )}
                <div className="pq_table_th">Purchase Quote Date</div>
                <div className="pq_table_th">Valid Till</div>
                <div className="pq_table_th"></div>
              </div>

              <div className="pq_table_row">
                {isUpdated ? (
                  <div className="pq_table_td">PQ-{orderNo}</div>
                ) : (
                  <div className="pq_table_td"></div>
                )}
                <div className="pq_table_td">
                  <input
                    className="form-control"
                    type="date"
                    onChange={(e) => setEstimateDate(e.target.value)}
                    value={isEstimateDate}
                    max={isValidTillDate || undefined}
                  />
                </div>
                <div className="pq_table_td">
                  <input
                    className="form-control"
                    type="date"
                    onChange={(e) => setValidTillDate(e.target.value)}
                    value={isValidTillDate}
                    min={isEstimateDate || undefined}
                  />
                </div>
                <div className="pq_table_td">
                  {/* <input
                    className="form-control"
                    type="text"
                    onChange={(e) => setReference(e.target.value)}
                    value={reference}
                    placeholder="Reference"
                  /> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-2 ms-2 position-relative">
              <label
                className="form-label text-black"
                style={{ fontSize: "14px" }}
              >
                Supplier *
              </label>
              <input
                className="form-control supplier-search"
                type="text"
                value={selectedSupplier.supplier_name || searchTerm}
                onChange={handleSearch}
                placeholder="Search supplier..."
              />
              {showDropdown && (
                <ul className="list-group supplier-search-dropdown">
                  {filteredSuppliers.length > 0 ? (
                    filteredSuppliers.map((supplier, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelectSupplier(supplier)}
                        className="list-group-item"
                        style={{ cursor: "pointer" }}
                      >
                        {supplier.first_name} {supplier.last_name}
                      </li>
                    ))
                  ) : (
                    <li className="list-group-item">No supplier found</li>
                  )}
                  {showAddSupplier && (
                    <li className="list-group-item">
                      <button
                        className="btn btn-primary w-100"
                        onClick={handleCreateSupplier}
                      >
                        Add Supplier
                      </button>
                    </li>
                  )}
                </ul>
              )}
            </div>

            <div className="col-md-2">
              <label className="form-label" style={{ fontSize: "14px" }}>
                Supplier Quotation No
              </label>
              <input
                className="form-control"
                type="number"
                onChange={(e) => setOrderNo(e.target.value)}
                value={orderNo}
                placeholder="Quotation No"
              />
            </div>
            <div className="col-md-2">
              <label className="form-label" style={{ fontSize: "14px" }}>
                Supplier Quotation Date
              </label>
              <input
                className="form-control"
                type="date"
                onChange={(e) => setOrderDate(e.target.value)}
                value={isOrderDate}
              />
            </div>

            <div className="col-md-4">
              <SupplierDetails
                supplierId={supplierId}
                supplierDetails={selectedSupplier}
                handleEditSupplier={handleEditSupplier}
              />
            </div>
          </div>

          <div className="new-purchase-quote-table">
            {isUpdated || isQuotationUpdated ? (
              <CommonItemTable
                type={type}
                headers={headers}
                subTotalValue={(amount) => handleSubTotal(amount)}
                totalValue={(amount) => handleTotalAmount(amount)}
                onFormDataChange={(items) => updatedSelectedItems(items)}
                editedRowData={
                  isQuotationUpdated ? purchaseQuotationCopy : editedRowData
                }
                isCopy={isQuotationUpdated}
              />
            ) : (
              <CommonSalePopupTable
                type={type}
                updatedSelectedItems={(items) => updatedSelectedItems(items)}
                totalAmountValue={(totalAmount) =>
                  handleTotalAmount(totalAmount)
                }
                subTotalValue={(subTotal) => handleSubTotal(subTotal)}
                gstValue={(gstValue) => handleGstValue(gstValue)}
                discountValue={(discount) => handleDiscountValue(discount)}
              />
            )}
          </div>
        </div>
      </div>

      {isShowSupplierPopup && (
        <div className="purchase-quote-supplier-popup">
          <CommonSupplierPopup
            isSupplierUpdated={isSupplierUpdated}
            supplierDetails={selectedSupplier}
            onClose={closeCommonSupplierPopup}
          />
        </div>
      )}
    </>
  );
}

export default NewPurchaseQuotation;