import React, { useEffect, useState } from 'react';
import './UpdateRestaurantConfig.css';
import { BASE_URL } from '../../Url/constants';
import { sendRequest } from '../../ApiRequest';
import successNotification from '../../Notification/SuccessNotification';
import { useTranslation } from "react-i18next";
import CommonCloseButton from '../../components/Restaurant/CommonCloseButton/CommonCloseButton';

const UpdateRestaurantConfig = ({ onClose }) => {
  const [configOptions, setConfigOptions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getConfigOptions();
  }, []);

  const getConfigOptions = async () => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/restaurant/orders/orders/get_config_options`);
    if (response.status === 200) {
      console.log(response.data.data);
      setConfigOptions(response.data.data);
    }
  }

  const updateConfigOption = async (option_id, checked_value) => {
    const response = await sendRequest('PUT', `${BASE_URL}/v1/restaurant/orders/orders/update_option_config?option_id=${option_id}&status=${checked_value}`);
    if (response.status === 200) {
      successNotification("updated successfully");
      getConfigOptions();
      onClose();
    }
  }

  const handleCheckboxChange = (option_id, event) => {
    const checked_value = event.target.checked;
    updateConfigOption(option_id, checked_value);
  }

  return (
    <>
      <div className='update-restaurant-setting-menu-btn-main'>
        <div className='setting-menu-title'>
          <span className='update-menu-title-heading'>{t("Pax Setting")}</span>
          <span onClick={onClose}>
            <CommonCloseButton />
          </span>
        </div>

        <div className='res-updateconfig-sub'>
          <div className='config-update-div-first'>
            {configOptions.map((option) => (
              <div className='update-config-option-container' key={option.id}>
                <input
                  type='checkbox'
                   className='config-custom-checkbox'
                  checked={option?.config_options && option?.config_options?.configs?.status}
                  onChange={(event) => handleCheckboxChange(option.id, event)}
                />
                <label className='update-config-option-name'>{option.name.replace(/_/g, ' ')}</label>
              </div>
            ))}
          </div>
          <div className='update-res-config-setting-button'>
            <span className='buttons-updateconfig-close'>
              <button className='btn btn-danger update-config-close' onClick={onClose}> {t("Close")}</button>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateRestaurantConfig;
