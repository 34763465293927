import React, { useState } from 'react';
import './EditTaxGroup.css';
import { getToken } from '../../SecureStorage/Token';
import { BASE_URL } from '../../../Url/constants';
import Select from 'react-select'
import { sendRequest } from '../../../ApiRequest';
import { NotificationContainer } from 'react-notifications';
import ErrorNotification from '../../../Notification/ErrorNotification';
import successNotification from '../../../Notification/SuccessNotification';

const EditTaxGroup = ({ taxrates, taxgroup, handleUpdateFormSubmit, handleCloseModal }) => {
  const token = getToken();
  const [taxData, setTaxData] = useState({
    name: taxgroup.name,
    tax_ids: taxgroup.tax_ids
  });

  const [errors, setErrors] = useState({ name: '' });

  const validateGroupName = (name) => {
    const regex = /^[a-zA-Z0-9]{0,16}$/;
    if (!regex.test(name)) {
      if (name.length > 16) {
        return 'Group Name cannot exceed 16 characters.';
      } else {
        return 'Group Name can only contain alphanumeric characters.';
      }
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = validateGroupName(value);
    setErrors({ ...errors, [name]: error });
    setTaxData({ ...taxData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const groupNameError = validateGroupName(taxData.name);
    if (groupNameError) {
      setErrors({ ...errors, name: groupNameError });
      return;
    }

    const params = {
      tax_group: {
        name: taxData.name,
        tax_ids: taxData.tax_ids
      }
    };

    const url = `${BASE_URL}/v1/taxes/tax_groups/${taxgroup.id}`;
    try {
      const response = await sendRequest('put', url, params, token);
      if (response.status === 200) {
        handleUpdateFormSubmit(response.data.data);
        handleCloseModal();
        successNotification('Tax updated successfully');
      } else {
        ErrorNotification("Failed to update tax!");
      }
    } catch (error) {
      console.error('Error occurred while updating tax:', error);
      ErrorNotification("Failed to update tax!");
    }
  };

  return (
    <div className="edit-tax-container">
      <div className="edit-tax-group-modal-content">
        <span className="tax-close" onClick={handleCloseModal}>&times;</span>
        <h2>Edit Tax Group</h2>
        <hr />
        <form className='taxrate-edit-form' onSubmit={handleSubmit}>
          <div>
            <div className="edit-tax-rate-form-group">
              <label className='taxgroup-edit-name'>Group Name</label>
              <input
                type="text"
                name="name"
                className='taxrate-fom-input-title taxrate-fom-input input-b-color'
                value={taxData.name || ''}
                onChange={handleChange}
              />
              {errors.name && <div className="error-message">{errors.name}</div>}
            </div>
            <div className="edit-tax-rate-form-group">
              <label>Taxes</label>
              <Select
                isMulti
                options={taxrates}
                getOptionLabel={(option) => option.title}
                getOptionValue={(option) => option.id}
                className="basic-multi-select tax-group-multiselect"
                classNamePrefix='select'
                value={taxrates.filter(option => taxData.tax_ids.includes(option.id))}
                onChange={(selectedOptions) => {
                  const taxIds = selectedOptions.map(option => option.id);
                  setTaxData({ ...taxData, tax_ids: taxIds });
                }}
              />
            </div>
          </div>
          <hr />
          <button className="taxedit-submit-button" type="submit">Update</button>
        </form>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default EditTaxGroup;
