import React from "react";
import { BsFillTrashFill } from "react-icons/bs";
import "./CompanyUserCss/Company.css";
import { useTranslation } from "react-i18next";

function AddmoreBranch(props) {
  const { data, handleClick, handleChange, handleDelete } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="addmore-section"  style={{ flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
        {data.map((val, i) => (
          <div className="admore-field">
              <div className="col-md-3">
                <input
                  type="text"
                  name="branch_name"
                  placeholder={t("Enter Branch Name")}
                  className="form-control"
                  value={val.branch_name}
                  onChange={(e) => handleChange(e, i)}
                />
              </div>
              <div className="col-md-3">
            <input
              type="text"
              name="address"
              placeholder={t("Enter Address Name")}
              className="form-control"
              value={val.address}
              onChange={(e) => handleChange(e, i)}
              style={{marginLeft: "100px"}}

            />
            </div>
            <div className="col-md-3">
            <input
              type="text"
              name="pin_code"
              placeholder={t("Enter Pin Code")}
              className="form-control"
              value={val.pin_code}
              onChange={(e) => handleChange(e, i)}
              style={{marginLeft: "200px"}}
            />
            </div>
            {i !== 0 && (
           
                <BsFillTrashFill onClick={() => handleDelete(i)} />

            )}
          </div>
        ))}
          <div className="col-md-6 text-center">
            <a
              className="btn btn-primary ad-customer"
              style={{ marginRight: "-850px" }}
              onClick={handleClick}
            >
              +{t("Add More")}
            </a>
          </div>
      </div>
    </>
  );
}

export default AddmoreBranch;