import React, { useEffect, useRef, useState } from "react";
import "./Quotation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewQuotation from "./NewQuotation";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import PaginationControls from "../../Common/PaginationControls";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import NewSaleInvoice from "../SaleInvoice/NewSaleInvoice";
import NewSaleOrder from "../SaleOrder/NewSaleOrder";

function Quotation() {
  const [isNewQuotation, setNewQuotation] = useState(false);
  // const [showColumnFilter, setShowColumnFilter] = useState(false);
  // const [showDownIconOpton, setShowDownIconOpton] = useState(false);
  // const [showTurnIconOpton, setShowTurnIconOpton] = useState(false);
  const [showAttributeFilter, setShowAttributeFilter] = useState(false);
  const [showDownIconAction, setShowDownIconAction] = useState(false);
  const [selectedQuotation, setSelcectedQuotation] = useState("");
  const [quotations, setQuotations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  // const [searchQuery, setSearchQuery] = useState("");
  // const [visibleColumns, setVisibleColumns] = useState({
  //   date: true,
  //   estimateNo: true,
  //   contact: true,
  //   buyerOrderNo: true,
  //   buyerOrderDate: true,
  //   refNo: true,
  //   dueDays: true,
  //   status: true,
  //   mobileNumber: true,
  //   amount: true,
  //   dueDate: true,
  //   action: true,
  // });

  // const [tempVisibleColumns, setTempVisibleColumns] = useState({ ...visibleColumns });
  // const columnFilterRef = useRef(null);
  const attributeFilterRef = useRef(null);
  const [quotationCopy, setQuotationCopy] = useState("");
  const [QuotationUpdated, setQuotationUpdated] = useState(false);

  const [isNewQuotationInvoice, setNewQuotationInvoice] = useState(false);
  const [isQuotationInvoice, setQuotationInvoice] = useState("");
  const [isNewQuotationSaleOrder, setNewQuotationSaleOrder] = useState(false);
  const [isQuotationSaleOrder, setQuotationSaleOrder] = useState("");
  // const downIconFilterRef = useRef(null);
  // const turnIconFilterRef = useRef(null);
  const actionDownIconFilterRef = useRef(null);
  const [actionDropdownPosition, setActionDropdownPosition] = useState({ top: 0, left: 0 });

  // const handleCheckboxChange = (column) => {
  //   setTempVisibleColumns((prevState) => ({
  //     ...prevState,
  //     [column]: !prevState[column],
  //   }));
  // };

  // const handleApplyFilter = () => {
  //   setVisibleColumns(tempVisibleColumns);
  //   setShowColumnFilter(false);
  // };

  const OpenQuotationPopup = () => {
    setNewQuotation(true);
  };

  const closeNewQuotationPopup = () => {
    setNewQuotation(false);
    setQuotationCopy("");
    setQuotationUpdated(false);
  };

  const closeNewQuotationInvoicePopup = () => {
    setNewQuotationInvoice(false);
  };

  const closeNewQuotationSaleOrderPopup = () => {
    setNewQuotationSaleOrder(false);
  }

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       columnFilterRef.current &&
  //       !columnFilterRef.current.contains(event.target)
  //     ) {
  //       setShowColumnFilter(false);
  //     }
  //   };

  //   if (showColumnFilter) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [showColumnFilter]);

  useEffect(() => {
    const handleAttributeClickOutside = (event) => {
      if (
        attributeFilterRef.current &&
        !attributeFilterRef.current.contains(event.target)
      ) {
        setShowAttributeFilter(false);
      }
    };

    if (showAttributeFilter) {
      document.addEventListener("mousedown", handleAttributeClickOutside);
    } else {
      document.removeEventListener("mousedown", handleAttributeClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleAttributeClickOutside);
    };
  }, [showAttributeFilter]);

  // useEffect(() => {
  //   const handleDownIconClickOutside = (event) => {
  //     if (
  //       downIconFilterRef.current &&
  //       !downIconFilterRef.current.contains(event.target)
  //     ) {
  //       setShowDownIconOpton(false);
  //     }
  //   };

  //   if (showDownIconOpton) {
  //     document.addEventListener("mousedown", handleDownIconClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleDownIconClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleDownIconClickOutside);
  //   };
  // }, [showDownIconOpton]);

  // useEffect(() => {
  //   const handleTurnIconClickOutside = (event) => {
  //     if (
  //       turnIconFilterRef.current &&
  //       !turnIconFilterRef.current.contains(event.target)
  //     ) {
  //       setShowTurnIconOpton(false);
  //     }
  //   };

  //   if (showTurnIconOpton) {
  //     document.addEventListener("mousedown", handleTurnIconClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleTurnIconClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleTurnIconClickOutside);
  //   };
  // }, [showTurnIconOpton]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionDownIconFilterRef.current && !actionDownIconFilterRef.current.contains(event.target)) {
        setShowDownIconAction(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchQuotations = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/transactions/entries?type=quotation&page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setQuotations(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching quotations:", error);
    }
  };

  // const handlQuotationEdit = async (quotationEditId) => {
  //   if (quotationEditId) {
  //     try {
  //       const response = await sendRequest("PUT", `${BASE_URL}/v1/transactions/entries/${quotationEditId}?type=quotation`);
  //         console.log("Quotation updated successfully!");
  //         fetchQuotations(currentPage, recordsPerPage);
  //         setShowDownIconAction(false);
  //     } catch (error) {
  //       console.error("Error updating the quotation:", error);
  //       console.log("An error occurred while deleting the quotation.");
  //     }
  //   }
  // };

  const handleQuotationDelete = async (quotationId) => {
    if (quotationId) {
      try {
        const response = await sendRequest("DELETE", `${BASE_URL}/v1/transactions/entries/${quotationId}?type=quotation`);
        if (response.status === 200) {
          console.log("Quotation deleted successfully!");
          fetchQuotations(currentPage, recordsPerPage);
          setShowDownIconAction(false);
        } else {
          console.log("Failed to delete the quotation. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting the quotation:", error);
        console.log("An error occurred while deleting the quotation.");
      }
    }
  };

  const handleQuotationCopy = async (quotationCopyId, updated) => {
    if (updated) {
      setQuotationUpdated(updated);
    }
    if (quotationCopyId) {
      try {
        const response = await sendRequest("Get", `${BASE_URL}/v1/transactions/entries/${quotationCopyId}?type=quotation`);
        setQuotationCopy(response.data.entry);
        setNewQuotation(true);
      } catch (error) {
        console.error("Error copy the quotation:", error);
        console.log("An error occurred while deleting the copy quotation.");
      }
    }
  };

  const handleQuotationinvoice = async (quotationInvoiceId) => {
    if (quotationInvoiceId) {
      try {
        const response = await sendRequest("Get", `${BASE_URL}/v1/transactions/entries/${quotationInvoiceId}?type=quotation`);
        setQuotationInvoice(response.data.entry);
        setNewQuotationInvoice(true);
      } catch (error) {
        console.error("Error for invoice quotation:", error);
        console.log("An error occurred while deleting the invoice quotation.");
      }
    }
  };

  const handleQuotationSaleOrder = async (quotationSaleOrderId) => {
    if (quotationSaleOrderId) {
      try {
        const response = await sendRequest("Get", `${BASE_URL}/v1/transactions/entries/${quotationSaleOrderId}?type=quotation`);
        setQuotationSaleOrder(response.data.entry);
        setNewQuotationSaleOrder(true);
      } catch (error) {
        console.error("Error for invoice quotation:", error);
        console.log("An error occurred while deleting the invoice quotation.");
      }
    }
  };

  useEffect(() => {
    fetchQuotations(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // const handleSearch = (query) => {
  //   setSearchQuery(query.length >= 2 ? query : "");
  // };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  // const handleColumnFilterClick = () => {
  //   setTempVisibleColumns({ ...visibleColumns });
  //   setShowColumnFilter(true);
  //   setShowAttributeFilter(false);
  // };

  // const handleAttributeFilterClick = () => {
  //   setShowAttributeFilter((prev) => !prev);
  //   setShowColumnFilter(false);
  // };

  // const handleDownIconPopup = () => {
  //   setShowDownIconOpton(true);
  // }

  // const handleTurnIconPopup = () => {
  //   setShowTurnIconOpton(true);
  // }

  // const closeTurnIconPopup = () => {
  //   setShowTurnIconOpton(false);
  // }

  // const handleDownIconAction = (quotation) => {
  //   setSelcectedQuotation(quotation);
  //   setShowDownIconAction(true);
  // }
  const handleDownIconAction = (quotation, event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setActionDropdownPosition({
      top: rect.bottom + window.scrollY,
    });
    setSelcectedQuotation(quotation);
    setShowDownIconAction(true);
  }

  const handleNewQuotationCreated = (value) => {
    if (!value) return;

    setQuotations(prevQuotations => [...prevQuotations, ...value]);
    setTotalRecords(prevTotalRecords => prevTotalRecords + 1);
  }

  return (
    <>
      {/* <SalesHeader /> */}
      <div className="quotation-main-container">
        <div className="quotation-container-first-div">
          <div className="quotation-first-left">
            <span className="all-quotation-show">All Quotations ({totalRecords})</span>
          </div>
          <div className="quotation-first-right">
            <button
              className="quotation-first-right-botton"
              onClick={OpenQuotationPopup}
            >
              New Quote
              {/* <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span> */}
            </button>
          </div>
        </div>
        <div className="quotation-container-second-div">
          <div className="quotation-second-div-right">
            <div className="quotation-header">
              <EntriesPerPageDropdown recordPerPage={recordsPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
            </div>

            <div className="quotation-second-part-div">
              {/* <div className="quotation-second-drop-first">
              <div className="quotation-turn-icon-grp">
                <span className="quote-turn-right-icon">
                  <FontAwesomeIcon icon={faArrowsTurnRight} onClick={handleTurnIconPopup} />
                </span>
              </div>
              <span className="quotatin-icon-between"></span>
              <div className="quotation-down-icon-grp" >
                <span className="quote-caret-down-icon">
                  <FontAwesomeIcon icon={faCaretDown} onClick={handleDownIconPopup} />
                </span>
              </div>
            </div> */}
              {/* <div className="quote-second-filter" onClick={handleAttributeFilterClick}>
                <span className="quote-filter-icon">
                  <FontAwesomeIcon icon={faFilter} />
                </span>
              </div> */}
              {/* <div
                className="quotation-second-three-dot-grop"
                onClick={handleColumnFilterClick}
              >
                <span className="quote-ellips-icon">
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </span>
              </div> */}
            </div>
          </div>
        </div>

        {/* {showColumnFilter && (
          <div className="column-filter-popup" ref={columnFilterRef}>
            <form className="column-filter-form">
              {Object.keys(tempVisibleColumns).map((column) => (
                <div key={column} className="quotation-label-input-grp">
                  <label>
                    <input
                      type="checkbox"
                      className="quotation-visible-column-input"
                      checked={tempVisibleColumns[column]}
                      onChange={() => handleCheckboxChange(column)}
                  event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setActionDropdownPosition({
      top: rect.bottom + window.scrollY,
    });      />
                    {column.charAt(0).toUpperCase() + column.slice(1)}
                  </label>
                </div>
              ))}
              <button type="button" onClick={handleApplyFilter} className="visible-box-btn">
                Apply
              </button>
            </form>
          </div>
        )} */}

        {showAttributeFilter && (
          <div className="quotation-attribute-filter-popup-container" ref={attributeFilterRef}>
            <div className="quote-first-att-div">
              <label className="quote-first-att-div-label">Contacts / Estimate# / Ref# / Amount</label>
              <select>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
            <div className="quote-second-att-div">
              <label className="quote-second-att-div-label">Status</label>
              <select>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
            <div className="quote-third-att-div">
              <div className="quote-third-part-1">
                <label className="quote-third-part-1-label">Start Date</label>
                <input type="date" className="quote-third-att-date" />
              </div>
              <div className="quote-third-part-2">
                <label className="quote-third-part-2-label">End Date</label>
                <input type="date" className="quote-third-att-date" />
              </div>
            </div>
            <div className="quote-attr-btn-grp">
              <button className="quote-attr-pop-btn">Search</button>
              <button className="quote-attr-pop-btn">Clear</button>
            </div>
          </div>
        )}

        {/* {showDownIconOpton && (
          <div className="quotation-down-icon-popup" ref={downIconFilterRef}>
            <ul className="quote-down-icon-list-grp">
              <li className="quote-down-options">Import XLS</li>
              <li className="quote-down-options">Import Tally XML</li>
              <li className="quote-down-options">Export XLS</li>
            </ul>
          </div>
        )} */}

        {/* {showTurnIconOpton && (
          <div className="quoation-turn-icon-popup" ref={turnIconFilterRef}>
            <div className="quote-turn-popup-div">
              <label className="quote-turn-pop-label">Export Method</label>
              <select>
                <option>List</option>
                <option>List with Items</option>
                <option>Whole Transactions</option>
              </select>
            </div>
            <div className="quote-turn-popup-div-2">
              <label className="quote-turn-pop-label" >Export Type</label>
              <select>
                <option>CSV</option>
                <option>PDF</option>
                <option>XLS</option>
              </select>
            </div>
            <div className="quote-turn-btn-grp">
              <button className="quote-turn-btn">Export</button>
              <button className="quote-turn-btn" onClick={closeTurnIconPopup}>Cancel</button>
            </div>
          </div>
        )} */}

        {showDownIconAction && (
          <div
            className="quotation-down-icon-action"
            ref={actionDownIconFilterRef}
            style={{
              position: 'absolute',
              top: `${actionDropdownPosition.top}px`,
            }}
          >
            <ul className="quote-down-icon-action-ul">
              <li className="quote-down-icon-action-li" onClick={() => handleQuotationSaleOrder(selectedQuotation.id)}>Create sales Order</li>
              <li className="quote-down-icon-action-li" onClick={() => handleQuotationinvoice(selectedQuotation.id)}>Create Invoice</li>
              <li className="quote-down-icon-action-li" onClick={() => handleQuotationCopy(selectedQuotation.id, true)}>Edit</li>
              <li className="quote-down-icon-action-li" onClick={() => handleQuotationCopy(selectedQuotation.id)}>Copy</li>
              <li className="quote-down-icon-action-li" onClick={() => handleQuotationDelete(selectedQuotation.id)}>Delete</li>
            </ul>
          </div>
        )}

        <div className="quatation-table-group">
          <table className="quatation-table">
            <thead>
              <tr className="quatation-thead-row">
                {/* {visibleColumns.date &&  */}
                <th className="quatation-tab-th">Date</th>
                {/* } */}
                {/* {visibleColumns.estimateNo &&  */}
                <th className="quatation-tab-th">Estimate No</th>
                {/* } */}
                {/* {visibleColumns.contact &&  */}
                <th className="quatation-tab-th">Contact</th>
                {/* } */}
                {/* {visibleColumns.buyerOrderNo &&  */}
                <th className="quatation-tab-th">Buyer's Order No#</th>
                {/* } */}
                {/* {visibleColumns.buyerOrderDate &&  */}
                <th className="quatation-tab-th">Buyer's Order Date</th>
                {/* } */}
                {/* {visibleColumns.refNo &&  */}
                {/* <th className="quatation-tab-th">Ref.No</th> */}
                {/* } */}
                {/* {visibleColumns.dueDays && <th className="quatation-tab-th">Due Days</th>} */}
                {/* {visibleColumns.status &&  */}
                <th className="quatation-tab-th">Status</th>
                {/* } */}
                {/* {visibleColumns.mobileNumber &&  */}
                <th className="quatation-tab-th">Mobile Number</th>
                {/* } */}
                {/* {visibleColumns.amount &&  */}
                <th className="quatation-tab-th">Amount</th>
                {/* } */}
                {/* {visibleColumns.dueDate && <th className="quatation-tab-th">Due Amount</th>} */}
                {/* {visibleColumns.action &&  */}
                <th className="quatation-tab-th">Action</th>
                {/* } */}
              </tr>
            </thead>
            <tbody>
              {quotations.map((row, index) => (
                <tr key={index}>
                  {/* {visibleColumns.date &&  */}
                  <td className="quatation-tab-td">{row.date}</td>
                  {/* } */}
                  {/* {visibleColumns.estimateNo &&  */}
                  <td className="quatation-tab-td">{row.bill_number}</td>
                  {/* } */}
                  {/* {visibleColumns.contact &&  */}
                  <td className="quatation-tab-td">{row.customer.customer_name}</td>
                  {/* } */}
                  {/* {visibleColumns.buyerOrderNo &&  */}
                  <td className="quatation-tab-td">{row.order_no}</td>
                  {/* } */}
                  {/* {visibleColumns.buyerOrderDate &&  */}
                  <td className="quatation-tab-td">{row.order_date}</td>
                  {/* } */}
                  {/* {visibleColumns.refNo &&  */}
                  {/* <td className="quatation-tab-td">{row.reference_no}</td> */}
                  {/* } */}
                  {/* {visibleColumns.dueDays && <td className="quatation-tab-td">{row.due_days}</td>} */}
                  {/* {visibleColumns.status &&  */}
                  <td className="quatation-tab-td">{row.status}</td>
                  {/* } */}
                  {/* {visibleColumns.mobileNumber &&  */}
                  <td className="quatation-tab-td">{row.customer.mobile_number}</td>
                  {/* } */}
                  {/* {visibleColumns.amount &&  */}
                  <td className="quatation-tab-td">{row.total_amount}</td>
                  {/* } */}
                  {/* {visibleColumns.dueDate && <td className="quatation-tab-td">{row.due_amount}</td>} */}
                  {/* {visibleColumns.action && ( */}
                  <td className="quatation-tab-td">
                    {row.action}
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="quote-table-body-td"
                      onClick={(e) => handleDownIconAction(row, e)}
                    />
                  </td>
                  {/* )} */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}

        {quotations.length === 0 && (
          <div className="quotation-no-data-message">
            No quotations found.
          </div>
        )}

        {isNewQuotation && (
          <div className="new-quotation-popup-container">
            <NewQuotation onClose={closeNewQuotationPopup} isUpdated={QuotationUpdated} quotationCopy={quotationCopy} quotationCreated={handleNewQuotationCreated} />
          </div>
        )}

        {isNewQuotationInvoice && (
          <div className="new-quotation-popup-container">
            <NewSaleInvoice onClose={closeNewQuotationInvoicePopup} isQuotationInvoice={isQuotationInvoice} />
          </div>
        )}

        {isNewQuotationSaleOrder && (
          <div className="new-quotation-popup-container">
            <NewSaleOrder onClose={closeNewQuotationSaleOrderPopup} isQuotationSaleOrder={isQuotationSaleOrder} />
          </div>
        )}
      </div>
      <Copyrights />
    </>
  );
}

export default Quotation;
