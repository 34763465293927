import React, { useEffect, useState } from "react";
import "./CommonCustomerPopup.css";
import { BASE_URL } from "../../Url/constants";
import { sendRequest } from "../../ApiRequest";
import Select from 'react-select';
import successNotification from "../../Notification/SuccessNotification";

function CommonCustomerPopup({ selectedCustomer, onClose, customerDetails, onCustomerUpdate }) {
  const [isFirstName, setFirstName] = useState(customerDetails?.first_name ? customerDetails?.first_name : '');
  const [isLastName, setLastName] = useState(customerDetails?.last_name ? customerDetails?.last_name : '');
  const [isPhone, setPhone] = useState(customerDetails?.phone ? customerDetails?.phone : '');
  const [isemail, setEmail] = useState(customerDetails?.email ? customerDetails?.email : '');
  const [isCompanyName, setCompanyName] = useState(customerDetails?.company_name ? customerDetails?.company_name : '');
  const [isGstNumber, setGstNumber] = useState(customerDetails?.gst_number ? customerDetails?.gst_number : '');
  const [isPanNumber, setPanNumber] = useState(customerDetails?.pan_number ? customerDetails?.pan_number : '');
  const [isAccountNumber, setAccountNumber] = useState(customerDetails?.bank_account?.account_number ? customerDetails?.bank_account?.account_number : '');
  const [isAccountType, setAccountType] = useState(customerDetails?.bank_account?.account_type ? customerDetails?.bank_account?.account_type : '');
  const [isBankName, setBankName] = useState(customerDetails?.bank_account?.bank_name ? customerDetails?.bank_account?.bank_name : '');
  const [isIfscCode, setIfscCode] = useState(customerDetails?.bank_account?.ifsc_code ? customerDetails?.bank_account?.ifsc_code : '');
  const [isAccountName, setAccountName] = useState(customerDetails?.bank_account?.account_name ? customerDetails?.bank_account?.account_name : '');
  const [isBranchName, setBranchName] = useState(customerDetails?.bank_account?.branch_name ? customerDetails?.bank_account?.branch_name : '');
  const [activeTab, setActiveTab] = useState('address');
  const [billingAddress, setBillingAddress] = useState({
    id: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    postal_code: '',
    country: 'India',
    state: 'Madhya Pradesh',
    address_type: "billing",
  });

  const [shippingAddress, setShippingAddress] = useState({
    id: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    postal_code: '',
    phone: '',
    shipping_gstin: '',
    country: 'India',
    state: 'Madhya Pradesh',
    address_type: "shipping",
  });

  const [selectedBillingState, setSelectedBillingState] = useState({
    value: "Madhya Pradesh",
    label: "Madhya Pradesh"
  });

  const [selectedShippingState, setSelectedShippingState] = useState({
    value: "Madhya Pradesh",
    label: "Madhya Pradesh"
  });

  const handleBillingStateChange = (selectedOption) => {
    setSelectedBillingState(selectedOption);
  };

  const handleShippingStateChange = (selectedOption) => {
    setSelectedShippingState(selectedOption);
  };

  useEffect(() => {
    if (customerDetails?.addresses && (customerDetails?.addresses?.length > 0)) {
      const billing = customerDetails?.addresses.find(addr => addr.address_type === 'billing');
      if (billing) {
        setBillingAddress({
          ...billing,
          country: billing.country || 'India',
          state: billing.state || 'Madhya Pradesh',
        });
        setSelectedBillingState({
          value: billing.state || 'Madhya Pradesh',
          label: billing.state || 'Madhya Pradesh'
        });
      }
      const shipping = customerDetails.addresses.find(addr => addr.address_type === 'shipping');
      if (shipping) {
        setShippingAddress({
          ...shipping,
          country: shipping.country || 'India',
          state: shipping.state || 'Madhya Pradesh',
        });
        setSelectedShippingState({
          value: shipping.state || 'Madhya Pradesh',
          label: shipping.state || 'Madhya Pradesh'
        });
      }
    }
  }, [customerDetails]);

  const indianStates = [
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Meghalaya', label: 'Meghalaya' },
    { value: 'Mizoram', label: 'Mizoram' },
    { value: 'Nagaland', label: 'Nagaland' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'West Bengal', label: 'West Bengal' },
    { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'Dadra and Nagar Haveli and Daman and Diu' },
    { value: 'Lakshadweep', label: 'Lakshadweep' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Puducherry', label: 'Puducherry' },
    { value: 'Ladakh', label: 'Ladakh' },
    { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' }
  ];

  const commonCustStyle = {
    menu: (provided) => ({
      ...provided,
      maxHeight: 150,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 150,
    }),
  };

  const renderFormContent = () => {
    switch (activeTab) {
      case 'address':
        return (
          <div className="customer-address-form">
            <span className="cust-billing-title">Billing Address</span>
            <div className="customer-address-form-subcontainer">
              <div className="cust-add-input-first-grp">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address1"
                  value={billingAddress?.address_line_1}
                  onChange={(e) => setBillingAddress({ ...billingAddress, address_line_1: e.target.value })}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address2"
                  value={billingAddress?.address_line_2}
                  onChange={(e) => setBillingAddress({ ...billingAddress, address_line_2: e.target.value })}
                />
              </div>
              <div className="cust-address-input-row-second">
                <input
                  type="text"
                  className="form-control"
                  placeholder="City / Town"
                  value={billingAddress?.city}
                  onChange={(e) => setBillingAddress({ ...billingAddress, city: e.target.value })}
                />
                <input
                  type="number"
                  className="form-control"
                  placeholder="Postal / Zip code"
                  value={billingAddress?.postal_code}
                  onChange={(e) => setBillingAddress({ ...billingAddress, postal_code: e.target.value })}
                />
              </div>
              <div className="cust-address-input-row-third">
                <select
                  className="form-select cust-country-select"
                  value={billingAddress?.country}
                  onChange={(e) => setBillingAddress({ ...billingAddress, country: e.target.value })}
                >
                  <option value="India">India</option>
                </select>
                <Select
                  options={indianStates}
                  value={selectedBillingState}
                  onChange={handleBillingStateChange}
                  placeholder="Select a state"
                  styles={commonCustStyle}
                  defaultValue={selectedBillingState}
                  isSearchable
                />

              </div>
            </div>
          </div>
        );
      case 'shipping':
        return (
          <div className="customer-shipping-form">
            <span className="cust-shipping-title">Shipping Address</span>
            <div className="customer-shipping-form-subcontainer">
              <div className="cust-shipping-input-first-grp">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address1"
                  value={shippingAddress?.address_line_1}
                  onChange={(e) => setShippingAddress({ ...shippingAddress, address_line_1: e.target.value })}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address2"
                  value={shippingAddress?.address_line_2}
                  onChange={(e) => setShippingAddress({ ...shippingAddress, address_line_2: e.target.value })}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Shipping GSTIN"
                  value={shippingAddress?.shipping_gstin}
                  onChange={(e) => setShippingAddress({ ...shippingAddress, shipping_gstin: e.target.value })}
                />
                <input
                  type="number"
                  className="form-control"
                  placeholder="Phone"
                  value={shippingAddress?.phone}
                  onChange={(e) => setShippingAddress({ ...shippingAddress, phone: e.target.value })}
                />
              </div>
              <div className="cust-shipping-input-row-second">
                <input
                  type="text"
                  className="form-control"
                  placeholder="City / Town"
                  value={shippingAddress?.city}
                  onChange={(e) => setShippingAddress({ ...shippingAddress, city: e.target.value })}
                />
                <input
                  type="number"
                  className="form-control"
                  placeholder="Postal / Zip code"
                  value={shippingAddress?.postal_code}
                  onChange={(e) => setShippingAddress({ ...shippingAddress, postal_code: e.target.value })}
                />
              </div>
              <div className="cust-shipping-input-row-third">
                <select
                  className="form-select cust-country-select"
                  value={shippingAddress?.country}
                  onChange={(e) => setShippingAddress({ ...shippingAddress, country: e.target.value })}
                >
                  <option value="India">India</option>
                </select>
                <Select
                  options={indianStates}
                  value={selectedShippingState}
                  onChange={handleShippingStateChange}
                  placeholder="Select a state"
                  styles={commonCustStyle}
                  defaultValue={selectedShippingState}
                  isSearchable
                />
              </div>
            </div>
          </div>
        );
      case 'bank':
        return (
          <div className="bank-details-form">
            {/* <span>Bank Details</span> */}
            <div className="customer-bank-detail-form">
              <div className="customer-bank-div-grp">
                <span>Account Name</span>
                <input type="text" className="form-control cust-input-div-field" placeholder="Account Name"
                  onChange={(e) => setAccountName(e.target.value)}
                  value={isAccountName}
                />
              </div>
            </div>
            <div className="customer-bank-detail-form">
              <div className="customer-bank-div-grp">
                <span>Account Number</span>
                <input type="text" className="form-control cust-input-div-field" placeholder="Account Number"
                  onChange={(e) => setAccountNumber(e.target.value)}
                  value={isAccountNumber}
                />
              </div>
            </div>
            <div className="customer-bank-detail-form">
              <div className="customer-bank-div-grp">
                <span>Account Type (Savings / Current)</span>
                <input type="text" className="form-control cust-input-div-field" placeholder="Account Type"
                  onChange={(e) => setAccountType(e.target.value)}
                  value={isAccountType}
                />
              </div>
            </div>
            <div className="customer-bank-detail-form">
              <div className="customer-bank-div-grp">
                <span>Bank Name</span>
                <input type="text" className="form-control cust-input-div-field" placeholder="Bank Name"
                  onChange={(e) => setBankName(e.target.value)}
                  value={isBankName}
                />
              </div>
            </div>
            <div className="customer-bank-detail-form">
              <div className="customer-bank-div-grp">
                <span>Bank Branch</span>
                <input type="text" className="form-control cust-input-div-field" placeholder="Bank Branch"
                  onChange={(e) => setBranchName(e.target.value)}
                  value={isBranchName}
                />
              </div>
            </div>
            <div className="customer-bank-detail-form">
              <div className="customer-bank-div-grp">
                <span>IFSC Code</span>
                <input type="text" className="form-control cust-input-div-field" placeholder="IFSC Code"
                  onChange={(e) => setIfscCode(e.target.value)}
                  value={isIfscCode}
                />
              </div>
            </div>
            {/* <div className="customer-bank-detail-form">
              <div className="customer-bank-div-grp">
                <span>Swift Code</span>
                <input type="text" className="form-control cust-input-div-field" placeholder="Swift Code" />
              </div>
            </div>
            <div className="customer-bank-detail-form">
              <div className="customer-bank-div-grp">
                <span>Correspondent Bank</span>
                <textarea className="form-control cust-account-textarea" placeholder="Correspondent Bank">
                </textarea>
              </div>
            </div>
            <div className="customer-bank-detail-form">
              <div className="customer-bank-div-grp">
                <span>Authorised Dealer Code</span>
                <input type="text" className="form-control cust-input-div-field" placeholder="Authorised Dealer Code" />
              </div>
            </div> */}
          </div>
        );
      // case 'default':
      //   return (
      //     <div className="customer-default-form">
      //       <div className="customer-default-div-grp">
      //         <span>Notes</span>
      //         <textarea className="form-control cust-default-account-textarea" placeholder="Notes">
      //         </textarea>
      //       </div>
      //       <div className="customer-default-second-div-grp">
      //         <span>Terms and Conditions</span>
      //         <textarea className="form-control cust-default-account-textarea" placeholder="Terms and Conditions">
      //         </textarea>
      //       </div>
      //     </div>
      //   );
      default:
        return null;
    }
  };

  const bankId = customerDetails ? customerDetails?.bank_account?.id : '';

  const handleCustomerSave = async () => {
    try {
      const formData = new FormData();
      formData.append("customer[company_name]", isCompanyName);
      formData.append("customer[first_name]", isFirstName);
      formData.append("customer[last_name]", isLastName);
      formData.append("customer[phone]", isPhone);
      formData.append("customer[email]", isemail);
      formData.append("customer[gst_number]", isGstNumber);
      formData.append("customer[pan_number]", isPanNumber);
      formData.append("customer[addresses_attributes][0][address_line_1]", billingAddress.address_line_1);
      formData.append("customer[addresses_attributes][0][address_line_2]", billingAddress.address_line_2);
      formData.append("customer[addresses_attributes][0][city]", billingAddress.city);
      formData.append("customer[addresses_attributes][0][postal_code]", billingAddress.postal_code);
      formData.append("customer[addresses_attributes][0][country]", billingAddress.country);
      formData.append("customer[addresses_attributes][0][state]", billingAddress.state);
      formData.append("customer[addresses_attributes][0][address_type]", billingAddress.address_type);
      formData.append("customer[addresses_attributes][1][address_line_1]", shippingAddress.address_line_1);
      formData.append("customer[addresses_attributes][1][address_line_2]", shippingAddress.address_line_2);
      formData.append("customer[addresses_attributes][1][city]", shippingAddress.city);
      formData.append("customer[addresses_attributes][1][postal_code]", shippingAddress.postal_code);
      formData.append("customer[addresses_attributes][1][phone]", shippingAddress.phone);
      formData.append("customer[addresses_attributes][1][shipping_gstin]", shippingAddress.shipping_gstin);
      formData.append("customer[addresses_attributes][1][country]", shippingAddress.country);
      formData.append("customer[addresses_attributes][1][state]", shippingAddress.state);
      formData.append("customer[addresses_attributes][1][address_type]", shippingAddress.address_type);

      formData.append("customer[bank_account_attributes][account_number]", isAccountNumber);
      formData.append("customer[bank_account_attributes][account_type]", isAccountType);
      formData.append("customer[bank_account_attributes][bank_name]", isBankName);
      formData.append("customer[bank_account_attributes][ifsc_code]", isIfscCode);
      formData.append("customer[bank_account_attributes][account_name]", isAccountName);
      formData.append("customer[bank_account_attributes][branch_name]", isBranchName);
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/onboarding/customers`,
        formData
      );
      successNotification("Customer created!");
      selectedCustomer(response?.data?.data);
      onClose();
    } catch (error) {
      console.log("customer not created!")
    }
  };

  const handleCustomerUpdate = async (customerDetailId) => {
    if (customerDetailId) {
      try {
        const formData = new FormData();
        formData.append("customer[company_name]", isCompanyName);
        formData.append("customer[first_name]", isFirstName);
        formData.append("customer[last_name]", isLastName);
        formData.append("customer[phone]", isPhone);
        formData.append("customer[email]", isemail);
        formData.append("customer[gst_number]", isGstNumber);
        formData.append("customer[pan_number]", isPanNumber);

        formData.append("customer[addresses_attributes][0][id]", billingAddress.id);
        formData.append("customer[addresses_attributes][0][address_line_1]", billingAddress.address_line_1);
        formData.append("customer[addresses_attributes][0][address_line_2]", billingAddress.address_line_2);
        formData.append("customer[addresses_attributes][0][city]", billingAddress.city);
        formData.append("customer[addresses_attributes][0][postal_code]", billingAddress.postal_code);
        formData.append("customer[addresses_attributes][0][country]", billingAddress.country);
        formData.append("customer[addresses_attributes][0][state]", billingAddress.state);
        formData.append("customer[addresses_attributes][0][address_type]", billingAddress.address_type);

        formData.append("customer[addresses_attributes][1][id]", shippingAddress.id);
        formData.append("customer[addresses_attributes][1][address_line_1]", shippingAddress.address_line_1);
        formData.append("customer[addresses_attributes][1][address_line_2]", shippingAddress.address_line_2);
        formData.append("customer[addresses_attributes][1][city]", shippingAddress.city);
        formData.append("customer[addresses_attributes][1][postal_code]", shippingAddress.postal_code);
        formData.append("customer[addresses_attributes][1][phone]", shippingAddress.phone);
        formData.append("customer[addresses_attributes][1][shipping_gstin]", shippingAddress.shipping_gstin);
        formData.append("customer[addresses_attributes][1][country]", shippingAddress.country);
        formData.append("customer[addresses_attributes][1][state]", shippingAddress.state);
        formData.append("customer[addresses_attributes][1][address_type]", shippingAddress.address_type);
        if (bankId) {
          formData.append("customer[bank_account_attributes][id]", bankId);
          formData.append("customer[bank_account_attributes][account_number]", isAccountNumber);
          formData.append("customer[bank_account_attributes][account_type]", isAccountType);
          formData.append("customer[bank_account_attributes][bank_name]", isBankName);
          formData.append("customer[bank_account_attributes][ifsc_code]", isIfscCode);
          formData.append("customer[bank_account_attributes][account_name]", isAccountName);
          formData.append("customer[bank_account_attributes][branch_name]", isBranchName);
        }
        const response = await sendRequest(
          "PUT",
          `${BASE_URL}/v1/onboarding/customers/${customerDetailId}`,
          formData
        );
        const updatedCustomerId = response.data.data.id;
        successNotification("Customer update!");
        onCustomerUpdate(updatedCustomerId);
        onClose();
      } catch (error) {
        console.log("customer not Updated!")
      }
    }
    else {
      console.log("Failed to update customer.")
    }
  };

  return (
    <>
      <div className="common-customer-main-container">
        <div className="common-customer-header-content">
          <span className="common-cust-title">Customer</span>
          <div className="common-customer-btn-grp">
            {
              customerDetails ? (
                <button className="common-cust-btn" onClick={() => handleCustomerUpdate(customerDetails.id)}>Update</button>
              )
                :
                (
                  <button className="common-cust-btn" onClick={handleCustomerSave}>Save</button>
                )
            }
            {/* <button className="common-cust-btn" onClick={handleCustomerSave}>Save</button> */}
            <button className="common-cust-btn" onClick={onClose}>Cancel</button>
          </div>
        </div>
        <div className="common-customer-sub-container">
          <div className="common-customer-form-grp">
            <div className="common-cust-form-field-grp">
              <label className="common-cust-label">Company Name</label>
              <input
                className="form-control common-cust-label-field"
                type="text"
                onChange={(e) => setCompanyName(e.target.value)}
                value={isCompanyName}
              />
            </div>
            <div className="common-cust-form-field-grp">
              <label className="common-cust-label">First Name</label>
              <input
                className="form-control common-cust-label-field"
                type="text"
                onChange={(e) => setFirstName(e.target.value)}
                value={isFirstName}
              />
            </div>
            <div className="common-cust-form-field-grp">
              <label className="common-cust-label">Last Name</label>
              <input
                className="form-control common-cust-label-field"
                type="text"
                onChange={(e) => setLastName(e.target.value)}
                value={isLastName}
              />
            </div>
            <div className="common-cust-form-field-grp">
              <label className="common-cust-label">Phone</label>
              <input
                className="form-control common-cust-label-field"
                type="text"
                onChange={(e) => setPhone(e.target.value)}
                value={isPhone}
              />
            </div>
            <div className="common-cust-form-field-grp">
              <label className="common-cust-label">Email</label>
              <input
                className="form-control common-cust-label-field"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                value={isemail}
              />
            </div>
            <div className="common-cust-form-field-grp">
              <label className="common-cust-label">GSTIN</label>
              <input
                className="form-control common-cust-label-field"
                type="text"
                onChange={(e) => setGstNumber(e.target.value)}
                value={isGstNumber}
              />
            </div>
            <div className="common-cust-form-field-grp">
              <label className="common-cust-label">Pan No.</label>
              <input
                className="form-control common-cust-label-field"
                type="text"
                onChange={(e) => setPanNumber(e.target.value)}
                value={isPanNumber}
              />
            </div>
          </div>
          {/*  */}
          <div className="common-customerr-second-sub-container" >
            <div className="common-customer-tab-navigation">
              <button className={activeTab === 'address' ? 'active' : ''} onClick={() => setActiveTab('address')}>Address</button>
              <button className={activeTab === 'shipping' ? 'active' : ''} onClick={() => setActiveTab('shipping')}>Shipping Address</button>
              <button className={activeTab === 'bank' ? 'active' : ''} onClick={() => setActiveTab('bank')}>Bank Details</button>
              {/* <button className={activeTab === 'default' ? 'active' : ''} onClick={() => setActiveTab('default')}>Default</button> */}
            </div>
            {renderFormContent()}
          </div>
        </div>
      </div>
    </>
  );
}

export default CommonCustomerPopup;
