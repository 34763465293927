import React, { useState } from "react";
import "./BillingCustomer.css"; // Assuming this is the updated CSS file
import CommonCloseButton from "./Restaurant/CommonCloseButton/CommonCloseButton";
import { useTranslation } from "react-i18next";
import { sendRequest } from "../ApiRequest";
import { BASE_URL } from "../Url/constants";
import successNotification from "../Notification/SuccessNotification";
import ErrorNotification from "../Notification/ErrorNotification";

function BillingCustomer({ onClose }) {
  const { t } = useTranslation();
  const [newGuest, setNewGuest] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
  });
  const [validationError, setValidationError] = useState("");

  const handleCreateGuestChange = (e) => {
    const { name, value } = e.target;
    setNewGuest((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveNewGuest = async () => {
    if (newGuest.phone.trim() === "") {
      setValidationError("Phone number cannot be blank");
      return;
    }

    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/onboarding/customers`,
        { customer: newGuest }
      );

      if (response.status === 200) {
        successNotification("Customer Created Successfully!");
        const createdGuest = response.data.data;
        onClose(createdGuest); // Pass the newly created guest back to the parent component
      }
    } catch (error) {
      const errorMessages = error?.data?.errors || ["An unknown error occurred"];
      setValidationError(errorMessages.join(", "));
      ErrorNotification("Error creating new guest:", errorMessages.join(", "));
    }
  };

  return (
    <>
      <div className="billing-customer-popup-main"> {/* Changed class name */}
        <div className="billing-customer-main"> {/* Changed class name */}
          <div className="billing-customer-title"> {/* Changed class name */}
            <span className="billing-customer-title-heading">{t("Billing Customer")}</span>
            <span onClick={onClose}>
              <CommonCloseButton />
            </span>
          </div>
          <div className="billing-customer-sub-container"> {/* Changed class name */}
            <div className="billing-customer-header-box"> {/* Changed class name */}
              <span className="billing-customer-header-title">{t("Create New Guest")}</span>
            </div>
            <div className="billing-customer-form-group"> {/* Changed class name */}
              <label>{t("First Name")}</label>
              <input
                type="text"
                name="first_name"
                value={newGuest.first_name}
                onChange={handleCreateGuestChange}
                className="form-control"
                maxLength={12}
              />
            </div>
            <div className="billing-customer-form-group"> {/* Changed class name */}
              <label>{t("Last Name")}</label>
              <input
                type="text"
                name="last_name"
                value={newGuest.last_name}
                onChange={handleCreateGuestChange}
                className="form-control"
                maxLength={12}
              />
            </div>
            <div className="billing-customer-form-group"> {/* Changed class name */}
              <label>{t("Phone")}</label>
              <input
                type="text"
                name="phone"
                value={newGuest.phone}
                onChange={handleCreateGuestChange}
                className="form-control"
              />
            </div>
            {validationError && (
              <div className="billing-customer-error-msg"> {/* Changed class name */}
                <span className="billing-customer-phone-error">{validationError}</span> {/* Changed class name */}
              </div>
            )}
            <div className="billing-customer-email"> {/* Changed class name */}
              <div className="billing-customer-form-group"> {/* Changed class name */}
                <label>{t("Email")}</label>
                <input
                  type="text"
                  name="email"
                  value={newGuest.email}
                  onChange={handleCreateGuestChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="billing-customer-form-group"> {/* Changed class name */}
              <label>{t("Address")}</label>
              <input
                type="text"
                name="address"
                value={newGuest.address}
                onChange={handleCreateGuestChange}
                className="form-control"
                maxLength={60}
              />
            </div>
            <div className="billing-customer-form-group billing-customer-form-save-btn"> {/* Changed class name */}
              <button onClick={handleSaveNewGuest} className="billing-customer-create-form"> {/* Changed class name */}
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BillingCustomer;
