export const validateEmail = (email) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!emailRegex.test(email)) {
    return 'Please enter a valid email address';
  }
  return null;
};
  
export const validatePhone = (phone) => {
  const phoneRegex = /^[0-9]+$/;
  if (!phoneRegex.test(phone)) {
    return 'Phone number should only contain numeric digits.';
  }
  if (phone.length < 10) {
    return 'Phone number should be minimum 10 digits';
  }
  if (phone.length > 12) {
    return 'Phone number should be maximum 12 digits';
  }
  return null;
};