import React from "react";
import "./templatelayout1.css"

function TemplateLayout1() {

    return (
        <>
            <div className='invoice-template-first'>
                <div className='invoice-header'>
                    <img alt="Company Logo" src="/companylogo.jpeg" />
                    <div className="first-template-company-info">
                        <h2>Company Name</h2>
                        <p>+1 3649-6589 <i className="fa fa-phone"></i></p>
                        <p>company@gmail.com <i className="fa fa-envelope-o"></i></p>
                        <p>USA <i className="fa fa-location-arrow"></i></p>
                    </div>
                </div>
                <div className='first-template-invoice-details'>
                    <div className="template-first-customer-datail">
                        <h5>Customer Name </h5>
                        <p><b>Mobile :</b> +1 12345-4569</p>
                        <p><b>Email :</b> customer@gmail.com</p>
                        <p><b>Address :</b> New York, USA</p>
                    </div>
                    <div className='first-template-invoice-number'>Invoice #12345</div>
                </div>
                <div className="demo">
                    <table>
                        <thead>
                            <tr>
                                <th>Service</th>
                                <th>Description</th>
                                <th>Rate</th>
                                <th>QTY</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>xyz</td>
                                <td>Provide by IT infrastaructure</td>
                                <td>50000</td>
                                <td>2</td>
                                <td>100000</td>
                            </tr>
                            <tr>
                                <td>xyz</td>
                                <td>Provide by IT infrastaructure</td>
                                <td>50000</td>
                                <td>2</td>
                                <td>100000</td>
                            </tr>
                            <tr>
                                <td>xyz</td>
                                <td>Provide by IT infrastaructure</td>
                                <td>50000</td>
                                <td>2</td>
                                <td>100000</td>
                            </tr>
                            <tr>
                                <td>xyz</td>
                                <td>Provide by IT infrastaructure</td>
                                <td>50000</td>
                                <td>2</td>
                                <td>100000</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='first-template-invoice-total'>
                    <strong>Total:</strong> 400000
                </div>
                <div className='template-first-footer-container'>
                    <div className='row mt-5' >
                        <div className='col-md-9 d-flex justify-content-start'>Date: May 1, 2024</div>
                        <div className='col-md-3 d-flex justify-content-end'>Authority signature</div>
                    </div>
                    <div className='row'>
                        <div className='template-first-statement mb-4'>Thanks for Shopping.!</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TemplateLayout1;
