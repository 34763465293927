import React, { useEffect, useRef, useState } from "react";
import "./PurchaseBill.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewPurchaseBill from "./NewPurchaseBill";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import ErrorNotification from "../../../Notification/ErrorNotification";
import successNotification from "../../../Notification/SuccessNotification";

function PurchaseBill() {
  const [openNewPurchaseBill, setNewPurchaseBill] = useState(false);
  const [purchaseBills, setPurchaseBills] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [title, setTitle] = useState("");
  const [editedRowData, setEditedRowData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quotationId, setQuotationId] = useState(null);
  const [copiedBillData, setCopiedBillData] = useState(null);
  const [type, setType] = useState();

  const [activeActionDropdownIndex, setActiveActionDropdownIndex] =
    useState(null);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveActionDropdownIndex(null);
    }
  };

  const handleCopyPB = (bill) => {
    setCopiedBillData(bill);
    setIsUpdated(false);
    setIsCreated(true);
    setTitle("Copy Purchase Bill");
    setType("purchase_bill");
    setNewPurchaseBill(true);
    setActiveActionDropdownIndex(null);
  };

  useEffect(() => {
    if (activeActionDropdownIndex !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeActionDropdownIndex]);

  const handlePurchaseBill = () => {
    setIsUpdated(false);
    setIsCreated(true);
    setTitle("New Purchase Bill");
    setType("purchase_bill");
    setNewPurchaseBill(true);
  };

  const closePurchaseBill = () => {
    setNewPurchaseBill(false);
    setCopiedBillData(null);
  };
  
  const fetchPurchaseBills = async () => {
    try {
      const response = await sendRequest(
        "get",
        `${BASE_URL}/v1/transactions/entries?type=purchase_bill`
      );

      if (!response.status === 200) {
        throw new Error("Failed to fetch purchase bills");
      }
      setPurchaseBills(response.data.data);
      setTotalRecords(response.data.total_count)
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPurchaseBills();
  }, []);

  const handleEditPQ = (bill) => {
    setIsUpdated(true);
    setIsCreated(false);
    setTitle("Edit Purchase Bill");
    setType("purchase_bill");
    setNewPurchaseBill(true);
    setEditedRowData(bill);
  };

  const handleDeleteClick = (id) => {
    setQuotationId(id);
    setIsModalOpen(true);
    setActiveActionDropdownIndex(null);
  };

  const handleDeleteCancel = () => {
    setIsModalOpen(false);
    setQuotationId(null);
    setActiveActionDropdownIndex(null);
  };

  const toggleActionDropdown = (index) => {
    setActiveActionDropdownIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  const handleDeleteConfirm = async () => {
    if (!quotationId) return;
    try {
      const deleteUrl = `${BASE_URL}/v1/transactions/entries/${quotationId}?type=purchase_bill&is_purchase=true`;
      await sendRequest("delete", deleteUrl);
      successNotification("Purchase Bill deleted");
      fetchPurchaseBills();
      setIsModalOpen(false);
    } catch (error) {
      ErrorNotification("Unable to delete");
    }
  };

  const handleNewPurchaseBill = (bill) => {
    setEditedRowData(bill);
    setIsUpdated(false);
    setIsCreated(true);
    setTitle("New Purchase Bill");
    setType("purchase_bill");
    setNewPurchaseBill(true);
  }

  return (
    <>
      <div className="purchase-bill-main-container">
        <div className="purchase-bill-container-first-div">
          <div className="purchase-bill-first-left">
            <span className="all-purchase-bill-show">
              All Purchase bill{" "}
              {totalRecords > 0 ? `(${totalRecords})` : ""}
            </span>
          </div>
          <div className="purchase-bill-first-right">
            <button
              className="purchase-bill-first-right-botton"
              onClick={handlePurchaseBill}
            >
              New Purchase bill
              <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            </button>
          </div>
        </div>

        <div className="purchase-bill-table-group">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <table className="purchase-bill-table">
              <thead>
                <tr className="purchase-bill-thead-row">
                  <th className="purchase-bill-tab-th">Date</th>
                  <th className="purchase-bill-tab-th">Purchase Bill No</th>
                  <th className="purchase-bill-tab-th">Contact</th>
                  <th className="purchase-bill-tab-th">Mobile Number</th>
                  <th className="purchase-bill-tab-th">Supplier Invoice No</th>
                  <th className="purchase-bill-tab-th">
                    Supplier Invoice Date
                  </th>
                  <th className="purchase-bill-tab-th">Ref.No</th>
                  <th className="purchase-bill-tab-th">Due Days</th>
                  <th className="purchase-bill-tab-th text-center">Status</th>
                  <th className="purchase-bill-tab-th text-end">Amount</th>
                  <th className="purchase-bill-tab-th">Due Amount</th>
                  <th className="purchase-bill-tab-th text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {purchaseBills.map((bill, index) => (
                  <tr key={index} className="purchase-bill-tbody-row">
                    <td className="purchase-bill-tab-td">{bill.date}</td>
                    <td className="purchase-bill-tab-td">{bill.bill_number}</td>
                    <td className="purchase-bill-tab-td">
                      {bill.supplier.supplier_name}
                    </td>
                    <td className="purchase-bill-tab-td">
                      {bill.supplier.mobile_number}
                    </td>
                    <td className="purchase-bill-tab-td">{bill.order_no}</td>
                    <td className="purchase-bill-tab-td">{bill.order_date}</td>
                    <td className="purchase-bill-tab-td">{bill.reference_no}</td>
                    <td className="purchase-bill-tab-td">{bill.due_days}</td>
                    <td className="purchase-bill-tab-td text-center">
                      {bill.status === "open" && (
                        <span
                          style={{
                            color: ["open"].includes(bill.status)
                              ? "green"
                              : "inherit",
                          }}
                        >
                          {bill.status}
                        </span>
                      )}
                    </td>

                    <td className="purchase-bill-tab-td text-end">
                      {(bill.total_amount ?? 0).toFixed(2)}
                    </td>
                    <td className="purchase-bill-tab-td">{bill.due_amount}</td>
                    <td className="purchase-quote-tab-td text-center">
                      {bill.status === "open" && (
                        <span
                          style={{
                            color: ["open", "confirmed"].includes(bill.status)
                              ? "green"
                              : "inherit",
                            cursor: "pointer",
                          }}
                          onClick={() => handleEditPQ(bill)}
                        >
                          Edit
                        </span>
                      )}
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        className="purchase-quote-table-body-td"
                        onClick={() => toggleActionDropdown(index)}
                        style={{ cursor: "pointer", marginLeft: "8px" }}
                      />
                      {activeActionDropdownIndex === index && (
                        <ul className="action-dropdown text-start" ref={dropdownRef}>
                          {bill.status === "open" && (
                            <>
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() => handleNewPurchaseBill(bill)}
                              >
                                Create Bill Payment
                              </li>
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() => handleEditPQ(bill)}
                              >
                                Edit PB
                              </li>
                            </>
                          )}
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteClick(bill.id)}
                          >
                            Delete PB
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => console.log("Rejected selected")}
                          >
                            Print
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => console.log("Rejected selected")}
                          >
                            Sent SMS
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => console.log("Rejected selected")}
                          >
                            Send WhatsApp
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => handleCopyPB(bill)}
                          >
                            Copy
                          </li>
                        </ul>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal bg-light">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            style={{ fontSize: "20px", color: "red", marginBottom: "10px" }}
          />
          <h2>Warning</h2>
          <p>Do you want to delete this Purchase Order ?</p>
          <div className="d-flex justify-content-between">
            <button className="btn btn-primary" onClick={handleDeleteConfirm}>
              Confirm
            </button>
            <button
              className="btn btn-cancel bg-body-secondary"
              onClick={handleDeleteCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {openNewPurchaseBill && (
        <div className="new-purchase-bill-popup-container">
          <NewPurchaseBill
            fetchPurchaseBills={fetchPurchaseBills}
            isUpdated={isUpdated}
            isCreated={isCreated}
            editedRowData={isUpdated || isCreated ? editedRowData : copiedBillData}
            title={title}
            type={type}
            onClose={closePurchaseBill}
            isCopy={copiedBillData !== null}
          />
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default PurchaseBill;