import "./item.css";
import ItemAddPrice from "./ItemAddPrice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faDownload } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import React, {useEffect, useState } from "react";
import { getToken } from "../SecureStorage/Token";
import { BASE_URL } from '../../Url/constants';
import ErrorNotification from "../../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import { sendRequest } from "../../ApiRequest";
import Copyrights from "../Copyrights";
import { useTranslation } from "react-i18next";
import successNotification from "../../Notification/SuccessNotification";

function Item() {
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const { t } = useTranslation();
  const [errorFilePath, setErrorFilePath] = useState("");
  const [isUploading, setIsUploading] = useState(false); 

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  const token1 = getToken();
  const token = { headers: { Authorization:  `${token1}`} };

  useEffect(() => {
    getSettings();
  },[])

  const [setting, setSetting] = useState({})
  const handleFileUpload = () => {
    if (selectedFile) {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("params", selectedFile);
  
      const url = `${BASE_URL}/v1/inventory_managment/items/bulk_upload`;
      sendRequest('POST', url, formData)
        .then((res) => {
          setIsUploading(false);
          if (res.data.message === 'Bulk upload completed successfully') {
            successNotification(res.data.message);
          }
          if (res.data.error_file) {
            setErrorFilePath(res.data.error_file);
          } else {
            setErrorFilePath("");
          }
          setSelectedFile(null);
          setFileName("");
        })
        .catch((error) => {
          setIsUploading(false);
          ErrorNotification("Error uploading file:", error);
        });
    } else {
      ErrorNotification("No file selected.");
    }
  };

  const downloadFile = async () => {
    if (!errorFilePath) {
      console.error('No error file path available');
      return;
    }
  
    try {
      const apiUrl = `${BASE_URL}/v1/inventory_managment/items/download_error_file`;
      
      const response = await sendRequest(
        'GET',
        apiUrl,
        null,
        {
          responseType: 'blob',
          params: {
            file_path: errorFilePath,
          },
        }
      ); 
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const date = new Date();
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}_${date.getHours().toString().padStart(2, '0')}-${date.getMinutes().toString().padStart(2, '0')}`;
      const filename = `bulk-item-upload-error_${formattedDate}.xlsx`;
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  
  
  

  const downloadCsvFile = async () => {
    try {
      const token = getToken();

      const response = await axios.get(`${BASE_URL}/v1/inventory_managment/items/download_file.xlsx?devise_type=web`, {
        headers: {
          Authorization: token ? `${token}` : '',
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'blob',  // Ensure that the response is treated as a blob (binary data)
      });

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Sample-File.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting" , error);
    }
  };

  const getSettings = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/setting/settings/item_setting_options?type=global`,
        null,
        getToken()
      );
      console.log(response.data.data);
      setSetting(response.data.data);
    } catch (error) {
      console.error("Error fetching options config:", error.response || error.message);
    }
  };

  return (
    <>
      <div className="item-main-content">
        <div className="item-main-sub-content">
          <div className="row">
            <div className="col-md-8 item-file-download-class">
              <div className="button-row">
                <label htmlFor="document-upload" className="full-width-button">
                  {t("Browse File")} 
                  <span className="icon-wrapper"><FontAwesomeIcon icon={faUpload} /></span>
                </label>
                <input
                  id="document-upload"
                  type="file"
                  className="file-input"
                  accept=".xlsx, .xls"
                  onChange={handleFileChange}
                />
                <button className="small-upload-button" onClick={handleFileUpload}>
                  {t("Upload")}
                </button>
                  {isUploading && (
                    <span className="loading-indicator">
                      <span className="progress-message">Bulk uploading in progress</span>
                      <span className="dot">.</span>
                      <span className="dot">.</span>
                      <span className="dot">.</span>
                    </span>
                  )}
              </div>
              {errorFilePath && (
                <div>
                  <div className="error-item-file-download">
                    <a 
                      href="#" 
                      onClick={(e) => { e.preventDefault(); downloadFile(); }} 
                      className="bulk-upload-error-download-link"
                    >
                      {t("Download Error File")}
                    </a>
                  </div>
                  <div className="bulk-upload-error-file-note">
                    {t("Don't use error file for bulk item upload, only use the downloaded sample file.")}
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-4" >
                <div className="attribute-name">
                  {t("Download sample file")}
                  <span className="icon-wrapper" onClick={downloadCsvFile}><FontAwesomeIcon icon={faDownload} /></span>
                </div>
            </div>
          </div>
          <div className="file-color-name">{fileName ? fileName : ""}</div>
        </div>
        <div className="bulk-perform">{t("Only bulk upload perform through file")}</div>
        <hr className="horizontal-line" />
        <ItemAddPrice setting={setting}/>
      </div>
      <NotificationContainer />
      <Copyrights />
    </>
  )
}

export default Item;

