import React, { useEffect, useRef, useState } from "react";
import "./NewEWayBill.css";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import successNotification from "../../../Notification/SuccessNotification";
import ErrorNotification from "../../../Notification/ErrorNotification";
import { current_user } from "../../../Authentication/AuthenticationContext";

function NewEWayBill({ onClose, selectedEWayBill, isCopy = false, ewayBillcreated }) {

  const [transactionSupplyType, settransactionSupplyType] = useState(selectedEWayBill.transaction_supply_type);
  const [transactionSubType, settransactionSubType] = useState(selectedEWayBill.transaction_sub_type);
  const [transporter, settransporter] = useState(selectedEWayBill.transporter);
  const [transporterGstin, settransporterGstin] = useState(selectedEWayBill.transporter_gstin);
  const [distance, setdistance] = useState(selectedEWayBill.distance || "");
  const [sourceAddress, setsourceAddress] = useState(selectedEWayBill.source_address);
  const [modeOfTransportation, setmodeOfTransportation] = useState(selectedEWayBill.mode_of_transportation);
  const [vehicleType, setvehicleType] = useState(selectedEWayBill.vehicle_type);
  const [vehicleNo, setvehicleNo] = useState(selectedEWayBill.vehicle_no);
  const [transporterDocNo, settransporterDocNo] = useState(selectedEWayBill.transporter_doc_no)
  const [transporterDocDate, settransporterDocDate] = useState(selectedEWayBill.transporter_doc_date);
  const [isEWayTableFilter, setEWayTableFilter] = useState(false);
  const [branchId, setBranchId] = useState("");
  const [companyId, setCompanyId] = useState("");

  // const handleNewEWayFilter = () => {
  //   setEWayTableFilter(true);
  // }

  const closeTableFilter = () => {
    setEWayTableFilter(false);
  }

  const newEWayTableRef = useRef(null);

  useEffect(() => {
    const handleFilterClickOutside = (event) => {
      if (
        newEWayTableRef.current &&
        !newEWayTableRef.current.contains(event.target)
      ) {
        setEWayTableFilter(false);
      }
    };

    if (isEWayTableFilter) {
      document.addEventListener("mousedown", handleFilterClickOutside);
    } else {
      document.removeEventListener("mousedown", handleFilterClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleFilterClickOutside);
    };
  }, [isEWayTableFilter]);

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setBranchId(currentUser.company.branch_info);
      setCompanyId(currentUser.company);
    };
    fetchUser();
  }, []);


  const handlSubmitBill = async () => {
    try {
      const formData = new FormData();
      formData.append("e_way_bill[transaction_sub_type]", transactionSubType);
      formData.append("e_way_bill[transporter]", transporter);
      formData.append("e_way_bill[transporter_gstin]", transporterGstin);
      formData.append("e_way_bill[distance]", distance);
      formData.append("e_way_bill[source_address]", sourceAddress);
      formData.append("e_way_bill[mode_of_transportation]", modeOfTransportation);
      formData.append("e_way_bill[transaction_supply_type]", transactionSupplyType);
      formData.append("e_way_bill[vehicle_type]", vehicleType);
      formData.append("e_way_bill[vehicle_no]", vehicleNo);
      formData.append("e_way_bill[transporter_doc_no]", transporterDocNo);
      formData.append("e_way_bill[transporter_doc_date]", transporterDocDate);
      formData.append("e_way_bill[branch_id]", branchId.id);
      formData.append("e_way_bill[company_id]", companyId.id);
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/transactions/e_way_bills`,
        formData
      );
      successNotification("EWay Bill Created!");
      onClose();
      if (ewayBillcreated){
        ewayBillcreated(Array.isArray(response.data.entry) ? response.data.entry : [response.data.entry]);
      }
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
    }
  };

  const handleUpdateBill = async (selectedEWayBillId) => {
    try {
      const formData = new FormData();
      formData.append("e_way_bill[transaction_sub_type]", transactionSubType);
      formData.append("e_way_bill[transporter]", transporter);
      formData.append("e_way_bill[transporter_gstin]", transporterGstin);
      formData.append("e_way_bill[distance]", distance);
      formData.append("e_way_bill[source_address]", sourceAddress);
      formData.append("e_way_bill[mode_of_transportation]", modeOfTransportation);
      formData.append("e_way_bill[transaction_supply_type]", transactionSupplyType);
      formData.append("e_way_bill[vehicle_type]", vehicleType);
      formData.append("e_way_bill[vehicle_no]", vehicleNo);
      formData.append("e_way_bill[transporter_doc_no]", transporterDocNo);
      formData.append("e_way_bill[transporter_doc_date]", transporterDocDate);
      formData.append("e_way_bill[branch_id]", branchId.id);
      await sendRequest(
        "PUT",
        `${BASE_URL}/v1/transactions/e_way_bills/${selectedEWayBillId}`,
        formData
      );
      successNotification("EWay Bill Updated!");
      onClose();
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
    }
  };


  const handleSupplyTypeChange = (event) => {
    settransactionSupplyType(event.target.value);
  };

  const handleSubTypeChange = (event) => {
    settransactionSubType(event.target.value);
  };

  const handleTransporterChange = (event) => {
    settransporter(event.target.value);
  };

  const handleTransporterGstInChange = (event) => {
    settransporterGstin(event.target.value);
  };

  const handleDistanceChange = (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setdistance(value);
    }
  };

  const handleSourceAddressChange = (event) => {
    setsourceAddress(event.target.value);
  };

  const handleModeOfTransportationChange = (event) => {
    setmodeOfTransportation(event.target.value);
  };

  const handleVehicleTypeChange = (event) => {
    setvehicleType(event.target.value);
  };

  const handleVehicleNoChange = (event) => {
    setvehicleNo(event.target.value);
  };

  const handleTransporterDocNoChange = (event) => {
    settransporterDocNo(event.target.value);
  };

  const handleTransporterDocDateChange = (event) => {
    settransporterDocDate(event.target.value);
  };

  return (
    <div className="new-eway-main-container">
      <div className="new-eway-header-container">
        <div className="new-eway-header-left-part">
          <span className="new-eway-head-title">E-Way Bill</span>
        </div>
        <div className="new-eway-header-right-part">
          {!selectedEWayBill.id && !isCopy ? (
            <button className="new-eway-right-button" onClick={handlSubmitBill}>
              Save
            </button>
          ) : selectedEWayBill.id && !isCopy ? (
            <button className="new-eway-right-button" onClick={() => handleUpdateBill(selectedEWayBill.id)}>
              Update
            </button>
          ) : selectedEWayBill.id && isCopy ? (
            <button className="new-eway-right-button" onClick={handlSubmitBill}>
              Copy
            </button>
          ) : null}
          <button className="new-eway-right-button" onClick={onClose}>Cancel</button>
        </div>
      </div>

      <div className="new-eway-sub-container">
        <div className="new-eway-box-group">
          <div className="new-eway-first-box">
            <span className="new-eway-first-part-name">PART - A</span>
            <div className="new-eway-first-part-label-and-input-grp">
              <div className="new-eway-fist-div-grp">
                <label className="new-eway-first-label">Transaction Supply Type*</label>
                <select
                  className="new-eway-first-input"
                  value={transactionSupplyType}
                  onChange={handleSupplyTypeChange}
                >
                  <option selected disabled>None</option>
                  <option>Outward</option>
                  <option>Inward</option>
                </select>
              </div>

              <div className="new-eway-first-div-grp">
                <label className="new-eway-first-label">Transaction Sub Type*</label>
                <select
                  className="new-eway-first-input"
                  value={transactionSubType}
                  onChange={handleSubTypeChange}
                >
                  <option selected disabled>None</option>
                  <option>Supply</option>
                  <option>Other</option>
                </select>
              </div>

              <div className="new-eway-first-div-grp">
                <label className="new-eway-first-label">Transporter</label>
                <input
                  type="text"
                  className="new-eway-first-input"
                  value={transporter}
                  onChange={handleTransporterChange}
                />
              </div>

              <div className="new-eway-first-div-grp">
                <label className="new-eway-first-label">Transporter GSTIN/TRANSIN</label>
                <input
                  type="text"
                  className="new-eway-first-input"
                  value={transporterGstin}
                  onChange={handleTransporterGstInChange}
                />
              </div>

              <div className="new-eway-first-div-grp">
                <label className="new-eway-first-label">Distance (in Km)*</label>
                <input
                  type="text"
                  className="new-eway-first-input"
                  value={distance}
                  onChange={handleDistanceChange}
                />
              </div>

              <div className="new-eway-first-div-grp">
                <label className="new-eway-first-label">Source Address*</label>
                <textarea
                  type="text"
                  className="new-eway-first-input"
                  value={sourceAddress}
                  onChange={handleSourceAddressChange}
                >
                </textarea>
              </div>
            </div>
          </div>
          {/* <div className="new-eway-vertical-line"></div> */}
          <div className="new-eway-second-box">
            <span className="new-eway-second-part-name">PART - B</span>
            <div className="new-eway-second-part-label-and-input-grp">
              <div className="new-eway-second-div-grp">
                <label className="new-eway-second-label">Mode of Transportation*</label>
                <select
                  className="new-eway-second-input"
                  value={modeOfTransportation}
                  onChange={handleModeOfTransportationChange}
                >
                  <option selected disabled>Mode</option>
                  <option>road</option>
                  <option>rail</option>
                  <option>ship</option>
                  <option>air</option>
                </select>
              </div>
              <div className="new-eway-second-div-grp">
                <label className="new-eway-second-label">Vehicle Type</label>
                <select
                  className="new-eway-second-input"
                  value={vehicleType}
                  onChange={handleVehicleTypeChange}
                >
                  <option selected disabled>Type</option>
                  <option>regular</option>
                  <option>heavy</option>
                </select>
              </div>
              <div className="new-eway-second-div-grp">
                <label className="new-eway-second-label">Vehicle No</label>
                <input
                  type="text"
                  className="new-eway-second-input"
                  value={vehicleNo}
                  onChange={handleVehicleNoChange}
                />
              </div>
              <div className="new-eway-second-div-grp">
                <label className="new-eway-second-label">Transporter's Doc No</label>
                <input
                  type="text"
                  className="new-eway-second-input"
                  value={transporterDocNo}
                  onChange={handleTransporterDocNoChange}
                />
              </div>
              <div className="new-eway-second-div-grp">
                <label className="new-eway-second-label">Transporter's Doc Date</label>
                <input
                  type="date"
                  className="new-eway-second-input"
                  value={transporterDocDate}
                  onChange={handleTransporterDocDateChange}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <span className="new-eway-table-filter"><FontAwesomeIcon icon={faFilter} onClick={handleNewEWayFilter} /></span>
        </div> */}
        {/* <div className="new-eway-bill-table-group">
          <table className="new-eway-bill-table">
            <thead>
              <tr className="new-eway-bill-thead-row">
                <th className="new-eway-tab-th">Date</th>
                <th className="new-eway-tab-th">No</th>
                <th className="new-eway-tab-th">Contact Name</th>
                <th className="new-eway-tab-th">Contact GSTIN</th>
                <th className="new-eway-tab-th">Total</th>
              </tr>
            </thead>
            <tbody className="new-eway-bill-table-body">
              {tableData.map((row, index) => (
                <tr key={index} className="new-eway-bill-tbody-row">
                  <td className="new-eway-tab-td">{row.date}</td>
                  <td className="new-eway-tab-td">{row.no}</td>
                  <td className="new-eway-tab-td">{row.contactName}</td>
                  <td className="new-eway-tab-td">{row.contactGstin}</td>
                  <td className="new-eway-tab-td">{row.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
      </div>

      {isEWayTableFilter && (
        <div className="new-eway-table-filter-container" ref={newEWayTableRef}>
          <div className="new-eway-table-first-att-div">
            <label className="new-eway-table-first-att-div-label">Contacts / Estimate# / Ref# / Amount</label>
            <select>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
          </div>
          <div className="new-eway-table-second-att-div">
            <label className="new-eway-table-second-att-div-label">Status</label>
            <select>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
          </div>
          <div className="new-eway-table-third-att-div">
            <div className="new-eway-table-third-part-1">
              <label className="new-eway-table-third-part-1-label">Start Date</label>
              <input type="date" className="new-eway-table-third-att-date" />
            </div>
            <div className="new-eway-table-third-part-2">
              <label className="new-eway-table-third-part-2-label">End Date</label>
              <input type="date" className="new-eway-table-third-att-date" />
            </div>
          </div>
          <div className="new-eway-table-attr-btn-grp">
            <button className="new-eway-table-attr-pop-btn">Search</button>
            <button className="new-eway-table-attr-pop-btn" onClick={closeTableFilter}>
              Clear
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewEWayBill;
