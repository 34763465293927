import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { faPrint, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Copyrights from "../components/Copyrights";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./Report/reports.css";
import MonthListDropdown from "./Report/MonthListDropdown";
import YearListDropdown from "./Report/YearListDropdown";
import { BASE_URL } from "../Url/constants";
import { getToken } from "../components/SecureStorage/Token";
import CustomTable from "../components/Common/CustomTable";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import EntriesPerPageDropdown from "../components/Common/EntriesPerPageDropdown";
import PaginationControls from "../components/Common/PaginationControls";
import Search from "../components/Search/Search";
import { sendRequest } from "../ApiRequest";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Modal as BootstrapModal } from 'react-bootstrap';


import ReportSearchableDropdown from "./Report/ReportSearchableDropdown";
import { current_user } from "../Authentication/AuthenticationContext";

const DURATION_OPTIONS = {
  YESTERDAY: "yesterday",
  TODAY: "today",
  MONTHLY: "monthly",
  YEARLY: "yearly",
  CUSTOM: "custom",
};

const REPORT_TYPE_OPTIONS = {
  SALES: "sales",
  PURCHASE: "purchase",
  PARTY_WISE: "party_wise",
  DOCTOR_WISE: "doctor_wise",
  INVENTORY: "inventory",
  GST: "gst",
  SALES_PERSON: "sales_person",
};

function SaleReport() {
  const location = useLocation();
  const reportType = location.state;

  const fullMonthList = Array.from({ length: 12 }, (_, index) => {
    const monthName = format(new Date(2022, index, 1), "LLLL");
    return { value: index + 1, label: monthName };
  });

  const token = getToken();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(
    DURATION_OPTIONS.TODAY
  );
  const [selectedReportType, setSelectedReportType] = useState(
    reportType === "Purchase"
      ? REPORT_TYPE_OPTIONS.PURCHASE
      : reportType === "Sales Executive"
        ? REPORT_TYPE_OPTIONS.SALES_PERSON
        : REPORT_TYPE_OPTIONS.SALES
  );
  const [reportData, setReportData] = useState([]);
  const [exportFormat, setExportFormat] = useState("");
  const [recordPerPage, setrecordPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSale, setTotalSale] = useState(0);
  const [totalPurchase, setTotalPurchase] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const startIndex = (currentPage - 1) * recordPerPage + 1;
  const endIndex = Math.min(currentPage * recordPerPage, totalRecords);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(null);
  const { t } = useTranslation();
  const [errorUnauthorize, setErrorUnauthorize] = useState(false);
  const navigate = useNavigate();
  const [selectedPartyDoctor, setSelectedPartyDoctor] = useState(null);
  const [partyDoctorOptions, setPartyDoctorOptions] = useState([]);
  const [isPharmaceutical, setIsPharmaceutical] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(null);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const fetchUser = async () => {
    try {
      const currentUser = await current_user();
      setIsPharmaceutical(currentUser.company.business_type === 'Pharmaceutical');
    } catch (error) {
      console.error('Error fetching user:', error);
      setIsPharmaceutical(false);
    }
  };

  useEffect(() => {
    const initializeUser = async () => {
      await fetchUser();
    };
    initializeUser();
  }, []);

  useEffect(() => {
    setSelectedPartyDoctor(null);
    setPartyDoctorOptions([]);
  }, [selectedReportType]);

  useEffect(() => {
    fetchTotals();
  }, []);

  const fetchTotals = async () => {
    let url = `${BASE_URL}/v1/reports/reports/totals?filter_by=${selectedDuration}&redirect_to=reports`;
    if (
      selectedDuration === DURATION_OPTIONS.CUSTOM &&
      startDate &&
      endDate
    ) {
      url += `&start_date=${startDate}&end_date=${endDate}`;
    }

    if (selectedDuration === DURATION_OPTIONS.MONTHLY && selectedMonth) {
      url += `&month=${selectedMonth}`;
    } else if (
      selectedDuration === DURATION_OPTIONS.YEARLY &&
      selectedYear
    ) {
      url += `&year=${selectedYear}`;
    }
    try {
      const response = await sendRequest('get', url);
      const { total_sales, total_purchases, total_revenue } = response.data;
      setTotalSale(total_sales);
      setTotalPurchase(total_purchases);
      setTotalRevenue(total_revenue);
    } catch (error) {
      if (error && error.data && error.data.errors) {
        if (error.data.errors.length === 49) {
          setErrorUnauthorize(true); // Handle unauthorized case by showing the modal
        } else {
          ErrorNotification('Error fetching items:', error);
        }
      } else {
        ErrorNotification('Unknown error occurred', error);
      }
    }
  };

  const handleCloseItemModel = () => {
    setErrorUnauthorize(false);
    navigate("/dashboard");
  }

  const handlePrint = () => {
    window.print();
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleDurationChange = (event) => {
    const currentDate = new Date();
    const duration = event.target.value;
    setSelectedDuration(duration);
    if (duration === DURATION_OPTIONS.TODAY) {
      const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
      setStartDate(firstDayOfYear);
    } else if (duration === DURATION_OPTIONS.MONTHLY) {
      const monthList = fullMonthList.filter(
        (month) => month.value === currentDate.getMonth() + 1
      );
      setSelectedMonth(monthList[0].label);
      const firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      setStartDate(firstDayOfMonth);
    } else if (duration === DURATION_OPTIONS.YEARLY) {
      const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
      setStartDate(firstDayOfYear);
    } else if (duration === DURATION_OPTIONS.CUSTOM) {
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = currentDate
        .toLocaleDateString("en-GB", options)
        .split("/")
        .reverse()
        .join("-");
      setStartDate(formattedDate);
      setEndDate(formattedDate);
    }
  };

  const handleReportTypeChange = (event) => {
    setSelectedReportType(event.target.value);
    const selectElement = event.target;
    selectElement.style.color = "black";
    setExportFormat("");
    setSearchQuery("");
  };

  const handleExportFormatChange = async (e) => {
    const format = e.target.value;
    setExportFormat(format);
    if (format === "pdf") {
      await handleExportPDF();
    }
    if (format === "csv") {
      await handleExportCSV();
    }
  };

  const handleExport = async (format) => {
    try {
      let exportUrl = "";
      if (format === "csv") {
        exportUrl = `${BASE_URL}/v1/reports/reports/export_csv`;
      } else if (format === "pdf") {
        exportUrl = `${BASE_URL}/v1/reports/reports/export_pdf`;
      }

      const response = await axios.get(exportUrl, {
        headers: {
          Authorization: `${token}`,
        },
        params: {
          report_type: selectedReportType,
          filter_by: selectedDuration,
          header_title: headerTitle(),
          devise_type: "web",
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: format === "csv" ? "text/csv" : "application/pdf",
      });

      const currDate = new Date().toLocaleDateString("en-CA");
      const options = { hour: "2-digit", minute: "2-digit", hour12: true };
      const currTime = new Date().toLocaleTimeString([], options);
      // const currTime = new Date().toLocaleTimeString();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${selectedReportType} reports ${currDate} ${currTime} .${format}`
      );
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error(`Error exporting ${format.toUpperCase()}:`, error);
    }
  };

  const handleExportCSV = () => {
    handleExport("csv");
  };

  const handleExportPDF = () => {
    handleExport("pdf");
  };

  const headerTitle = () => {
    // Helper function to get report type text based on selectedReportType
    const getReportTypeText = () => {
      switch (selectedReportType) {
        case REPORT_TYPE_OPTIONS.SALES:
          return "Sale";
        case REPORT_TYPE_OPTIONS.PURCHASE:
          return "Purchase";
        case REPORT_TYPE_OPTIONS.PARTY_WISE:
          return "Party wise";
        case REPORT_TYPE_OPTIONS.DOCTOR_WISE:
          return "Doctor wise";
        case REPORT_TYPE_OPTIONS.GST:
          return "GST";
        case REPORT_TYPE_OPTIONS.SALES_PERSON:
          return "Sales Executive";
        default:
          return "";
      }
    };

    if (selectedReportType === REPORT_TYPE_OPTIONS.SALES_PERSON) {
      return `${t("Sales Executive Report")} - ${selectedYear || ""}`;
    } else if (
      selectedDuration === DURATION_OPTIONS.MONTHLY &&
      selectedReportType === REPORT_TYPE_OPTIONS.SALES
    ) {
      return `Sale report - ${startDate
        ? startDate.toLocaleString("default", {
          month: "long",
          year: "numeric",
        })
        : ""
        }`;
    } else if (
      selectedDuration === DURATION_OPTIONS.MONTHLY &&
      selectedReportType === REPORT_TYPE_OPTIONS.PURCHASE
    ) {
      return `Purchase report - ${startDate
        ? startDate.toLocaleString("default", {
          month: "long",
          year: "numeric",
        })
        : ""
        }`;
    } else if (
      selectedDuration === DURATION_OPTIONS.MONTHLY &&
      selectedReportType === REPORT_TYPE_OPTIONS.PARTY_WISE
    ) {
      return `Party wise report - ${startDate
        ? startDate.toLocaleString("default", {
          month: "long",
          year: "numeric",
        })
        : ""
        }`;
    } else if (
      selectedDuration === DURATION_OPTIONS.MONTHLY &&
      selectedReportType === REPORT_TYPE_OPTIONS.DOCTOR_WISE
    ) {
      return `Doctor wise report - ${startDate
        ? startDate.toLocaleString("default", {
          month: "long",
          year: "numeric",
        })
        : ""
        }`;
    } else if (
      selectedDuration === DURATION_OPTIONS.YEARLY &&
      selectedReportType === REPORT_TYPE_OPTIONS.SALES
    ) {
      return `Sale report - ${selectedYear}`;
    } else if (
      selectedDuration === DURATION_OPTIONS.YEARLY &&
      selectedReportType === REPORT_TYPE_OPTIONS.PURCHASE
    ) {
      return `Purchase report - ${selectedYear}`;
    } else if (
      selectedDuration === DURATION_OPTIONS.YEARLY &&
      selectedReportType === REPORT_TYPE_OPTIONS.PARTY_WISE
    ) {
      return `Party wise report - ${selectedYear}`;
    } else if (
      selectedDuration === DURATION_OPTIONS.YEARLY &&
      selectedReportType === REPORT_TYPE_OPTIONS.DOCTOR_WISE
    ) {
      return `Doctor wise report - ${selectedYear}`;
    } else if (selectedDuration === DURATION_OPTIONS.CUSTOM) {
      return `${getReportTypeText()} Report - ${startDate && endDate
        ? startDate.toLocaleString("default", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }) +
        " to " +
        endDate.toLocaleString("default", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
        : ""
        }`;
    } else if (selectedReportType === REPORT_TYPE_OPTIONS.GST) {
      return `GST Report - ${selectedDuration === DURATION_OPTIONS.MONTHLY
        ? startDate.toLocaleString("default", { month: "long", year: "numeric" })
        : selectedDuration === DURATION_OPTIONS.YEARLY
          ? selectedYear
          : "Custom Date Range"
        }`;
    } else {
      return `Daily ${selectedReportType === REPORT_TYPE_OPTIONS.PURCHASE
        ? "purchase"
        : selectedReportType === REPORT_TYPE_OPTIONS.SALES
          ? "sales"
          : selectedReportType === REPORT_TYPE_OPTIONS.PARTY_WISE
            ? "party wise"
            : selectedReportType === REPORT_TYPE_OPTIONS.DOCTOR_WISE
              ? "doctor wise"
              : selectedReportType === REPORT_TYPE_OPTIONS.GST
                ? "GST"
                : ""
        } report`;
    }
  };

  const fetchReportData = async (pageNumber = 1) => {
    try {
      let url = `${BASE_URL}/v1/reports/${selectedReportType}?filter_by=${selectedDuration}&page=${pageNumber}&per_page=${recordPerPage}&data_for=reports`;
      
      if (searchQuery) {
        url += `&query=${searchQuery}`;
      }

      if (selectedDuration === DURATION_OPTIONS.CUSTOM && startDate && endDate) {
        url += `&start_date=${startDate}&end_date=${endDate}`;
      }

      if (selectedDuration === DURATION_OPTIONS.MONTHLY && selectedMonth) {
        url += `&month=${selectedMonth}`;
      } else if (selectedDuration === DURATION_OPTIONS.YEARLY && selectedYear) {
        url += `&year=${selectedYear}`;
      }

      if (selectedPartyDoctor) {
        url += `&${selectedReportType === REPORT_TYPE_OPTIONS.PARTY_WISE ? 'party_id' : 'doctor_id'}=${selectedPartyDoctor.value}`;
      }

      const response = await sendRequest("GET", url);

      if (response.status === 200) {
        const { data, total_pages, total_records } = response.data;
        setReportData(data);
        setTotalPages(total_pages);
        setTotalRecords(total_records);

        if (selectedReportType === REPORT_TYPE_OPTIONS.PARTY_WISE || selectedReportType === REPORT_TYPE_OPTIONS.DOCTOR_WISE) {
          const options = data.map(item => ({ value: item.id, label: item.name }));
          setPartyDoctorOptions(options);
        }
      } else {
        ErrorNotification("Failed to fetch data");
      }
    } catch (error) {
      ErrorNotification(error.data.errors);
    }
  };

  const handleSearch = (query) => {
    if (query.length >= 2) {
      setSearchQuery(query);
    } else {
      setSearchQuery("");
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (selectedDuration === DURATION_OPTIONS.TODAY) {
      fetchReportData();
      fetchTotals();
    }
    else if (selectedDuration === DURATION_OPTIONS.YESTERDAY) {
      fetchReportData();
      fetchTotals();
    }
    else if (selectedReportType === REPORT_TYPE_OPTIONS.SALES_PERSON) {
      fetchReportData();
    }
    else if (selectedDuration === DURATION_OPTIONS.MONTHLY || selectedMonth) {
      fetchReportData();
      fetchTotals();
    } else if (selectedDuration === DURATION_OPTIONS.YEARLY || selectedYear) {
      fetchReportData();
      fetchTotals();
    } else if (selectedDuration === DURATION_OPTIONS.CUSTOM) {
      fetchReportData();
      fetchTotals();
    }
  }, [
    selectedDuration,
    selectedMonth,
    selectedYear,
    selectedReportType,
    startDate,
    endDate,
    recordPerPage,
    searchQuery,
    selectedPartyDoctor,
  ]);

  const calculateTotalSales = (userData) => {
    let totalSales = 0;
    
    if (userData && Array.isArray(userData)) {
      userData.forEach(user => {
        if (user.bill_items && Array.isArray(user.bill_items)) {
          user.bill_items.forEach(billItem => {
            const itemAmount = parseFloat(billItem.item_total_amount) || 0;
            const quantity = parseInt(billItem.quantity) || 0;
            totalSales += (itemAmount * quantity);
          });
        }
      });
    }
    
    return totalSales;
  };

  const paginate = async (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      await fetchReportData(pageNumber);
    }
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setrecordPerPage(perPage);
    setCurrentPage(1);
  };

  const getColumnNames = () => {
    switch (selectedReportType) {
      case REPORT_TYPE_OPTIONS.SALES:
        return [
          t("S. No."),
          t("Customer details"),
          t("Trans. ID"),
          t("Amount"),
          t("Billing date"),
          t("Payment status"),
          t("View invoice"),
        ];
      case REPORT_TYPE_OPTIONS.PURCHASE:
        return [
          t("S. No."),
          t("Supplier details"),
          t("Trans. ID"),
          t("Amount"),
          t("Billing date"),
          t("Payment status"),
          t("View invoice"),
        ];
      case REPORT_TYPE_OPTIONS.PARTY_WISE:
        return [
          t("S. No."),
          t("Party details"),
          t("Total transactions"),
          t("Total amount"),
          t("Last transaction date"),
        ];
      case REPORT_TYPE_OPTIONS.DOCTOR_WISE:
        return [
          t("S. No."),
          t("Doctor details"),
          t("Total transactions"),
          t("Total revenue"),
          t("Last transaction date"),
        ];

      case REPORT_TYPE_OPTIONS.GST:
        return [
          t("S. No."),
          t("Customer details"),
          t("Trans. ID"),
          t("Amount"),
          t("Billing date"),
          t("Payment status"),
          t("View invoice"),
        ];
      case REPORT_TYPE_OPTIONS.SALES_PERSON:
        return [
          t("S. No."),
          t("Sales Person"),
          t("Total Sales"),
          // t("Total Bills"),
          t("Total Bills"),
          t("Actions")
        ]
      default:
        return [];
    }
  };

  const col_names = getColumnNames();

  const tableData = reportData.map((item, index) => {
    console.log("item......", item);
    switch (selectedReportType) {
      case REPORT_TYPE_OPTIONS.SALES:
      case REPORT_TYPE_OPTIONS.PURCHASE:
        return {
          "S. No.": startIndex + index,
          [selectedReportType === REPORT_TYPE_OPTIONS.SALES ? "Customer details" : "Supplier details"]:
            `${item.phone || item.phone_number || ""} ${item.email || ""}`.trim() || " ",
          "Trans. ID": item.bill_number,
          Amount: `₹${parseFloat(item.total_amount).toFixed(2)}`,
          "Billing date": new Date(item.created_at).toLocaleString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }).replace(",", ""),
          "Payment status": item.status,
          "View invoice": (
            <Link to={`/invoice/${item.id}`} target="_blank">
              {t("View Details")}
            </Link>
          ),
        };
      case REPORT_TYPE_OPTIONS.SALES_PERSON:
        const userTotalSales = calculateTotalSales([item])
        return {
          "S. No.": startIndex + index,
          "Sales Person": item.user?.first_name || item.first_name || "N/A",
          "Total Sales": `₹${userTotalSales.toFixed(2)}`,   // Added ₹ symbol and proper formatting
          // "Total Bills": item.total_bills || 0,
          "Total Bills": (
            <div className="bill-items-summary">
              {item.bill_items?.slice(0, 2).map((billItem, idx) => (
                <div key={idx} className="bill-item-preview">
                  <span>Bill #{billItem.bill_id}</span>
                  {/* <span>{billItem.items_count} items</span> */}
                  {/* <span>₹{(parseFloat(billItem.total_amount) || 0).toFixed(2)}</span> */}
                </div>
              ))}
              {(item.bill_items?.length || 0) > 2 && (
                <div className="more-items">+ {item.bill_items.length - 2} more bills</div>
              )}
            </div>
          ),
          "Actions": (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => handleViewDetails(item.user?.id || item.id)}
            >
              {t("View Details")}
            </button>
          )
        };
      case REPORT_TYPE_OPTIONS.PARTY_WISE:
        return {
          "S. No.": startIndex + index,
          "Party details": item.name,
          "Total transactions": item.total_transactions,
          "Total amount": parseFloat(item.total_revenue).toFixed(2),
          "Last transaction date": new Date(item.last_transaction_date).toLocaleString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }).replace(",", ""),
        };

      case REPORT_TYPE_OPTIONS.DOCTOR_WISE:
        return {
          "S. No.": startIndex + index,
          "Doctor details": item.name,
          "Total transactions": item.total_transactions,
          "Total revenue": parseFloat(item.total_revenue).toFixed(2),
          "Last transaction date": new Date(item.last_transaction_date).toLocaleString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }).replace(",", ""),
        };

      case REPORT_TYPE_OPTIONS.GST:
        return {
          "S. No.": startIndex + index,
          [selectedReportType === REPORT_TYPE_OPTIONS.GST ? "Customer details" : "Customer/Supplier details"]:
            `${item.phone || item.phone_number || ""} ${item.email || ""}`.trim() || " ",
          "Trans. ID": item.bill_number,
          "Amount": `₹${parseFloat(item.total_amount).toFixed(2)}`,
          "Billing date": new Date(item.created_at).toLocaleString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }).replace(",", ""),
          "Payment status": item.status,
          "View invoice": (
            <Link to={`/invoice/${item.id}`} target="_blank">
              {t("View Details")}
            </Link>
          ),
        };
      default:
        return {};
    }
  });

  const handleViewDetails = (userId) => {
    setSelectedSalesPerson(userId); // Add this line
    setShowDetailsModal(true);
  };

  const SalesPersonDetailModal = ({ userId, show, onHide }) => {
    const [details, setDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
  
    useEffect(() => {
      if (show && userId) {
        fetchSalesPersonDetails(userId);
      }
    }, [show, userId]);
  
    const fetchSalesPersonDetails = async (id) => {
      try {
        setIsLoading(true);
        const response = await sendRequest(
          'get',
          `${BASE_URL}/v1/reports/sales_executive_details/${id}`
        );
        if (response && response.data) {
          setDetails(response.data);
        }
      } catch (error) {
        ErrorNotification("Failed to fetch details");
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <BootstrapModal show={show} onHide={onHide} size="lg">
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>
            {t("Sales Person Details")}
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {isLoading ? (
            <div className="text-center p-4">
              <span>{t("Loading...")}</span>
            </div>
          ) : details && (
            <div className="sales-person-details">
              <div className="sales-person-header mb-4">
                <h4>{details.user?.first_name || 'N/A'}</h4>
              </div>
  
              <div className="summary-section mb-4">
                <h5 className="section-title">{t("Summary")}</h5>
                <div className="summary-grid">
                  <div className="summary-item">
                    <span className="label">{t("Total Bills")}:</span>
                    <span className="value">{details.total_bills || 0}</span>
                  </div>
                  <div className="summary-item">
                    <span className="label">{t("Total Items Sold")}:</span>
                    <span className="value">{details.total_items || 0}</span>
                  </div>
                  <div className="summary-item">
                    <span className="label">{t("Total Amount")}:</span>
                    <span className="value">₹{(parseFloat(details.total_amount) || 0).toFixed(2)}</span>
                  </div>
                </div>
              </div>
  
              <div className="bills-section">
                <h5 className="section-title">{t("Recent Bills")}</h5>
                {Array.isArray(details.bills) && details.bills.map((bill, index) => (
                  <div key={index} className="bill-card mb-3">
                    <div className="bill-header">
                      <h6>{t("Bill")} #{bill.id}</h6>
                      <span className="bill-date">
                        {new Date(bill.created_at).toLocaleDateString()}
                      </span>
                    </div>
                    
                    <div className="bill-items-table">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th>{t("Item")}</th>
                            <th>{t("Quantity")}</th>
                            <th>{t("Price")}</th>
                            <th>{t("Total")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(bill.items) && bill.items.map((item, itemIndex) => (
                            <tr key={itemIndex}>
                              <td>{item.name?.en || 'N/A'}</td>
                              <td>{item.quantity || 0}</td>
                              <td>₹{(parseFloat(item.amount) || 0).toFixed(2)}</td>
                              <td>₹{(parseFloat(item.amount) || 0).toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    
                    <div className="bill-footer">
                      <strong>{t("Bill Total")}: ₹{(parseFloat(bill.total_amount) || 0).toFixed(2)}</strong>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </BootstrapModal.Body>
      </BootstrapModal>
    );
  };
  return (
    <>
      <div className="content style-tax">
        <div className="sale-purchase-box mt-10 report-box">

          <div className="icon-container">
            <h3 className="title">{t("Report")}</h3>

            <div className="icon-content">
              <p className="action-label">{t("Export")} </p>
              <select
                className="form-select"
                value={exportFormat}
                onChange={handleExportFormatChange}
              >
                <option value="" disabled hidden>
                  {t("Select file type")}
                </option>
                <option value="csv">CSV</option>
                <option value="pdf">PDF</option>
              </select>

              <button className="action-button" onClick={handlePrint}>
                <FontAwesomeIcon icon={faPrint} className="print-icon" />
              </button>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-4 mt-0">
              <div className="sp-blue common-heigth-report-amount">
                <div className="sp-icon">
                  <svg
                    width="42"
                    height="55"
                    viewBox="0 0 42 69"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_531_811)">
                      <path
                        d="M0.000447751 8.625C0.000447751 6.23965 1.87732 4.3125 4.20045 4.3125H12.6004H14.7004H37.8004C40.1236 4.3125 42.0005 6.23965 42.0005 8.625C42.0005 11.0104 40.1236 12.9375 37.8004 12.9375H30.4242C31.6842 14.8781 32.6161 17.0613 33.1411 19.4062H37.8004C40.1236 19.4062 42.0005 21.3334 42.0005 23.7188C42.0005 26.1041 40.1236 28.0312 37.8004 28.0312H33.1279C31.3955 35.8881 25.0036 41.9391 17.0892 42.9768L36.0417 56.8711C37.9317 58.2592 38.3648 60.9545 37.013 62.8816C35.6611 64.8088 33.0361 65.267 31.1592 63.8789L1.7592 42.3164C0.276073 41.2383 -0.353927 39.2842 0.197323 37.4918C0.748573 35.6994 2.37607 34.5 4.20045 34.5H14.7004C19.0054 34.5 22.7067 31.8451 24.3211 28.0312H4.20045C1.87732 28.0312 0.000447751 26.1041 0.000447751 23.7188C0.000447751 21.3334 1.87732 19.4062 4.20045 19.4062H24.3211C22.7067 15.5924 19.0054 12.9375 14.7004 12.9375H12.6004H4.20045C1.87732 12.9375 0.000447751 11.0104 0.000447751 8.625Z"
                        fill="#474DDC"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_531_811">
                        <rect width="42" height="69" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="sp-content">
                  <span>{t("Total sale")}</span>
                  <h3>{totalSale?.toFixed(2)}</h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-0">
              <div className="sp-red common-heigth-report-amount">
                <div className="sp-icon">
                  <svg
                    width="42"
                    height="60"
                    viewBox="0 0 42 69"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_531_815)">
                      <path
                        d="M0.000447751 8.625C0.000447751 6.23965 1.87732 4.3125 4.20045 4.3125H12.6004H14.7004H37.8004C40.1236 4.3125 42.0005 6.23965 42.0005 8.625C42.0005 11.0104 40.1236 12.9375 37.8004 12.9375H30.4242C31.6842 14.8781 32.6161 17.0613 33.1411 19.4062H37.8004C40.1236 19.4062 42.0005 21.3334 42.0005 23.7188C42.0005 26.1041 40.1236 28.0312 37.8004 28.0312H33.1279C31.3955 35.8881 25.0036 41.9391 17.0892 42.9768L36.0417 56.8711C37.9317 58.2592 38.3648 60.9545 37.013 62.8816C35.6611 64.8088 33.0361 65.267 31.1592 63.8789L1.7592 42.3164C0.276073 41.2383 -0.353927 39.2842 0.197323 37.4918C0.748573 35.6994 2.37607 34.5 4.20045 34.5H14.7004C19.0054 34.5 22.7067 31.8451 24.3211 28.0312H4.20045C1.87732 28.0312 0.000447751 26.1041 0.000447751 23.7188C0.000447751 21.3334 1.87732 19.4062 4.20045 19.4062H24.3211C22.7067 15.5924 19.0054 12.9375 14.7004 12.9375H12.6004H4.20045C1.87732 12.9375 0.000447751 11.0104 0.000447751 8.625Z"
                        fill="#FE1818"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_531_815">
                        <rect width="42" height="69" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="sp-content">
                  <span>{t("Total purchase")}</span>
                  <h3>{totalPurchase?.toFixed(2)}</h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-0">
              <div className="sp-green common-heigth-report-amount">
                <div className="sp-icon">
                  <svg
                    width="50"
                    height="60"
                    viewBox="0 0 50 73"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      clipPath="url(#clip0_531_817)"
                      filter="url(#filter0_d_531_817)"
                    >
                      <path
                        d="M4.00045 8.625C4.00045 6.23965 5.87732 4.3125 8.20045 4.3125H16.6004H18.7004H41.8004C44.1236 4.3125 46.0005 6.23965 46.0005 8.625C46.0005 11.0104 44.1236 12.9375 41.8004 12.9375H34.4242C35.6842 14.8781 36.6161 17.0613 37.1411 19.4062H41.8004C44.1236 19.4062 46.0005 21.3334 46.0005 23.7188C46.0005 26.1041 44.1236 28.0312 41.8004 28.0312H37.1279C35.3955 35.8881 29.0036 41.9391 21.0892 42.9768L40.0417 56.8711C41.9317 58.2592 42.3648 60.9545 41.013 62.8816C39.6611 64.8088 37.0361 65.267 35.1592 63.8789L5.7592 42.3164C4.27607 41.2383 3.64607 39.2842 4.19732 37.4918C4.74857 35.6994 6.37607 34.5 8.20045 34.5H18.7004C23.0054 34.5 26.7067 31.8451 28.3211 28.0312H8.20045C5.87732 28.0312 4.00045 26.1041 4.00045 23.7188C4.00045 21.3334 5.87732 19.4062 8.20045 19.4062H28.3211C26.7067 15.5924 23.0054 12.9375 18.7004 12.9375H16.6004H8.20045C5.87732 12.9375 4.00045 11.0104 4.00045 8.625Z"
                        fill="#23A203"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_531_817">
                        <rect
                          width="42"
                          height="69"
                          fill="white"
                          transform="translate(4)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="sp-content">
                  <span>{t("Total revenue")}</span>
                  <h3>{totalRevenue?.toFixed(2)}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-2">
              <select
                className="form-select report-type"
                onChange={handleReportTypeChange}
                value={selectedReportType}
              >
                <option value={REPORT_TYPE_OPTIONS.SALES}>
                  {t("Sale report")}
                </option>
                <option value={REPORT_TYPE_OPTIONS.PURCHASE}>
                  {t("Purchase report")}
                </option>
                {isPharmaceutical && (
                  <>
                    <option value={REPORT_TYPE_OPTIONS.PARTY_WISE}>
                      {t("Party-wise report")}
                    </option>
                    <option value={REPORT_TYPE_OPTIONS.DOCTOR_WISE}>
                      {t("Doctor-wise report")}
                    </option>
                  </>
                )}
                <option value={REPORT_TYPE_OPTIONS.GST}>
                  {t("GST report")}
                </option>
                <option value={REPORT_TYPE_OPTIONS.SALES_PERSON}>
                  {t("Sales Executive")}
                </option>
              </select>
            </div>

            {(selectedReportType === REPORT_TYPE_OPTIONS.PARTY_WISE ||
              selectedReportType === REPORT_TYPE_OPTIONS.DOCTOR_WISE) && (
                <div className="col-md-2">
                  <div className="report-searchable-dropdown-container">
                    <ReportSearchableDropdown
                      options={partyDoctorOptions}
                      value={selectedPartyDoctor}
                      onChange={(selected) => setSelectedPartyDoctor(selected)}
                      placeholder={
                        selectedReportType === REPORT_TYPE_OPTIONS.PARTY_WISE
                          ? t("Search party...")
                          : t("Search doctor...")
                      }
                    />
                  </div>
                </div>
              )}

            <div className="col-md-2">
              <div className="select-report-content">
                <select
                  className="form-select report-type"
                  onChange={handleDurationChange}
                  defaultValue={DURATION_OPTIONS.TODAY}
                >
                  <option value={DURATION_OPTIONS.TODAY}>{t("Today")}</option>
                  <option value={DURATION_OPTIONS.YESTERDAY}>{t("Yesterday")}</option>
                  <option value={DURATION_OPTIONS.MONTHLY}>
                    {t("Monthly")}
                  </option>
                  <option value={DURATION_OPTIONS.YEARLY}>{t("Yearly")}</option>
                  <option value={DURATION_OPTIONS.CUSTOM}>{t("Custom")}</option>
                </select>
              </div>
            </div>

            {selectedDuration === DURATION_OPTIONS.CUSTOM && (
              <>
                <div className="col-md-2">
                  <div className="date-picker-container">
                    <DatePicker
                      placeholderText="Start date"
                      showYearDropdown
                      showMonthDropdown
                      selected={startDate}
                      dateFormat="d MMM yyyy"
                      onChange={(date) => handleStartDateChange(date)}
                      className="date-picker report-type form-select"
                      maxDate={endDate ? endDate : new Date()}
                    />
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="date-picker-icon"
                    />
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="date-picker-container">
                    <DatePicker
                      placeholderText="End date"
                      showYearDropdown
                      showMonthDropdown
                      selected={endDate ? endDate : new Date()}
                      dateFormat="d MMM yyyy"
                      onChange={(date) => handleEndDateChange(date)}
                      minDate={startDate}
                      maxDate={new Date()}
                      className="date-picker report-type form-select"
                    />
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="date-picker-icon"
                    />
                  </div>
                </div>
              </>
            )}

            {selectedDuration === DURATION_OPTIONS.MONTHLY && (
              <div className="col-md-2">
                <MonthListDropdown
                  selectedMonth={selectedMonth}
                  handleMonthChange={handleMonthChange}
                />
              </div>
            )}

            {selectedDuration === DURATION_OPTIONS.YEARLY && (
              <div className="col-md-2">
                <YearListDropdown
                  selectYear={selectedYear}
                  handleYearChange={handleYearChange}
                />
              </div>
            )}
          </div>
        </div>

        <div className="pagination-dropdown">
          <div className="pagination-controls">
            <EntriesPerPageDropdown
              recordPerPage={recordPerPage}
              handleRecordPerPageChange={handleRecordPerPageChange}
            />
          </div>
          <div className="reports-control-label">
            <h3 className="title">{headerTitle()}</h3>
          </div>
          <div
            className="reports-search-box"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          >
            <Search placeholder={t("Search reports...")} />
          </div>
        </div>

        <br />

        <div className="bd-example sp-table sp-table-header" style={{ zIndex: 0 }}>
          <div className="report-table-data">
            <CustomTable col_names={col_names} data={tableData} />
          </div>
        </div>

        {reportData.length === 0 && (
          <div className="no-report-data-message">{t("No records found")}</div>
        )}

        <>
          {reportData.map((index) => (
            <div key={index}></div>
          ))}

          {reportData.length > 0 && (
            <div>
              {totalRecords >= recordPerPage && (
                <div className="report-pagination-info">
                  <div className="report-pagination-container">
                    <PaginationControls
                      totalRecords={totalRecords}
                      totalPages={totalPages}
                      currentPage={currentPage}
                      paginate={paginate}
                      startIndex={startIndex}
                      endIndex={endIndex}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </>
        <SalesPersonDetailModal
          show={showDetailsModal}
          onHide={() => {
            setShowDetailsModal(false);
            setSelectedSalesPerson(null);
          }}
          userId={selectedSalesPerson}
        />
        <NotificationContainer />
      </div>
      {
        errorUnauthorize && (
          <div className="item-purchase-unauthorize-modal-popup">
            <div className="">
              {/* <Modal onClose={handleCloseItemModel} /> */}
            </div>
          </div>
        )
      }
      <Copyrights />
    </>
  );
}

export default SaleReport;
