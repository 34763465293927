import React, { useState } from "react";
import CreateFloorModal from "../../components/Restaurant/CreateFloorModal";
import successNotification from "../../Notification/SuccessNotification";
import ErrorNotification from "../../Notification/ErrorNotification";
import { BASE_URL } from "../../Url/constants";
import "./RestaurentSettings.css";
import { useNavigate } from "react-router-dom";
import UpdateRestaurantConfig from "./UpdateRestaurantConfig";
import { useTranslation } from "react-i18next";
import { sendRequest } from "../../ApiRequest";

const RestaurantSettings = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [showRestaurantConfig, setShowRestaurantConfig] = useState(false);
  const { t } = useTranslation();

  const handleCreateFloors = async (floorDetails) => {
    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/onboarding/companies/create_floors_and_tables`,
        { floor_details: floorDetails }
      );

      if (response.status === 200) {
        const data = response.data;
        if (Array.isArray(data.data)) {
          successNotification("Floors created successfully", "success");
          setShowModal(false);
          navigate("/restaurants");
        } else {
          console.error("Invalid response data format");
        }
      } else {
        const errorData = await response.json();
        ErrorNotification("Name can't be blank", errorData.error);
      }
    } catch (error) {
      ErrorNotification("Error creating floors:", error.data.error);
    }
  };

  const handleRestaurantConfig = () => {
    setShowRestaurantConfig(true);
  };

  return (
    <div className="restaurant-settings">
      <h2>{t("Restaurant Settings")}</h2>

      <div className="settings-section">
        <h3>{t("Manage Floors and Tables")}</h3>
        <p>
          {t("Create and manage your restaurant's floors and tables to efficiently organize your seating arrangements.")}
        </p>
        <button onClick={() => setShowModal(true)}>
          {t("Create Floors and Tables")}
        </button>
      </div>

      <div className="settings-section">
        <h3>{t("Menu Management")}</h3>
        <p>
          {t("Update your menu items, pricing, and categories to keep your offerings fresh and appealing to your customers.")}
        </p>
        <button>{t("Manage Menu")}</button>
      </div>

      <div className="settings-section">
        <h3>{t("Employee Management")}</h3>
        <p>
          {t("Onboard new staff members, assign roles and permissions, and manage schedules to ensure efficient operations.")}
        </p>
        <button>{t("Manage Employees")}</button>
      </div>

      <div className="settings-section">
        <h3>{t("Manage Pax Setting")}</h3>
        <p>
          {t("Enabling this setting allows you to apply configurations for select pax and the captain.")}
        </p>
        <button onClick={handleRestaurantConfig}>{t("Manage Pax Setting")}</button>
      </div>

      {showModal && (
        <CreateFloorModal
          onSubmit={handleCreateFloors}
          onClose={() => setShowModal(false)}
        />
      )}

      {showRestaurantConfig && (
        <div className="restaurant-config-main-popup">
          <UpdateRestaurantConfig
            onClose={() => setShowRestaurantConfig(false)}
          />
        </div>
      )}
    </div>
  );
};

export default RestaurantSettings;
