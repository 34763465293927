import React, { useEffect, useState } from "react";
import "./NewCreditNote.css"
import SaleCommonPopup from "../SaleCommonPopupHeader/SaleCommonPopupHeader";
import CommonSalePopupTable from "../CommonSalePopupTable/CommonSalePopupTable";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import { current_user } from "../../../Authentication/AuthenticationContext";
import successNotification from "../../../Notification/SuccessNotification";
import ErrorNotification from "../../../Notification/ErrorNotification";
import CommonCustomerPopup from "../../Common/CommonCustomerPopup";
import { BsPencilSquare } from "react-icons/bs";
import CommonToggleButton from "../../Common/CommonToggleButton";

function NewCreditNote({ onClose, saleCreditNoteCopy, isUpdated, creditNoteCreated }) {

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setBranchId(currentUser.company.branch_info);
    };
    fetchUser();
  }, []);

  const [totalAmount, setTotalAmount] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [gstAmount, setGstAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [branchId, setBranchId] = useState("");
  const [isEstimateDate, setEstimateDate] = useState(saleCreditNoteCopy?.estimate_date ? saleCreditNoteCopy?.estimate_date : "");
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState(saleCreditNoteCopy?.customer?.customer_name ? saleCreditNoteCopy?.customer?.customer_name : "");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [isShowCustomerPopup, setShowCustomerPopup] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [customerError, setCustomerError] = useState("");
  const [customerDetails, setCustomerDetails] = useState();
  const [itemWiseTax, setItemWiseTax] = useState(true);
  const [packagingCharges, setpackagingCharges] = useState("");
  const [BtnDisable, setBtnDisabled] = useState(false);

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/customers/customer_search?query=${term}`
        );
        const customers = response.data;
        if (customers.length > 0) {
          setFilteredCustomers(customers);
          setShowDropdown(true);
          setShowAddCustomer(false);
        } else {
          setFilteredCustomers([]);
          setShowDropdown(true);
          setShowAddCustomer(true);
        }
      } catch (error) {
        setShowDropdown(false);
        setShowAddCustomer(true);
      }
    } else {
      setShowDropdown(false);
      setShowAddCustomer(false);
    }
  };

  const handleSelectCustomer = (customer) => {
    setCustomerDetails(customer);
    setCustomerId(customer.id);
    setSearchTerm(
      customer.first_name && customer.last_name
        ? `${customer.first_name} ${customer.last_name}`
        : customer.first_name || customer.last_name
    );
    setShowDropdown(false);
  };

  const handleCreateCustomer = () => {
    setShowCustomerPopup(true);
  }

  const closeCommonCustomerPopup = () => {
    setShowCustomerPopup(false);
  }

  const handleAddCustomer = () => {
    setShowCustomerPopup(true);
    setShowDropdown(false);
    setShowAddCustomer(false);
  };

  const handleSelectedCustomer = (customer) => {
    setCustomerId(customer?.id);
    setSearchTerm(
      customer.first_name && customer.last_name
        ? `${customer.first_name} ${customer.last_name}`
        : customer.first_name || customer.last_name
    );
  }

  const handleTotalAmount = (amount) => {
    setTotalAmount(amount);
  }

  const handleSubTotal = (amount) => {
    setSubTotal(amount);
  }

  const handleGstValue = (amount) => {
    setGstAmount(amount);
  }

  const handleDiscountValue = (amount) => {
    setDiscount(amount);
  }

  const updatedSelectedItems = (items) => {
    setSelectedItems(items);
  }

  const handleNewSaleCreditNote = async () => {
    setBtnDisabled(true);
    if (customerId || (saleCreditNoteCopy && saleCreditNoteCopy?.customer?.id)) {
      setCustomerError("");
    } else {
      setCustomerError("Please select a customer before saving the Credit Note.");
      return;
    }

    try {
      const uniqueItems = [];
      const itemIds = new Set();
      selectedItems.forEach(item => {
        if (!itemIds.has(item.id)) {
          itemIds.add(item.id);
          uniqueItems.push({
            item_id: item?.item_id,
            quantity: item?.qty,
            item_total_discount: item.discountAmount ? item?.discountAmount?.toFixed(2) : 0,
            item_total_tax: item.tax ? item?.tax?.toFixed(2) : 0,
            item_total_amount: item?.saleAmount?.toFixed(2) || '',
            branch_id: branchId.id
          });
        }
      });

      const formData = {
        bill: {
          customer_id: customerId || 1,
          estimate_date: isEstimateDate,
          bill_items_attributes: uniqueItems,
          total_amount: totalAmount,
          sub_total: subTotal,
          total_gst: gstAmount,
          status: 0,
          total_discount: discount,
          parcel_charges: packagingCharges
        }
      }
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/transactions/entries?type=credit_note`,
        formData
      );
      successNotification("Credit Note Created!");
      if (creditNoteCreated) {
        creditNoteCreated(Array.isArray(response.data.entry) ? response.data.entry : [response.data.entry]);
      }
      onClose();
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
    }
  }

  const handleEditCreditNote = async (saleCreditNoteEditId) => {
    setBtnDisabled(true);
    if (customerId || (saleCreditNoteCopy && saleCreditNoteCopy?.customer?.id)) {
      setCustomerError("");
    } else {
      setCustomerError("Please select a customer before saving the Credit Note.");
      return;
    }

    if (saleCreditNoteEditId) {
      try {
        const uniqueItems = [];
        const itemIds = new Set();

        selectedItems.forEach(item => {
          if (!itemIds.has(item.id)) {
            itemIds.add(item.id);
            uniqueItems.push({
              id: item?.id ? item?.id : null,
              item_id: item?.item_id,
              quantity: item?.qty,
              item_total_discount: item?.discount_amount?.toFixed(2),
              item_total_tax: item?.tax?.toFixed(2),
              item_total_amount: item?.saleAmount?.toFixed(2),
              branch_id: branchId.id
            });
          }
        });

        const formData = {
          bill: {
            customer_id: customerId || 1,
            estimate_date: isEstimateDate,
            bill_items_attributes: uniqueItems,
            total_amount: totalAmount,
            sub_total: subTotal,
            total_gst: gstAmount,
            status: 0,
            total_discount: discount,
            parcel_charges: packagingCharges
          }
        }
        await sendRequest(
          "PUT",
          `${BASE_URL}/v1/transactions/entries/${saleCreditNoteEditId}?type=credit_note`,
          formData
        );
        successNotification("Sale credit note updated!");
        onClose();
      }
      catch (error) {
        error.data.errors.map((error) => ErrorNotification(error));
      }
    }
    else {
      console.log("Failed to update credit note")
    }
  }

  const fetchUpdatedCustCreditDetails = async (updatedCustId) => {
    if (updatedCustId) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/customers/${updatedCustId}`
        );
        const updateCustomers = response.data.data;
        setCustomerDetails(updateCustomers);
      }
      catch {
        console.log("customer not fetch")
      }
    }
  }

  const handleToggleChange = (value) => {
    setItemWiseTax(value);
  }

  const handlePackagingCharge = (amount) => {
    setpackagingCharges(amount);
  }
  
  const handleCustCreditUpdate = (updatedCustomer) => {
    fetchUpdatedCustCreditDetails(updatedCustomer);
  };

  return (
    <>
      <div className="new-credit-note-main-container">
        <SaleCommonPopup onClose={onClose} onSave={handleNewSaleCreditNote} isUpdated={isUpdated} onUpdate={() => handleEditCreditNote(saleCreditNoteCopy.id)}
          BtnDisable={BtnDisable}
        />
        <div className="new-credit-note-sub-container">
          <div className="new-credit-note-first-div">
            {/* <div className="new-credit-note-estimate-no-grp">
              <span className="new-credit-note-est-no-title">Credit Note No</span>
              <span className="new-credit-note-est-no">CN2</span>
            </div> */}
            <div className="new-credit-note-estimate-date-grp">
              <span className="new-credit-note-estdate-title">Credit Note Date</span>
              <span className="new-credit-note-estdate-input">
                <input className="form-control new-credit-note-first-div-input"
                  type="date"
                  onChange={(e) => setEstimateDate(e.target.value)}
                  value={isEstimateDate} />
              </span>
            </div>
            <div className="new-credit-note-reference-grp">
              <span className="new-credit-note-est-reference-title">Invoice </span>
              <span className="new-credit-note-est-reference-input">
                <input className="form-control new-credit-note-first-div-input" />
              </span>
            </div>
          </div>

          <div className="new-credit-note-second-div">
            <div className="new-credit-note-customer-div">
              <span className="new-credit-note-cust-title">Customer</span>
              <span className="new-credit-note-cust-search">
                <input
                  className="form-control new-credit-note-cust-field"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search customer..."
                />
                {showDropdown && (
                  <ul className="new-credit-note-customer-dropdown">
                    {filteredCustomers.length > 0 ? (
                      filteredCustomers.map((customer, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelectCustomer(customer)}
                          className="new-credit-note-dropdown-item"
                        >
                          {customer.first_name && customer.last_name
                            ? `${customer.first_name} ${customer.last_name}`
                            : customer.first_name || customer.last_name}
                        </li>
                      ))
                    ) : (
                      <li className="customer-dropdown-item">No customer found</li>
                    )}
                    {showAddCustomer && (
                      <li className="customer-dropdown-item" onClick={handleAddCustomer}>
                        <button className="btn btn-primary" onClick={handleCreateCustomer}>Add Customer</button>
                      </li>
                    )}
                  </ul>
                )}
              </span>
              {customerError && (
                <span className="error-message" style={{ color: 'red', marginTop: '5px' }}>
                  {customerError}
                </span>
              )}
            </div>
            {customerId ? (
              <>
                <div className="new-quote-second-billing">
                  <div className="new-quote-billing-first-div">
                    <span className="new-quote-bill-title">Billing Address</span>
                    <BsPencilSquare onClick={handleCreateCustomer} />
                  </div>
                  <span className="new-quote-billing-address">
                    {customerDetails?.addresses && (
                      <>
                        {customerDetails?.addresses
                          .filter(address => address.address_type === 'billing')
                          .map(billingAddress => (
                            <div key={billingAddress.id}>
                              {billingAddress?.address_line_1},
                              {billingAddress?.address_line_2},
                              {billingAddress?.city},
                              {billingAddress?.state},
                              {billingAddress?.country}
                            </div>
                          ))}
                      </>
                    )}
                  </span>
                </div>

                <div className="new-quote-second-shipping">
                  <div className="new-quote-billing-first-div">
                    <span className="new-quote-shipping-title">Shipping Address</span>
                    <BsPencilSquare onClick={handleCreateCustomer} />
                  </div>
                  <span className="new-quote-shipping-address">
                    {customerDetails?.addresses && (
                      <>
                        {customerDetails?.addresses
                          .filter(address => address.address_type === 'shipping')
                          .map(shippingAddress => (
                            <div key={shippingAddress.id}>
                              {shippingAddress?.address_line_1},
                              {shippingAddress?.address_line_2},
                              {shippingAddress?.city},
                              {shippingAddress?.state},
                              {shippingAddress?.country}
                            </div>
                          ))}
                      </>
                    )}
                  </span>
                </div>
              </>
            ) : (
              ''
            )}
          </div>

          <div className="new-credit-note-toggle-button-grp">
            <CommonToggleButton onToggleChange={handleToggleChange} />
          </div>

          <div className="new-credit-note-table">
            <CommonSalePopupTable
              updatedSelectedItems={(items) => updatedSelectedItems(items)}
              totalAmountValue={(totalAmount) => handleTotalAmount(totalAmount)}
              subTotalValue={(subTotal) => handleSubTotal(subTotal)}
              gstValue={(gstValue) => handleGstValue(gstValue)}
              discountValue={(discount) => handleDiscountValue(discount)}
              packagingValue={(packagingCharges) => handlePackagingCharge(packagingCharges)}
              saleCreditNoteCopy={saleCreditNoteCopy}
              isTotalTaxApplied={itemWiseTax}
            />
          </div>

          {/* <div className="new-credit-note-third-div">
            <div className="new-credit-note-note-grp">
              <span className="new-credit-note-note-title">Notes</span>
              <span className="new-credit-note-note-area">
                <textarea className="new-credit-note-note-textarea-input" />
              </span>
            </div>
            <div className="new-credit-note-terms-condition-grp">
              <span className="new-credit-note-term-title">Terms & Conditions</span>
              <span className="new-credit-note-term-textarea">
                <textarea className="new-credit-note-term-textarea-input" />
              </span>
            </div>
            <div className="new-credit-note-bank-grp">
              <span className="new-credit-note-bank-title">Bank</span>
              <span className="new-credit-note-bank-input">
                <input className="form-control new-credit-note-last-input" />
              </span>
            </div>
            <div className="new-credit-note-sales-rep-grp">
              <span className="new-credit-note-sale-rep-title">Sales Rep. </span>
              <span className="new-credit-note-sale-rep-input">
                <input className="form-control new-credit-note-last-input" />
              </span>
            </div>
          </div> */}
        </div>
      </div>
      {isShowCustomerPopup && (
        <div className="new-sale-order-customer-popup">
          <CommonCustomerPopup
            selectedCustomer={(customer) => handleSelectedCustomer(customer)}
            onClose={closeCommonCustomerPopup}
            customerDetails={customerDetails}
            onCustomerUpdate={handleCustCreditUpdate}
          />
        </div>
      )}
    </>
  );
}

export default NewCreditNote;
