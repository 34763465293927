import React from 'react';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const NotificationWrapper = ({ children }) => {
  return (
    <>
      {children}
      <NotificationContainer />
    </>
  );
};

export default NotificationWrapper;
