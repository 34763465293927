import React from "react"
import "./CommonCloseButton.css"

function CommonCloseButton({onClose}) {
  return (
    <>
      <span className='res-common-close-button' onClose={onClose}>X</span>
    </>
  )
}

export default CommonCloseButton;
