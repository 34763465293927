import { useState, useEffect } from 'react';
import { sendRequest } from '../../ApiRequest';
import { BASE_URL } from '../../Url/constants';

const UnitOptions = ({ token }) => {
  const [units, setUnits] = useState([]);

  useEffect(() => {
    const fetchUnitOptions = async () => {
      try {
        const response = await sendRequest(
          'get',
          `${BASE_URL}/v1/inventory_managment/units`,
          null,
          token
        );
        if (response.status === 200) {
          setUnits(response.data.data || []);
        } else {
          console.error('Failed to fetch unit options');
        }
      } catch (error) {
        console.error('Failed to fetch unit options');
      }
    };
    fetchUnitOptions();
  }, [token]);

  return units;
};

export default UnitOptions;
