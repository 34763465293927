// import React, { useState, useEffect, useCallback } from "react";
// import "./OnlineOrder.css";
// import NewOrder from "./NewOrder";
// import Store from "./Store";
// import PlatformItems from "./PlatformItem/PlatformItems";
// import PlatformForStore from "./PlatformForStore/PlatformForStore";
// import AllOrders from "./AllOrders";
// // import Items from "./Items";
// // import PlatformForItem from "./PlatformForItem";
// // import PlatformForStore from "./PlatformForStore";
// // import Customers from "./Customers";
// import swiggyLogo from "../../../Assets/Images/swiggy.png";
// import zomatoLogo from "../../../Assets/Images/zomato.png";
// import uberEatsLogo from "../../../Assets/Images/ubereates.png";
// import foodPandaLogo from "../../../Assets/Images/pandas.png";
// import { getToken } from "../../SecureStorage/Token";

// function OnlineOrder() {
//   const [activePlatform, setActivePlatform] = useState(null);
//   const [isOpenNewOrder, setIsOpenNewOrder] = useState(false);
//   const [isApprovedSectionOpen, setIsApprovedSectionOpen] = useState(false);
//   const [isCompletedSectionOpen, setIsCompletedSectionOpen] = useState(false);
//   const [activeComponent, setActiveComponent] = useState(null);
//   const [activeTab, setActiveTab] = useState("all");
//   const navigate = useNavigate();
//   const [showAllOrders, setShowAllOrders] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const fetchAllPlatformOrders = useCallback(async () => {
//     setIsLoading(true);
//     setError(null);
//     try {
//       const token = getToken();
//       const response = await fetch(
//         `http://localhost:3001/v1/restaurant/orders/all_platform_orders`,
//         {
//           method: "GET",
//           headers: {
//             Authorization: token,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       const data = await response.json();
//       setPlatformOrders(data.orders);
//     } catch (error) {
//       console.error("Error fetching all platform orders:", error);
//       setError("Failed to fetch orders. Please try again.");
//     } finally {
//       setIsLoading(false);
//     }
//   }, []);


//   useEffect(() => {
//     let isMounted = true;
//     const fetchAllOrders = async () => {
//       const platforms = ["swiggy", "zomato", "ubereats", "foodpanda"];
//       const allOrders = {};
//       for (const platform of platforms) {
//         const orders = await fetchPlatformOrders(platform);
//         if (isMounted) {
//           allOrders[platform] = orders;
//         }
//       }
//       if (isMounted) {
//         setPlatformOrders(allOrders);
//       }
//     };
//     fetchAllOrders();
//     return () => {
//       isMounted = false;
//     };
//   }, []);
//   useEffect(() => {
//     fetchAllPlatformOrders();
//   }, [fetchAllPlatformOrders]);

  
//   const handleOrderDetail = () => {
//     setIsOpenNewOrder(true);
//     setActiveComponent(null);
//   };
//   const [platformOrders, setPlatformOrders] = useState({
//     zomato: [],
//     swiggy: [],
//     ubereats: [],
//     foodpanda: [],
//   });

//   const platformLogos = {
//     swiggy: swiggyLogo,
//     zomato: zomatoLogo,
//     ubereats: uberEatsLogo,
//     foodpanda: foodPandaLogo,
//   };

//   const fetchPlatformOrders = async (platform) => {
//     try {
//       const token = getToken();
//       const response = await fetch(
//         `http://localhost:3001/v1/restaurant/orders/start_continuous_fetch?platform=${platform}`,
//         {
//           method: "GET",
//           headers: {
//             Authorization: token,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       const data = await response.json();
//       return data.orders || [];
//     } catch (error) {
//       console.error("Error fetching orders:", error);
//       return [];
//     }
//   };

//   const handlePlatformClick = async (platform) => {
//     setActiveTab(platform);
//     const orders = await fetchPlatformOrders(platform);
//     setPlatformOrders({ [platform]: orders });
//     setIsOpenNewOrder(true);
//     setActivePlatform(platform);
//   };

//   const handleViewOrder = (order, platform) => {
//     setActivePlatform(platform);
//     setIsOpenNewOrder(true);
//     setPlatformOrders({ [platform]: [order] });
//   };

//   const handleAllOrdersClick = () => {
//     setShowAllOrders(true);
//     fetchAllPlatformOrders();
//   };

//   const handleClose = () => {
//     setIsOpenNewOrder(false);
//   };

//   const toggleApprovedSection = () => {
//     setIsApprovedSectionOpen(!isApprovedSectionOpen);
//     setIsCompletedSectionOpen(false);
//     setActiveComponent(null);
//   };

//   const toggleCompletedSection = () => {
//     setIsCompletedSectionOpen(!isCompletedSectionOpen);
//     setIsApprovedSectionOpen(false);
//     setActiveComponent(null);
//   };

//   const toggleDropdown = () => {
//     setIsOnlineOrderDropdownOpen(!isOnlineOrderDropdownOpen);
//   };

//   const handleDropdownClick = (component) => {
//     setActiveComponent(component);
//     setIsOnlineOrderDropdownOpen(false);
//   };

//   // const dummyOrders = [
//   //   {
//   //     id: 1,
//   //     channel: "zomato",
//   //     name: "Kashish",
//   //     phone: "04471820353",
//   //     orderId: "1542078227",
//   //     restaurant: "Chowmein Restaurant",
//   //     orderTime: "09/07/2019 @ 10:07PM",
//   //     amount: "₹ 110 /-",
//   //     address:
//   //       "riti 16 opp MJ ASSOCIATES, Riti 16, 5th Cross, - , Riti 16, 5th Cross Road, Vasanth Nagar, Benga, Bangalore.",
//   //     partner: "partner",
//   //     kotPrinted: "0 KOT printed",
//   //   },
//   //   {
//   //     id: 2,
//   //     channel: "zomato",
//   //     name: "Gopika Prasad",
//   //     phone: "04045207355",
//   //     orderId: "1894176454",
//   //     restaurant: "Chowmein Restaurant",
//   //     orderTime: "09/07/2019 @ 2:01PM",
//   //     amount: "₹ 377.5 /-",
//   //     address:
//   //       "No.20/2,Ground floor,9th main road, srirampur, - , Bashyam Nagar, Lakshminarayanapuram, Rajajina, Bangalore.",
//   //     partner: "partner",
//   //     kotPrinted: "0 KOT printed",
//   //   },
//   // ];

//   const handleTogglePlateform = () => {
//     navigate("/toggle-platform-item");
//   };

//   return (
//     <>
//       <div className="online-platform-list-header">
//         <button onClick={handleAllOrdersClick} disabled={isLoading}>
//           {isLoading ? "Loading..." : "All Orders"}
//         </button>
//         {["swiggy", "zomato", "ubereats", "foodpanda"].map((platform) => (
//           <div
//             className={`online-platform-icon ${
//               activeTab === platform ? "active" : ""
//             }`}
//             key={platform}
//             onClick={() => handlePlatformClick(platform)}
//           >
//             <img
//               src={platformLogos[platform]}
//               alt={platform}
//               className="online-platform-icon-img"
//             />
//           </div>
//         ))}
//       </div>

//       <div className="online-ordering-sys-main-container">
//         <div className="online-order-dash-main">
//           <button onClick={handleAllOrdersClick}>All Orders</button>
//           <button onClick={handleOrderDetail}>New Order</button>
//           <button onClick={toggleApprovedSection}>Approved / Confirmed</button>
//           <button>Cancelled</button>
//           <button onClick={toggleCompletedSection}>
//             Completed <span className="online-order-sys-order-count">94</span>
//           </button>
//           {isOnlineOrderDropdownOpen && (
//             <div className="online-order-dropdown-menu">
//               <ul>
//                 <li onClick={() => handleDropdownClick("Store")}>Store</li>
//                 <li onClick={() => handleDropdownClick("Items")}>Items</li>
//                 <li onClick={handleTogglePlateform}>Platform for Item</li>
//                 <li onClick={() => handleDropdownClick("PlatformForStore")}>
//                   Platform for Store
//                 </li>
//                 <li onClick={() => handleDropdownClick("Customers")}>
//                   Customers
//                 </li>
//               </ul>
//             </div>
//           )}
//         </div>
//       </div>

//       {(isApprovedSectionOpen || isCompletedSectionOpen) && (
//         <div className="online-order-sys-order-section">
//           <div className="online-order-sys-filter-row">
//             <select>
//               <option>All Channel</option>
//             </select>
//             <select>
//               <option>All Store</option>
//             </select>
//             <input type="date" placeholder="Start Date" />
//             <input type="date" placeholder="End Date" />
//             <input type="text" placeholder="Enter Order ID" />
//           </div>

//           {/* {isCompletedSectionOpen && (
//             <div className="completed-orders">
//               {dummyOrders.map((order) => (
//                 <div key={order.id} className="online-order-sys-order-item">
//                   <div className="onlie-order-sys-order-channel">
//                     <img
//                       src={`${order.channel}-logo.png`}
//                       alt={order.channel}
//                     />
//                   </div>
//                   <div className="onlie-order-sys-order-details">
//                     <div className="onlie-order-sys-customer-info">
//                       <p>{order.name}</p>
//                       <p>{order.phone}</p>
//                       <p>{order.orderId}</p>
//                       <p>{order.restaurant}</p>
//                     </div>
//                     <div className="order-info">
//                       <p>{order.orderTime}</p>
//                       <p>{order.amount}</p>
//                       <p>{order.address}</p>
//                       <p>{order.partner}</p>
//                       <p>{order.kotPrinted}</p>
//                     </div>
//                   </div>
//                   <div className="onlie-order-sys-order-actions">
//                     <button className="online-order-sys-view-button">View</button>
//                     <button className="online-order-sys-user-button">👤</button>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           )} */}

//           <div className="pagination">
//             <button>First</button>
//             <button>Previous</button>
//             <button className="active">1</button>
//             <button>Next</button>
//             <button>Last</button>
//           </div>
//         </div>
//       )}

//       {isOpenNewOrder && (
//         <div className="online-new-order-overlay">
//           {activePlatform === "all"
//             ? Object.entries(platformOrders).map(([platform, orders]) =>
//                 orders.map((order) => (
//                   <NewOrder
//                     key={order.id}
//                     onClose={handleClose}
//                     order={order}
//                     platform={platform}
//                   />
//                 ))
//               )
//             : platformOrders[activePlatform]?.map((order) => (
//                 <NewOrder
//                   key={order.id}
//                   onClose={handleClose}
//                   order={order}
//                   platform={activePlatform}
//                 />
//               ))}
//         </div>
//       )}
//       {error && <div className="error-message">{error}</div>}

//       {showAllOrders && !isLoading && !error && (
//         <AllOrders orders={platformOrders} onViewOrder={handleViewOrder} />
//       )}
//       {activeComponent === "Store" && <Store />}
//       {activeComponent === "PlatformItems" && <PlatformItems />}
//       {activeComponent === "PlatformForStore" && <PlatformForStore />}
//     </>
//   );
// }

// export default OnlineOrder;

import React from "react";
import "./OnlineOrder.css";

function OnlineOrder() {
 
  return (
    <>
      <div className="online-platform-list-header">
        <h1>Online Order</h1>
      </div>
    </>
  );
}

export default OnlineOrder;