import React, { useState } from 'react';
import { getToken } from "../../SecureStorage/Token";
import { BASE_URL } from '../../../Url/constants';
import { sendRequest } from '../../../ApiRequest';

const DownloadComponent = () => {
  const [downloadType, setDownloadType] = useState('');

  const handleSelectChange = async (e) => {
    const selectedType = e.target.value;
    setDownloadType(selectedType);
    
    if (selectedType) {
      await handleDownload(selectedType);
    }
  };

  const handleDownload = async (type) => {
    try {
      const token = getToken();
      const fileExtension = type === 'pdf' ? 'pdf' : 'csv';

      const response = await sendRequest(
        'GET',
        `${BASE_URL}/v1/transactions/entries/download_bill_pdf?download_type=${type}&type=delivery_challan`,
        null,
        {
          Authorization: token ? `${token}` : '',
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        'blob' // Pass 'blob' as a response type, if your sendRequest supports it
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `download.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Failed to download file. Please try again.');
    }
  };

  return (
    <div>
      <select class="form-select" value={downloadType} onChange={handleSelectChange}>
        <option value="" disabled>Select Download Type</option>
        <option value="pdf">PDF</option>
        <option value="csv">CSV</option>
      </select>
    </div>
  );
};

export default DownloadComponent;
