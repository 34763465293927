import React, { useState, useEffect } from "react";
import "./PharmaPurchaseItemSettings.css";
import { useTranslation } from "react-i18next";

function PharmaPurchaseItemSettings({
  item,
  closeSettingPopup,
  type,
  isPharmaceutical,
  handleSettingsUpdate
}) {
  const [options, setOptions] = useState([]);
  const [settings, setSettings] = useState({});
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchOptions();
  }, [item.id]);

  const fetchOptions = async () => {
   
    let fetchedOptions = [
      { id: 1, name: "MRP" },
      { id: 2, name: "Batch" },
      { id: 3, name: "ExpiryDate" }
    ];

    if (isPharmaceutical) {
      fetchedOptions = fetchedOptions.filter(option =>
        ["MRP", "Batch", "ExpiryDate"].includes(option.name)
      );
    }

    setOptions(fetchedOptions);
  };

  useEffect(() => {
    const isAnyOptionEnabled = options.some(
      option =>
        settings[option.name]?.status &&
        settings[option.name]?.free_text.trim() !== ""
    );
    setIsSaveButtonEnabled(isAnyOptionEnabled);
  }, [settings, options]);

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [id]: {
        ...prevSettings[id],
        status: checked,
        free_text: checked ? prevSettings[id]?.free_text || "" : "",
      },
    }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    const numericFields5Digits = ["MRP"];
    if (numericFields5Digits.includes(id)) {
      if (/^\d{0,12}$/.test(value)) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          [id]: { ...prevSettings[id], free_text: value },
        }));
      }
    } else {
      setSettings((prevSettings) => ({
        ...prevSettings,
        [id]: { ...prevSettings[id], free_text: value },
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const itemIndex = item.index;
    handleSettingsUpdate(settings, itemIndex);
    closeSettingPopup();
  };

  return (
    <>
      <form className="pharma-purchase-settings-container mt-5" onSubmit={handleSubmit}>
        <div className="pharma-purchase-header">
          <h3>
            {t("Item Setting")}: {item.item_name}
          </h3>
          <hr />
          {options.map((option) => (
            <div className="form-group-i" key={option.id}>
              <div className="pharma-purchase-data-label">
                <input
                  type="checkbox"
                  className="pharma-purchase-checkbox"
                  id={option.name}
                  checked={settings[option.name]?.status || false}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor={option.name}>
                  {option.name.replace(/([a-z])([A-Z])/g, "$1 $2")}
                </label>
              </div>
              <input
                className="pharma-purchase-input"
                type={
                  option.name === "ExpiryDate" || option.name === "MfgDate"
                    ? "date"
                    : "text"
                }
                id={option.name}
                value={settings[option.name]?.free_text || ""}
                onChange={handleInputChange}
                placeholder={option.name.replace(/([a-z])([A-Z])/g, "$1 $2")}
                disabled={!settings[option.name]?.status}
              />
            </div>
          ))}
        </div>
        <div className="col-md-12 mt-20 text-right">
          <button
            type="submit"
            className={` ${isSaveButtonEnabled ? "pharma-purchase-btn-primary" : "pharma-purchase-btn-disabled"}`}
            disabled={!isSaveButtonEnabled}
          >
            {t("Save")}
          </button>
        </div>
      </form>
    </>
  );
}

export default PharmaPurchaseItemSettings;
