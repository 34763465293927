import React from 'react'

const Footer = () => {
  return (
    <div className= "copyrights">
        <div>
        <h5>
        "All copyrights reserved "
        <a href="https://justmindwork.com">JMW</a>
        "2024 "
        </h5>
        </div>
    </div>
  )
}

export default Footer
