import React from 'react';
import Select from 'react-select';

const ReportSearchableDropdown = ({ options, value, onChange, placeholder }) => {
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      maxHeight: 200,  
      overflowY: 'auto',  
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 200,  
    }),
  };

  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isClearable
      styles={customStyles}
    />
  );
};

export default ReportSearchableDropdown;
