import React, { useEffect, useState } from "react";
import { BASE_URL, NO_RECORD_ERROR } from "../../../Url/constants";
import ErrorNotification from "../../../Notification/ErrorNotification";
import { getToken } from "../../SecureStorage/Token";
import { sendRequest } from "../../../ApiRequest";
import './BillingTemplateTwo.css'

function BillingTemplateTwo({ template, formData, discount = "0.00", isPartyEnabled }) {
  const [TemplateData, setTemplateData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [items, setItems] = useState([]);
  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();
 
  useEffect(() => {
    getTemplateConfig(template);
    companyDetails();
    fetchItemDetails(formData);
  }, [template, formData]);

  const getTemplateConfig = async (template) => {
    try {
      const url = `${BASE_URL}/v1/template/templates/${template}`;
      const response = await sendRequest('get', url);
      setTemplateData(response.data.data.template_options);
    } catch (error) {
      ErrorNotification(NO_RECORD_ERROR('template'));
    }
  };

  const companyDetails = async () => {
    try {
      const url = `${BASE_URL}/v1/onboarding/companies/current_user_company`;
      const response = await sendRequest('get', url);
      setCompanyData(response.data.data);
    } catch (error) {
      ErrorNotification(NO_RECORD_ERROR('company'));
    }
  };

  const fetchItemDetails = async (formData) => {
    try {
      const fetchedItems = await Promise.all(
        formData?.bill?.bill_items_attributes?.map(async (item) => {
          const url = `${BASE_URL}/v1/inventory_managment/items/${item.item_id}?is_normal_billing=true`;
         const response = await sendRequest('get', url);
         return {
          ...response.data.data,
          editedWholesalePrice: item.item_total / item.quantity,
          sales_price: item?.salesPrice,
          sale_amount: item?.saleAmount,
         };
        })
      );
      setItems(fetchedItems);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const enabledTemplateData = TemplateData
    .filter(option => option.template_config?.is_enable)
    .map(option => ({
      option_name: option.option_name,
      free_text: option.template_config.free_text,
      is_enable: option.template_config.is_enable
    }));

  const formatBillNumber = (number) => {
    return number?.toString().padStart(4, "0");
  };

  const companyAddress = companyData?.branch_info
    ? `${companyData.branch_info.address} ${companyData.branch_info.city}, ${companyData.branch_info.state} (${companyData.branch_info.pin_code})`
    : null;
    return (
      <div className="template-two-main">
        <div className="template-two-sub">
          <div className="template-two-date-time">
            {enabledTemplateData.find(option => option.option_name === "date")?.is_enable && (
              <div className="template-two-date">
                Date: {currDate}
              </div>
            )}
            {enabledTemplateData.find(option => option.option_name === "time")?.is_enable && (
              <div className="template-two-time">
                Time: {currTime}
              </div>
            )}
          </div>
          <div className="template-two-date-time">
            <div className="template-two-date">
              Branch: {companyData?.branch_info?.city}
            </div>
            {enabledTemplateData.find(option => option.option_name === 'invoice_number')?.is_enable && (
              <div className="template-two-time">
                Receipt No.: {formatBillNumber(formData?.bill?.bill_number)}
              </div>
            )}
          </div>
          <table className="cash-memo-template-second">
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Price</th>
                <th>Qty</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {formData?.bill?.bill_items_attributes?.map((billItem, index) => {
                const correspondingItem = items.find(item => item.id === billItem.item_id);
  
                if (correspondingItem) {
                  const taxDetails = [];
                  if (correspondingItem?.tax_type === "Individual" && correspondingItem?.tax_info) {
                    taxDetails.push(
                      `${correspondingItem.tax_info.title}: ${correspondingItem.tax_info.tax_value}${correspondingItem.tax_info.tax_type === "fixed" ? "(F)" : "(%)"}`
                    );
                  } else if (correspondingItem?.tax_type === "Group" && correspondingItem?.group_tax_info) {
                    correspondingItem.group_tax_info.forEach(tax => {
                      taxDetails.push(
                        `${tax.title}: ${tax.tax_value}${tax.tax_type === "fixed" ? "(F)" : "(%)"}`
                      );
                    });
                  }
                  const price = isPartyEnabled 
                    ? (correspondingItem.editedWholesalePrice || correspondingItem.wholesale_price)
                    : correspondingItem.sales_price;

                  return (
                    <tr key={index}>
                      <td>
                        {correspondingItem.item_name}
                        {taxDetails.length > 0 && (
                          <div className="tax-font-size">
                            {taxDetails.join(", ")}
                          </div>
                        )}
                      </td>
                      <td>{price.toFixed(2)}</td>
                      <td>{billItem.quantity}</td>
                      <td>{(price * billItem.quantity).toFixed(2)}</td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </table>
          <table className="template-two-summary-table">
            <tbody>
              <tr>
                <td>Sub-Total:</td>
                <td>{formData?.bill?.total_discount ?  ( (Number(formData?.bill?.sub_total)  || 0)  +  (Number(formData?.bill?.total_discount) || 0) ).toFixed(2) :  formData?.bill?.sub_total }</td>
              </tr>
              <tr>
                <td>Discount:</td>
                <td>{formData?.bill?.total_discount || "0.00"}</td>
              </tr>
              <tr>
                <td>Service charge:</td>
                <td>0.00</td>
              </tr>
              {formData?.bill?.is_gst_enable ? (
                formData?.bill?.is_igst_enable ? (
                  <tr>
                    <td>IGST:</td>
                    <td>{formData?.bill?.total_gst || "0.00"}</td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <td>CGST:</td>
                      <td>{(formData?.bill?.total_gst / 2).toFixed(2) || "0.00"}</td>
                    </tr>
                    <tr>
                      <td>SGST:</td>
                      <td>{(formData?.bill?.total_gst / 2).toFixed(2) || "0.00"}</td>
                    </tr>
                  </>
                )
              ) : (
                <tr>
                  <td>Tax:</td>
                  <td>{formData?.bill?.total_tax || "0.00"}</td>
                </tr>
              )}
              <tr>
                <td>Total Bill:</td>
                <td>{formData?.bill?.total_amount || "0.00"}</td>
              </tr>
              <tr>
                <td>Cash Paid:</td>
                <td>
                  {enabledTemplateData.find(option => option.option_name === 'display_received_amount')?.is_enable && (
                    formData?.bill?.received_amount || formData?.bill?.total_amount || "0.00"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {enabledTemplateData.find(option => option.option_name === "barcode")?.is_enable && (
          <img className="barcode-image" src="/barcode.png" alt="barcode" />
        )}
        <div className="second-template-two-footer">
          <div className="row">
            {enabledTemplateData.find(option => option.option_name === 'company_name')?.is_enable && (
              <span className="second-company-title">
                {enabledTemplateData.find(option => option.option_name === "company_name").free_text || `${companyData.name}`}
              </span>
            )}
          </div>
          <div className="row">
            {enabledTemplateData.find(option => option.option_name === 'address')?.is_enable && (
              <div className="template-two-second-company-address">
                {enabledTemplateData.find(option => option.option_name === "address").free_text || companyAddress || "417,Sadguru Prinay, Indore"}
              </div>
            )}
            {enabledTemplateData.find(option => option.option_name === 'email')?.is_enable && (
              <div className="template-two-second-com-mail">
                Email: {enabledTemplateData.find(option => option.option_name === "email").free_text || companyData?.user_info?.email}
              </div>
            )}
            {enabledTemplateData.find(option => option.option_name === 'insta_id')?.is_enable && (
              <div className="template-two-second-com-mail">
                Insta Id: {enabledTemplateData.find(option => option.option_name === "insta_id").free_text || "@nike"}
              </div>
            )}
            {(enabledTemplateData.find(option => option.option_name === 'gst_number')?.is_enable || formData?.bill?.print_gst_no) && (
              <div className="template-one-com-mail">Gst Number:  {companyData?.gst_number}</div>
            )}
          </div>
        </div>
        <div className="template-two-content">
          {enabledTemplateData.find(option => option.option_name === 'phone_number')?.is_enable && (
            <span>CALL FOR HOME DELIVERY {enabledTemplateData.find(option => option.option_name === 'phone_number').free_text || companyData?.user_info?.phone}</span>
          )}
        </div>
      </div>
    )
  }
  
  export default BillingTemplateTwo;
  