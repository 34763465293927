import React, { useEffect, useState } from "react";
import "./ItemDiscountInvoice.css";
import CommonCloseButton from "../CommonCloseButton/CommonCloseButton";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import CommonKeypadButton from "../CommonKeypadButton/CommonKeypadButton";
import { useTranslation } from "react-i18next";
import successNotification from "../../../Notification/SuccessNotification";

function ItemDiscountInvoice({ initialDiscountValue, discountValue, initialDiscountType, couponResponse, discountType, initialSelectedDiscountReason, discountReason, onClose, subTotalValue }) {
  const [inputValue, setInputValue] = useState(initialDiscountValue || "");
  const [selectedDiscountReasons, setSelectedDiscountReasons] = useState([]);
  const [discountReasonData, setDiscountReasonData] = useState([]);
  const [newDescription, setNewDescription] = useState("");
  const { t } = useTranslation();

  const [couponCode, setCouponCode] = useState("");
  const [discountError, setDiscountError] = useState("");
  const [couponError, setCouponError] = useState("");

  useEffect(() => {
    getDiscountReason();
  }, []);

  const handleDiscountTypeChange = (e) => {
    const value = e.target.value;
    if (value === "percent") {
      discountType("percent");
    } else {
      discountType("fixed");
    }
    setInputValue("");
    discountValue("");
    setDiscountError("");
  };

  const handleDiscountValueChange = (e) => {
    const value = e.target.value;
    const numericValue = parseFloat(value);

    if (!initialDiscountType) {
      setDiscountError("Please select a discount type (percent or fixed) first.");
      return;
    }

    if (value === "") {
      setInputValue("");
      discountValue("");
      setDiscountError("");
      return;
    }

    if (initialDiscountType === "percent") {
      if (numericValue >= 0 && numericValue <= 100) {
        setInputValue(value);
        discountValue(value);
        setDiscountError("");
      } else {
        setDiscountError("Cannot provide a discount greater than 100 percent.");
      }
    } else if (initialDiscountType === "fixed") {
      if (numericValue >= 0 && numericValue <= subTotalValue) {
        setInputValue(value);
        discountValue(value);
        setDiscountError("");
      } else {
        setDiscountError("Discount cannot be greater than the total bill amount.");
      }
    } else {
      if (/^\d*\.?\d*$/.test(value)) {
        setInputValue(value);
        discountValue(value);
        setDiscountError("");
      }
    }
  };

  const handleDiscountReasonChange = (reason) => {
    setSelectedDiscountReasons((prev) => {
      if (prev.includes(reason)) {
        return prev.filter((r) => r !== reason);
      } else {
        return [...prev, reason];
      }
    });
  };

  const handleKeyPress = (value) => {
    if (!initialDiscountType) {
      setDiscountError("Please select a discount type (percent or fixed) first.");
      return;
    }

    setInputValue((prevValue) => {
      const newValue = prevValue + value;
      const numericValue = parseFloat(newValue);

      if (initialDiscountType === "percent") {
        if (numericValue >= 0 && numericValue <= 100) {
          discountValue(newValue);
          setDiscountError("");
          return newValue;
        } else {
          setDiscountError("Cannot provide a discount greater than 100 percent.");
          return prevValue;
        }
      } else if (initialDiscountType === "fixed") {
        if (numericValue >= 0 && numericValue <= subTotalValue) {
          discountValue(newValue);
          setDiscountError("");
          return newValue;
        } else {
          setDiscountError("Discount cannot be greater than the total bill amount.");
          return prevValue;
        }
      }

      if (/^\d*\.?\d*$/.test(newValue)) {
        discountValue(newValue);
        setDiscountError("");
        return newValue;
      }

      return prevValue;
    });
  };

  const handleDelete = () => {
    const newValue = inputValue.slice(0, -1);
    setInputValue(newValue);
    discountValue(newValue);
  };

  const handleClear = () => {
    setInputValue("");
    discountValue("");
    setDiscountError("");
  };

  const getDiscountReason = async () => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/restaurant/orders/orders/index_discount_reason`);
    if (response.status === 200) {
      const res = response.data;
      setDiscountReasonData(res);
    }
  };

  const handleCreateAndSaveDiscount = async () => {
    discountReason(selectedDiscountReasons);
    handleCreateDiscount();
  }

  const handleCreateDiscount = async () => {
    if (newDescription.length > 0) {
      const reasonParams = {
        discount_reason: {
          reason: newDescription
        }
      };
      const response = await sendRequest('POST', `${BASE_URL}/v1/restaurant/orders/orders/create_discount_reason`, reasonParams);
      if (response.status === 201) {
        const res = response.data;
        setSelectedDiscountReasons(prev => [...prev, res.id]);
        discountReason([...selectedDiscountReasons, res.id]);
        onClose();
      }
    } else {
      onClose();
    }
  };

  const handleApplyCoupon = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/setting/coupons/show_by_code/${couponCode}`,
        null
      );
      if (response.status === 200) {
        const res = response.data;
        couponResponse(res);
        onClose();
        successNotification("Coupon Code Applied Successfully.");
        setCouponError("");
      } else {
        setCouponError("Coupon not found or an error occurred.");
      }
    } catch (error) {
      setCouponError("Coupon not found or an error occurred.");
    }
  };

  return (
    <>
      <div className="discount-invoice-main">
        <div className="invoice-discount-title">
          <span className="invoice-discount-title-heading">{t("Discount")}</span>
          <span onClick={onClose}>
            <CommonCloseButton />
          </span>
        </div>

        <div className="invoice-discount-boxes">
          <div className="invoice-discount-box-first">
            <span className="discount-food-title">{t("FOOD")}</span>
            <div className="discount-input-and-label">
              <label className="discount-label-and-input">
                <input
                  type="radio"
                  name="discountType"
                  value="percent"
                  checked={initialDiscountType === "percent"}
                  onChange={handleDiscountTypeChange}
                />{" "}
                %
              </label>
              <label className="discount-label-and-input">
                <input
                  type="radio"
                  name="discountType"
                  value="fixed"
                  checked={initialDiscountType === "fixed"}
                  onChange={handleDiscountTypeChange}
                />{" "}
                Rs
              </label>
              <input
                placeholder="Enter discount"
                className="form-control"
                value={inputValue}
                onChange={handleDiscountValueChange}
                maxLength={5}
                step="any"
                disabled={!initialDiscountType}
              />
              {discountError && (
                <span className="restaurant-invoice-discount-error-message">{discountError}</span>
              )}
            </div>
            <div className="discount-input-and-label-face-2">
              <div className="discount-invoice-second-content">
                <label className="dis-coupon-title">{t("Coupon Code")}</label>
                <input type="text" placeholder="Enter discount coupon" className="form-control dis-coupon-input-field" value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)} maxLength={12} />
                  {couponError && (
                  <span className="restaurant-invoice-discount-error-message">{couponError}</span>
                )}
              </div>
              <button className="discount-apply-coupon-button" onClick={handleApplyCoupon}>{t("Apply Coupon")}</button>
            </div>

            <div className="select-discount-reason-group">
              <span className="select-discount-reason-title">{t("Select Discount Reason")}</span>
              <div className="discount-reason-select">
                {discountReasonData.map(reason => (
                  <button
                    key={reason.id}
                    className={`coupon-distance-margin ${initialSelectedDiscountReason.length > 0
                      ? initialSelectedDiscountReason.includes(reason.id) ? 'discount-selected-reason-color' : ''
                      : selectedDiscountReasons.includes(reason.id) ? 'discount-selected-reason-color' : ''
                      }`}
                    onClick={() => handleDiscountReasonChange(reason.id)}>
                    {reason.reason}
                  </button>
                ))}
              </div>
            </div>
            <div className="add-new-reason-title">{t("Add New Discount Reason")}</div>
            <input
              className="form-control add-new-discount-reason"
              placeholder={t("New Reason")}
              value={newDescription}
              maxLength={20}
              onChange={(e) => {
                const value = e.target.value;
                const newValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
                setNewDescription(newValue);
              }}
            />
            <button className="discount-save-and-select" onClick={handleCreateAndSaveDiscount}>{t("Save & Select")}</button>
          </div>

          <div className="invoice-discount-box-second">
            <CommonKeypadButton
              onKeyPress={handleKeyPress}
              onClear={handleClear}
              onDelete={handleDelete}
              onEnter={onClose}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemDiscountInvoice;
