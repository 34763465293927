import React from "react";
import "./KotTransactionOrder.css";
import CommonCloseButton from "../CommonCloseButton/CommonCloseButton";

function KotTransactionOrder({ order, onClose }) {
  return (
    <div className='kot-transaction-order-main'>
      <div className='transaction-kot-header'>
        <span className='kot-trans-header-title'>Order Item Details</span>
        <span onClick={onClose}>
          <CommonCloseButton />
        </span>
      </div>

      <div className="trans-kot-sub-part">
        <div className="kot-trans-sub-second">
          {order.order_groups && order.order_groups.length > 0 ? (
            order.order_groups.map((group, groupIndex) => {
              const groupTotalAmount = group.order_items.reduce((sum, item) => sum + (item.item_total_amount * item.quantity), 0);
              return (
                <div key={groupIndex} className="order-group">
                  <div className="kot-trans-group-order-container">
                    <span className="kot-trans-order-group-title">Order Group ID: {group.id}</span>
                  </div>
                  <div className="order-items-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Item Name</th>
                          <th>Quantity</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {group.order_items && group.order_items.length > 0 ? (
                          group.order_items.map((item, itemIndex) => (
                            <tr key={itemIndex}>
                              <td>{item.item_name}</td>
                              <td>{item.quantity}</td>
                              <td>{item.item_total_amount}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3">No items found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="kot-trans-group-total-amount">
                    <span>Total Amount: </span>
                    <span className="kot-trans-group-total-value">₹{groupTotalAmount.toFixed(2)}</span>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No order groups found</div>
          )}
        </div>
        <div className="kot-transaction-grand-total">
          <span>Grand Total Amount: </span>
          <span className="trans-grand-total-value">₹{order.total_amount.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
}

export default KotTransactionOrder;
