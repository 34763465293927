import React, { useState } from 'react';
import './CreateFloorModal.css';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const CreateFloorModal = ({ onSubmit, onClose }) => {
  const [floorDetails, setFloorDetails] = useState([{ name: '', prefix: '', quantity: '' }]);
  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const handleChange = (index, field, value) => {
    const updatedFloorDetails = [...floorDetails];
    updatedFloorDetails[index][field] = value;
    setFloorDetails(updatedFloorDetails);

    const updatedErrors = [...errors];
    if (field === 'name' && value.length > 30) {
      updatedErrors[index] = { ...updatedErrors[index], [field]: true };
    } else {
      updatedErrors[index] = { ...updatedErrors[index], [field]: false };
    }
    setErrors(updatedErrors);
  };

  const handleAddFloor = () => {
    if (floorDetails.length < 3) {
      setFloorDetails([...floorDetails, { name: '', prefix: '', quantity: '' }]);
      setErrors([...errors, { name: false, prefix: false, quantity: false }]);
      setErrorMessage('');
    } else {
      setErrorMessage('You can add only three floors at a time.');
    }
  };

  const handleRemoveFloor = (index) => {
    const updatedFloorDetails = floorDetails.filter((_, i) => i !== index);
    const updatedErrors = errors.filter((_, i) => i !== index);

    setFloorDetails(updatedFloorDetails);
    setErrors(updatedErrors);

    if (updatedFloorDetails.length < 3) {
      setErrorMessage('');
    }
  };

  const handleSubmit = () => {
    const newErrors = floorDetails.map((floor) => ({
      name: !floor.name || floor.name.length > 30,
      prefix: !floor.prefix,
      quantity: !floor.quantity,
    }));

    setErrors(newErrors);

    const isValid = newErrors.every(error => !error.name && !error.prefix && !error.quantity);

    if (isValid) {
      onSubmit(floorDetails);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content-create-floors">
        <h2>{t("Create Floors and Tables")}</h2>
        {floorDetails.map((floor, index) => (
          <div key={index} className="floor-details">
            <Link className="remove-link" onClick={() => handleRemoveFloor(index)}> - </Link>
            <div className="floor-input">
              <input
                type="text"
                placeholder={t("Floor Name")}
                value={floor.name}
                maxLength={15}
                onChange={(e) => handleChange(index, 'name', e.target.value)}
                className={`form-control ${errors[index]?.name ? "input-error" : ""}`}
              />
              {errors[index]?.name && <span className="error-message">{t("Floor name is required and must be 30 characters or less")}</span>}
            </div>
            <div className="floor-input">
              <input
                type="text"
                placeholder={t("Table Prefix")}
                value={floor.prefix}
                onChange={(e) => handleChange(index, 'prefix', e.target.value)}
                className={`form-control ${errors[index]?.prefix ? "input-error" : ""}`}
                maxLength={5}
              />
              {errors[index]?.prefix && <span className="error-message">{t("Table prefix is required")}</span>}
            </div>
            <div className="floor-input">
              <input
                type="number"
                placeholder={t("Number of Tables")}
                value={floor.quantity}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 5) {
                    handleChange(index, 'quantity', inputValue);
                  }
                }}
                className={`form-control ${errors[index]?.quantity ? "input-error" : ""}`}
                aria-label={t("Number of Tables")}
              />
              {errors[index]?.quantity && (
                <span className="error-message" role="alert">
                  {t("Number of tables is required")}
                </span>
              )}
            </div>

          </div>
        ))}
        <button onClick={handleAddFloor}>{t("Add Floor")}</button>
        {errorMessage && <p className="limit-message">{errorMessage}</p>}
        <div className="modal-actions">
          <button onClick={onClose}>{t("Cancel")}</button>
          <button onClick={handleSubmit}>{t("Submit")}</button>
        </div>
      </div>
    </div>
  );
};

export default CreateFloorModal;
