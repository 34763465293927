import React from 'react';
import './CreateCustomer.css';

const ConfirmPopup = ({ title, message, onConfirm, onCancel }) => {
  return (
    <div className="confirm-popup">
      <div className="modal-content-delete">
        <div className="modal-header-delete">
          <h3>{title}</h3>
        </div>
        <div className="modal-body-delete">
          <p>{message}</p>
        </div>
        <div className="modal-footer-delete">
          <button className="btn btn-primary save-btn-delete" onClick={onCancel}>Cancel</button>
          <button className= "btn btn-primary" onClick={onConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;