import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import './NotificationSettings.css'; // We'll create this file for custom styling
import { sendRequest } from "../ApiRequest";
import { BASE_URL } from '../Url/constants';
import ErrorNotification from '../Notification/ErrorNotification';
import successNotification from '../Notification/SuccessNotification';
import { useTranslation } from "react-i18next";


const NotificationSettings = ({ show, onHide, companyId }) => {
    const [notificationSettings, setNotificationSettings] = useState({
        notification_type: '',
        trigger_on: '',
        description: '',
        notify_days: ''
      });
    
    const { t } = useTranslation();
    const [whatsAppSettings, setWhatsAppSettings] = useState({
        value: '',
        status: false
      });

  const handleNotificationSubmit = async (e) => {
    e.preventDefault();
    // API call to create notification
    try {
      const response = await sendRequest(
        'POST',
        `${BASE_URL}/v1/notifications`,
        { notification: { ...notificationSettings, company_id: companyId } }
      );
      if (response.status === 200) {
        successNotification('Notification settings saved successfully');
      }
    } catch (error) {
      ErrorNotification('Failed to save notification settings');
    }
  };

  const handleWhatsAppSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendRequest(
        'PUT',
        `${BASE_URL}/v1/setting/settings/create_whatsapp_settings`,
        { setting: { ...whatsAppSettings, company_id: companyId } }
      );
      if (response.status === 200) {
        successNotification('WhatsApp settings saved successfully');
      }
    } catch (error) {
      ErrorNotification('Failed to save WhatsApp settings');
    }
  };


  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Notification & WhatsApp Settings")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dual-form-container">
          <div className="form-section notification-form">
            <h4>{t("Notification Settings")}</h4>
            <Form onSubmit={handleNotificationSubmit}>
              {/* Add form fields for notification settings */}
              <Form.Group>
                <Form.Label>{t("Notification Type")}</Form.Label>
                <Form.Control
                  type="text"
                  value={notificationSettings.notification_type}
                  onChange={(e) => setNotificationSettings({...notificationSettings, notification_type: e.target.value})}
                />
              </Form.Group>
              {/* Add other fields: trigger_on, description */}
              <Form.Group>
                <Form.Label>{t("Notify Days")}</Form.Label>
                <Form.Control
                  as="select"
                  value={notificationSettings.notify_days}
                  onChange={(e) => setNotificationSettings({...notificationSettings, notify_days: e.target.value})}
                >
                  <option value="">{t("Select Days")}</option>
                  {[...Array(10)].map((_, i) => (
                    <option key={i+1} value={i+1}>{i+1}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Button type="submit" className="mt-3">{t("Save Notification Settings")}</Button>
            </Form>
          </div>
          <div className="form-section whatsapp-form">
            <h4>{t("WhatsApp Settings")}</h4>
            <Form onSubmit={handleWhatsAppSubmit}>
              <Form.Group>
                <Form.Label>{t("Value")}</Form.Label>
                <Form.Control
                  type="text"
                  value={whatsAppSettings.value}
                  onChange={(e) => setWhatsAppSettings({...whatsAppSettings, value: e.target.value})}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Check 
                  type="checkbox"
                  label={t("Enable WhatsApp Service")}
                  checked={whatsAppSettings.status}
                  onChange={(e) => setWhatsAppSettings({...whatsAppSettings, status: e.target.checked})}
                />
              </Form.Group>
              <Button type="submit" className="mt-3">{t("Save WhatsApp Settings")}</Button>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NotificationSettings;