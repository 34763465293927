import React from 'react';
import "./reports.css"

const YearListDropdown = ({ select_year, defaultValue, handleYearChange }) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, i) => currentYear - i);

  return (
    <select className="form-select report-type text-black" aria-label="Year select" defaultValue={defaultValue || select_year} onChange={handleYearChange}>
      {years.map((year, index) => (
        <option key={index} value={year}>
          {year}
        </option>
      ))}
    </select>
  );
};

export default YearListDropdown;
