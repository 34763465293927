import React, { useEffect, useState } from "react";
import './chart.css';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Charts = ({ chartData }) => {
  const calculateTotal = () => {
    return chartData?.reduce((accumulator, currentItem) => {
      return accumulator + (currentItem?.value || 0);
    }, 0); // Initial value of accumulator is 0
  };
  const total = calculateTotal();

  return (
    <>
      <div className="chart-main-class">
        <h5>Total Value: ₹{Number.isFinite(total) ? total.toFixed(2) : '0.00'}</h5>
        <ResponsiveContainer className="chart-container">
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="label" tickFormatter={(value) => `${value?.slice(0, 3)}`} />
            <YAxis tick={false} />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke="green" dot={{ stroke: 'green', r: 3 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default Charts;
