import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './HttpStatusCodes.css';

const HttpStatusCodes = () => {

  const location = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 800);
    return () => clearTimeout(timer);
  }, [location]);

  if (!show) return null;

  return (
      <div className="App error">
       <Link to="/dashboard">
       <div className="button-contents" style={{marginRight: '1600px'}}>
          <div className='btn-error'>
            <div className='error-page'>
            <a href=""><b>Go Back</b></a>
            </div>
          </div>
          </div>
        </Link>
      </div>
  );
};

export default HttpStatusCodes;
