import React from "react";
import AddMoreTemplate from "./AddMoreTemplate";
// import './addmoretemplate.css';

function Template() {

  return (
    <>
      <AddMoreTemplate/>
    </>
  )
}

export default Template;
