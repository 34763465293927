import { sendRequest } from "../ApiRequest";
import { BASE_URL } from "../Url/constants";

export const fetchBillData = async (props) => {

  if (props.reference.length < 2) return;

  try {
    const response = await sendRequest(
      "GET",
      `${BASE_URL}/v1/transactions/entries?query=${props.reference}&type=${props.type}`
    );
    const data = response.data.data;
    return data;
  } catch (error) {
    console.error("Error fetching reference data:", error);
  }
};