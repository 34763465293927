import React, { useEffect, useRef, useState } from "react";
import "./SaleOrder.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewSaleOrder from "./NewSaleOrder";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import PaginationControls from "../../Common/PaginationControls";
import NewSaleInvoice from "../SaleInvoice/NewSaleInvoice";
import NewDeliveryChallan from "../DeliveryChallan/NewDeliveryChallan";

function SaleOrder() {
  const [showSaleOrderColumnFilter, setShowSaleOrderColumnFilter] = useState(false);
  const [visibleSaleOrderColumns, setVisibleSaleOrderColumns] = useState({
    refNo: true,
    status: true,
    contact: true,
    // production: true,
    // delivered: true,
    buyerOrderNo: true,
    buyerOrderDate: true,
  });
  const [tempVisibleColumns, setTempVisibleColumns] = useState({ ...visibleSaleOrderColumns });
  const [isOpenSaleOrderPopup, setOpenSaleOrderPopup] = useState(false);
  const [isSaleOrderFilterPopup, setSaleOrderFilterPopup] = useState(false);
  const [isSaleOrderDownPopup, setSaleOrderDownPopup] = useState(false);
  const [selectedSaleOrder, setSelectedSaleOrder] = useState("");
  const [saleOrder, setSaleOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [saleOrderCopy, setSaleOrderCopy] = useState("");
  const [saleOrderUpdated, setSaleOrderUpdated] = useState(false);
  const [isSaleOrderInvoice, setSaleOrderInvoice] = useState("");
  const [isOpenSaleOrderInvoicePopup, setOpenSaleOrderInvoicePopup] = useState(false);
  const [isSaleOrderDeliveryChallan, setSaleOrderDeliveryChallan] = useState("");
  const [isSaleOrderDeliveryChallanPopup, setSaleOrderDeliveryChallanPopup] = useState(false);
  const [actionDropdownPosition, setActionDropdownPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const handleSaleOrderClickOutside = (event) => {
      if (
        columnSalOrderFilterRef.current &&
        !columnSalOrderFilterRef.current.contains(event.target)
      ) {
        setShowSaleOrderColumnFilter(false);
      }
    };

    if (showSaleOrderColumnFilter) {
      document.addEventListener("mousedown", handleSaleOrderClickOutside);
    } else {
      document.removeEventListener("mousedown", handleSaleOrderClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleSaleOrderClickOutside);
    };
  }, [showSaleOrderColumnFilter]);

  useEffect(() => {
    const handleFilterSaleOrderClickOutside = (event) => {
      if (
        saleOrderFilterRef.current &&
        !saleOrderFilterRef.current.contains(event.target)
      ) {
        setSaleOrderFilterPopup(false);
      }
    };

    if (isSaleOrderFilterPopup) {
      document.addEventListener("mousedown", handleFilterSaleOrderClickOutside);
    } else {
      document.removeEventListener("mousedown", handleFilterSaleOrderClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleFilterSaleOrderClickOutside);
    };
  }, [isSaleOrderFilterPopup]);

  useEffect(() => {
    const handleSaleOrderDownClickOutside = (event) => {
      if (
        downSalOrderRef.current &&
        !downSalOrderRef.current.contains(event.target)
      ) {
        setSaleOrderDownPopup(false);
      }
    };

    if (isSaleOrderDownPopup) {
      document.addEventListener("mousedown", handleSaleOrderDownClickOutside);
    } else {
      document.removeEventListener("mousedown", handleSaleOrderDownClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleSaleOrderDownClickOutside);
    };
  }, [isSaleOrderDownPopup]);

  const handleSaleCheckboxChange = (column) => {
    setTempVisibleColumns((prevState) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const handleApplySaleOrderFilter = () => {
    setVisibleSaleOrderColumns(tempVisibleColumns);
    setShowSaleOrderColumnFilter(false);
  };

  const handleSaleOrderColumnFilter = () => {
    setTempVisibleColumns({ ...visibleSaleOrderColumns });
    setShowSaleOrderColumnFilter(true);
  };

  const handleNewSaleOrder = () => {
    setOpenSaleOrderPopup(true);
  }

  const closeNewSaleOrderPopup = () => {
    setOpenSaleOrderPopup(false);
    setSaleOrderCopy("");
    setSaleOrderUpdated(false);
  }

  // const handleSaleOrderFilter = () => {
  //   setSaleOrderFilterPopup(true);
  // }

  const handleSaleOrderDownAction = (saleOrder, event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setActionDropdownPosition({
      top: rect.bottom + window.scrollY,
    });
    setSelectedSaleOrder(saleOrder);
    setSaleOrderDownPopup(true);
  }

  const columnSalOrderFilterRef = useRef(null);
  const downSalOrderRef = useRef(null);
  const saleOrderFilterRef = useRef(null);


  const fetchSaleOrder = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/transactions/entries?type=sale_order&page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setSaleOrder(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching sale order:", error);
    }
  };

  const handleDeleteSaleOrder = async (saleOrderId) => {
    if (saleOrderId) {
      try {
        const response = await sendRequest("DELETE", `${BASE_URL}/v1/transactions/entries/${saleOrderId}?type=sale_order`);
        if (response.status === 200) {
          console.log("sale order deleted successfully!");
          fetchSaleOrder(currentPage, recordsPerPage);
          setSaleOrderDownPopup(false);
        } else {
          console.log("Failed to delete the sale order. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting the sale order:", error);
        console.log("An error occurred while deleting the sale order.");
      }
    }
  };

  const handleSaleOrderCopy = async (saleOrderCopyId, updated) => {
    if (updated) {
      setSaleOrderUpdated(updated);
    }
    if (saleOrderCopyId) {
      try {
        const response = await sendRequest("GET", `${BASE_URL}/v1/transactions/entries/${saleOrderCopyId}?type=sale_order`);
        setSaleOrderCopy(response.data.entry);
        setOpenSaleOrderPopup(true);
      } catch (error) {
        console.error("Error copy the sale order:", error);
        console.log("An error occurred while deleting the copy sale order.");
      }
    }
  };

  const handleSaleOrderInvoice = async (saleOrderinvoiceId) => {
    if (saleOrderinvoiceId) {
      try {
        const response = await sendRequest("GET", `${BASE_URL}/v1/transactions/entries/${saleOrderinvoiceId}?type=sale_order`);
        setSaleOrderInvoice(response.data.entry);
        setOpenSaleOrderInvoicePopup(true);
      } catch (error) {
        console.error("Error for sale order invoice:", error);
        console.log("An error occurred while deleting the invoice sale order.");
      }
    }
  };

  const handleSaleOrderDeliveryChallan = async (saleOrderDeliveryId) => {
    if (saleOrderDeliveryId) {
      try {
        const response = await sendRequest("GET", `${BASE_URL}/v1/transactions/entries/${saleOrderDeliveryId}?type=sale_order`);
        setSaleOrderDeliveryChallan(response.data.entry);
        setSaleOrderDeliveryChallanPopup(true);
      } catch (error) {
        console.error("Error for sale order invoice:", error);
        console.log("An error occurred while deleting the invoice sale order.");
      }
    }
  };

  const closeNewSaleOrderInvoicePopup = () => {
    setOpenSaleOrderInvoicePopup(false);
  }

  const closeNewSaleOrderDeliveryPopup = () => {
    setSaleOrderDeliveryChallanPopup(false);
  }

  useEffect(() => {
    fetchSaleOrder(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const handelNewSaleOrderCreated = (value) => {
    setSaleOrder(prevSaleOrders => [...prevSaleOrders, ...value]);
    setTotalRecords(prevTotalRecords => prevTotalRecords + 1);
  }

  return (
    <>
      <div className="sales-order-main-container">
        <div className="sales-order-container-first-div">
          <div className="sales-order-first-left">
            <span className="all-sales-order-show">All Sales Order({totalRecords})</span>
          </div>
          <div className="sales-order-first-right">
            <button className="sales-order-first-right-botton" onClick={handleNewSaleOrder}>
              New Sale Order
              {/* <span><FontAwesomeIcon icon={faCaretDown} /></span> */}
            </button>
          </div>
        </div>
        <div className="sales-order-container-second-div">
          <div className="sales-order-second-div-right">
            {/* <div className="sales-order-second-drop-first">
              <span className="sales-order-turn-right-icon"><FontAwesomeIcon icon={faArrowsTurnRight} /></span>
              <span className="sales-order-caret-down-icon"><FontAwesomeIcon icon={faCaretDown} /></span>
            </div> */}
            <div className="sale-order-pagination-drop-first">
              <EntriesPerPageDropdown recordPerPage={recordsPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
            </div>
            <div className="sale-order-right-first">
              {/* <div className="sales-order-second-filter" onClick={handleSaleOrderFilter}>
                <span className="sales-order-filter-icon"><FontAwesomeIcon icon={faFilter} /></span>
              </div> */}
              <div className="sales-order-second-three-dot-grop" onClick={handleSaleOrderColumnFilter}>
                {/* <span className="sales-order-ellips-icon"><FontAwesomeIcon icon={faEllipsisVertical} /></span> */}
              </div>
            </div>
          </div>
        </div>

        <div className="sale-order-table-group">
          <table className="sale-order-table">
            <thead>
              <tr className="sale-order-thead-row">
                <th className="sale-order-tab-th">Date</th>
                <th className="sale-order-tab-th">Order No</th>
                {visibleSaleOrderColumns.contact && (
                  <th className="sale-order-tab-th">Contact</th>
                )}
                {visibleSaleOrderColumns.refNo && (
                  <th className="sale-order-tab-th">Ref.No</th>
                )}
                {/* <th className="sale-order-tab-th">Stock</th> */}
                {visibleSaleOrderColumns.buyerOrderNo && (
                  <th className="sale-order-tab-th">Buyer's Order No#</th>
                )}
                {visibleSaleOrderColumns.buyerOrderDate && (
                  <th className="sale-order-tab-th">Buyer's Order Date</th>
                )}
                {/* {visibleSaleOrderColumns.production && (
                  <th className="sale-order-tab-th">Production</th>
                )}
                {visibleSaleOrderColumns.delivered && (
                  <th className="sale-order-tab-th">Delivered</th>
                )} */}
                {visibleSaleOrderColumns.status && (
                  <th className="sale-order-tab-th">Status</th>
                )}
                <th className="sale-order-tab-th">Amount</th>
                <th className="sale-order-tab-th">Action</th>
              </tr>
            </thead>
            <tbody className="sale-order-table-body">
              {saleOrder.map((row, index) => (
                <tr key={index} className="sale-order-tbody-row">
                  <td className="sale-order-tab-td">{row.date}</td>
                  <td className="sale-order-tab-td">{row.bill_number}</td>
                  {visibleSaleOrderColumns.contact && (
                    <td className="sale-order-tab-td">{row.customer.customer_name}</td>
                  )}
                  {visibleSaleOrderColumns.refNo && (
                    <td className="sale-order-tab-td">{row.reference_no}</td>
                  )}
                  {/* <td className="sale-order-tab-td">{row.stock}</td> */}
                  {visibleSaleOrderColumns.buyerOrderNo && (
                    <td className="sale-order-tab-td">{row.order_no}</td>
                  )}
                  {visibleSaleOrderColumns.buyerOrderDate && (
                    <td className="sale-order-tab-td">{row.order_date}</td>
                  )}
                  {/* {visibleSaleOrderColumns.production && (
                    <td className="sale-order-tab-td">{row.production}</td>
                  )}
                  {visibleSaleOrderColumns.delivered && (
                    <td className="sale-order-tab-td">{row.delivered}</td>
                  )} */}
                  {visibleSaleOrderColumns.status && (
                    <td className="sale-order-tab-td">{row.status}</td>
                  )}
                  <td className="sale-order-tab-td">{row.total_amount}</td>
                  <td className="sale-order-tab-td">
                    <FontAwesomeIcon icon={faCaretDown} className="sale-order-table-body-td" onClick={(e) => handleSaleOrderDownAction(row, e)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}

        {saleOrder.length === 0 && (
          <div className="sale-order-no-data-message">
            No sale order found.
          </div>
        )}

        {showSaleOrderColumnFilter && (
          <div className="sale-order-table-column-popup" ref={columnSalOrderFilterRef}>
            <form className="sale-order-column-filter-form">
              {Object.keys(tempVisibleColumns).map((column) => (
                <div key={column} className="sale-order-label-input-grp">
                  <label>
                    <input
                      type="checkbox"
                      className="sale-order-visible-column-input"
                      checked={tempVisibleColumns[column]}
                      onChange={() => handleSaleCheckboxChange(column)}
                    />
                    {column.charAt(0).toUpperCase() + column.slice(1)}
                  </label>
                </div>
              ))}
              <button type="button" onClick={handleApplySaleOrderFilter} className="sale-order-visible-box-btn">
                Apply
              </button>
            </form>
          </div>
        )}

        {isOpenSaleOrderPopup && (
          <div className="new-sale-order-popup-container">
            <NewSaleOrder onClose={closeNewSaleOrderPopup} saleOrderCopy={saleOrderCopy} isUpdated={saleOrderUpdated} saleOrderCreated={handelNewSaleOrderCreated} />
          </div>
        )}

        {isOpenSaleOrderInvoicePopup && (
          <div className="new-sale-order-popup-container">
            <NewSaleInvoice onClose={closeNewSaleOrderInvoicePopup} isSaleOrderInvoice={isSaleOrderInvoice} />
          </div>
        )}

        {isSaleOrderDeliveryChallanPopup && (
          <div className="new-sale-order-popup-container">
            <NewDeliveryChallan onClose={closeNewSaleOrderDeliveryPopup} isSaleOrderDeliveryChallan={isSaleOrderDeliveryChallan} />
          </div>
        )}

        {isSaleOrderFilterPopup && (
          <div className="sale-order-filter-popup-container" ref={saleOrderFilterRef}>
            <div className="sale-order-first-att-div">
              <label className="sale-order-first-att-div-label">Contacts / Sales Order / Ref# / Amount</label>
              <select>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
            <div className="sale-order-second-att-div">
              <label className="sale-order-second-att-div-label">Status</label>
              <select>
                <option>send</option>
                <option>Expired</option>
                <option>Rejected</option>
                <option>Confirmed</option>
                <option>Invoiced</option>
                <option>Revised</option>
              </select>
            </div>
            <div className="sale-order-third-att-div">
              <div className="sale-order-third-part-1">
                <label className="sale-order-third-part-1-label">Start Date</label>
                <input type="date" className="sale-order-third-att-date" />
              </div>
              <div className="sale-order-third-part-2">
                <label className="sale-order-third-part-2-label">End Date</label>
                <input type="date" className="sale-order-third-att-date" />
              </div>
            </div>
            <div className="sale-order-attr-btn-grp">
              <button className="sale-order-attr-pop-btn">Search</button>
              <button className="sale-order-attr-pop-btn">Clear</button>
            </div>
          </div>
        )}

        {isSaleOrderDownPopup && (
          <div className="sale-order-down-icon-action"
            ref={downSalOrderRef}
            style={{
              position: 'absolute',
              top: `${actionDropdownPosition.top}px`,
            }}
          >
            <ul className="sale-order-down-icon-action-ul">
              <li className="sale-order-down-icon-action-li" onClick={() => handleSaleOrderCopy(selectedSaleOrder.id, true)}>Edit</li>
              <li className="sale-order-down-icon-action-li" onClick={() => handleDeleteSaleOrder(selectedSaleOrder.id)}>
                Delete
              </li>
              <li className="sale-order-down-icon-action-li" onClick={() => handleSaleOrderInvoice(selectedSaleOrder.id)}>Create Invoice</li>
              {/* <li className="sale-order-down-icon-action-li">Receipt</li> */}
              <li className="sale-order-down-icon-action-li" onClick={() => handleSaleOrderDeliveryChallan(selectedSaleOrder.id)}>Create Delivery Challan</li>
              {/* <li className="sale-order-down-icon-action-li">Print</li>
              <li className="sale-order-down-icon-action-li">Send</li>
              <li className="sale-order-down-icon-action-li">Send SMS</li>
              <li className="sale-order-down-icon-action-li">Send WhatsApp</li> */}
              <li className="sale-order-down-icon-action-li" onClick={() => handleSaleOrderCopy(selectedSaleOrder.id)}>Copy</li>
              {/* <li className="sale-order-down-icon-action-li">Add Shipment</li> */}
            </ul>
          </div>
        )}
      </div>
      <Copyrights />
    </>
  );
}

export default SaleOrder;
