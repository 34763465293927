import React, { createContext, useState, useContext } from 'react';

const TransliterationContext = createContext();

export const TransliterationProvider = ({ children }) => {
  const [text, setText] = useState('');

  return (
    <TransliterationContext.Provider value={{ text, setText }}>
      {children} 
    </TransliterationContext.Provider>
  );
};

export const useTransliteration = () => useContext(TransliterationContext);
