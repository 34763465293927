import React, { useState } from 'react';
import './VoidRemark.css';
import { sendRequest } from '../../../../ApiRequest';
import ErrorNotification from '../../../../Notification/ErrorNotification';
import { BASE_URL } from '../../../../Url/constants';
import { useTranslation } from "react-i18next";

function VoidRemark({ onClose, isVisible, orderId, itemIds, onItemVoided }) {
  const [voidReason, setVoidReason] = useState('');
  const { t } = useTranslation();

  const handleVoidReason = async (reason) => {
    const voidedItemIds = [];
    try {
      for (const itemId of itemIds) {
        await sendRequest('DELETE', `${BASE_URL}/v1/billings/bills/${orderId}/destroy_void_item?reason=${encodeURIComponent(reason)}&bill_item_id=${itemId}`);
        voidedItemIds.push(itemId);
      }
      onItemVoided(voidedItemIds);
      onClose();
    } catch (error) {
      ErrorNotification("Error sending void reason");
    }
  };

  const handleSave = () => {
    handleVoidReason(voidReason);
  };

  return (
    isVisible && (
      <div className='modal-overlay'>
        <div className='void-rem-item-modifier'>
          <div className='void-remark-res-modify-title'>
            <span className='remark-modify-title-heading'>{t("Void Remarks")}</span>
            <span className='remark-modify-cross-icon' onClick={onClose}>X</span>
          </div>
          <div className='void-remark-sub'>
            <div className='void-remark-content'>
              <div>{t("Select Void Reason")}</div>
              <div>
                <button className='btn void-reason-button' onClick={() => handleVoidReason('Poor Quality')}>{t("Poor Quality")}</button>
                <button className='btn void-reason-button' onClick={() => handleVoidReason('Guest Rejected')}>{t("Guest Rejected")}</button>
                <button className='btn void-reason-button' onClick={() => handleVoidReason('Changed The Item')}>{t("Changed The Item")}</button>
                <button className='btn void-reason-button' onClick={() => handleVoidReason('Bad Test')}>{t("Bad Test")}</button>
                <button className='btn void-reason-button' onClick={() => handleVoidReason('Wrong Punch')}>{t("Wrong Punch")}</button>
              </div>
              <div className='void-remark-void-reason'>{t("New Void Reason")}</div>
              <div>
                <div>
                  <input
                    type="text"
                    className='form-control'
                    value={voidReason}
                    onChange={(e) => setVoidReason(e.target.value)}
                  />
                </div>
              </div>
              <div className='save-button-container'>
                <button className='void-remark-save-btn' onClick={handleSave}>{t("Save")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default VoidRemark;
