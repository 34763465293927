import React, { useState, useEffect } from "react";
import Copyrights from "../components/Copyrights";
import { BASE_URL, NO_RECORD_ERROR, WENTWRONG } from "../Url/constants";
import { getToken } from "../components/SecureStorage/Token";
import ErrorNotification from "../Notification/ErrorNotification";
import "../components/CreateCustomer.css";
import { BsEye, BsFillTrashFill, BsPencilSquare } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import CustomTable from "../components/Common/CustomTable";
import ConfirmPopup from "../components/ConfirmPop";
import Search from "../components/Search/Search";
import EntriesPerPageDropdown from "../components/Common/EntriesPerPageDropdown";
import PaginationControls from "../components/Common/PaginationControls";
import { current_user } from "../Authentication/AuthenticationContext";
import { useTranslation } from "react-i18next";
import { sendRequest } from "../ApiRequest";
import successNotification from "../Notification/SuccessNotification";
import Modal from '../ModalPermission.js'

function Staff() {
  const [StaffData, setStaffData] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setrecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const startIndex = (currentPage - 1) * recordPerPage + 1;
  const endIndex = Math.min(currentPage * recordPerPage, totalRecords);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  const [errorUnauthorize, setErrorUnauthorize] = useState(false);



  const token1 = getToken();
  const token = { headers: { Authorization: `${token1}` } };

  useEffect(() => {
    fetchData();
    fetchUser();
  }, [recordPerPage, searchQuery]);

  const fetchUser = async () => {
    const currentUser = await current_user();
    setUser(currentUser);
  }

  const fetchData = async (pageNumber = 1) => {
    try {
      let request_url = `${BASE_URL}/v1/staff_management/staffs?&page=${pageNumber}&per_page=${recordPerPage}`;
      if (searchQuery) {
        request_url += `&query=${searchQuery}`;
      }
      const response = await sendRequest('GET', request_url)

      if (response.status === 200) {
        const res = await response.data
        setStaffData(res?.data);
        setTotalPages(res?.total_pages);
        setTotalRecords(res?.total_count);
      } else {
        const msg = NO_RECORD_ERROR("staff");
        ErrorNotification(msg);
      }
    } catch (error) {
      if (error && error.data && error.data.errors) {
        if (error.data.errors.length === 49) {
          setErrorUnauthorize(true); // Handle unauthorized case by showing the modal
        } else {
          ErrorNotification('Error fetching items:', error);
        }
      } else {
        ErrorNotification('Unknown error occurred', error);
      }
    }
  };

  const handleCloseItemModel = () => {
    setErrorUnauthorize(false);
    navigate("/dashboard");
  }

  const handleUpdateButtonClick = (staff) => {
    setSelectedStaff(staff);
    navigate(`/update-staff/${staff.id}`, { state: staff });
  };

  const handleShowButtonClick = (staff) => {
    setSelectedStaff(staff);
    navigate(`/show-staff/${staff.id}`);
  };

  const handleDeleteButtonClick = (staff) => {
    setSelectedStaff(staff);
    setShowPopup(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedStaff) {
      const response = await sendRequest('DELETE', `${BASE_URL}/v1/staff_management/staffs/${selectedStaff.id}`);
      if (response.status === 200) {
        successNotification(response.data.message)
        const updatedStaffs = StaffData?.filter(
          (sup) => sup.id !== selectedStaff.id
        );
        setStaffData(updatedStaffs);
        setShowPopup(false);
        fetchData(currentPage);
      } else {
        ErrorNotification(WENTWRONG);
      }
    }
    setShowPopup(false);
  };

  const handleSearch = (query) => {
    if (query.length >= 2) {
      setSearchQuery(query);
    } else {
      setSearchQuery("");
      setCurrentPage(1);
    }
  };

  const handleCancelDelete = () => {
    setSelectedStaff(null);
    setShowPopup(false);
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setrecordPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = async (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      await fetchData(pageNumber);
    }
  };

  return (
    <>
      <div className="content customer-create-tab">
        <div className="button-container">
          <Link to="/add-staff" className="btn btn-primary">
            + {t("Add Staff")}
          </Link>
        </div>
        <>
          <div className="row">
            <div className="col-md-12">
              <h3 className="title">{t("Staff List")} ({totalRecords})</h3>
            </div>
            <div className="pagination-dropdown">
              <div className="pagination-controls">
                <EntriesPerPageDropdown
                  recordPerPage={recordPerPage}
                  handleRecordPerPageChange={handleRecordPerPageChange}
                />
              </div>
              <div
                className="text-right"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
              >
                <Search placeholder={"Search Staffs ..."} />
              </div>
            </div>
          </div>
          <div className="table-view suppliers-table cust-common-table">
            <CustomTable
              col_names={[
                "S. No.",
                "First Name",
                "Last Name",
                "Email ID",
                "Phone No",
                "Gender",
                "Actions",
              ]}
              data={StaffData.map((staff, index) => ({
                "S. No.": startIndex + index,
                "First Name": staff.first_name,
                "Last Name": staff.last_name,
                "Email ID": <a href={`mailto:${staff.email}`}>{staff.email}</a>,
                "Phone No": staff.phone,
                Gender: staff.gender,
                Actions: (
                  <div className="col-md-12">
                    <span>
                      {user &&
                        !(['manager', 'staff', 'chef', 'cashier'].includes(user?.user_detail?.role?.name) &&
                          ['owner', 'admin'].includes(staff?.role_details?.role_name)) &&
                        !(['staff', 'chef', 'cashier'].includes(user?.user_detail?.role?.name) &&
                          ['owner', 'admin', 'manager'].includes(staff?.role_details?.role_name)) && (
                          <a
                            className="icon-link"
                            onClick={() => handleUpdateButtonClick(staff)}
                          >
                            <BsPencilSquare />
                          </a>
                        )}
                      <a
                        className="show-icon"
                        onClick={() => handleShowButtonClick(staff)}
                      >
                        <BsEye />
                      </a>
                      {user &&
                        !(['manager', 'staff', 'chef', 'cashier'].includes(user?.user_detail?.role?.name) &&
                          ['owner', 'admin'].includes(staff?.role_details?.role_name)) &&
                        !(['staff', 'chef', 'cashier'].includes(user?.user_detail?.role?.name) &&
                          ['owner', 'admin', 'manager'].includes(staff?.role_details?.role_name)) && (
                          <a
                            className="deletebtn delete-icon"
                            onClick={() => handleDeleteButtonClick(staff)}
                          >
                            <BsFillTrashFill />
                          </a>
                        )}
                    </span>
                  </div>
                ),
              }))}
            />
            {StaffData.length === 0 && searchQuery.length > 1 && (
              <div className="no-report-data-message">{t("No match found")}</div>
            )}
            {StaffData.length === 0 && searchQuery.length === 0 && (
              <div className="no-report-data-message">{t("No records found")}</div>
            )}
          </div>
          {totalRecords >= recordPerPage && (
            <div>
              <div className="report-pagination-info">
                <div className="report-pagination-container">
                  <PaginationControls
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                    totalRecords={totalRecords}
                    startIndex={startIndex}
                    endIndex={endIndex}
                  />
                </div>
              </div>
            </div>
          )}
        </>
        {/* )} */}
      </div>
      {
        errorUnauthorize && (
          <div className="item-unauthorize-modal-popup">
            <div className="">
              <Modal onClose={handleCloseItemModel}/>
            </div>
          </div>
        )
      }
      <Copyrights />
      {showPopup && (
        <ConfirmPopup
          title="Confirm Delete"
          message={`Are you sure you want to delete ?`}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </>
  );
}

export default Staff;
