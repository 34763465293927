import React, { useState, useEffect } from 'react';
import './VoidKot.css';
import VoidRemark from './VoidRemark/VoidRemark';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const VoidKot = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [table, setTable] = useState();
  const [orderId, setOrderId] = useState();
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState([]);

  const [tableInfo, setTableInfo] = useState({
    tableNo: '',
    kotId: '',
    date: '',
    captain: '',
    noOfPerson: ''
  });

  useEffect(() => {
    const tableInfo = location.state?.tableInfo;
    const orderId = tableInfo.orderId;
    const tabletitle = tableInfo.table;
    if (tableInfo) {
      setOrderId(orderId);
      setTable(tabletitle);
      setTableInfo({
        tableNo: tableInfo.tableNo,
        kotId: tableInfo.kotId,
        date: tableInfo.date,
        captain: tableInfo.captain,
        noOfPerson: tableInfo.noOfPerson
      });

      const items = tableInfo.orderItems.map(item => ({
        ...item,
        amount: item.item_total_amount,
        selected: false,
        description: '',
        id: item.id
      }));

      setItems(items);
    }
  }, [location.state?.tableInfo]);

  const handleCheckboxChange = (index) => {
    const newItems = [...items];
    newItems[index].selected = !newItems[index].selected;
    setItems(newItems);

    const selected = newItems.filter(item => item.selected).map(item => item.id);
    setSelectedItems(selected);
  };

  const handleConfirmSelection = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItems([]);
  };

  const handleItemVoided = (voidedItemIds) => {
    const newItems = items.filter(item => !voidedItemIds.includes(item.id));
    setItems(newItems);
    setSelectedItems([]);
  };

  const totalAmount = items.reduce((acc, item) => acc + item.amount * item.quantity, 0).toFixed(2);

  const handleTableList = () => {
    navigate("/restaurants", { state: { floor_id: table?.floor_id } });
  };

  const handleshowInvoicePage = (table) => {
    navigate('/bill-item', { state: { table } });
  };

  return (
    <div className='void-kot-container'>
      <div className='void-kot-box-container'>
        <div className='void-kot-box void-kot-part-first'>
          <div className="void-kot-header">
            <div className='row header-first-kot-header'>
              <div className='col-md-3'>{t("Table No")}</div>
              <div className='col-md-3'>{tableInfo.tableNo}</div>
              <div className='col-md-3'>{t("KOT#")}</div>
              <div className='col-md-3'>{tableInfo.kotId}</div>
            </div>

            <div className='row header-second-kot-header'>
              <div className='col-md-3'>{t("Date")}</div>
              <div className='col-md-3'>{tableInfo.date}</div>
              <div className='col-md-3'>{t("Captain")}</div>
              <div className='col-md-3'>
                <div>{tableInfo.captain}</div>
              </div>
            </div>

            <div className='row header-third-kot-header'>
              <span>{t("No Of Person:")} {tableInfo.noOfPerson}</span>
            </div>
          </div>
          <div className="order-list">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{("Item Name")}</th>
                  <th>{t("Description")}</th>
                  <th>{t("Quantity")}</th>
                  <th>{t("Rate")}</th>
                  <th>{t("Amount")}</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.item_name}</td>
                    <td>{item.description}</td>
                    <td>{item.quantity}</td>
                    <td>{item.amount}</td>
                    <td>{(item.amount * item.quantity).toFixed(2)}</td>
                  </tr>
                ))}
                <tr></tr>
              </tbody>
            </table>
          </div>

          <div className='order-item-total-title'>
            <div className='order-item-span-group'>
              <div className='total-item-sub-total-order-void-res'>
                <span className='total-order-item'>{t("Total Items:")} {items.length}</span>
                <span className='sub-total-order-item'>{t("Sub Total:")} ₹{totalAmount}</span>
              </div>
            </div>
          </div>
          <div className='order-item-save-list-group'>
            <button className="order-item-save-and-print" onClick={() => handleshowInvoicePage(table)}>{t("Save & Print")}</button>
            <button className="order-table-list-button" onClick={handleTableList}>{t("Table List")}</button>
          </div>
        </div>
        <div className='void-kot-box void-kot-part-second'>
          <div className="order-list">
            <table className='table-order-list'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t("Select")}</th>
                  <th>{t("Item Name")}</th>
                  <th>{t("Description")}</th>
                  <th>{t("Quantity")}</th>
                  <th>{t("Rate")}</th>
                  <th>{t("Amount")}</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.selected}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </td>
                    <td>{item.item_name}</td>
                    <td>{item.description}</td>
                    <td>{item.quantity}</td>
                    <td>₹{item.amount}</td>
                    <td>₹{(item.amount * item.quantity).toFixed(2)}</td>
                  </tr>
                ))}
                <tr></tr>
              </tbody>
            </table>
          </div>
          <div className='void-item-confirm-button-container'>
            <button className='void-item-confirm-selection-button' onClick={handleConfirmSelection}>{t("Confirm")}</button>
          </div>
        </div>
      </div>
      <VoidRemark
        onClose={handleCloseModal}
        isVisible={isModalOpen}
        itemIds={selectedItems}
        onItemVoided={handleItemVoided}
        orderId={orderId}
      />
    </div>
  );
};

export default VoidKot;
