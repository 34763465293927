import React, { useState } from 'react';
import "./PlatformForStore.css";
import BackButton from '../../../../BackButton';

const PlatformForStore = () => {
  const [selectedStore, setSelectedStore] = useState('');
  const [platforms, setPlatforms] = useState([
    { id: 1, name: 'All', icon: '#', active: false },
    { id: 2, name: 'Urban Piper', icon: 'urban-piper-icon.png', active: false },
    { id: 3, name: 'Zomato', icon: 'zomato-icon.png', active: true },
    { id: 4, name: 'FoodPanda', icon: 'foodpanda-icon.png', active: false },
    { id: 5, name: 'Swiggy', icon: 'swiggy-icon.png', active: true },
  ]);

  const handleStoreChange = (e) => {
    setSelectedStore(e.target.value);
  };

  const handlePlatformToggle = (id) => {
    setPlatforms(platforms.map(platform => 
      platform.id === id ? { ...platform, active: !platform.active } : platform
    ));
  };

  const handleEnableAll = () => {
    setPlatforms(platforms.map(platform => ({ ...platform, active: true })));
  };

  const handleDisableAll = () => {
    setPlatforms(platforms.map(platform => ({ ...platform, active: false })));
  };

  return (
    <div className="platform-for-store-container">
      <div className="platform-for-store-header">
        <span className='platform-for-store-back-btn'><BackButton /></span>
        <span className='platform-store-title'>Platform for Store</span>
      </div>
      
      <div className='platform-store-sub-container'>
        <div className="platform-for-store-select-container">
          <span className='platform-select-store-option-title'>SELECT STORE NAME</span>
          <select 
            value={selectedStore} 
            onChange={handleStoreChange} 
            className="form-control"
          >
            <option value="">Select a store</option>
            <option value="chowmein">Chowmein Restaurant</option>
            <option value="pizzaplace">Pizza Place</option>
            <option value="burgerspot">Burger Spot</option>
          </select>
        </div>

        <div className="platform-for-store-list-container">
          <span className='platform-list-store-title'>PLATFORM LIST</span>
          <table className="platform-for-store-table">
            <thead>
              <tr>
                <th className="platform-for-store-th">Icon</th>
                <th className="platform-for-store-th">Platform Name</th>
                <th className="platform-for-store-th">Active</th>
              </tr>
            </thead>
            <tbody>
              {platforms.map(platform => (
                <tr key={platform.id}>
                  <td>
                    <img 
                      src={platform.icon} 
                      alt={platform.name} 
                      className="platform-for-store-icon" 
                    />
                  </td>
                  <td>{platform.name}</td>
                  <td>
                    <input 
                      type="checkbox" 
                      checked={platform.active} 
                      onChange={() => handlePlatformToggle(platform.id)}
                      className="platform-for-store-checkbox"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="platform-for-store-action-buttons">
          <button 
            className="platform-for-store-enable-button" 
            onClick={handleEnableAll}
          >
            Enable
          </button>
          <button 
            className="platform-for-store-disable-button" 
            onClick={handleDisableAll}
          >
            Disable
          </button>
        </div>
        </div>
    </div>
  );
};

export default PlatformForStore;
