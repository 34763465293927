import React from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const Unauthorized = () => {
  const location = useLocation();
  const fromPage = location.state?.from || "Unknown Page";
  const { reason } = useParams();
  return (
    <div
      className="header-link "
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
        textAlign: "center",
        animation: "fadeIn 2s ease-in-out",
        opacity: 1, // To make sure the initial opacity is set
      }}
    >
      <h1
        style={{
          fontSize: "2.5rem",
          color: "#ff4d4d",
          fontWeight: "bold",
          animation: "bounceIn 1.5s ease-out",
        }}
      >
        You are not authorized to access this {reason} page.
      </h1>

      {/* Inline keyframes for the animations */}
      <style>
        {`
          @keyframes fadeIn {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }

          @keyframes bounceIn {
            0% { transform: scale(0.8); opacity: 0; }
            50% { transform: scale(1.1); opacity: 0.7; }
            100% { transform: scale(1); opacity: 1; }
          }
        `}
      </style>
    </div>
  );
};

export default Unauthorized;
