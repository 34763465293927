import React, { useEffect, useState } from "react";
import { BASE_URL, NO_RECORD_ERROR } from "../../../Url/constants";
import ErrorNotification from "../../../Notification/ErrorNotification";
import { sendRequest } from "../../../ApiRequest";

function InvoicePrintTemplate({ invoiceData, isSale, isPurchase }) {
  const [templateData, setTemplateData] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [branchData, setBranchData] = useState({});
  const [userData, setUserData] = useState({});
  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  useEffect(() => {
    getTemplateConfig();
    companyDetails();
  }, []);

  const getTemplateConfig = async (template) => {
    try {
      const url = `${BASE_URL}/v1/template/templates/${template}`;
      const response = await sendRequest("get", url);
      setTemplateData(response.data.data.template_options);
    } catch (error) {
      ErrorNotification(NO_RECORD_ERROR("template"));
    }
  };

  const companyDetails = async () => {
    try {
      const url = `${BASE_URL}/v1/onboarding/companies/current_user_company`;
      const response = await sendRequest("get", url);
      setCompanyData(response.data.data);
      setBranchData(response.data.data.branch_info);
      setUserData(response.data.data.user_info);
    } catch (error) {
      ErrorNotification(NO_RECORD_ERROR("company"));
    }
  };

  const enabledTemplateData = templateData
    .filter((option) => option.template_config.is_enable)
    .map((option) => ({
      option_name: option.option_name,
      free_text: option.template_config.free_text,
      is_enable: option.template_config.is_enable,
    }));

  const formatBillNumber = (number) => {
    return number?.toString().padStart(4, "0");
  };

  const companyAddress = companyData?.branch_info
    ? `${companyData.branch_info.address} ${companyData.branch_info.city}, ${companyData.branch_info.state} (${companyData.branch_info.pin_code})`
    : null;

  return (
    <>
      <style>
        {`
          .invoice-template {
            font-family: Arial, sans-serif;
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
          }
          .invoice-header {
            text-align: center;
            margin-bottom: 30px;
          }
          .invoice-title {
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .company-details {
            margin-bottom: 20px;
          }
          .address-section {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
          }
          .address-box {
            width: 45%;
          }
          .invoice-table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
          }
          .invoice-table th, .invoice-table td {
            border: 1px solid #ddd;
            padding: 10px;
            text-align: left;
          }
          .invoice-table th {
            background-color: #f2f2f2;
          }
          .invoice-summary {
            text-align: right;
          }
          .thank-you {
            text-align: center;
            margin-top: 30px;
            font-style: italic;
          }
        `}
      </style>
      <div className="invoice-template">
        <div className="invoice-header">
          <div className="invoice-title">INVOICE</div>
          <div>Company Name: {companyData.name}</div>
          <div>Address: {branchData.address}</div>
          <div>Phone: {userData.phone}</div>
          <div>Email: {userData.email}</div>
        </div>

        <div className="address-section">
          <div className="address-box">
            <h3>Bill To:</h3>
            {isSale ? (
              <div>
                <p> Customer Name: {invoiceData.bill_to?.name || ""}</p>
                {/* <p>{invoiceData.bill_to?.address || ""}</p> */}
                <p> Phone:{invoiceData.bill_to?.phone || ""}</p>
                <p> Email:{invoiceData.bill_to?.email || ""}</p>
              </div>
            ) : isPurchase ? (
              <div>
                <p>Company Name: {invoiceData.company?.name || ""}</p>
                <p>GST Number: {invoiceData.company?.gst_number || ""}</p>
                <p> Business: {invoiceData.company?.business_type || ""}</p>
                <p>
                  Business Category:
                  {invoiceData.company?.business_category || ""}
                </p>
              </div>
            ) : null}
          </div>
          <div className="address-box">
            {isPurchase ? (
              <div>
                <h3>Ship To:</h3>
                <p>
                  {" "}
                  Supplier Name: {invoiceData.ship_from?.first_name ||
                    "N/A"}{" "}
                  {invoiceData.ship_from?.last_name || "N/A"}
                </p>
                {/* <p>{invoiceData.ship_from?.address || "N/A"}</p>
                <p>{invoiceData.ship_from?.phone || "N/A"}</p> */}
                <p> Supplier Email:{invoiceData.ship_from?.email || "N/A"}</p>
              </div>
            ) : null}
          </div>
        </div>

        <div>
          {/* <p><strong>Invoice Number:</strong> {formatBillNumber(invoiceData.bill_number)}</p>
          <p><strong>Invoice Date:</strong> {formatDate(invoiceData.bill_date)}</p> */}
        </div>

        <table className="invoice-table">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.bill_items.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>
                  {invoiceData.is_pharmaceutical
                    ? item.item_total_amount
                      ? (item?.item_total_amount + item?.item_total_discount).toFixed(2)
                      : "0.00"
                    : isPurchase
                    ? item.purchase_price
                      ? item.purchase_price.toFixed(2)
                      : "0.00"
                    : item.sales_price
                    ? item.sales_price.toFixed(2)
                    : "0.00"}
                </td>
                <td>
                  {invoiceData.is_pharmaceutical
                    ? item.item_total_amount
                      ? ((item?.item_total_amount + item?.item_total_discount) * item.quantity).toFixed(2)
                      : "0.00"
                    : isPurchase
                    ? (item.purchase_price * item.quantity).toFixed(2)
                    : (item.sales_price * item.quantity).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="invoice-summary">
          <p>Sub Total: {((invoiceData?.sub_total || 0) + (invoiceData?.total_discount || 0)).toFixed(2)}</p>
          <p>Discount: {invoiceData?.total_discount || "0.00"}</p>

      {isSale && invoiceData?.is_gst_enable && (
        <>
          {invoiceData?.is_igst_enable ? (
            <div className="invoice-total-line">
              <span className="invoice-label">IGST:</span>
              <span className="invoice-value">
                {invoiceData.total_gst ? invoiceData.total_gst.toFixed(2) : "0.00"}
              </span>
            </div>
          ) : (
            <>
              <div className="invoice-total-line" style={{ marginBottom: "10px" }}>
                <span className="invoice-label">CGST:</span>
                <span className="invoice-value">
                  {invoiceData.total_gst ? (invoiceData.total_gst / 2).toFixed(2) : "0.00"}
                </span>
              </div>
              <div className="invoice-total-line" style={{ marginTop: "10px" }}>
                <span className="invoice-label">SGST:</span>
                <span className="invoice-value">
                  {invoiceData.total_gst ? (invoiceData.total_gst / 2).toFixed(2) : "0.00"}
                </span>
              </div>
            </>
          )}
        </>
      )}

      {!invoiceData?.is_gst_enable && (
        <div className="invoice-total-line">
          <span className="invoice-label">Tax:</span>
          <span className="invoice-value">
            {invoiceData.total_tax ? invoiceData.total_tax.toFixed(2) : "0.00"}
          </span>
        </div>
      )}

  <p><strong>Total Amount: {invoiceData.total_amount ? invoiceData.total_amount.toFixed(2) : "0.00"}</strong></p>
</div>

        <div className="thank-you">Thank you for your business!</div>
      </div>
    </>
  );
}

export default InvoicePrintTemplate;
