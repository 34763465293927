import React, { useState } from "react"
import "./ParcelCharges.css"
import CommonCloseButton from "../CommonCloseButton/CommonCloseButton";
import CommonKeypadButton from "../CommonKeypadButton/CommonKeypadButton";
import { useTranslation } from "react-i18next";

function ParcelCharges({ ParcelChargeValue, setParcelValue, isPracelChargeable, onClose }) {

  const [ParcelCharge, setInputparcelValue] = useState(ParcelChargeValue || "");
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setParcelValue(value);
      setInputparcelValue(value);
      isPracelChargeable(value.length > 0);
    }
  };

  const handleKeyPress = (value) => {
    setInputparcelValue(prevValue => {
      const newValue = prevValue + value;
      if (/^\d{0,5}(\.\d{0,2})?$/.test(newValue)) {
        isPracelChargeable(true);
        setParcelValue(newValue);
      }
      return newValue;
    });
  };

  const handleDelete = () => {
    setInputparcelValue(ParcelCharge.slice(0, -1));
    setParcelValue(ParcelCharge.slice(0, -1));
  };

  const handleClear = () => {
    setInputparcelValue("");
    setParcelValue("");
  };


  return (
    <>
      <div className='parcel-charge-main'>
        <div className='parcel-charge-title'>
          <span className="parcel-charge-title-heading">{t("Parcel Charges")}</span>
          <span onClick={onClose}>
            <CommonCloseButton />
          </span>
        </div>

        <div className="parcel-charge-sub">
          <div className="parcel-first-label-and-input">
            <label className="parcel-label-field">{t("Parcel Charges")}</label>
            <input
              className="form-control parcel-input-field"
              value={ParcelChargeValue}
              onChange={handleInputChange}
              maxLength={5}
            />
          </div>

          <div className="parcel-calculator-formate">
            <div className='parcel-calculator-container'>
              <CommonKeypadButton
                onKeyPress={handleKeyPress}
                onClear={handleClear}
                onDelete={handleDelete}
                onEnter={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ParcelCharges;
