import React from "react";
import { BsPencilSquare } from "react-icons/bs";
import { sendRequest } from "../ApiRequest";
import { BASE_URL } from "../Url/constants";
import ErrorNotification from "../Notification/ErrorNotification";

export const handleSearchSupplier = async (query) => {
  
  try {
    const response = await sendRequest(
      "GET",
      `${BASE_URL}/v1/onboarding/suppliers?query=${query}`
    );

    if (response.status === 200) {
      const suppliersData = response.data.data;
      return suppliersData;
    }
  } catch (error) {
    ErrorNotification("Unable to fetch Supplier");
    throw error;
  }
};

export function SupplierDetails(props) {

  return (
    <>
      {props.supplierId ? (
        <>
          <div className="row">
            <div className="col-md-5">
              <div className="d-inline-flex">
                <h5 className="fs-6 mb-0 ">Billing Address</h5>
                <BsPencilSquare
                  onClick={props.handleEditSupplier}
                  role="button"
                  className="text-primary"
                />
              </div>
              <div>
                {props.supplierDetails?.addresses &&
                  props.supplierDetails.addresses
                    .filter((address) => address.address_type === "billing")
                    .map((billingAddress) => (
                      <p key={billingAddress.id} className="mb-1">
                        {billingAddress.address_line_1},{" "}
                        {billingAddress.address_line_2}, {billingAddress.city},{" "}
                        {billingAddress.state}, {billingAddress.country}
                      </p>
                    ))}
              </div>
            </div>

            <div className="col-md-6">
              <div className="d-inline-flex justify-content-between align-items-center">
                <h5 className="fs-6 mb-0">Shipping Address</h5>
                <BsPencilSquare
                  onClick={props.handleEditSupplier}
                  role="button"
                  className="text-primary"
                />
              </div>
              <div>
                {props.supplierDetails?.addresses &&
                  props.supplierDetails.addresses
                    .filter((address) => address.address_type === "shipping")
                    .map((shippingAddress) => (
                      <p key={shippingAddress.id} className="mb-1">
                        {shippingAddress.address_line_1},{" "}
                        {shippingAddress.address_line_2}, {shippingAddress.city}
                        , {shippingAddress.state}, {shippingAddress.country}
                      </p>
                    ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
