import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { NotificationContainer } from "react-notifications";
import ErrorNotification from "../../../Notification/ErrorNotification";
import SearchSupplier from "../suppliers/search-supplier/search-supplier";
import "./purchase-payment.css";
import { updateBillingStatus } from "../update-bill-status/update-bill";
import { useTranslation } from "react-i18next";

export function PurchasePayment({ transactionDetails }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [supplierExists, setSupplierExists] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchSupplierData() {
      try {
        if (transactionDetails.ship_from) {
          setSupplierExists(true);
        } else {
          setSupplierExists(false);
        }
      } catch (error) {
        if (error.data && Array.isArray(error.data.errors)) {
          error.data.errors.forEach((err) => ErrorNotification(err));
        } else {
          ErrorNotification("An unexpected error occurred.");
        }
        setSupplierExists(false);
      }
    }

    fetchSupplierData();
  }, [id, transactionDetails]);

  if (supplierExists === null) {
    return <p>{t("Loading...")}</p>;
  }

  const handlePayment = () => {
    if (supplierExists) {
      updateBillingStatus(id, null, navigate);
    } else {
      ErrorNotification("Please add a supplier before proceeding with the payment.");
    }
  };

  return (
    <div className="purchase-payment-container">
      <NotificationContainer />
      <h2>{t("Payment")}</h2>
      {supplierExists ? (
        <div>
          <button
            className="btn btn-primary"
            onClick={handlePayment}
          >
            {t("Pay Now")}
          </button>
        </div>
      ) : (
        <div>
          <div className="form-group w-10">
            <SearchSupplier id={id} navigate={navigate}/>
          </div>
        </div>
      )}
    </div>
  );
}
