import React, { useEffect, useState } from "react";
import { BASE_URL, NO_RECORD_ERROR } from "../../../Url/constants";
import { getToken } from "../../SecureStorage/Token";
import ErrorNotification from "../../../Notification/ErrorNotification";
import { sendRequest } from "../../../ApiRequest";
import './BillingTemplateThree.css'

function BillingTemplateThree({template, formData, discount = "0.00", isPartyEnabled}) {
  const token1 = getToken();
  const token = { headers: { Authorization: `${token1}` } };
  const [TemplateData, setTemplateData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [items, setItems] = useState([]);
  const currDate = new Date().toLocaleDateString();
  // const currTime = new Date().toLocaleTimeString();

  useEffect(() => {
    getTemplateConfig(template);
    companyDetails();
    fetchItemDetails(formData);
  }, [template, formData]);

  const getTemplateConfig = async (template) => {
    try {
      const url = `${BASE_URL}/v1/template/templates/${template}`;
      const response = await sendRequest('get', url);
      setTemplateData(response.data.data.template_options);
    } catch (error) {
      ErrorNotification(NO_RECORD_ERROR('template'));
    }
  };

  const companyDetails = async () => {
    try {
      const url = `${BASE_URL}/v1/onboarding/companies/current_user_company`;
      const response = await sendRequest('get', url);
      setCompanyData(response.data.data);
    } catch (error) {
      ErrorNotification(NO_RECORD_ERROR('company'));
    }
  };

  const fetchItemDetails = async (formData) => {
    try {
      const fetchedItems = await Promise.all(
        formData?.bill?.bill_items_attributes?.map(async (item) => {
          const url = `${BASE_URL}/v1/inventory_managment/items/${item.item_id}?is_normal_billing=true`;
          const response = await sendRequest('get', url);
          return {
            ...response.data.data,
            editedWholesalePrice: item.item_total / item.quantity,
            sales_price: item?.salesPrice,
            sale_amount: item?.saleAmount,
          };
        })
      );
      setItems(fetchedItems);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const enabledTemplateData = TemplateData
  .filter(option => option.template_config?.is_enable)
  .map(option => ({
    option_name: option.option_name,
    free_text: option.template_config.free_text,
    is_enable: option.template_config.is_enable
  }));

  const formatBillNumber = (number) => {
    return number?.toString().padStart(4, "0");
  };

  const companyAddress = companyData?.branch_info
    ? `${companyData.branch_info.address} ${companyData.branch_info.city}, ${companyData.branch_info.state} (${companyData.branch_info.pin_code})`
    : null;
    return (
      <div className="template-three-main">
        <div className="template-three-sub">
          {(enabledTemplateData.find(option => option.option_name === 'company_logo')?.is_enable || formData?.bill?.print_logo) && (
            <div className="template-three-header">
              <img className="template-three-logo" src={companyData.logo_url || "/companylogo.jpeg"} alt="logo"/>
            </div>
          )}
          <div className="template-three-date-time">
            {enabledTemplateData.find(option => option.option_name === 'date')?.is_enable && (
              <div className="template-three-date">
                Date: {currDate}
              </div>
            )}
            {enabledTemplateData.find(option => option.option_name === 'invoice_number')?.is_enable && (
              <div className="template-three-receipt">
                Receipt No.: {formatBillNumber(formData?.bill?.bill_number)}
              </div>
            )}
          </div>
          <hr className="template-three-hr" />
          {enabledTemplateData.find(option => option.option_name === 'company_name')?.is_enable && (
            <div className="template-three-company">
              {enabledTemplateData.find(option => option.option_name === "company_name")?.free_text ||  `${companyData.name}` || "JUSTMINDWORK Private Limited"}
            </div>
          )}
          <div className="template-three-details">
            {enabledTemplateData.find(option => option.option_name === 'address' )?.is_enable && (
              <div>{enabledTemplateData.find(option => option.option_name === "address")?.free_text || companyAddress || "417,Sadguru Prinay, Indore"}</div>
            )}
            {enabledTemplateData.find(option => option.option_name === 'email')?.is_enable && (
              <div>Email: {enabledTemplateData.find(option => option.option_name === "email")?.free_text || companyData?.user_info?.email}</div>
            )}
            {enabledTemplateData.find(option => option.option_name === 'insta_id')?.is_enable && (
              <span>
                Insta Id: {enabledTemplateData.find(option => option.option_name === "insta_id")?.free_text || "@nike"}
              </span>
            )}
            {(enabledTemplateData.find(option => option.option_name === 'gst_number')?.is_enable || formData?.bill?.print_gst_no) && (
              <div>GstNumber: {companyData?.gst_number}</div>
            )}
          </div>
          <hr className="template-three-hr" />
  
          <table className="template-three-table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Rate</th>
                <th>Qty</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {formData?.bill?.bill_items_attributes?.map((billItem, index) => {
                const correspondingItem = items.find(item => item.id === billItem.item_id);
  
                if (correspondingItem) {
                  const taxDetails = [];
                  if (correspondingItem?.tax_type === "Individual" && correspondingItem?.tax_info) {
                    taxDetails.push(
                      `${correspondingItem.tax_info.title}: ${correspondingItem.tax_info.tax_value}${correspondingItem.tax_info.tax_type === "fixed" ? "(F)" : "(%)"}`
                    );
                  } else if (correspondingItem?.tax_type === "Group" && correspondingItem?.group_tax_info) {
                    correspondingItem.group_tax_info.forEach(tax => {
                      taxDetails.push(
                        `${tax.title}: ${tax.tax_value}${tax.tax_type === "fixed" ? "(F)" : "(%)"}`
                      );
                    });
                  }
                  const price = isPartyEnabled 
                    ? (correspondingItem.editedWholesalePrice || correspondingItem.wholesale_price)
                    : correspondingItem.sales_price;
                  return (
                    <tr key={index}>
                      <td>
                        {correspondingItem.item_name}
                        {taxDetails.length > 0 && (
                          <div className="tax-font-size">
                            {taxDetails.join(", ")}
                          </div>
                        )}
                      </td>
                      <td>{price.toFixed(2)}</td>
                      <td>{billItem.quantity}</td>
                      <td>{(price * billItem.quantity).toFixed(2)}</td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </table>
  
          <table className="template-three-summary">
            <tbody>
              <tr>
                <td>Sub Total</td>
                <td>{formData?.bill?.total_discount ?  ( (Number(formData?.bill?.sub_total)  || 0)  +  (Number(formData?.bill?.total_discount) || 0) ).toFixed(2) :  formData?.bill?.sub_total }</td>
              </tr>
              <tr>
                <td>Discount</td>
                <td>{formData?.bill?.total_discount || "0.00"}</td>
              </tr>
              {formData?.bill?.is_gst_enable ? (
                formData?.bill?.is_igst_enable ? (
                  <tr>
                    <td>IGST:</td>
                    <td>{formData?.bill?.total_gst || "0.00"}</td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <td>CGST:</td>
                      <td>{(formData?.bill?.total_gst / 2).toFixed(2) || "0.00"}</td>
                    </tr>
                    <tr>
                      <td>SGST:</td>
                      <td>{(formData?.bill?.total_gst / 2).toFixed(2) || "0.00"}</td>
                    </tr>
                  </>
                )
              ) : (
                <tr>
                  <td>Tax:</td>
                  <td>{formData?.bill?.total_tax || "0.00"}</td>
                </tr>
              )}
              <tr>
                <td>Total</td>
                <td>{formData?.bill?.total_amount || "0.00"}</td>
              </tr>
            </tbody>
          </table>
  
          <div className="template-three-footer">
            {enabledTemplateData.find(option => option.option_name === 'return_policy')?.is_enable && (
              <div className="template-three-policy">
                Return Policy: {enabledTemplateData.find(option => option.option_name === "return_policy")?.free_text || "Non returnable items"}
              </div>
            )}
            <div className="template-three-signature">
              This is an electronic generated receipt so doesn't require any signature
            </div>
            {enabledTemplateData.find(option => option.option_name === "recycle_offer")?.is_enable && (
              <div className="template-three-offer">
                <div className="template-three-offer-title">Recycle Offer:</div>
                <div className="template-three-offer-content">
                  {enabledTemplateData.find(option => option.option_name === "recycle_offer")?.free_text || "Recycle shopping bag & cash for each bag on discount on your purchase"}
                </div>
              </div>
            )}
          </div>
  
          <div className="template-three-contact">
            {enabledTemplateData.find(option => option.option_name === 'phone_number')?.is_enable && (
              <span>CALL FOR HOME DELIVERY {enabledTemplateData.find(option => option.option_name === "phone_number")?.free_text || companyData?.user_info?.phone}</span>
            )}
          </div>
          <div className="template-three-message">***Thank you for shopping with us***</div>
        </div>
      </div>
    )
  }
  
  export default BillingTemplateThree;
  