import React, { useState } from "react";
import './PurchaseAmountEdit.css';
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import successNotification from "../../../Notification/SuccessNotification";
import ErrorNotification from "../../../Notification/ErrorNotification";

const InvoiceNoUpdate = ({item, onClose, updateInvoiceNo}) => {
  const [InvoiceNo, setInvoiceNo] = useState(0);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = {
      invoice_no: InvoiceNo,
    }
    const response = await sendRequest('PUT', `${BASE_URL}/v1/inventory_managment/purchase_stocks/update_purchase_amount?id=${item.id}`, params);
    if (response.status === 204) {
      successNotification("Invoice Number updated successfully");
      updateInvoiceNo(item.id, InvoiceNo);
      onClose();
    } else {
      ErrorNotification("unable to update Invoice Number");
    }
  }
  return (
    <div className="purchase-amount-qty-edit-container">
      <div className="purchase-amount-edit-qty-modal-content">
      <button className="purchase-amount-close-btn" onClick={onClose}>&#10005;</button> 
        <form className="purchase-amount-qty-edit-form" onSubmit={handleSubmit}>
          <div className="purchase-amount-edit-item-qty-form-group" >
          <label htmlFor="quantity">Invoice No:</label>
            <input
              id="InvoiceNo"
              className="form-control"
              type="text"
              value={InvoiceNo}
              onChange={(e) => setInvoiceNo(e.target.value)}
              placeholder="Enter Invoice Number"
            />
          </div>
          <div className="purchase-amount-flexbox">
            <button className="purchase-amount-update-btn" type="submit">Update</button>
          </div>
          
        </form>
      </div>
    </div>
  );
}

export default InvoiceNoUpdate;
