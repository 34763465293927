import React, { useState } from "react";
import "./Store.css";
import BackButton from "../../../BackButton";

function Store() {
  const [isAddingNewStore, setIsAddingNewStore] = useState(false);

  const handleAddNewStore = () => {
    setIsAddingNewStore(!isAddingNewStore);
  };

  const handleSaveNewStore = () => {
    setIsAddingNewStore(false);
  };

  const handleCancelNewStore = () => {
    setIsAddingNewStore(false);
  };

  return (
    <div className="online-order-sys-store-container">
      <div className="online-order-store-heade-container">
        <span className="online-order-store-back-btn"><BackButton /></span>
        <button className="online-order-sys-add-new-store-button" onClick={handleAddNewStore}>
          ADD NEW STORE
        </button>
      </div>

      {isAddingNewStore && (
        <div className="online-order-sys-add-new-store-form">
          <div className="online-new-store-header-container">
            <span className="online-new-store-header-title">NEW STORE</span>
          </div>
          <div className="online-store-reference-grp">
            <span className="online-store-reference-id">Store Reference ID: 626183619996</span>
          </div>
          <div className="online-store-new-form-grp">
            <form>
              <div className="online-order-sys-store-form-row">
                <div className="online-order-sys-store-form-group">
                  <label className="online-order-sys-form-label">Name</label>
                  <input className="online-order-sys-input-text" type="text" placeholder="Name" />
                </div>
                <div className="online-order-sys-store-form-group">
                  <label className="online-order-sys-form-label">Address</label>
                  <input className="online-order-sys-input-text" type="text" placeholder="Address" />
                </div>
              </div>

              <div className="online-order-sys-store-form-row">
                <div className="online-order-sys-store-form-group">
                  <label className="online-order-sys-form-label">City</label>
                  <input className="online-order-sys-input-text" type="text" placeholder="City" />
                </div>
                <div className="online-order-sys-store-form-group">
                  <label className="online-order-sys-form-label">Phone Number</label>
                  <input className="online-order-sys-input-text" type="text" placeholder="Phone Number" />
                </div>
              </div>

              <div className="online-order-sys-store-form-row">
                <div className="online-order-sys-store-form-group">
                  <label className="online-order-sys-form-label">Email</label>
                  <input className="online-order-sys-input-text" type="text" placeholder="Email" />
                </div>
                <div className="online-order-sys-store-form-group">
                  <label className="online-order-sys-form-label">
                    User Type <i className="online-order-sys-info-icon">i</i>
                  </label>
                  <select className="online-order-sys-select">
                    <option>-- Select Type --</option>
                  </select>
                </div>
              </div>
              <div className="online-order-sys-store-form-row add-new-store-form-checkoxes">
                <div className="online-order-sys-store-form-group online-order-sys-form-group-hide-name">
                  <label className="form-hide-name-label">
                    Hide Name From UI <i className="online-order-sys-info-icon">i</i>
                  </label>
                  <input className="online-order-sys-form-hide-name-input" type="checkbox" />
                </div>
                <div className="online-active-new-store-form-checkox">
                  <label className="online-order-sys-form-label">Active</label>
                  <input className="online-order-sys-form-hide-name-input" type="checkbox" />
                </div>
              </div>

              <h3>ORDERING DETAILS</h3>
              <div className="online-order-sys-store-form-row">
                <div className="online-order-sys-enable-order">
                  <label className="online-order-sys-form-label">Enabled for Ordering</label>
                  <input className="online-order-sys-form-hide-name-input" type="checkbox" />
                </div>
                <div className="online-order-sys-store-form-group">
                  <label className="online-order-sys-form-label">
                    Zip Codes <span>(Comma separated)</span>
                  </label>
                  <input className="online-order-sys-input-text" type="text" />
                </div>
              </div>
              <div className="online-order-sys-store-form-row">
                <div className="online-order-sys-store-form-group">
                  <label className="online-order-sys-form-label">Latitude</label>
                  <input className="online-order-sys-input-text" type="text" placeholder="Latitude" />
                </div>
                <div className="online-order-sys-store-form-group">
                  <label className="online-order-sys-form-label">Longitude</label>
                  <input className="online-order-sys-input-text" type="text" placeholder="Longitude" />
                </div>
              </div>
              <div className="online-order-sys-store-form-row">
                <div className="online-order-sys-store-form-group">
                  <label className="online-order-sys-form-label">
                    Notification Phone No. <span>(Use , to separate)</span>
                  </label>
                  <input className="online-order-sys-input-text" type="text" />
                </div>
                <div className="online-order-sys-store-form-group">
                  <label className="online-order-sys-form-label">
                    Notification Emails <span>(Use , to separate)</span>
                  </label>
                  <input className="online-order-sys-input-text" type="text" />
                </div>
              </div>

              <div className="online-order-sys-store-form-actions">
                <button className="online-order-new-store-button" type="button" onClick={handleSaveNewStore}>
                  Save
                </button>
                <button  className="online-order-new-store-button" type="button" onClick={handleCancelNewStore}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {!isAddingNewStore && (
        <div className="online-store-details">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Ordering Enabled</th>
                <th>Sync Catalogue</th>
                <th>Credentials</th>
                <th>Active / Inactive</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Chowmein Restaurant</td>
                <td>&#10004;</td>
                <td>Catalogue A</td>
                <td>******</td>
                <td>Active</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Store;
