import React, { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import debounce from "lodash/debounce";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";
import { getToken } from "../SecureStorage/Token";
import ErrorNotification from "../../Notification/ErrorNotification";
import successNotification from "../../Notification/SuccessNotification";

const DoctorBilling = ({ onDoctorSelect }) => {
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showAddDoctorModal, setShowAddDoctorModal] = useState(false);
  const token = getToken();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const handleSearch = async (query) => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/staff_management/staffs/doctors?query=${query}`,
        null,
        token
      );
      setSearchResults(response.data.doctors);
    } catch (error) {
      ErrorNotification("Doctor Not Found");
    }
  };

  const handleDoctorSelect = (doctor) => {
    onDoctorSelect(doctor);
    setSelectedDoctor(doctor.phone);
    setSearchResults([]);
  };

  const handleAddDoctor = async (event) => {
    event.preventDefault();

    const newErrors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (value.trim() === "") {
        newErrors[key] = `${
          key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ")
        } is required`;
      }
    });

    setFormErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));

    if (
      Object.values({ ...formErrors, ...newErrors }).some(
        (error) => error !== ""
      )
    ) {
      return;
    }

    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/staff_management/staffs/create_doctor`,
        { user: formData },
        token,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        successNotification("Doctor Created Successfully!");
        setShowAddDoctorModal(false);
        const newDoctor = response.data.doctor;
        setSearchResults((prevResults) => [newDoctor, ...prevResults]);
        onDoctorSelect(newDoctor);
        setSelectedDoctor(newDoctor.phone);
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          gender: "",
        });
        setFormErrors({});
      }
    } catch (error) {
      console.error("Error:", error);
      ErrorNotification("Failed to create doctor");
    }
  };

  const handleDoctorInputChange = (event) => {
    const { name, value } = event.target;
    let newValue = value;
    let error = "";

    switch (name) {
      case "first_name":
      case "last_name":
        newValue = value.replace(/[^A-Za-z]/g, "").slice(0, 32);
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value.length > 64) {
          newValue = value.slice(0, 64);
        }
        if (value && (!emailRegex.test(value) || value.length > 64)) {
          error = "Please enter a valid email address (up to 64 characters)";
        }
        break;
      case "phone":
        newValue = value.replace(/\D/g, "").slice(0, 10);
        if (newValue.length > 0 && !/^[6-9]\d{9}$/.test(newValue)) {
          error = "Please enter a valid 10-digit phone number";
        }
        break;
      default:
        break;
    }

    setFormData((prev) => ({ ...prev, [name]: newValue }));
    setFormErrors((prev) => ({ ...prev, [name]: error }));
  };

  const handleCloseModal = () => {
    setShowAddDoctorModal(false);
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      gender: "",
    });
    setFormErrors({});
  };

  return (
    <div className="Apps">
      <SearchableDropdownDoctor
        options={searchResults}
        onSearch={handleSearch}
        label="phone"
        selectedVal={selectedDoctor}
        handleChange={setSelectedDoctor}
        onDoctorSelection={handleDoctorSelect}
        setSearchResults={setSearchResults}
        onAddDoctorClick={() => setShowAddDoctorModal(true)}
      />
      <Modal show={showAddDoctorModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Doctor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddDoctor}>
            <Form.Group as={Row} controlId="formFirstName">
              <Form.Label column sm={4}>
                First Name
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleDoctorInputChange}
                  isInvalid={!!formErrors.first_name}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.first_name}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formLastName">
              <Form.Label column sm={4}>
                Last Name
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleDoctorInputChange}
                  isInvalid={!!formErrors.last_name}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.last_name}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formEmail">
              <Form.Label column sm={4}>
                Email
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleDoctorInputChange}
                  isInvalid={!!formErrors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.email}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPhone">
              <Form.Label column sm={4}>
                Phone
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleDoctorInputChange}
                  isInvalid={!!formErrors.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.phone}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formGender">
              <Form.Label column sm={4}>
                Gender
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  as="select"
                  name="gender"
                  value={formData.gender}
                  onChange={handleDoctorInputChange}
                  isInvalid={!!formErrors.gender}
                >
                  <option value="">Select Gender*</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </Form.Control>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "74%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none",
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.gender}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <div className="d-flex justify-content-center mt-3">
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <div style={{ width: "15px" }} />
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const SearchableDropdownDoctor = ({
  options,
  label,
  selectedVal,
  handleChange,
  onSearch,
  onDoctorSelection,
  setSearchResults,
  onAddDoctorClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");

  const debouncedSearch = useCallback(
    debounce((inputValue) => {
      if (inputValue.length >= 2) {
        onSearch(inputValue);
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }, 500),
    []
  );

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
    handleChange(inputValue);
    debouncedSearch(inputValue);
  };

  return (
    <div className="dropdown">
      <div className="col-md-14 input-search-bill">
        <input
          type="text"
          value={query || selectedVal}
          name="searchTerm"
          placeholder="Search By Doctor Email Or Phone"
          className="form-control"
          onChange={handleInputChange}
          onFocus={() => {
            if (query.length >= 2) {
              setIsOpen(true);
            }
          }}
          autoComplete="off"
        />
        <div className="search-icon text-black-50 bg-white ps-1 me-2">
          <FontAwesomeIcon icon={faSearch} />
        </div>
      </div>
      {isOpen && options.length === 0 && (
        <div className="no-results">
          <div>No results found</div>
          <Button variant="link" onClick={onAddDoctorClick} className="mt-2">
            Add Doctor
          </Button>
        </div>
      )}
      {isOpen && options.length > 0 && (
        <div className="options open">
          {options.map((option, index) => (
            <div
              onClick={() => {
                setQuery(option.phone || option.email);
                onDoctorSelection(option);
                handleChange(option.phone || option.email);
                setIsOpen(false);
              }}
              className={`option ${
                option[label] === selectedVal ? "selected" : ""
              }`}
              key={index}
            >
              <div>
                {option.first_name} {option.last_name}
                {option.email && option.phone ? ", " : ""}
                {option.email}
                {option.phone ? ", " : ""}
                {option.phone}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DoctorBilling;
