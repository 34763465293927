import React, { useEffect, useState } from "react";
import "./CommonSupplierPopup.css";
import Select from 'react-select';
import { BASE_URL } from "../../Url/constants";
import { sendRequest } from "../../ApiRequest";
import successNotification from "../../Notification/SuccessNotification";

function CommonSupplierPopup({ onClose, supplierDetails, selectedSupplier }) {
  const [isFirstName, setFirstName] = useState(supplierDetails?.first_name ? supplierDetails?.first_name : '');
  const [isLastName, setLastName] = useState(supplierDetails?.last_name ? supplierDetails?.last_name : '');
  const [isPhone, setPhone] = useState(supplierDetails?.phone_number ? supplierDetails?.phone_number : '');
  const [isemail, setEmail] = useState(supplierDetails?.email ? supplierDetails?.email : '');
  const [isCompanyName, setCompanyName] = useState(supplierDetails?.supp_company ? supplierDetails?.supp_company : '');
  const [isGstNumber, setGstNumber] = useState(supplierDetails?.gst_number ? supplierDetails?.gst_number : '');
  const [isPanNumber, setPanNumber] = useState(supplierDetails?.pan_number ? supplierDetails?.pan_number : '');
  const [isNote, setNote] = useState(supplierDetails?.notes ? supplierDetails?.notes : '');
  const [isTermsConditions, setTermsConditions] = useState(supplierDetails?.terms_and_conditions ? supplierDetails?.terms_and_conditions : '');
  const [isAccountNumber, setAccountNumber] = useState(supplierDetails?.bank_account?.account_number ? supplierDetails?.bank_account?.account_number : '');
  const [isAccountType, setAccountType] = useState(supplierDetails?.bank_account?.account_type ? supplierDetails?.bank_account?.account_type : '');
  const [isBankName, setBankName] = useState(supplierDetails?.bank_account?.bank_name ? supplierDetails?.bank_account?.bank_name : '');
  const [isIfscCode, setIfscCode] = useState(supplierDetails?.bank_account?.ifsc_code ? supplierDetails?.bank_account?.ifsc_code : '');
  const [isAccountName, setAccountName] = useState(supplierDetails?.bank_account?.account_name ? supplierDetails?.bank_account?.account_name : '');
  const [isBranchName, setBranchName] = useState(supplierDetails?.bank_account?.branch_name ? supplierDetails?.bank_account?.branch_name : '');
  const [supplierActiveTab, setSupplierActiveTab] = useState('address');
  const [supBillingAddress, setSupBillingAddress] = useState({
    id: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    postal_code: '',
    country: 'India',
    state: 'Madhya Pradesh',
    address_type: "billing",
  });

  const [supShippingAddress, setSupShippingAddress] = useState({
    id: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    postal_code: '',
    phone: '',
    shipping_gstin: '',
    country: 'India',
    state: 'Madhya Pradesh',
    address_type: "shipping",
  });

  const [selectedSupBillingState, setSelectedSupBillingState] = useState({
    value: "Madhya Pradesh",
    label: "Madhya Pradesh"
  });

  const [selectedSupShippingState, setSelectedSupShippingState] = useState({
    value: "Madhya Pradesh",
    label: "Madhya Pradesh"
  });

  const handleSupBillingStateChange = (selectedOption) => {
    setSelectedSupBillingState(selectedOption);
  };

  const handleSupShippingStateChange = (selectedOption) => {
    setSelectedSupShippingState(selectedOption);
  };

  useEffect(() => {
    if (supplierDetails?.addresses && (supplierDetails?.addresses?.length > 0)) {
      const billing = supplierDetails?.addresses.find(addr => addr.address_type === 'billing');
      if (billing) {
        setSupBillingAddress({
          ...billing,
          country: billing.country || 'India',
          state: billing.state || 'Madhya Pradesh',
        });
        setSelectedSupBillingState({
          value: billing.state || 'Madhya Pradesh',
          label: billing.state || 'Madhya Pradesh'
        });
      }
      const shipping = supplierDetails.addresses.find(addr => addr.address_type === 'shipping');
      if (shipping) {
        setSupShippingAddress({
          ...shipping,
          country: shipping.country || 'India',
          state: shipping.state || 'Madhya Pradesh',
        });
        setSelectedSupBillingState({
          value: shipping.state || 'Madhya Pradesh',
          label: shipping.state || 'Madhya Pradesh'
        });
      }
    }
  }, [supplierDetails]);

  const indianStates = [
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Meghalaya', label: 'Meghalaya' },
    { value: 'Mizoram', label: 'Mizoram' },
    { value: 'Nagaland', label: 'Nagaland' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'West Bengal', label: 'West Bengal' },
    { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'Dadra and Nagar Haveli and Daman and Diu' },
    { value: 'Lakshadweep', label: 'Lakshadweep' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Puducherry', label: 'Puducherry' },
    { value: 'Ladakh', label: 'Ladakh' },
    { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' }
  ];

  const commonSupplierStyle = {
    menu: (provided) => ({
      ...provided,
      maxHeight: 150,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 150,
    }),
  };

  const supplierBankId = supplierDetails ? supplierDetails?.bank_account?.id : '';

  const handleSupplierSave = async () => {
    try {
      const formData = new FormData();
      formData.append("supplier[supp_company]", isCompanyName);
      formData.append("supplier[first_name]", isFirstName);
      formData.append("supplier[last_name]", isLastName);
      formData.append("supplier[phone_number]", isPhone);
      formData.append("supplier[email]", isemail);
      formData.append("supplier[gst_number]", isGstNumber);
      formData.append("supplier[pan_number]", isPanNumber);
      formData.append("supplier[notes]", isNote);
      formData.append("supplier[terms_and_conditions]", isTermsConditions);

      formData.append("supplier[addresses_attributes][0][address_line_1]", supBillingAddress.address_line_1);
      formData.append("supplier[addresses_attributes][0][address_line_2]", supBillingAddress.address_line_2);
      formData.append("supplier[addresses_attributes][0][city]", supBillingAddress.city);
      formData.append("supplier[addresses_attributes][0][postal_code]", supBillingAddress.postal_code);
      formData.append("supplier[addresses_attributes][0][country]", supBillingAddress.country);
      formData.append("supplier[addresses_attributes][0][state]", supBillingAddress.state);
      formData.append("supplier[addresses_attributes][0][address_type]", supBillingAddress.address_type);

      formData.append("supplier[addresses_attributes][1][address_line_1]", supShippingAddress.address_line_1);
      formData.append("supplier[addresses_attributes][1][address_line_2]", supShippingAddress.address_line_2);
      formData.append("supplier[addresses_attributes][1][city]", supShippingAddress.city);
      formData.append("supplier[addresses_attributes][1][postal_code]", supShippingAddress.postal_code);
      formData.append("supplier[addresses_attributes][1][phone]", supShippingAddress.phone);
      formData.append("supplier[addresses_attributes][1][shipping_gstin]", supShippingAddress.shipping_gstin);
      formData.append("supplier[addresses_attributes][1][country]", supShippingAddress.country);
      formData.append("supplier[addresses_attributes][1][state]", supShippingAddress.state);
      formData.append("supplier[addresses_attributes][1][address_type]", supShippingAddress.address_type);
      formData.append("supplier[bank_account_attributes][account_number]", isAccountNumber);
      formData.append("supplier[bank_account_attributes][account_type]", isAccountType);
      formData.append("supplier[bank_account_attributes][bank_name]", isBankName);
      formData.append("supplier[bank_account_attributes][ifsc_code]", isIfscCode);
      formData.append("supplier[bank_account_attributes][account_name]", isAccountName);
      formData.append("supplier[bank_account_attributes][branch_name]", isBranchName);
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/onboarding/suppliers`,
        formData
      );
      successNotification("Supplier created!");
      selectedSupplier(response?.data?.data);
      onClose();
    } catch (error) {
      console.log("Supplier not created!")
    }
  };

  const handleSupplierUpdate = async (supplierDetailId) => {
    if (supplierDetailId) {
      try {
        const formData = new FormData();
        formData.append("supplier[supp_company]", isCompanyName);
        formData.append("supplier[first_name]", isFirstName);
        formData.append("supplier[last_name]", isLastName);
        formData.append("supplier[phone_number]", isPhone);
        formData.append("supplier[email]", isemail);
        formData.append("supplier[gst_number]", isGstNumber);
        formData.append("supplier[pan_number]", isPanNumber);
        formData.append("supplier[notes]", isNote);
        formData.append("supplier[terms_and_conditions]", isTermsConditions);

        formData.append("supplier[addresses_attributes][0][id]", supBillingAddress.id);
        formData.append("supplier[addresses_attributes][0][address_line_1]", supBillingAddress.address_line_1);
        formData.append("supplier[addresses_attributes][0][address_line_2]", supBillingAddress.address_line_2);
        formData.append("supplier[addresses_attributes][0][city]", supBillingAddress.city);
        formData.append("supplier[addresses_attributes][0][postal_code]", supBillingAddress.postal_code);
        formData.append("supplier[addresses_attributes][0][country]", supBillingAddress.country);
        formData.append("supplier[addresses_attributes][0][state]", supBillingAddress.state);
        formData.append("supplier[addresses_attributes][0][address_type]", supBillingAddress.address_type);

        formData.append("supplier[addresses_attributes][1][id]", supShippingAddress.id);
        formData.append("supplier[addresses_attributes][1][address_line_1]", supShippingAddress.address_line_1);
        formData.append("supplier[addresses_attributes][1][address_line_2]", supShippingAddress.address_line_2);
        formData.append("supplier[addresses_attributes][1][city]", supShippingAddress.city);
        formData.append("supplier[addresses_attributes][1][postal_code]", supShippingAddress.postal_code);
        formData.append("supplier[addresses_attributes][1][phone]", supShippingAddress.phone);
        formData.append("supplier[addresses_attributes][1][shipping_gstin]", supShippingAddress.shipping_gstin);
        formData.append("supplier[addresses_attributes][1][country]", supShippingAddress.country);
        formData.append("supplier[addresses_attributes][1][state]", supShippingAddress.state);
        formData.append("supplier[addresses_attributes][1][address_type]", supShippingAddress.address_type);
        if (supplierBankId) {
          formData.append("supplier[bank_account_attributes][id]", supplierBankId);
          formData.append("supplier[bank_account_attributes][account_number]", isAccountNumber);
          formData.append("supplier[bank_account_attributes][account_type]", isAccountType);
          formData.append("supplier[bank_account_attributes][bank_name]", isBankName);
          formData.append("supplier[bank_account_attributes][ifsc_code]", isIfscCode);
          formData.append("supplier[bank_account_attributes][account_name]", isAccountName);
          formData.append("supplier[bank_account_attributes][branch_name]", isBranchName);
        }
        await sendRequest(
          "PUT",
          `${BASE_URL}/v1/onboarding/suppliers/${supplierDetailId}`,
          formData
        );
        successNotification("Supplier update!");
        onClose();
      } catch (error) {
        console.log("Supplier not Updated!")
      }
    }
    else {
      console.log("Failed to update supplier.")
    }
  };

  const renderFormContent = () => {
    switch (supplierActiveTab) {
      case 'address':
        return (
          <div className="supplier-address-form">
            <span className="supplier-billing-title">Billing Address</span>
            <div className="supplier-address-form-subcontainer">
              <div className="supplier-add-input-first-grp">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address1"
                  value={supBillingAddress?.address_line_1}
                  onChange={(e) => setSupBillingAddress({ ...supBillingAddress, address_line_1: e.target.value })}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address2"
                  value={supBillingAddress?.address_line_2}
                  onChange={(e) => setSupBillingAddress({ ...supBillingAddress, address_line_2: e.target.value })}
                />
              </div>
              <div className="supplier-address-input-row-second">
                <input
                  type="text"
                  className="form-control"
                  placeholder="City / Town"
                  value={supBillingAddress?.city}
                  onChange={(e) => setSupBillingAddress({ ...supBillingAddress, city: e.target.value })}
                />
                <input
                  type="number"
                  className="form-control"
                  placeholder="Postal / Zip code"
                  value={supBillingAddress?.postal_code}
                  onChange={(e) => setSupBillingAddress({ ...supBillingAddress, postal_code: e.target.value })}
                />
              </div>
              <div className="supplier-address-input-row-third">
                <select
                  className="form-select supplier-country-select"
                  value={supBillingAddress?.country}
                  onChange={(e) => setSupBillingAddress({ ...supBillingAddress, country: e.target.value })}
                >
                  <option value="India">India</option>
                </select>
                <Select
                  options={indianStates}
                  value={selectedSupBillingState}
                  onChange={handleSupBillingStateChange}
                  placeholder="Select a state"
                  styles={commonSupplierStyle}
                  defaultValue={selectedSupBillingState}
                  isSearchable
                />
              </div>
            </div>
          </div>
        );
      case 'shipping':
        return (
          <div className="supplier-shipping-form">
            <span className="supplier-shipping-title">Shipping Address</span>
            <div className="supplier-shipping-form-subcontainer">
              <div className="supplier-shipping-input-first-grp">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address1"
                  value={supShippingAddress?.address_line_1}
                  onChange={(e) => setSupShippingAddress({ ...supShippingAddress, address_line_1: e.target.value })}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address2"
                  value={supShippingAddress?.address_line_2}
                  onChange={(e) => setSupShippingAddress({ ...supShippingAddress, address_line_2: e.target.value })}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Shipping GSTIN"
                  value={supShippingAddress?.shipping_gstin}
                  onChange={(e) => setSupShippingAddress({ ...supShippingAddress, shipping_gstin: e.target.value })}
                />
                <input
                  type="number"
                  className="form-control"
                  placeholder="Phone"
                  value={supShippingAddress?.phone}
                  onChange={(e) => setSupShippingAddress({ ...supShippingAddress, phone: e.target.value })}
                />
              </div>
              <div className="supplier-shipping-input-row-second">
                <input
                  type="text"
                  className="form-control"
                  placeholder="City / Town"
                  value={supShippingAddress?.city}
                  onChange={(e) => setSupShippingAddress({ ...supShippingAddress, city: e.target.value })}
                />
                <input
                  type="number"
                  className="form-control"
                  placeholder="Postal / Zip code"
                  value={supShippingAddress?.postal_code}
                  onChange={(e) => setSupShippingAddress({ ...supShippingAddress, postal_code: e.target.value })}
                />
              </div>
              <div className="supplier-shipping-input-row-third">
                <select
                  className="form-select supplier-country-select"
                  value={supShippingAddress?.country}
                  onChange={(e) => setSupShippingAddress({ ...supShippingAddress, country: e.target.value })}
                >
                  <option value="India">India</option>
                </select>
                <Select
                  options={indianStates}
                  value={selectedSupShippingState}
                  onChange={handleSupShippingStateChange}
                  placeholder="Select a state"
                  styles={commonSupplierStyle}
                  defaultValue={selectedSupShippingState}
                  isSearchable
                />
              </div>
            </div>
          </div>
        );
      case 'bank':
        return (
          <div className="bank-details-form">
            {/* <span>Bank Details</span> */}
            <div className="supplier-bank-detail-form">
              <div className="supplier-bank-div-grp">
                <span>Account Name</span>
                <input type="text" className="form-control supplier-input-div-field" placeholder="Account Name"
                  onChange={(e) => setAccountName(e.target.value)}
                  value={isAccountName}
                />
              </div>
            </div>
            <div className="supplier-bank-detail-form">
              <div className="supplier-bank-div-grp">
                <span>Account Number</span>
                <input type="text" className="form-control supplier-input-div-field" placeholder="Account Number"
                  onChange={(e) => setAccountNumber(e.target.value)}
                  value={isAccountNumber}
                />
              </div>
            </div>
            <div className="supplier-bank-detail-form">
              <div className="supplier-bank-div-grp">
                <span>Account Type (Savings / Current)</span>
                <input type="text" className="form-control supplier-input-div-field" placeholder="Account Type"
                  onChange={(e) => setAccountType(e.target.value)}
                  value={isAccountType}
                />
              </div>
            </div>
            <div className="supplier-bank-detail-form">
              <div className="supplier-bank-div-grp">
                <span>Bank Name</span>
                <input type="text" className="form-control supplier-input-div-field" placeholder="Bank Name"
                  onChange={(e) => setBankName(e.target.value)}
                  value={isBankName}
                />
              </div>
            </div>
            <div className="supplier-bank-detail-form">
              <div className="supplier-bank-div-grp">
                <span>Bank Branch</span>
                <input type="text" className="form-control supplier-input-div-field" placeholder="Bank Branch"
                  onChange={(e) => setBranchName(e.target.value)}
                  value={isBranchName}
                />
              </div>
            </div>
            <div className="supplier-bank-detail-form">
              <div className="supplier-bank-div-grp">
                <span>IFSC Code</span>
                <input type="text" className="form-control supplier-input-div-field" placeholder="IFSC Code"
                  onChange={(e) => setIfscCode(e.target.value)}
                  value={isIfscCode}
                />
              </div>
            </div>
            {/* <div className="supplier-bank-detail-form">
              <div className="supplier-bank-div-grp">
                <span>Swift Code</span>
                <input type="text" className="form-control supplier-input-div-field" placeholder="Swift Code" />
              </div>
            </div>
            <div className="supplier-bank-detail-form">
              <div className="supplier-bank-div-grp">
                <span>Correspondent Bank</span>
                <textarea className="form-control supplier-account-textarea" placeholder="Correspondent Bank">
                </textarea>
              </div>
            </div>
            <div className="supplier-bank-detail-form">
              <div className="supplier-bank-div-grp">
                <span>Authorised Dealer Code</span>
                <input type="text" className="form-control supplier-input-div-field" placeholder="Authorised Dealer Code" />
              </div>
            </div> */}
          </div>
        );
      case 'default':
        return (
          <div className="supplier-default-form">
            <div className="supplier-default-div-grp">
              <span>Notes</span>
              <textarea className="form-control supplier-default-account-textarea"
                placeholder="Notes"
                type="text"
                onChange={(e) => setNote(e.target.value)}
                value={isNote}
              >
              </textarea>
            </div>
            <div className="supplier-default-second-div-grp">
              <span>Terms and Conditions</span>
              <textarea className="form-control supplier-default-account-textarea"
                placeholder="Terms and Conditions"
                type="text"
                onChange={(e) => setTermsConditions(e.target.value)}
                value={isTermsConditions}
              >
              </textarea>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="common-supplier-main-container">
      <div className="common-supplier-header-content">
        <span className="common-supplier-title">Supplier</span>
        <div className="common-supplier-btn-grp">
          {
            supplierDetails ? (
              <button className="common-supplier-btn" onClick={() => handleSupplierUpdate(supplierDetails.id)}>Update</button>
            )
              :
              (
                <button className="common-supplier-btn" onClick={handleSupplierSave}>Save</button>
              )
          }
          <button className="common-supplier-btn" onClick={onClose}>Cancel</button>
        </div>
      </div>
      <div className="common-supplier-sub-container">
        <div className="common-supplier-form-grp">
          <div className="common-supplier-form-field-grp">
            <label className="common-supplier-label">Company Name</label>
            <input
              className="form-control common-supplier-label-field"
              type="text"
              onChange={(e) => setCompanyName(e.target.value)}
              value={isCompanyName}
            />
          </div>
          <div className="common-supplier-form-field-grp">
            <label className="common-supplier-label">First Name</label>
            <input
              className="form-control common-supplier-label-field"
              type="text"
              onChange={(e) => setFirstName(e.target.value)}
              value={isFirstName}
            />
          </div>
          <div className="common-supplier-form-field-grp">
            <label className="common-supplier-label">Last Name</label>
            <input
              className="form-control common-supplier-label-field"
              type="text"
              onChange={(e) => setLastName(e.target.value)}
              value={isLastName}
            />
          </div>
          <div className="common-supplier-form-field-grp">
            <label className="common-supplier-label">Phone</label>
            <input
              className="form-control common-supplier-label-field"
              type="text"
              onChange={(e) => setPhone(e.target.value)}
              value={isPhone}
            />
          </div>
          <div className="common-supplier-form-field-grp">
            <label className="common-supplier-label">Email</label>
            <input
              className="form-control common-supplier-label-field"
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              value={isemail}
            />
          </div>
          <div className="common-supplier-form-field-grp">
            <label className="common-supplier-label">GSTIN</label>
            <input
              className="form-control common-supplier-label-field"
              type="text"
              onChange={(e) => setGstNumber(e.target.value)}
              value={isGstNumber}
            />
          </div>
          <div className="common-supplier-form-field-grp">
            <label className="common-supplier-label">Pan No.</label>
            <input
              className="form-control common-supplier-label-field"
              type="text"
              onChange={(e) => setPanNumber(e.target.value)}
              value={isPanNumber}
            />
          </div>
        </div>
        <div className="common-supplierr-second-sub-container" >
          <div className="common-supplier-tab-navigation">
            <button className={supplierActiveTab === 'address' ? 'active' : ''} onClick={() => setSupplierActiveTab('address')}>Address</button>
            <button className={supplierActiveTab === 'shipping' ? 'active' : ''} onClick={() => setSupplierActiveTab('shipping')}>Shipping Address</button>
            <button className={supplierActiveTab === 'bank' ? 'active' : ''} onClick={() => setSupplierActiveTab('bank')}>Bank Details</button>
            <button className={supplierActiveTab === 'default' ? 'active' : ''} onClick={() => setSupplierActiveTab('default')}>Default</button>
          </div>
          {renderFormContent()}
        </div>
      </div>
    </div>
  );
}

export default CommonSupplierPopup;
