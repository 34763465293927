import React, { useState } from "react";
import './RestourentBillings.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faHeart } from "@fortawesome/free-solid-svg-icons";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";

const GroupSubCategoryItem = ({ item, isActive }) => {
  const firstFlag = item.flags?.[0];
  const itemTypeClass = firstFlag === "veg" ? 'veg' : firstFlag === "non-veg" ? 'non-veg' : '';
  const [updateItem, setUpdatedItem] = useState([]);

  const handleFavouriteItem = async () => {
    const response = await sendRequest(
      "PUT",
      `${BASE_URL}/v1/inventory_managment/items/${item.id}`,
      { item: { is_favourite: true } },
    );
    if (response.status === 200) {
      const fav = response.data.data
      setUpdatedItem(fav);
    }
  };

  return (
    <div>
      <div className={`card-content-item-category-kot ${isActive?.length > 0 && isActive?.includes(item.id) ? 'active' : ''}`} >
        <div className="card-content-both">
          {firstFlag === "veg" || firstFlag === "non-veg" ? (
            <span className="item-type">
              <FontAwesomeIcon icon={faCircle} className={`item-kot-type ${itemTypeClass}`} />
            </span>
          ) : (
            ''
          )}
          <span className="item-card-heart-icon">
            <FontAwesomeIcon icon={faHeart} className={updateItem.is_favourite || item.is_favourite ? 'item-heart-icon-red' : ''} onClick={handleFavouriteItem} />
          </span>
        </div>
        <div className="item-kot-name">
          <span className="cat-font-size">{item?.item_name}</span>
        </div>
        <div className="price-of-item-kot">
          <span className="item-price">
            {item?.sale_amount?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GroupSubCategoryItem;
