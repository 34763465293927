import React from 'react';
import { Carousel } from 'react-bootstrap';
import './addmoretemplate.css';

const AddMoreTemplate = () => {
  const ExampleCarouselImage = ({ src, text }) => (
    <img
      className="d-block w-100"
      src={src}
      alt={text}
    />
  );

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <Carousel 
            prevIcon={<span className="carousel-arrow-left"></span>} 
            nextIcon={<span className="carousel-arrow-right"></span>}
          >
            <Carousel.Item>
              <ExampleCarouselImage 
                src="/demoinvoice1.png" 
                text="First slide" 
              />
              <Carousel.Caption>
                {/* <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <ExampleCarouselImage 
                src="/demoinvoice2.png" 
                text="Second slide" 
              />
              <Carousel.Caption>
                {/* <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <ExampleCarouselImage 
                src="/demoinvoice3.png" 
                text="Third slide" 
              />
              <Carousel.Caption>
                {/* <h3>Third slide label</h3>
                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default AddMoreTemplate;
