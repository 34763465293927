import React from "react";
import './RestourentBillings.css'

const GroupSubCategories = ({subcategory, isActive}) => {
  return (
    <div>

      <div className={`card-content-kot-1 ${subcategory.id === isActive ? 'active' : ''}`}>
        <span className="sub-cat-font-size">{subcategory.name}</span>
      </div>
    </div>
  );
};

export default GroupSubCategories;
