import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Collapsible from "react-collapsible";
import AddmoreBranch from "./AddmoreBranch";
import { getToken } from './SecureStorage/Token';
import { BASE_URL } from './../Url/constants';
import "./Accordion.css";
import { useTranslation } from "react-i18next";
import { sendRequest } from "../ApiRequest";


const Accordion = () => {
  const token = getToken();
  const navigate = useNavigate();
  const [data, setData] = useState([{ branch_name: "", address: "", pin_code: "" }]);
  const { t } = useTranslation();

  const handleClick = () => {
    setData([...data, { branch_name: "", address: "", pin_code: "" }]);
  };

  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    const onchangeVal = [...data];
    onchangeVal[i][name] = value;
    setData(onchangeVal);
  };

  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, i);
    setData(deleteVal);
  };

  const [companyData, setCompanyData] = useState({
    name: "",
    gst_number: "",
    logo: null,
    business_type: "",
    business_category: "",
  });

  const [ownerData, setOwnerData] = useState({
    name: "",
    user_name: "",
    email: "",
    phone: "",
  });
  // const [branchData, setBranchData] = useState({
  //   branch_name: "",
  //   address: "",
  //   city: "",
  //   state: "",
  //   pin_code: "",
  // });

  const handleCompanyChange = (e) => {
    setCompanyData({
      ...companyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogoChange = (e) => {
    setCompanyData({
      ...companyData,
      [e.target.name]: e.target.files[0],
    });
  };


  const handleOwnerChange = (e) => {
    setOwnerData({
      ...ownerData,
      [e.target.name]: e.target.value,
    });
  };

  const branchesAttributes = {};
  data.forEach((branch, index) => {
    branchesAttributes[index] = {
      ...branch,
      city: "City",  // Add default city
      state: "State",  // Add default state
      branch_code: "Branch_Code"  // Add default branch code
    };
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    formData.append("company[name]", companyData.name);
    formData.append("company[gst_number]", companyData.gst_number);
    formData.append("company[logo]", companyData.logo);
    formData.append("company[business_type]", companyData.business_type);
    formData.append("company[business_category]",companyData.business_category);

    formData.append("company[users_attributes][0][email]", ownerData.email);
    formData.append("company[users_attributes][0][name]", ownerData.name);
    formData.append("company[users_attributes][0][phone]", ownerData.phone);



    data.forEach(function(branch, index) {
      // Append each branch as a separate parameter
      formData.append(`company[branches_attributes][${index}][branch_name]`, branch.branch_name);
      formData.append(`company[branches_attributes][${index}][address]`, branch.address);
      formData.append(`company[branches_attributes][${index}][pin_code]`, branch.pin_code);
    });


    console.log("data====",data)
    try {
      const response = await sendRequest('POST',
        `${BASE_URL}/v1/onboarding/companies`,
        formData,
      );
        navigate('/dashboard');
      }
    catch (error) {
        // ErrorNotification(error.message)
        // Handle error case
      }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Collapsible trigger={"User Information"} open={true}>
          <div className="create-company">
            <div className="row">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Full Name")}
                  value={ownerData.name}
                  onChange={handleOwnerChange}
                  name="name"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("User Name")}
                  value={ownerData.user_name}
                  onChange={handleOwnerChange}
                  name="user_name"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Email ID")}
                  value={ownerData.email}
                  onChange={handleOwnerChange}
                  name="email"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Phone No")}
                  value={ownerData.phone}
                  onChange={handleOwnerChange}
                  name="phone"
                />
              </div>
            </div>
          </div>
        </Collapsible>

        <Collapsible trigger={"Company Details"}>
          <div className="create-company">
            <div className="row">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Company Name")}
                  value={companyData.name}
                  onChange={handleCompanyChange}
                  name="name"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("GST Number")}
                  value={companyData.gst_number}
                  onChange={handleCompanyChange}
                  name="gst_number"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="file"
                  className="form-control upload-img"
                  placeholder={t("Upload Logo")}
                  onChange={handleLogoChange}
                  name="logo"
                />
                <label className="note">{t("Upload Item Image")}</label>
              </div>
              <div className="col-md-6">
                <select
                  name="business_type"
                  className="form-control business-type-field"
                  placeholder={t("Business Type")}
                  value={companyData.business_type}
                  onChange={handleCompanyChange}
                >
                  <option value="">{t("Select Business Type")}</option>
                  <option value="retail">{t("Retail")}</option>
                  <option value="wholesale">{t("Wholesale")}</option>
                  <option value="distributor">{t("Distributor")}</option>
                  <option value="service">{t("Service")}</option>
                  <option value="manufacturing">{t("Manufacturing")}</option>
                  <option value="others">{t("Others")}</option>
                </select>
              </div>
              <div className="col-md-12">
                <select
                  name="business_category"
                  className="form-control"
                  placeholder={t("Business Category")}
                  value={companyData.business_category}
                  onChange={handleCompanyChange}
                >
                  <option value="">{t("Select Business Category")}</option>
                  <option value="accounting_and_ca">{t("Accounting & CA")}</option>
                  <option value="interior_designer">{t("Interior Designer")}</option>
                  <option value="saloon_and_spa">{t("Saloon And Spa")}</option>
                  <option value="liquor_store">{t("Liquor Store")}</option>
                  <option value="stationary_store">{t("Stationary Store")}</option>
                  <option value="construction_material">
                    {t("Construction Material")}
                  </option>
                  <option value="repairing_plumbing">{t("Repairing Plumbing")}</option>
                  <option value="chemical_and_fertilizer">
                    {t("Chemical And Fertilizer")}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </Collapsible>

        <Collapsible trigger={"Branch Details"}>
          <div className="create-company">
            <div className="row">
              <AddmoreBranch data={data} handleClick={handleClick} handleChange={handleChange} handleDelete={handleDelete}/>
            </div>
          </div>
        </Collapsible>
        <div className="col-md-12 mt-20 text-right">
            <button type="submit" className="btn btn-primary">{t("Submit")}</button>
        </div>
      </form>
    </>
  );
};

export default Accordion;
