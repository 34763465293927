import React, { useEffect, useCallback, useRef, useState } from "react";
import { sendRequest } from "../ApiRequest";
import { INPUT_PLACEHOLDER } from "../Url/constants";
import { BASE_URL } from "../Url/constants";
import { getToken } from "./SecureStorage/Token";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import successNotification from "../Notification/SuccessNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import BillingCustomer from "./BillingCustomer";
import "./BillingCustomer.css";
import { ImAccessibility } from "react-icons/im";
import { Button, Dropdown, Nav } from 'react-bootstrap';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import debounce from 'lodash/debounce';


const GetCustomer = ({ onCustomerSelect, onClose }) => {
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const token = getToken();
  const [isBillingCustomer, setBillingCustomer] = useState(false);
  const [isPreviewEnabled, setIspreviewEnabled] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: ''
  });

  const validateField = (name, value) => {
    switch (name) {
      case 'first_name':
      case 'last_name':
        return value.length > 64 ? "Maximum 64 characters allowed" : "";
      case 'email':
        if (value) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(value)) {
            return "Please enter a valid email address";
          }
          if (value.length > 128) {
            return "Email must not exceed 128 characters";
          }
        }
        return "";
      case 'phone':
        const phoneRegex = /^[6-9]\d{9}$/;
        if (!value) {
          return "Phone number is required";
        }
        if (!phoneRegex.test(value)) {
          return "Please enter a valid 10-digit phone number starting with 6-9";
        }
        return "";
      default:
        return "";
    }
  };

  const handleCustomerInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    switch (name) {
      case 'first_name':
      case 'last_name':
        newValue = value.replace(/[^A-Za-z\s]/g, '').slice(0, 64);
        break;
      case 'phone':
        newValue = value.replace(/\D/g, '').slice(0, 10);
        break;
      case 'email':
        newValue = value.slice(0, 128);
        break;
      default:
        break;
    }

    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));

    const error = validateField(name, newValue);
    setFormErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const handleMouseEnter = () => {
    setShowMessage(true);
  };

  const handleMouseLeave = () => {
    setShowMessage(false);
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleSearch = async (query) => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/onboarding/customers/customer_search?query=${query}`,
        null,
        token
      );
      setSearchResults(response.data);
    } catch (error) {
      ErrorNotification("Customer Not Found");
    }
  };

  const handleCustomerSelect = (customer) => {
    if (customer.data) {
      onCustomerSelect(customer.data);
      setSelectedCustomer(customer.data.phone);
    } else {
      onCustomerSelect(customer);
      setSelectedCustomer(customer.phone);
    }
    setSearchResults([]);
  };

  const openBillingCustomer = () => {
    setBillingCustomer(true);
  };

  const closeBillingCustomer = () => {
    setBillingCustomer(false);
  };

  const fetchSettings = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/setting/settings/billing_settings`
      );
      const billSettings = response.data.data;

      const previewSetting = billSettings.find(
        (setting) => setting.name === "customer_create"
      );
      if (previewSetting && previewSetting.billing_setting_config?.status) {
        setIspreviewEnabled(true);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const handleAddCustomer = async (event) => {
    event.preventDefault();
    
    const newErrors = {};
    Object.keys(formData).forEach(field => {
      const error = validateField(field, formData[field]);
      if (error) {
        newErrors[field] = error;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setFormErrors(newErrors);
      return;
    }

    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/onboarding/customers`,
        { customer: formData },
        token,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        successNotification("Customer Created Successfully!");
        setShowAddCustomerModal(false);
        const newCustomer = response.data;
        setSearchResults(prevResults => [newCustomer, ...prevResults]);
        onCustomerSelect(newCustomer);
        setSelectedCustomer(newCustomer.phone);
        
        setFormData({
          first_name: '',
          last_name: '',
          email: '',
          phone: ''
        });
        setFormErrors({});
      }
    } catch (error) {
      console.error("Error:", error);
      ErrorNotification("Failed to create customer");
    }
  };



  return (
    <div className="Apps">
      <SearchableDropdownCustomer
        options={searchResults}
        onSearch={handleSearch}
        label="phone"
        selectedVal={selectedCustomer}
        handleChange={setSelectedCustomer}
        onCustomerSelection={handleCustomerSelect}
        setSearchResults={setSearchResults}
        onAddCustomerClick={() => setShowAddCustomerModal(true)}
      />
      {isPreviewEnabled && (
        <>
          <span className="billing-get-customer">
            <ImAccessibility
              onClick={openBillingCustomer}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </span>
          {showMessage && <div className="hover-message">Create customer</div>}
          {isBillingCustomer && (
            <div className="billing-customer-popup-main">
              <BillingCustomer onClose={closeBillingCustomer} />
            </div>
          )}
        </>
      )}
      <div className="d-flex justify-content-center">
        <Modal show={showAddCustomerModal} onHide={() => setShowAddCustomerModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleAddCustomer}>
              <Form.Group as={Row} controlId="formFirstName">
                <Form.Label column sm={4}>First Name</Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleCustomerInputChange}
                    isInvalid={!!formErrors.first_name}
                  />
                  {formErrors.first_name && 
                    <div className="invalid-feedback">{formErrors.first_name}</div>
                  }
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formLastName">
                <Form.Label column sm={4}>Last Name</Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleCustomerInputChange}
                    isInvalid={!!formErrors.last_name}
                  />
                  {formErrors.last_name && 
                    <div className="invalid-feedback">{formErrors.last_name}</div>
                  }
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formEmail">
                <Form.Label column sm={4}>Email</Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleCustomerInputChange}
                    isInvalid={!!formErrors.email}
                  />
                  {formErrors.email && 
                    <div className="invalid-feedback">{formErrors.email}</div>
                  }
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPhone">
                <Form.Label column sm={4}>Phone *</Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleCustomerInputChange}
                    isInvalid={!!formErrors.phone}
                    required
                  />
                  {formErrors.phone && 
                    <div className="invalid-feedback">{formErrors.phone}</div>
                  }
                </Col>
              </Form.Group>

              <div className="d-flex justify-content-center mt-3">
                <Button
                  variant="secondary"
                  onClick={() => setShowAddCustomerModal(false)}
                >
                  Cancel
                </Button>
                <div style={{ width: '15px' }} />
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!!formErrors.phone}
                >
                  Save
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

const SearchableDropdownCustomer = ({
  options,
  label,
  selectedVal,
  handleChange,
  onSearch,
  onCustomerSelection,
  setSearchResults,
  onAddCustomerClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const inputRef = useRef(null);
  const [errorShown, setErrorShown] = useState(false);

  // Debounced search function to avoid excessive requests
  const debouncedSearch = useCallback(
    debounce((inputValue) => {
      if (inputValue.length >= 2) {
        onSearch(inputValue);
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }, 500),
    []
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getDisplayValue = () => {
    return query || selectedVal;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
    handleChange(inputValue);

    // Use debounced search function
    debouncedSearch(inputValue);
  };

  const handleEnter = async (event) => {
    if (event.key === "Enter" && query.trim() !== "") {
      let params = {};

      if (query.includes("@") && query.includes(".")) {
        params = { customer: { email: query } };
      } else if (/^[6-9]\d{9}$/.test(query)) {
        params = { customer: { phone: query } };
      } else {
        if (!errorShown) {
          ErrorNotification("Please enter a valid email address or 10-digit phone number");
          setErrorShown(true);
        }
        return;
      }

      try {
        const response = await sendRequest(
          "POST",
          `${BASE_URL}/v1/onboarding/customers`,
          params,
          getToken(),
          { headers: { "Content-Type": "application/json" } }
        );

        if (response.status === 200) {
          successNotification("Customer Created Successfully!");
          setSearchResults((prevResults) => [response.data, ...prevResults]);
          onCustomerSelection(response.data);
          setQuery(response.data.phone || response.data.email);
          setIsOpen(false);
        }
      } catch (error) {
        console.error("Error:", error);
        ErrorNotification("Failed to create customer");
      }
    }
  };

  return (
    <div className="dropdown">
      <div className="col-md-14 input-search-bill">
        <input
          ref={inputRef}
          type="text"
          value={getDisplayValue()}
          name="searchTerm"
          placeholder={INPUT_PLACEHOLDER()}
          className="form-control"
          onChange={handleInputChange}
          onKeyPress={handleEnter}
          onFocus={() => {
            if (query.length >= 2) {
              setIsOpen(true);
            }
          }}
          autoComplete="off"
        />
        <div className="search-icon text-black-50 bg-white ps-1 me-2">
          <FontAwesomeIcon icon={faSearch} />
        </div>
      </div>
      <Dropdown.Menu show={isOpen && options.length === 0}>
        {isOpen && options.length === 0 && (
          <div className="no-results">
            <div>No results found</div>
            <Nav.Link href="#" onClick={onAddCustomerClick} className="mt-2">
              Add Customer
            </Nav.Link>
          </div>
        )}
      </Dropdown.Menu>

      {isOpen && options.length > 0 && (
        <div className="options open">
          {options.map((option, index) => (
            <div
              onClick={() => {
                setQuery(option.phone || option.email);
                onCustomerSelection(option);
                handleChange(option.phone || option.email);
                setIsOpen(false);
              }}
              className={`option ${option[label] === selectedVal ? "selected" : ""}`}
              key={index}
            >
              <div>
                {option.first_name} {option.last_name} {/* Display first and last names */}
                {option.email && option.phone ? ", " : ""}
                {option.email}
                {option.phone ? ", " : ""}
                {option.phone}
              </div>
            </div>
          ))}
        </div>
      )}
      <NotificationContainer />
    </div>
  );
};


export default GetCustomer;
