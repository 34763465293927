import React from "react";
import './receiptsecond.css'

function ReceiptSecond({ enabledTemplateData }) {
  const getOption = (optionName) => {
    return enabledTemplateData.find((opt) => opt.option_name === optionName) || {};
  };

  const getAlignment = (optionName) => {
    const option = getOption(optionName);
    return option.alignment || 'center'; // Default to 'center' if alignment is not defined
  };

  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-IN');
  const formattedTime = today.toLocaleTimeString('en-IN');
  

  return (
    <>
      <div className="second-receipt-main">
        <div className="second-receipt-sub">
          <div className="second-receipt-sub-header">
            .......................................CASH MEMO.................................
          </div>
          <div className="receipt-date-time">
            <div className="receipt-date">
              {getOption('date').is_enable && (
                `Date: ${formattedDate}`
              )}
            </div>
            <div className="receipt-time">
              {getOption('time').is_enable && (
                `Time: ${formattedTime}`
              )}
            </div>
          </div>
          <div className="receipt-date-time">
            <div className="receipt-date">
              Branch: Indore
            </div>
            <div className="receipt-time">
              {getOption('invoice_number').is_enable && (
                `Receipt: #L21387`
              )}
            </div>
          </div>
          <div className="second-receipt-table-grp">
            <table className="cash-memo-table">
              <thead className="table-header-dotted">
                <tr>
                  <th>Item Name</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>colgate paste</td>
                  <td>1</td>
                  <td>40</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td>colgate paste</td>
                  <td>1</td>
                  <td>40</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td>colgate paste</td>
                  <td>1</td>
                  <td>40</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td>colgate paste</td>
                  <td>1</td>
                  <td>40</td>
                  <td>40</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr className="hr-dotted" />
          <div>
            <div className="row">
              <div className="col"></div>
              <div className="col-auto">
                <div className="second-receipt-expenses">Sub-Total: 10000</div>
                {getOption('discount').is_enable && (
                  <div className="second-receipt-expenses">Discount: 1000</div>
                )}
              </div>
            </div>
          </div>
          <hr className="hr-dotted" />
          <div>
            <div className="row">
              <div className="col"></div>
              <div className="col-auto">
                <div className="second-receipt-expenses">Service charge: 0rs</div>
                <div className="second-receipt-expenses">Tax(%): 10</div>
              </div>
            </div>
          </div>
          <hr className="hr-dotted" />
          <div className="row">
            <div className="col"></div>
            <div className="col-auto">
              <div className="second-receipt-right">
                <div className="second-receipt-expenses">Total Bill: 10000rs</div>
                {getOption('display_balance_amount').is_enable && (
                  <div className="second-receipt-expenses">Due: 0rs</div>
                )}
              </div>
            </div>
          </div>
          <hr className="hr-dotted" />
          <div className="row">
            <div className="col"></div>
            <div className="col-auto">
              {getOption('display_received_amount').is_enable && (
                <div className="second-receipt-right mb-2">Cash Paid: 9000</div>
              )}
            </div>
          </div>
        </div>
        {getOption('qr_code').is_enable && (
          <div className="qr-code-container">
            <img src="/qr-code-placeholder.png" alt="QR Code" className="qr-code-image" />
          </div>
        )}
        {getOption('barcode').is_enable && (
          <img className="rounded mx-auto d-block mt-2 w-1 h-auto" src="/barcode.png" alt="barcode" />
        )}
        <div className="second-receipt-footer">
          <div className="row">
            {getOption('company_name').is_enable && (
              <span 
                className="second-company-title"
                style={{ textAlign: getAlignment('company_name') }}
              >
                {getOption('company_name').free_text || "JUSTMINDWORK Private Limited"}
              </span>
            )}
          </div>
          <div className="row">
            {getOption('address').is_enable && (
              <div 
                className="receipt-second-company-address"
                style={{ textAlign: getAlignment('address') }}
              >
                {getOption('address').free_text || "111J, Satguru Parinay, Indore"}
              </div>
            )}
            {getOption('email').is_enable && (
              <div 
                className="receipt-second-com-mail"
                style={{ textAlign: getAlignment('email') }}
              >
                Email: {getOption('email').free_text || "justmindwork@gmail.com"}
              </div>
            )}
            {getOption('insta_id').is_enable && (
                <div
                  className="receipt-com-mail"
                  style={{ textAlign: getAlignment('insta_id') }}
                >
                  Insta Id: {getOption('insta_id').free_text || `@jmw_bill_easy`}
                </div>
              )}
          </div>
        </div>

        <div className="receipt-second-content">
          {getOption('phone_number').is_enable && (
            <span style={{ textAlign: getAlignment('phone_number') }}>
              CALL FOR HOME DELIVERY {getOption('phone_number').free_text || "+7849201710"}
            </span>
          )}
        </div>
      </div>
    </>
  )
}

export default ReceiptSecond;