import React from "react";
import "./TogglePlateformItem.css";
import BackButton from "../../../../BackButton";
function TogglePlatformItem() {
  return (
    <>
      <div className="toggle-plateform-main-conatainer">
        <div className="toggle-plateform-header-grp">
          <span className="toggle-platform-back-btn"><BackButton /></span>
          <span className="toggle-plateform-header-title">
            TOGGLE PLATEFORM FOR ITEM
          </span>
        </div>

        <div className="toggle-plateform-sub-conatainer">
          <div className="toggle-plateform-first-face">
            <div className="toggle-plateform-first-row">
              <label className="toggle-plateform-left-hand-label">
                Platforms
              </label>
              <div className="toggle-plateform-grp">
                <span className="plateform-checkbox-together">
                  <input className="plateform-checkbox" type="checkbox" />
                  <label className="plateform-zamoto-title">Zomato</label>
                </span>
                <span className="plateform-checkbox-together">
                  <input className="plateform-checkbox" type="checkbox" />
                  <label className="plateform-swiggy-title">Swiggy</label>
                </span>
              </div>
            </div>
            <div className="toggle-plateform-first-row">
              <label className="toggle-plateform-left-hand-label">
                Select Store
              </label>
              <div className="toggle-plateform-right-hand-value">
                <select className="form-control toggle-plateform-select">
                  <option value="" disabled>
                    Select Store
                  </option>
                </select>
              </div>
            </div>
            <div className="toggle-plateform-first-row">
              <label className="toggle-plateform-left-hand-label">
                Search Item
              </label>
              <div className="toggle-plateform-right-hand-value">
                <input className="form-control"/>
              </div>
            </div>
          </div>
          <div className="toggle-plateform-button-footer">
            <button className="toggle-plateform-button-1">Enable</button>
            <button className="toggle-plateform-button-2">Disable</button>
            <button className="toggle-plateform-button-3">Push</button>
            <button className="toggle-plateform-button-4">Enable All</button>
            <button className="toggle-plateform-button-5">Disable All</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TogglePlatformItem;
