import React from 'react';
import './Modal.css'; // Add your styles

const ModalPermission = ({ message, onClose }) => {
  return (
    // <div className="subscription-popup-overlay">
      <div className="subscription-popup-content-per">
        <div className='subcription-popup-subcontent'>
        <h2>User Not permited</h2>
        <button className="btn btn-primary unauthorize-close-button" onClick={onClose}>Close</button>
        </div>
        <div className="subscription-details">
          <div className="modal-details">
         <p>Access denied. Please contact your owner</p>
         </div>
        </div>
      </div>
    // </div>
  );
};

export default ModalPermission;

// Usage in your main component
export const openModalPopup = (message, setShowModal, setMessage) => {
  setMessage(message); // Set the message for the modal
  setShowModal(true); // Show the modal
};

export const closeModalPopup = (setShowModal) => {
  setShowModal(false); // Close the modal
  window.location.replace('/dashboard'); // Redirect to the dashboard
};
