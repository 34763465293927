import React, { useEffect, useRef, useState } from "react";
import "./SaleReceipt.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import NewSaleReceiptInvoice from "./NewSaleReceiptInvoice";
// import NewSaleReceiptAccount from "./NewSaleReceiptAccount";
import Copyrights from "../../Copyrights";
import PaginationControls from "../../Common/PaginationControls";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";

function SaleReceipt() {

  // const [visibleReceiptColumns, setVisibleReceiptColumns] = useState({
  //   paymentMode: true,
  //   contact: true,
  //   refNo: true,
  //   balance: true,
  // });

  const [isOpenReceiptInvoice, setOpenReceiptInvoice] = useState(false);
  // const [isOpenReceiptAccount, setOpenReceiptReceipt] = useState(false);
  // const [showColumnReceiptFilter, setShowColumnReceiptFilter] = useState(false);
  // const [tempVisibleColumns, setTempVisibleColumns] = useState({ ...visibleReceiptColumns });
  const [isReceiptActionOption, setReceiptActionOption] = useState(false);
  const [isReceiptAttributeFilter, setReceiptAttributeFilter] = useState(false);
  const [isReceiptTurnFilter, setReceiptTurnFilter] = useState(false);
  const [saleReceipt, setsaleReceipt] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedReceipt, setSelectedReceipt] = useState("");
  const [saleReceiptCopy, setSaleReceiptCopy] = useState("");
  const [saleReceiptView, setSaleReceiptView] = useState(false);
  const [actionDropdownPosition, setActionDropdownPosition] = useState({ top: 0, left: 0 });

  const handleReceiptInvoice = () => {
    setOpenReceiptInvoice(true);
  }

  const closeReceiptInvoicePopup = () => {
    setOpenReceiptInvoice(false);
    setSaleReceiptCopy("");
    setSaleReceiptView(false);
  }

  // const handleReceiptAccount = () => {
  //   setOpenReceiptReceipt(true);
  // }

  // const closeReceiptAccountPopup = () => {
  //   setOpenReceiptReceipt(false);
  // }

  // const handleCheckboxChange = (column) => {
  //   setTempVisibleColumns((prevState) => ({
  //     ...prevState,
  //     [column]: !prevState[column],
  //   }));
  // };

  // const handleApplyReceiptFilter = () => {
  //   setVisibleReceiptColumns(tempVisibleColumns)
  //   setShowColumnReceiptFilter(false);
  // };

  // const handleReceiptColumnFilterClick = () => {
  //   setTempVisibleColumns({ ...visibleReceiptColumns });
  //   setShowColumnReceiptFilter(true);
  // }

  const handleReceiptActionOption = (saleReceipt, event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setActionDropdownPosition({
      top: rect.bottom + window.scrollY,
    });
    setSelectedReceipt(saleReceipt)
    setReceiptActionOption(true);
  }

  // const handleAttributeReceiptFilter = () => {
  //   setReceiptAttributeFilter(true);
  // }

  const closeAttributeReceiptFilter = () => {
    setReceiptAttributeFilter(false);
  }

  // const handleTurnIconReceiptFilter = () => {
  //   setReceiptTurnFilter(true);
  // }

  const closeTurnIconPopup = () => {
    setReceiptTurnFilter(false);
  }


  // useEffect(() => {
  //   const handleClickColumnOutside = (event) => {
  //     if (
  //       columnReceiptFilterRef.current &&
  //       !columnReceiptFilterRef.current.contains(event.target)
  //     ) {
  //       setShowColumnReceiptFilter(false);
  //     }
  //   };

  //   if (showColumnReceiptFilter) {
  //     document.addEventListener("mousedown", handleClickColumnOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickColumnOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickColumnOutside);
  //   };
  // }, [showColumnReceiptFilter]);

  // useEffect(() => {
  //   const handleClickActionOutside = (event) => {
  //     if (
  //       actionReceiptFilterRef.current &&
  //       !actionReceiptFilterRef.current.contains(event.target)
  //     ) {
  //       setReceiptActionOption(false);
  //     }
  //   };

  //   if (isReceiptActionOption) {
  //     document.addEventListener("mousedown", handleClickActionOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickActionOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickActionOutside);
  //   };
  // }, [isReceiptActionOption]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionReceiptFilterRef.current && !actionReceiptFilterRef.current.contains(event.target)) {
        setReceiptActionOption(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickAttributeOutside = (event) => {
      if (
        receiptIconFilterRef.current &&
        !receiptIconFilterRef.current.contains(event.target)
      ) {
        setReceiptAttributeFilter(false);
      }
    };

    if (isReceiptAttributeFilter) {
      document.addEventListener("mousedown", handleClickAttributeOutside);
    } else {
      document.removeEventListener("mousedown", handleClickAttributeOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickAttributeOutside);
    };
  }, [isReceiptAttributeFilter]);

  useEffect(() => {
    const handleClickTurnOutside = (event) => {
      if (
        turnIconReceiptRef.current &&
        !turnIconReceiptRef.current.contains(event.target)
      ) {
        setReceiptTurnFilter(false);
      }
    };

    if (isReceiptTurnFilter) {
      document.addEventListener("mousedown", handleClickTurnOutside);
    } else {
      document.removeEventListener("mousedown", handleClickTurnOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickTurnOutside);
    };
  }, [isReceiptTurnFilter]);

  // const columnReceiptFilterRef = useRef(null);
  const actionReceiptFilterRef = useRef(null);
  const receiptIconFilterRef = useRef(null);
  const turnIconReceiptRef = useState(null);

  const fetchSaleReceipt = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/transactions/payment_transactions?type=sales&page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setsaleReceipt(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching sale receipt:", error);
    }
  };

  const handleReceiptDelete = async (receiptId) => {
    if (receiptId) {
      try {
        const response = await sendRequest("DELETE", `${BASE_URL}/v1/transactions/payment_transactions/${receiptId}?type=receipt`);
        if (response.status === 200) {
          console.log("Receipt deleted successfully!");
          fetchSaleReceipt(currentPage, recordsPerPage);
          setReceiptActionOption(false);
        } else {
          console.log("Failed to delete the receipt. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting the receipt:", error);
      }
    }
  };

  const handleSaleReceiptCopy = async (saleReceiptCopyId, viewed) => {
    if (viewed) {
      setSaleReceiptView(viewed);
    }
    if (saleReceiptCopyId) {
      try {
        const response = await sendRequest("GET", `${BASE_URL}/v1/transactions/payment_transactions/${saleReceiptCopyId}?type=receipt`);
        setSaleReceiptCopy(response.data.entry);
        setOpenReceiptInvoice(true);
      } catch (error) {
        console.error("Error copy the sale receipt:", error);
        console.log("An error occurred while deleting the copy receipt.");
      }
    }
  };

  useEffect(() => {
    fetchSaleReceipt(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage ]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const handleReceiptCreated = (value) => {
    setsaleReceipt(prevSaleReceipts => [...prevSaleReceipts, ...value]);
    setTotalRecords(prevTotalRecords => prevTotalRecords + 1);
  }

  return (
    <>
      <div className="sale-receipt-main-container">
        <div className="sale-receipt-container-first-div">
          <div className="sale-receipt-first-left">
            <span className="all-sale-receipt-show">All Sale Receipt ({totalRecords})</span>
          </div>
          <div className="sale-receipt-first-right">
            <button
              className="sale-receipt-first-right-botton"
              onClick={handleReceiptInvoice}
            >
              New Receipt
              {/* <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span> */}
            </button>
            {/* <button
              className="sale-receipt-first-right-botton"
              onClick={handleReceiptAccount}
            >
              New Receipt(Accounts)
              <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            </button> */}
          </div>
        </div>
        <div className="sale-receipt-container-second-div">
          {/* <div className="sale-receipt-second-div-right"> */}
          <div className="sale-receipt-pagination-header">
            <EntriesPerPageDropdown recordPerPage={recordsPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
          </div>
          <div className="sale-receipt-second-div-part">
            {/* <div className="sale-receipt-second-drop-first"> */}
            {/* <div className="sale-receipt-turn-icon-grp"
                onClick={handleTurnIconReceiptFilter}>
                <span className="sale-receipt-turn-right-icon">
                  <FontAwesomeIcon icon={faArrowsTurnRight} />
                </span>
              </div> */}
            {/* <span className="quotatin-icon-between"></span> */}
            {/* <div className="sale-receipt-down-icon-grp" >
                <span className="sale-receipt-caret-down-icon">
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </div> */}
            {/* </div> */}
            {/* <div className="sale-receipt-second-filter"
              onClick={handleAttributeReceiptFilter}>
              <span className="sale-receipt-filter-icon">
                <FontAwesomeIcon icon={faFilter} />
              </span>
            </div> */}
            {/* <div
              className="sale-receipt-second-three-dot-grop"
              onClick={handleReceiptColumnFilterClick}
            >
              <span className="sale-receipt-ellips-icon">
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </span>
            </div> */}
          </div>
          {/* </div> */}
        </div>

        <div className="sale-receipt-table-group">
          <table className="sale-receipt-table">
            <thead>
              <tr className="sale-receipt-thead-row">
                <th className="sale-receipt-tab-th">Date</th>
                <th className="sale-receipt-tab-th">Receipt No</th>
                <th className="sale-receipt-tab-th">Contact/Account</th>
                {/* {visibleReceiptColumns.depositeAccount && (
                  <th className="sale-receipt-tab-th">Deposit Account</th>
                )} */}
                {/* {visibleReceiptColumns.paymentMode && ( */}
                <th className="sale-receipt-tab-th">Payment Mode</th>
                {/* )} */}
                {/* {visibleReceiptColumns.refNo && ( */}
                <th className="sale-receipt-tab-th">Ref.No</th>
                {/* )} */}
                <th className="sale-receipt-tab-th">Amount</th>
                {/* {visibleReceiptColumns.balance && (
                  <th className="sale-receipt-tab-th">Balance</th>
                )} */}
                <th className="sale-receipt-tab-th">Action</th>
              </tr>
            </thead>
            <tbody className="sale-receipt-table-body">
              {saleReceipt.map((row, index) => (
                <tr key={index} className="sale-receipt-tbody-row">
                  <td className="sale-receipt-tab-td">{row.date}</td>
                  <td className="sale-receipt-tab-td">{row.bill_payment_number}</td>
                  <td className="sale-receipt-tab-td">{row.customer?.customer_name}</td>
                  {/* {visibleReceiptColumns.depositeAccount && (
                    <td className="sale-receipt-tab-td"></td>
                  )} */}
                  {/* {visibleReceiptColumns.paymentMode && ( */}
                  <td className="sale-receipt-tab-td">{row.payment_mode}</td>
                  {/* )} */}
                  {/* {visibleReceiptColumns.refNo && ( */}
                  <td className="sale-receipt-tab-td">{row.reference_no}</td>
                  {/* )} */}
                  <td className="sale-receipt-tab-td">{row.amount_paid}</td>
                  {/* {visibleReceiptColumns.balance && (
                    <td className="sale-receipt-tab-td">{row.amount_balance}</td>
                  )} */}
                  <td className="sale-receipt-tab-td">
                    <FontAwesomeIcon icon={faCaretDown}
                      onClick={(e) => handleReceiptActionOption(row, e)} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}

        {saleReceipt.length === 0 && (
          <div className="receipt-no-data-message">
            No receipt found.
          </div>
        )}
        <Copyrights />
      </div>

      {isOpenReceiptInvoice && (
        <div className="new-receipt-invoice-popup">
          <NewSaleReceiptInvoice onClose={closeReceiptInvoicePopup} saleReceiptCopy={saleReceiptCopy} isUpdated={false} saleReceiptView={saleReceiptView} saleReceiptCreated={handleReceiptCreated} isView={true}/>
        </div>
      )}

      {/* {isOpenReceiptAccount && (
        <div className="new-receipt-account-popup">
          <NewSaleReceiptAccount onClose={closeReceiptAccountPopup} />
        </div>
      )} */}

      {/* {showColumnReceiptFilter && (
        <div className="sale-receipt-column-filter-popup" ref={columnReceiptFilterRef}>
          <form className="sale-receipt-column-filter-form">
            {Object.keys(tempVisibleColumns).map((column) => (
              <div key={column} className="sale-receipt-label-input-grp">
                <label>
                  <input
                    type="checkbox"
                    className="sale-receipt-visible-column-input"
                    checked={tempVisibleColumns[column]}
                    onChange={() => handleCheckboxChange(column)}
                  />
                  {column.charAt(0).toUpperCase() + column.slice(1)}
                </label>
              </div>
            ))}
            <button type="button" onClick={handleApplyReceiptFilter} className="sale-receipt-visible-box-btn">
              Apply
            </button>
          </form>
        </div>
      )} */}

      {isReceiptActionOption && (
        <div
          className="sale-receipt-icon-action"
          ref={actionReceiptFilterRef}
          style={{
            position: 'absolute',
            top: `${actionDropdownPosition.top}px`,
          }}>
          <ul className="sale-receipt-down-icon-action-ul">
            <li className="sale-receipt-down-icon-action-li" onClick={() => handleSaleReceiptCopy(selectedReceipt.id, true)}>View</li>
            <li className="sale-receipt-down-icon-action-li" onClick={() => handleReceiptDelete(selectedReceipt.id)}>
              Delete
            </li>
            {/* <li className="sale-receipt-down-icon-action-li">Print</li>
            <li className="sale-receipt-down-icon-action-li">Send</li>
            <li className="sale-receipt-down-icon-action-li">Send SMS</li>
            <li className="sale-receipt-down-icon-action-li">Send WhatsApp</li> */}
            <li className="sale-receipt-down-icon-action-li" onClick={() => handleSaleReceiptCopy(selectedReceipt.id)}>Copy</li>
            {/* <li className="sale-receipt-down-icon-action-li">Void</li> */}
          </ul>
        </div>
      )}

      {isReceiptAttributeFilter && (
        <div className="sale-receipt-attribute-filter-popup-container" ref={receiptIconFilterRef}>
          <div className="sale-receipt-first-att-div">
            <label className="sale-receipt-first-att-div-label">Contacts / Receipt# / Ref# / Amount</label>
            <select>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
          </div>
          <div className="sale-receipt-second-att-div">
            <label className="sale-receipt-second-att-div-label">Status</label>
            <select>
              <option>Pending</option>
              <option>Completed</option>
              <option>Cancelled</option>
              <option>Due Amount Exists</option>
            </select>
          </div>
          <div className="sale-receipt-second-att-div">
            <label className="sale-receipt-second-att-div-label">Cash</label>
            <select>
              <option>Cash</option>
              <option>Cheque/DD</option>
              <option>Debit/Credit Card</option>
              <option>Electronic Transfer</option>
              <option>BHIM</option>
              <option>Paytm</option>
              <option>G-Pay</option>
              <option>PhonePe</option>
            </select>
          </div>
          <div className="sale-receipt-third-att-div">
            <div className="sale-receipt-third-part-1">
              <label className="sale-receipt-third-part-1-label">Start Date</label>
              <input type="date" className="sale-receipt-third-att-date" />
            </div>
            <div className="sale-receipt-third-part-2">
              <label className="sale-receipt-third-part-2-label">End Date</label>
              <input type="date" className="sale-receipt-third-att-date" />
            </div>
          </div>
          <div className="sale-receipt-attr-btn-grp">
            <button className="sale-receipt-attr-pop-btn">Search</button>
            <button className="sale-receipt-attr-pop-btn" onClick={closeAttributeReceiptFilter}>
              Clear
            </button>
          </div>
        </div>
      )}

      {isReceiptTurnFilter && (
        <div className="sale-receipt-turn-icon-popup" ref={turnIconReceiptRef}>
          <div className="sale-receipt-turn-popup-div">
            <label className="sale-receipt-turn-pop-label">Export Method</label>
            <select>
              <option>List</option>
              <option>Whole Transactions</option>
            </select>
          </div>
          <div className="sale-receipt-turn-popup-div-2">
            <label className="sale-receipt-turn-pop-label" >Export Type</label>
            <select>
              <option>CSV</option>
              <option>PDF</option>
              <option>XLS</option>
            </select>
          </div>
          <div className="sale-receipt-turn-btn-grp">
            <button className="sale-receipt-turn-btn">Export</button>
            <button className="sale-receipt-turn-btn" onClick={closeTurnIconPopup}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default SaleReceipt;
