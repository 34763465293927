import React, { useEffect, useRef, useState } from "react";
import "./PurchaseQuotation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewPurchaseQuotation from "./NewPurchaseQuotation";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import PaginationControls from "../../Common/PaginationControls";
import successNotification from "../../../Notification/SuccessNotification";
import ErrorNotification from "../../../Notification/ErrorNotification";
import NewPurchaseOrder from "../PurchaseOrder/NewPurchaseOrder";

function PurchaseQuotation() {
  const [isNewPurchaseQuote, setNewPurchaseQuote] = useState(false);
  const [newPO, setNewPO] = useState(false);
  const [purchaseQuotations, setPurchaseQuotations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [purchaseQuotationCopy, setPurchaseQuotationCopy] = useState("");
  const [isQuotationUpdated, setQuotationUpdated] = useState(false);
  const [selectedItems, setSelectedItems] = useState("");

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [activeStatusDropdownIndex, setActiveStatusDropdownIndex] =
    useState(null);
  const [activeActionDropdownIndex, setActiveActionDropdownIndex] =
    useState(null);
  const [title, setTitle] = useState();
  const [editedRowData, setEditedRowData] = useState();
  const [type, setType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quotationId, setQuotationId] = useState(null);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveActionDropdownIndex(null);
      setActiveStatusDropdownIndex(null);
    }
  };

  const handlePurchaseQuotationCopy = async (quotationCopyId) => {
    if (quotationCopyId) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/transactions/entries/${quotationCopyId}?type=purchase_quotation`
        );

        const { entry } = response.data;
        setPurchaseQuotationCopy(entry);
        setNewPurchaseQuote(true);
        setTitle("Copy Purchase Quotation");
        setType("purchase_quotation");
        setQuotationUpdated(true);

        successNotification("Purchase Quotation copied successfully!");
      } catch (error) {
        console.error("Error copying the purchase quotation:", error);
        ErrorNotification(
          "An error occurred while copying the purchase quotation."
        );
      }
    }
  };
  useEffect(() => {
    if (
      activeActionDropdownIndex !== null ||
      activeStatusDropdownIndex !== null
    ) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeActionDropdownIndex, activeStatusDropdownIndex]);

  const handleDeleteClick = (id) => {
    setQuotationId(id);
    setIsModalOpen(true);
    setActiveActionDropdownIndex(null);
  };

  const handleDeleteConfirm = async () => {
    if (!quotationId) return;

    try {
      const response = await sendRequest(
        "delete",
        `${BASE_URL}/v1/transactions/entries/${quotationId}?type=purchase_quotation&is_purchase=true`
      );

      if (response.status === 200) {
        successNotification("Purchase Quotation deleted");
        fetchPurchaseQuotations();
      }
    } catch (error) {
      console.error("Error deleting purchase quotation:", error);
      alert("Failed to delete Purchase Quotation.");
    } finally {
      setIsModalOpen(false);
      setQuotationId(null);
    }
  };

  const handleDeleteCancel = () => {
    setIsModalOpen(false);
    setQuotationId(null);
    setActiveActionDropdownIndex(null);
  };

  const handlePurchaseQuote = () => {
    setNewPO(false);
    setIsCreated(true);
    setIsUpdated(false);
    setNewPurchaseQuote(true);
    setQuotationUpdated(false);
    setTitle("New Purchase Quotation");
    setType("purchase_quotation");
  };

  const closeNewPO = () => {
    setNewPO(false);
  };

  const closeNewPurchasePopup = () => {
    setNewPurchaseQuote(false);
  };

  useEffect(() => {
    fetchPurchaseQuotations(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const fetchPurchaseQuotations = async (
    pageNumber = 1,
    perPage = 10,
    query = ""
  ) => {

    try {
      let request_url = `${BASE_URL}/v1/transactions/entries?type=purchase_quotation&page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setPurchaseQuotations(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching purchase quotations:", error);
    }
  };

  const toggleDropdown = (index) => {
    setActiveStatusDropdownIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  const toggleActionDropdown = (index) => {
    setActiveActionDropdownIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  const handleCreatePO = (row) => {
    setIsCreated(true);
    setEditedRowData(row);
    setNewPO(true);
    setTitle("New Purchase Order");
    setType("purchase_order");
  };

  const handleEditPQ = (row) => {
    setNewPO(false);
    setIsUpdated(true);
    setIsCreated(false);
    setEditedRowData(row);
    setNewPurchaseQuote(true);
    setTitle("Edit Purchase Quotation");
    setActiveActionDropdownIndex(null);
    setType("purchase_quotation");
  };

  return (
    <>
      <div className="purchase-quote-main-container">
        <div className="purchase-quote-container-first-div">
          <div className="purchase-quote-first-left">
            <span className="all-purchase-quote-show">
              All Purchase Quotation{" "}
              {totalRecords > 0 ? `(${totalRecords})` : ""}
            </span>
          </div>
          <div className="purchase-quote-first-right">
            <button
              className="purchase-quote-first-right-botton"
              onClick={handlePurchaseQuote}
            >
              New quote
              <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            </button>
          </div>
        </div>

        <div className="purchase-quotation-pagination-header">
          <EntriesPerPageDropdown
            recordPerPage={recordsPerPage}
            handleRecordPerPageChange={handleRecordPerPageChange}
          />
        </div>

        <div className="purchase-quote-table-group">
          <table className="purchase-quote-table p-0 m-0">
            <thead>
              <tr className="purchase-quote-thead-row p-0 m-0">
                <th className="purchase-quote-tab-th">Date</th>
                <th className="purchase-quote-tab-th">Purchase Quote No</th>
                <th className="purchase-quote-tab-th">Contact</th>
                <th className="purchase-quote-tab-th">Supplier Quatation No</th>
                <th className="purchase-quote-tab-th">
                  Supplier Quatation Date
                </th>
                {/* <th className="purchase-quote-tab-th">Ref.No</th> */}
                <th className="purchase-quote-tab-th text-center">Due Days</th>
                <th className="purchase-quote-tab-th text-center">Status</th>
                <th className="purchase-quote-tab-th text-end">Amount</th>
                <th className="purchase-quote-tab-th text-end">Due Amount</th>
                <th className="purchase-quote-tab-th  text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {purchaseQuotations.map((row, index) => (
                <tr key={index}>
                  <td className="purchase-quote-tab-td">{row.date}</td>
                  <td className="purchase-quote-tab-td">{row.bill_number}</td>
                  <td className="purchase-quote-tab-td">
                    {row.supplier.supplier_name}
                  </td>
                  <td className="purchase-quote-tab-td">{row.order_no}</td>
                  <td className="purchase-quote-tab-td">{row.order_date}</td>
                  {/* <td className="purchase-quote-tab-td">{row.reference_no}</td> */}
                  <td className="purchase-quote-tab-td text-center">
                    {row.valid_date && row.status !== 'confirmed' ? (
                      new Date(row.valid_date) >= new Date() ? (
                        <span style={{ color: "green" }}>
                          {`${Math.ceil(
                            (new Date(row.valid_date) - new Date()) /
                              (1000 * 60 * 60 * 24)
                          )} days left`}
                        </span>
                      ) : (
                        <span style={{ color: "red" }}>
                          {`${Math.abs(
                            Math.ceil(
                              (new Date(row.valid_date) - new Date()) /
                                (1000 * 60 * 60 * 24)
                            )
                          )} days overdue`}
                        </span>
                      )
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="purchase-quote-tab-td  text-center">
                    <span
                      style={{
                        color: ["sent", "confirmed"].includes(row.status)
                          ? "green"
                          : "inherit",
                      }}
                    >
                      {row.status}
                    </span>

                    {row.status === "sent" && (
                      <>
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          className="purchase-quote-table-body-td"
                          onClick={() => toggleDropdown(index)}
                          style={{ cursor: "pointer", marginLeft: "8px" }}
                        />

                        {activeStatusDropdownIndex === index && (
                          <ul className="pq-status-dropdown" ref={dropdownRef}>
                            <li
                              onClick={() => console.log("Approved selected")}
                            >
                              Approved
                            </li>
                            <li onClick={() => console.log("Pending selected")}>
                              Pending
                            </li>
                            <li
                              onClick={() => console.log("Rejected selected")}
                            >
                              Rejected
                            </li>
                          </ul>
                        )}
                      </>
                    )}
                  </td>
                  <td className="purchase-quote-tab-td text-end">
                    {(row.total_amount ?? 0).toFixed(2)}
                  </td>
                  <td className="purchase-quote-tab-td text-end">
                    {(row.due_amount ?? 0).toFixed(2)}
                  </td>
                  <td className="purchase-quote-tab-td text-center">
                    {row.status === "sent" && (
                      <span
                        style={{
                          color: ["sent", "confirmed"].includes(row.status)
                            ? "green"
                            : "inherit",
                          cursor: "pointer",
                        }}
                        onClick={() => handleEditPQ(row)}
                      >
                        Edit
                      </span>
                    )}
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="purchase-quote-table-body-td"
                      onClick={() => toggleActionDropdown(index)}
                      style={{ cursor: "pointer", marginLeft: "8px" }}
                    />
                    {activeActionDropdownIndex === index && (
                      <ul
                        className="pq-action-dropdown text-start p-1"
                        ref={dropdownRef}
                      >
                        {row.status === "sent" && (
                          <>
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={() => handleCreatePO(row)}
                            >
                              Create Purchase Order
                            </li>
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={() => handleEditPQ(row)}
                            >
                              Edit PQ
                            </li>
                          </>
                        )}
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteClick(row.id)}
                        >
                          Delete
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => console.log("Rejected selected")}
                        >
                          Print
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => console.log("Rejected selected")}
                        >
                          Sent To SMS
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => console.log("Rejected selected")}
                        >
                          Send To WhatsApp
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => console.log("Rejected selected")}
                        >
                          Send To Email
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => handlePurchaseQuotationCopy(row.id)}
                        >
                          Copy
                        </li>
                      </ul>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className="modal bg-light">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            style={{ fontSize: "20px", color: "red", marginBottom: "10px" }}
          />
          <h2>Warning</h2>
          <p>Do you want to delete this Purchase Quotation ?</p>
          <div className="d-flex justify-content-between">
            <button className="btn btn-primary" onClick={handleDeleteConfirm}>
              Confirm
            </button>
            <button
              className="btn btn-cancel bg-body-secondary"
              onClick={handleDeleteCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {purchaseQuotations.length === 0 && (
        <div className="purchase-quotation-no-data-message">
          No purchase quotations found.
        </div>
      )}

      {newPO && (
        <div className="new-purchase-quote-popup-container">
          <NewPurchaseOrder
            isCreatedPO={isCreated}
            editedRowData={editedRowData}
            type={type}
            title={title}
            onClose={() => closeNewPO()}
            fetchPurchaseOrders={fetchPurchaseQuotations}
          />
        </div>
      )}

      {isNewPurchaseQuote && (
        <div className="new-purchase-quote-popup-container">
          <NewPurchaseQuotation
            isUpdated={isUpdated}
            isCreated={isCreated}
            editedRowData={editedRowData}
            type={type}
            title={title}
            onClose={closeNewPurchasePopup}
            fetchPurchaseQuotations={fetchPurchaseQuotations}
            purchaseQuotationCopy={purchaseQuotationCopy}
            isQuotationUpdated={isQuotationUpdated}
            selectedItems
          />
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default PurchaseQuotation;