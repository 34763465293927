import React, { useState, useEffect } from "react";
import { sendRequest } from "../../../../ApiRequest";
import { BASE_URL } from "../../../../Url/constants";
import ErrorNotification from "../../../../Notification/ErrorNotification";
import AddSupplier from "../add-supplier/add-supplier";
import { updateBillingStatus } from "../../update-bill-status/update-bill";
import { useTranslation } from "react-i18next";


const SearchSupplier = ({ id, navigate }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const { t } = useTranslation();

  const fetchSuppliers = async (query) => {
    if (query.trim() !== "") {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/suppliers?query=${encodeURIComponent(
            query
          )}`,
          null
        );
        const suppliersData = response.data.data || [];

        const suggestions = suppliersData.map((supplier) => ({
          id: supplier.id,
          label: `${supplier.first_name} ${supplier.last_name}`,
          phone: supplier.phone_number,
          email: supplier.email,
          company: supplier.supp_company,
          address: supplier.com_address,
          companyId: supplier.company_id,
        }));
        setSuggestions(suggestions);
      } catch (error) {
        ErrorNotification("Error searching supplier");
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSupplierSelect = (supplier) => {
    setSelectedSupplier(supplier);
    setSuggestions([]);
  };

  const handleNewSupplier = (supplier) => {
    setSelectedSupplier({
      id: supplier.id,
      label: `${supplier.first_name} ${supplier.last_name}`,
      phone: supplier.phone_number,
      email: supplier.email,
      company: supplier.supp_company,
      address: supplier.com_address,
      companyId: supplier.company_id,
    });
    setShowAddForm(false);
  };

  useEffect(() => {
    fetchSuppliers(searchQuery);
  }, [searchQuery]);

  return (
    <div className="container-fluid">
      <div className="row justify-content-between">
        <div className="col">
          <input
            className="form-control ps-2 w-25"
            type="text"
            placeholder="Search Supplier"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {suggestions.length > 0 && (
            <ul
              className="list-group position-absolute"
              style={{ zIndex: 1000 }}
            >
              {suggestions.map((suggestion) => (
                <li
                  key={suggestion.id}
                  className="list-group-item list-group-item-action"
                  onClick={() => handleSupplierSelect(suggestion)}
                >
                  <div>{suggestion.label}</div>
                  <small>{suggestion.phone}</small>
                </li>
              ))}
            </ul>
          )}
          <br />
          {selectedSupplier && (
            <div>
              <strong>Supplier Details:</strong>
              {selectedSupplier.label && (
                <p className="mb-0">
                  Name: <strong>{selectedSupplier.label.toUpperCase()}</strong>
                </p>
              )}
              {selectedSupplier.phone && (
                <p className="mb-0">{t("Phone")}: +91 {selectedSupplier.phone}</p>
              )}
              {selectedSupplier.email && (
                <p className="mb-0">{t("Email")}: {selectedSupplier.email}</p>
              )}
              {selectedSupplier.company && (
                <p className="mb-0">{t("Company")}: {selectedSupplier.company}</p>
              )}
              {selectedSupplier.address && (
                <p>{t("Address")}: {selectedSupplier.address}</p>
              )}
            </div>
          )}
        </div>
        <div className="col">
          <button
            className="btn btn-primary mt-0"
            onClick={() => setShowAddForm(true)}
          >
            {t("Add Supplier")}
          </button>
        </div>
      </div>
      {showAddForm && (
        <div className="border border-2 border-primary">
          <AddSupplier
            id={id}
            onClose={() => setShowAddForm(false)}
            onAddSupplier={handleNewSupplier}
          />
        </div>
      )}
      <button
        className="btn btn-primary"
        onClick={() => updateBillingStatus(id, selectedSupplier, navigate)}
        disabled={!selectedSupplier}
      >
        {t("Pay Now")}
      </button>
    </div>
  );
};

export default SearchSupplier;
