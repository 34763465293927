import React, { useEffect, useRef, useState } from "react";
import "./PurchaseDebitNote.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewPurchaseDebitNote from "./NewPurchaseDebitNote";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import PaginationControls from "../../Common/PaginationControls";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import successNotification from "../../../Notification/SuccessNotification";

function PurchaseDebitNote() {
  const [openNewPurchaseDebit, setNewPurchaseDebit] = useState();
  const [purchaseDebitNote, setPurchaseDebitNote] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [purDebitDownIconAction, setPurDebitDownIconAction] = useState(false);
  const [selectedPurDebitNote, setSelectedPurDebitNote] = useState("");
  const [purDebitNoteCopy, setPurDebitNoteCopy] = useState("");
  const [purDebitNoteUpdated, setPurDebitNoteUpdated] = useState(false);
  const [actionPurDropPosition, setActionPurDropPosition] = useState({ top: 0, left: 0 });

  const actionPurchaseDebitNote = useRef(null);

  const handleNewDebitNote = () => {
    setNewPurchaseDebit(true);
  }

  const closeNewPurchaseDebit = () => {
    setNewPurchaseDebit(false);
  }

  const fetchPurchaseDebitNote = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/transactions/entries?type=sup_debit_note&page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setPurchaseDebitNote(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching debit note:", error);
    }
  };

  const handlePurDebitNoteDelete = async (purDebitId) => {
    if (purDebitId) {
      try {
        const response = await sendRequest("DELETE", `${BASE_URL}/v1/transactions/entries/${purDebitId}?type=sup_debit_note`);
        if (response.status === 200) {
          successNotification("Purchase debit note deleted")
          fetchPurchaseDebitNote(currentPage, recordsPerPage);
          setPurDebitDownIconAction(false);
        } else {
          console.log("Failed to delete the purchase debit note. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting the purchase debit note:", error);
      }
    }
  }

  const handlePurDebitCopy = async (PurDebitNoteCopyId, updated) => {
    if (updated) {
      setPurDebitNoteUpdated(updated);
    }
    if (PurDebitNoteCopyId) {
      try {
        const response = await sendRequest("GET", `${BASE_URL}/v1/transactions/entries/${PurDebitNoteCopyId}?type=sup_debit_note`);
        setPurDebitNoteCopy(response.data.entry);
        setNewPurchaseDebit(true);
      } catch (error) {
        console.error("Error copy the purchase debit note:", error);
        console.log("An error occurred while deleting the copy purchase debit note.");
      }
    }
  };

  useEffect(() => {
    fetchPurchaseDebitNote(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  useEffect(() => {
    const handleDownClickOutside = (event) => {
      if (
        actionPurchaseDebitNote.current &&
        !actionPurchaseDebitNote.current.contains(event.target)
      ) {
        setPurDebitDownIconAction(false);
      }
    };
    if (purDebitDownIconAction) {
      document.addEventListener("mousedown", handleDownClickOutside);
    } else {
      document.removeEventListener("mousedown", handleDownClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleDownClickOutside);
    };
  }, [purDebitDownIconAction]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const handlePurchaseDebitNoteCreated = (value) => {
    if (!value) return;
    setPurchaseDebitNote(prevCreditNotes => [...prevCreditNotes, ...value]);
    setTotalRecords(prevTotalRecords => prevTotalRecords + 1);
  }

  const handlePurchaseDebitAction = (PurDebit, event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setActionPurDropPosition({
      top: rect.bottom + window.scrollY,
    });
    setSelectedPurDebitNote(PurDebit);
    setPurDebitDownIconAction(true);
  }

  return (
    <>
      <div className="purchase-debit-note-main-container">
        <div className="purchase-debit-note-container-first-div">
          <div className="purchase-debit-note-first-left">
            <span className="all-purchase-debit-note-show">All debit Note({totalRecords})</span>
          </div>
          <div className="purchase-debit-note-first-right">
            <button
              className="purchase-debit-note-first-right-botton"
              onClick={handleNewDebitNote}
            >
              New Debit Note
              {/* <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span> */}
            </button>
          </div>
        </div>

        <div className="purchase-second-debit-note-container">
          <EntriesPerPageDropdown recordPerPage={recordsPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
        </div>

        <div className="purchase-debit-note-table-group">
          <table className="purchase-debit-note-table">
            <thead>
              <tr className="purchase-debit-note-thead-row">
                <th className="purchase-debit-note-tab-th">Date</th>
                <th className="purchase-debit-note-tab-th">Debit Note No</th>
                <th className="purchase-debit-note-tab-th">Contact</th>
                <th className="purchase-debit-note-tab-th">Supplier Debit Note No</th>
                <th className="purchase-debit-note-tab-th">Supplier Debit Note Date</th>
                <th className="purchase-debit-note-tab-th">Ref.No</th>
                {/* <th className="purchase-debit-note-tab-th">Due Days</th> */}
                <th className="purchase-debit-note-tab-th">Status</th>
                <th className="purchase-debit-note-tab-th">Amount</th>
                {/* <th className="purchase-debit-note-tab-th">Due Amount</th> */}
                <th className="purchase-debit-note-tab-th">Action</th>
              </tr>
            </thead>
            <tbody>
              {purchaseDebitNote.map((row, index) => (
                <tr key={index}>
                  <td className="purchase-debit-note-tab-td">{row.date}</td>
                  <td className="purchase-debit-note-tab-td">{row.bill_number}</td>
                  <td className="purchase-debit-note-tab-td">{row.customer.customer_name}</td>
                  <td className="purchase-debit-note-tab-td">{row.order_no}</td>
                  <td className="purchase-debit-note-tab-td">{row.order_date}</td>
                  <td className="purchase-debit-note-tab-td">{row.reference_no}</td>
                  {/* <td className="purchase-debit-note-tab-td"></td> */}
                  <td className="purchase-debit-note-tab-td">{row.status}</td>
                  <td className="purchase-debit-note-tab-td">{row.total_amount}</td>
                  {/* <td className="purchase-debit-note-tab-td"></td> */}
                  <td className="purchase-debit-note-tab-td">
                    <FontAwesomeIcon icon={faCaretDown} className="purchase-debit-note-table-body-td"
                      onClick={(e) => handlePurchaseDebitAction(row, e)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}

        {purchaseDebitNote.length === 0 && (
          <div className="debit-note-no-data-message">
            No purchase debit note found.
          </div>
        )}
      </div>

      {openNewPurchaseDebit && (
        <div className="new-purchase-debit-note-popup-container">
          <NewPurchaseDebitNote
            onClose={closeNewPurchaseDebit}
            purchaseDebitNoteCreated={handlePurchaseDebitNoteCreated} 
            purDebitNoteCopy={purDebitNoteCopy}  
            isUpdated={purDebitNoteUpdated}
          />
        </div>
      )}

      {
        purDebitDownIconAction && (
          <div className="purchase-debit-note-down-icon-action"
            ref={actionPurchaseDebitNote}
            style={{
              position: 'absolute',
              top: `${actionPurDropPosition.top}px`,
            }}
          >
            <ul className="purchase-debit-note-down-icon-action-ul">
              <li className="purchase-debit-note-down-icon-action-li"
                onClick={() => handlePurDebitCopy(selectedPurDebitNote.id, true)}
              >
                Edit
              </li>
              <li className="purchase-debit-note-down-icon-action-li"
                onClick={() => handlePurDebitNoteDelete(selectedPurDebitNote.id)}
              >
                Delete
              </li>
              <li className="purchase-debit-note-down-icon-action-li"
                onClick={() => handlePurDebitCopy(selectedPurDebitNote.id)}
              >
                Copy
              </li>
            </ul>
          </div>
        )
      }
      <Copyrights />
    </>
  );
}

export default PurchaseDebitNote;
