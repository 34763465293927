import React from "react";
import "./CommonKeypadButton.css"

function CommonKeypadButton({ onKeyPress, onClear, onDelete, onEnter }) {
    return (
        <>
            <div className="numeric-keypad">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(number => (
                    <button key={number} className="keypad-button" onClick={() => onKeyPress(number)}>
                        {number}
                    </button>
                ))}
                <button className="keypad-button" onClick={onDelete}>←</button>
                <button className="keypad-button" onClick={() => onKeyPress(0)}>0</button>
                <button className="keypad-button select-guest-clear-button" onClick={onClear}>Clear</button>
                <button className="keypad-button enter-button" onClick={onEnter}>Enter</button>
            </div>
        </>
    )
}

export default CommonKeypadButton;