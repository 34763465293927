import React, { useEffect, useState } from "react";
import "./NewDeliveryChallan.css"
import SaleCommonPopup from "../SaleCommonPopupHeader/SaleCommonPopupHeader";
import CommonSalePopupTable from "../CommonSalePopupTable/CommonSalePopupTable";
import CommonCustomerPopup from "../../Common/CommonCustomerPopup";
import { sendRequest } from "../../../ApiRequest";
import successNotification from "../../../Notification/SuccessNotification";
import { BASE_URL } from "../../../Url/constants";
import ErrorNotification from "../../../Notification/ErrorNotification";
import { current_user } from "../../../Authentication/AuthenticationContext";
import { BsPencilSquare } from "react-icons/bs";
import CommonToggleButton from "../../Common/CommonToggleButton";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function NewDeliveryChallan({ onClose, saleDeliveryChallanCopy, isUpdated, isSaleOrderDeliveryChallan, deliveryChallanCreated }) {

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setBranchId(currentUser.company.branch_info);
    };
    fetchUser();
  }, []);

  const [totalAmount, setTotalAmount] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [gstAmount, setGstAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [branchId, setBranchId] = useState("");
  const [isEstimateDate, setEstimateDate] = useState(
    saleDeliveryChallanCopy?.estimate_date ? saleDeliveryChallanCopy?.estimate_date : "" ||
      isSaleOrderDeliveryChallan?.estimate_date ? isSaleOrderDeliveryChallan?.estimate_date : "");
  const [isQuoteReference, setQuoteReference] = useState(
    saleDeliveryChallanCopy?.reference_no ? saleDeliveryChallanCopy?.reference_no : "" ||
      isSaleOrderDeliveryChallan?.reference_no ? isSaleOrderDeliveryChallan?.reference_no : "");
  const [isQuoteBuyerOrderNo, setQuoteBuyerOrderNo] = useState(
    saleDeliveryChallanCopy?.order_no ? saleDeliveryChallanCopy?.order_no : "" ||
      isSaleOrderDeliveryChallan?.order_no ? isSaleOrderDeliveryChallan?.order_no : "");
  const [isQuoteBuyerOrderDate, setQuoteBuyerOrderDate] = useState(
    saleDeliveryChallanCopy?.order_date ? saleDeliveryChallanCopy?.order_date : "" ||
      isSaleOrderDeliveryChallan?.order_date ? isSaleOrderDeliveryChallan?.order_date : ""
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState(
    saleDeliveryChallanCopy?.customer?.customer_name ? saleDeliveryChallanCopy?.customer?.customer_name : "" ||
      isSaleOrderDeliveryChallan?.customer?.customer_name ? isSaleOrderDeliveryChallan?.customer?.customer_name : "");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [isShowCustomerPopup, setShowCustomerPopup] = useState(false);
  const [customerId, setCustomerId] = useState(saleDeliveryChallanCopy?.customer?.id ? saleDeliveryChallanCopy?.customer?.id : "");
  const [customerError, setCustomerError] = useState("");
  const [gstEnable, setGstEnable] = useState(false);
  const [igstEnable, setIgstEnable] = useState(false);
  const [customerDetails, setCustomerDetails] = useState();
  const [itemWiseTax, setItemWiseTax] = useState(true);
  const [packagingCharges, setpackagingCharges] = useState("");
  const [BtnDisable, setBtnDisabled] = useState(false);

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/customers/customer_search?query=${term}`
        );
        const customers = response.data;
        if (customers.length > 0) {
          setFilteredCustomers(customers);
          setShowDropdown(true);
          setShowAddCustomer(false);
        } else {
          setFilteredCustomers([]);
          setShowDropdown(true);
          setShowAddCustomer(true);
        }
      } catch (error) {
        setShowDropdown(false);
        setShowAddCustomer(true);
      }
    } else {
      setShowDropdown(false);
      setShowAddCustomer(false);
    }
  };

  const handleSelectCustomer = (customer) => {
    setCustomerDetails(customer);
    setCustomerId(customer.id);
    setSearchTerm(
      customer.first_name && customer.last_name
        ? `${customer.first_name} ${customer.last_name}`
        : customer.first_name || customer.last_name
    );
    setShowDropdown(false);
  };

  const handleCreateCustomer = () => {
    setShowCustomerPopup(true);
  }

  const closeCommonCustomerPopup = () => {
    setShowCustomerPopup(false);
  }

  const handleAddCustomer = () => {
    setShowCustomerPopup(true);
    setShowDropdown(false);
    setShowAddCustomer(false);
  };

  const handleSelectedCustomer = (customer) => {
    setCustomerId(customer?.id);
    setSearchTerm(
      customer.first_name && customer.last_name
        ? `${customer.first_name} ${customer.last_name}`
        : customer.first_name || customer.last_name
    );
  }

  const handleTotalAmount = (amount) => {
    setTotalAmount(amount);
  }

  const handleSubTotal = (amount) => {
    setSubTotal(amount);
  }

  const handleGstValue = (amount) => {
    setGstAmount(amount);
  }

  const handleGstEnable = (value) => {
    setGstEnable(value);
  }
  const handleIgstEanble = (value) => {
    setIgstEnable(value);
  }

  const handleDiscountValue = (amount) => {
    setDiscount(amount);
  }

  const updatedSelectedItems = (items) => {
    setSelectedItems(items);
  }

  const handleNewSaleDeliveryChallan = async () => {
    setBtnDisabled(true);
    if (!customerId) {
      setCustomerError("Please select a customer before saving the Delivery Challan.");
      return;
    }
    setCustomerError("");

    try {
      const uniqueItems = [];
      const itemIds = new Set();
      selectedItems.forEach(item => {
        if (!itemIds.has(item.id)) {
          itemIds.add(item.id);
          uniqueItems.push({
            item_id: item?.item_id,
            quantity: item?.qty,
            item_total_discount: item?.discountAmount?.toFixed(2),
            item_total_tax: item?.tax?.toFixed(2),
            item_total_amount: item?.saleAmount?.toFixed(2),
            branch_id: branchId.id
          });
        }
      });
      const formData = {
        bill: {
          customer_id: customerId || 1,
          estimate_date: isEstimateDate,
          reference_no: Array.isArray(isQuoteReference) ? isQuoteReference : [isQuoteReference],
          order_no: isQuoteBuyerOrderNo,
          order_date: isQuoteBuyerOrderDate,
          bill_items_attributes: uniqueItems,
          total_amount: totalAmount,
          sub_total: subTotal,
          total_gst: gstAmount ? gstAmount : 0,
          status: 0,
          total_discount: discount,
          is_gst_enable: gstEnable,
          is_igst_enable: igstEnable,
          parcel_charges: packagingCharges
        }
      }
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/transactions/entries?type=delivery_challan`,
        formData
      );
      console.log("response", response);
      successNotification("Delivery Challan Created!");
      if (deliveryChallanCreated) {
        deliveryChallanCreated(Array.isArray(response.data.entry) ? response.data.entry : [response.data.entry]);
      }
      onClose();
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
    }
  }

  const handleEditSaleDeliveryChallan = async (saleDeliveryEditId) => {
    setBtnDisabled(true);
    if (!customerId) {
      setCustomerError("Please select a customer before saving the Delivery Challan.");
      return;
    }
    setCustomerError("");

    if (saleDeliveryEditId) {
      try {
        const uniqueItems = [];
        const itemIds = new Set();

        selectedItems.forEach(item => {
          if (!itemIds.has(item.id)) {
            itemIds.add(item.id);
            uniqueItems.push({
              id: item?.id ? item?.id : null,
              item_id: item?.item_id,
              quantity: item?.qty,
              item_total_discount: item?.discount_amount?.toFixed(2),
              item_total_tax: item?.tax?.toFixed(2),
              item_total_amount: item?.saleAmount?.toFixed(2),
              branch_id: branchId.id
            });
          }
        });

        const formData = {
          bill: {
            customer_id: customerId,
            estimate_date: isEstimateDate,
            // valid_date: isValidTillDate,
            reference_no: Array.isArray(isQuoteReference) ? isQuoteReference : [isQuoteReference],
            order_no: isQuoteBuyerOrderNo,
            order_date: isQuoteBuyerOrderDate,
            bill_items_attributes: uniqueItems,
            total_amount: totalAmount,
            sub_total: subTotal,
            total_gst: gstAmount,
            status: 0,
            total_discount: discount,
            parcel_charges: packagingCharges
          }
        }
        const response = await sendRequest(
          "PUT",
          `${BASE_URL}/v1/transactions/entries/${saleDeliveryEditId}?type=delivery_challan`,
          formData
        );
        console.log("response", response);
        successNotification("Sale delivery challan Updated!");
        onClose();
      }
      catch (error) {
        error.data.errors.map((error) => ErrorNotification(error));
      }
    }
    else {
      console.log("Failed to update delivery challan.")
    }
  }

  const fetchUpdatedCustDeliveryDetails = async (updatedCustId) => {
    if (updatedCustId) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/customers/${updatedCustId}`
        );
        const updateCustomers = response.data.data;
        setCustomerDetails(updateCustomers);
      }
      catch {
        console.log("customer not fetch")
      }
    }
  }

  const handleToggleChange = (value) => {
    setItemWiseTax(value);
  }

  const handlePackagingCharge = (amount) => {
    setpackagingCharges(amount);
  }
  
  const handleCustDeliveryUpdate = (updatedCustomer) => {
    fetchUpdatedCustDeliveryDetails(updatedCustomer);
  };

  return (
    <>
      <div className="new-delivery-challan-main-container">
        <SaleCommonPopup onClose={onClose} onSave={handleNewSaleDeliveryChallan} isUpdated={isUpdated} onUpdate={() => handleEditSaleDeliveryChallan(saleDeliveryChallanCopy.id)}
          BtnDisable={BtnDisable}
        />
        <div className="new-delivery-challan-sub-container">
          <div className="new-delivery-challan-first-div">
            {/* <div className="new-delivery-challan-estimate-no-grp">
              <span className="new-delivery-challan-est-no-title">Challan No</span>
              <span className="new-delivery-challan-est-no">DC14/22-23</span>
            </div> */}
            <div className="new-delivery-challan-estimate-date-grp">
              <span className="new-delivery-challan-estdate-title">Challan Date</span>
              <span className="new-delivery-challan-estdate-input">
                <input className="form-control new-delivery-challan-first-div-input"
                  type="date"
                  onChange={(e) => setEstimateDate(e.target.value)}
                  value={isEstimateDate}
                />
              </span>
            </div>
            <div className="new-delivery-challan-reference-grp">
              <span className="new-delivery-challan-est-reference-title">Reference </span>
              <span className="new-delivery-challan-est-reference-input">
                <input className="form-control new-delivery-challan-first-div-input" type="text"
                  onChange={(e) => setQuoteReference(e.target.value)}
                  value={isQuoteReference}
                />
              </span>
            </div>

            {/* <div className="new-delivery-challan-type-grp">
              <span className="new-delivery-challan-type-title">Challan Type</span>
              <select className="form-control new-delivery-challan-first-div-input">
                <option>Job Work</option>
                <option>Supply of Liquid Gas</option>
                <option>Supply on Approval</option>
                <option>Line Sales</option>
                <option>others</option>
              </select>
              <div className="new-delivery-challan-select-icon">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div> */}
          </div>

          <div className="new-delivery-challan-second-div">
            <div className="new-delivery-challan-customer-div">
              <span className="new-delivery-challan-cust-title">Customer</span>
              <span className="new-delivery-challan-cust-search">
                <input
                  className="form-control new-delivery-challan-cust-field"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search customer..."
                />
                {showDropdown && (
                  <ul className="new-delivery-challan-customer-dropdown">
                    {filteredCustomers.length > 0 ? (
                      filteredCustomers.map((customer, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelectCustomer(customer)}
                          className="new-delivery-challan-dropdown-item"
                        >
                          {customer.first_name && customer.last_name
                            ? `${customer.first_name} ${customer.last_name}`
                            : customer.first_name || customer.last_name}
                        </li>
                      ))
                    ) : (
                      <li className="customer-dropdown-item">No customer found</li>
                    )}
                    {showAddCustomer && (
                      <li className="customer-dropdown-item" onClick={handleAddCustomer}>
                        <button className="btn btn-primary" onClick={handleCreateCustomer}>Add Customer</button>
                      </li>
                    )}
                  </ul>
                )}
              </span>
              {customerError && (
                <span className="error-message" style={{ color: 'red', marginTop: '5px' }}>
                  {customerError}
                </span>
              )}
            </div>

            {customerId ? (
              <>
                <div className="new-quote-second-billing">
                  <div className="new-quote-billing-first-div">
                    <span className="new-quote-bill-title">Billing Address</span>
                    <BsPencilSquare onClick={handleCreateCustomer} />
                  </div>
                  <span className="new-quote-billing-address">
                    {customerDetails?.addresses && (
                      <>
                        {customerDetails?.addresses
                          .filter(address => address.address_type === 'billing')
                          .map(billingAddress => (
                            <div key={billingAddress.id}>
                              {billingAddress?.address_line_1},
                              {billingAddress?.address_line_2},
                              {billingAddress?.city},
                              {billingAddress?.state},
                              {billingAddress?.country}
                            </div>
                          ))}
                      </>
                    )}
                  </span>
                </div>

                <div className="new-quote-second-shipping">
                  <div className="new-quote-billing-first-div">
                    <span className="new-quote-shipping-title">Shipping Address</span>
                    <BsPencilSquare onClick={handleCreateCustomer} />
                  </div>
                  <span className="new-quote-shipping-address">
                    {customerDetails?.addresses && (
                      <>
                        {customerDetails?.addresses
                          .filter(address => address.address_type === 'shipping')
                          .map(shippingAddress => (
                            <div key={shippingAddress.id}>
                              {shippingAddress?.address_line_1},
                              {shippingAddress?.address_line_2},
                              {shippingAddress?.city},
                              {shippingAddress?.state},
                              {shippingAddress?.country}
                            </div>
                          ))}
                      </>
                    )}
                  </span>
                </div>
              </>
            ) : (
              ''
            )}
          </div>

          <div className="new-delivery-challan-next-div-group">
            <div className="new-delivery-challan-buyer-order-grp">
              <span className="new-delivery-challan-buyer-order-title">Buyer's Order</span>
              <input className="form-control new-delivery-challan-buyer-order"
                type="number"
                onChange={(e) => setQuoteBuyerOrderNo(e.target.value)}
                value={isQuoteBuyerOrderNo}
              />
            </div>

            {/* <div className="new-delivery-challan-buyer-order-grp">
              <span className="new-delivery-challan-buyer-order-title">Transportation Mode</span>
              <input className="form-control new-delivery-challan-buyer-order" />
            </div>
            <div className="new-delivery-challan-buyer-order-grp">
              <span className="new-delivery-challan-buyer-order-title">Transport Company</span>
              <input className="form-control new-delivery-challan-buyer-order" />
            </div>
            <div className="new-delivery-challan-buyer-order-grp">
              <span className="new-delivery-challan-buyer-order-title">Vehicle No</span>
              <input className="form-control new-delivery-challan-buyer-order" />
            </div>
            <div className="new-delivery-challan-buyer-order-grp">
              <span className="new-delivery-challan-buyer-order-title">Despatch Date</span>
              <input className="form-control new-delivery-challan-buyer-order" />
            </div> */}

            <div className="new-delivery-challan-buyer-order-date-grp">
              <span className="new-delivery-challan-buyer-order-date-title">Buyer's Order Date</span>
              <input className="form-control new-delivery-challan-buyer-order-date"
                type="date"
                onChange={(e) => setQuoteBuyerOrderDate(e.target.value)}
                value={isQuoteBuyerOrderDate} />
            </div>


            <div className="new-delivery-challan-toggle-button-grp">
              <CommonToggleButton onToggleChange={handleToggleChange} />
            </div>
          </div>

          <div className="new-delivery-challan-table">
            <CommonSalePopupTable
              updatedSelectedItems={(items) => updatedSelectedItems(items)}
              totalAmountValue={(totalAmount) => handleTotalAmount(totalAmount)}
              subTotalValue={(subTotal) => handleSubTotal(subTotal)}
              gstValue={(gstValue) => handleGstValue(gstValue)}
              discountValue={(discount) => handleDiscountValue(discount)}
              packagingValue={(packagingCharges) => handlePackagingCharge(packagingCharges)}
              saleDeliveryChallanCopy={saleDeliveryChallanCopy}
              isSaleOrderDeliveryChallan={isSaleOrderDeliveryChallan}
              isGstEnable={(gstStatus) => handleGstEnable(gstStatus)}
              isIgstEnable={(igstStatus) => handleIgstEanble(igstStatus)}
              isTotalTaxApplied={itemWiseTax}
            />
          </div>

          {/* <div className="new-delivery-challan-third-div">
            <div className="new-delivery-challan-note-grp">
              <span className="new-delivery-challan-note-title">Notes</span>
              <span className="new-delivery-challan-note-area">
                <textarea className="new-delivery-challan-note-textarea-input" />
              </span>
            </div>
            <div className="new-delivery-challan-terms-condition-grp">
              <span className="new-delivery-challan-term-title">Terms & Conditions</span>
              <span className="new-delivery-challan-term-textarea">
                <textarea className="new-delivery-challan-term-textarea-input" />
              </span>
            </div>
            <div className="new-delivery-challan-bank-grp">
              <span className="new-delivery-challan-bank-title">Bank</span>
              <span className="new-delivery-challan-bank-input">
                <input className="form-control new-delivery-challan-last-input" />
              </span>
            </div>
            <div className="new-delivery-challan-sales-rep-grp">
              <span className="new-delivery-challan-sale-rep-title">Sales Rep. </span>
              <span className="new-delivery-challan-sale-rep-input">
                <input className="form-control new-delivery-challan-last-input" />
              </span>
            </div>
          </div> */}
        </div>
      </div>
      {isShowCustomerPopup && (
        <div className="new-delivery-challan-customer-popup">
          <CommonCustomerPopup
            selectedCustomer={(customer) => handleSelectedCustomer(customer)}
            onClose={closeCommonCustomerPopup}
            customerDetails={customerDetails}
            onCustomerUpdate={handleCustDeliveryUpdate}
          />
        </div>
      )}
    </>
  );
}

export default NewDeliveryChallan;
