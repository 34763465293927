import React, { useState } from "react";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import ErrorNotification from "../../../Notification/ErrorNotification";
import successNotification from "../../../Notification/SuccessNotification";
import "./MergeTable.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { useTranslation } from "react-i18next";


const MergeTable = ({ onClose, floors, onMergeSuccess }) => {
  const [tablesToMerge, setTablesToMerge] = useState([]);
  const [targetTable, setTargetTable] = useState(null);
  const { t} = useTranslation();

  const handleTableSelect = (tableId) => {
    setTablesToMerge((prevTables) => {
      if (prevTables.includes(tableId)) {
        return prevTables.filter((id) => id !== tableId);
      }
      return [...prevTables, tableId];
    });
  };

  const handleTargetTableSelect = (tableId) => {
    setTargetTable(tableId);
  };

  const handleMergeTables = async () => {
    if (tablesToMerge.length > 0 && targetTable) {
      try {
        await Promise.all(
          tablesToMerge.map(async (tableId) => {
            const table = floors
              .flatMap((floor) => floor.tables)
              .find((t) => t.id === tableId);
            const response = await sendRequest(
              "GET",
              `${BASE_URL}/v1/restaurant/orders/orders/get_table_currrent_order?floor_table_id=${tableId}&floor_id=${table.floor_id}`
            );
            if (response.status === 200) {
              return response.data.order_id;
            }
            throw new Error(`Failed to fetch order for table ${tableId}`);
          })
        );

        const targetTableData = floors
          .flatMap((floor) => floor.tables)
          .find((t) => t.id === targetTable);
        const targetResponse = await sendRequest(
          "GET",
          `${BASE_URL}/v1/restaurant/orders/orders/get_table_currrent_order?floor_table_id=${targetTable}&floor_id=${targetTableData.floor_id}`
        );
        if (targetResponse.status === 200) {
          const floorId = targetTableData.floor_id;
          const mergeResponse = await sendRequest(
            "PATCH",
            `${BASE_URL}/v1/restaurant/orders/orders/merge_table?source_table_ids=[${tablesToMerge.join(
              ","
            )}]&target_table_id=${targetTable}&floor_id=${floorId}`
          );

          if (mergeResponse.status === 200) {
            successNotification("Tables merged successfully");
            setTablesToMerge([]);
            setTargetTable(null);
            if (typeof onMergeSuccess === "function") {
              onMergeSuccess();
            }
            onClose();
          } else {
            throw new Error("Failed to merge tables");
          }
        } else {
          throw new Error("Failed to fetch target table order");
        }
      } catch (error) {
        ErrorNotification(error.message);
      }
    } else {
      ErrorNotification(
        "Please select at least one table to merge and one target table."
      );
    }
  };

  return (
    <div className="container-fluid p-0">
      <div className="modal-header p-2">
        <label className="merge-table-header">{t("Merge Tables")}</label>
        <button className="merge-close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="row merge-table-main">
        <div className="col merge-table-from">
          <h2 className="table-from-header">{t("Select Table to Merge")}</h2>
          <div className="form-table-sub">
            {floors.map((floor) => (
              <div key={floor.id}>
                <div className="row floor-header">
                  <label className="fs-4">{floor.name}</label>
                </div>
                <div className="row tb-data">
                  {floor.tables
                    .filter((table) => table.status === "running")
                    .map((table) => (
                      <label key={table.id} className="ms-3 from-table">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="from-table-option"
                            value={table.id}
                            onChange={() => handleTableSelect(table.id)}
                            checked={tablesToMerge.includes(table.id)}
                            disabled={table.id === targetTable}
                          />
                          <label className="form-check-label table-label">
                            {table.name}
                          </label>
                        </div>
                      </label>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col merge-table-to">
          <h2 className="table-to-header">{t("Select Table to Merge With")}</h2>
          <div className="form-table-to-sub">
            {floors.map((floor) => (
              <div key={floor.id}>
                <div className="row floor-header">
                  <label className="fs-4">{floor.name}</label>
                </div>
                <div className="row tb-data">
                  {floor.tables
                    .filter((table) => table.status === "running")
                    .map((table) => (
                      <label key={table.id} className="from-table ms-3 me-1">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="to-table-option"
                            value={table.id}
                            onChange={() => handleTargetTableSelect(table.id)}
                            checked={targetTable === table.id}
                            disabled={tablesToMerge.includes(table.id)}
                          />
                          <label className="form-check-label table-label">
                            {table.name}
                          </label>
                        </div>
                      </label>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="merge-btn"
          onClick={handleMergeTables}
          disabled={tablesToMerge.length === 0 || targetTable === null}
        >
          {t("Merge")}
        </button>
      </div>
    </div>
  );
};

export default MergeTable;
