import React, { useEffect, useRef, useState } from "react";
import "./EWayBill.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewEWayBill from "./NewEWayBill";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import PaginationControls from "../../Common/PaginationControls";

function EWayBill() {

  const [isEWayBill, setisEWayBill] = useState(false);
  const [isEWayBillFilter, setEWayBillFilter] = useState(false);
  const [eWayBill, setEWayBill] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isEWayBillAction, setEWayBillAction] = useState(false);
  const [selectedEWayBill, setselectedEWayBill] = useState({});
  const [isCopy, setIsCopy] = useState(false);
  const [actionDropdownPosition, setActionDropdownPosition] = useState({ top: 0, left: 0 });

  const openEWayBill = () => {
    setisEWayBill(true);
  }

  const closeEWayBill = () => {
    setisEWayBill(false);
    fetchEWayBill();
    setIsCopy(false);
    setselectedEWayBill({});
  }

  // const handleEWayFilter = () => {
  //   setEWayBillFilter(true);
  // }

  const closeEwayButtonPopup = () => {
    setEWayBillFilter(false);
  }

  const handleEditEwayBill = (editEwayBill) => {
    setselectedEWayBill(editEwayBill);
    setisEWayBill(true);
  }

  const handleCopyEwayBill = (copyEwayBill) => {
    setselectedEWayBill(copyEwayBill);
    setIsCopy(true)
    setisEWayBill(true);
  }

  const handlTableAction = (eWayBill, event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setActionDropdownPosition({
      top: rect.bottom + window.scrollY,
    });
    setselectedEWayBill(eWayBill);
    setEWayBillAction(true);
  }

  useEffect(() => {
    const handleFilterClickOutside = (event) => {
      if (
        eWayFilterRef.current &&
        !eWayFilterRef.current.contains(event.target)
      ) {
        setEWayBillFilter(false);
      }
    };

    if (isEWayBillFilter) {
      document.addEventListener("mousedown", handleFilterClickOutside);
    } else {
      document.removeEventListener("mousedown", handleFilterClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleFilterClickOutside);
    };
  }, [isEWayBillFilter]);

  // useEffect(() => {
  //   const handleActionClickOutside = (event) => {
  //     if (
  //       eWayActionRef.current &&
  //       !eWayActionRef.current.contains(event.target)
  //     ) {
  //       setEWayBillAction(false);
  //     }
  //   };

  //   if (isEWayBillAction) {
  //     document.addEventListener("mousedown", handleActionClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleActionClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleActionClickOutside);
  //   };
  // }, [isEWayBillAction]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (eWayActionRef.current && !eWayActionRef.current.contains(event.target)) {
        setEWayBillAction(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const eWayFilterRef = useRef(null);
  const eWayActionRef = useRef(null);

  const fetchEWayBill = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/transactions/e_way_bills?page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setEWayBill(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching sale order:", error);
    }
  };

  const handleEWayDelete = async (ewayBillId) => {
    if (ewayBillId) {
      try {
        const response = await sendRequest("DELETE", `${BASE_URL}/v1/transactions/e_way_bills/${ewayBillId}`);
        if (response.status === 200) {
          console.log("EWay Bill deleted successfully!");
          fetchEWayBill(currentPage, recordsPerPage);
          setEWayBillAction(false);
        } else {
          console.log("Failed to delete the eway bill. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting the eway bill:", error);
        console.log("An error occurred while deleting the eway bill.");
      }
    }
  };

  useEffect(() => {
    fetchEWayBill(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const handleEWayCreated = (value) => {
    setEWayBill(prevEWayBills => [...prevEWayBills, ...value]);
    setTotalRecords(prevTotalRecords => prevTotalRecords + 1);
  }

  return (
    <>
      <div className="eway-bill-main-container">
        <div className="eway-bill-first-div">
          <span className="eway-title">E-Way Bill ({totalRecords})</span>
          <span className="eway-btn-grp">
            <button className="eway-new-btn" onClick={openEWayBill}>Add New</button>
          </span>
        </div>
        <div className="eway-bill-second-div">
          <div className="eway-bill-pagination-top">
            <EntriesPerPageDropdown recordPerPage={recordsPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
          </div>
          {/* <div>
            <div className="eway-second-icon-grp" onClick={handleEWayFilter}>
              <span className="eway-turn-right-icon">
                <FontAwesomeIcon icon={faFilter} />
              </span>
            </div>
          </div> */}
        </div>
        <div className="eway-bill-table-group">
          <table className="eway-bill-table">
            <thead>
              <tr className="eway-bill-thead-row">
                <th className="eway-tab-th">Supply Type</th>
                <th className="eway-tab-th">Sub Type</th>
                <th className="eway-tab-th">Transporter</th>
                <th className="eway-tab-th">Transporter GSTIN</th>
                <th className="eway-tab-th">Distance</th>
                <th className="eway-tab-th">Address</th>
                <th className="eway-tab-th">Mode</th>
                <th className="eway-tab-th">Vehical Type</th>
                <th className="eway-tab-th">Vehical No</th>
                <th className="eway-tab-th">Doc No</th>
                <th className="eway-tab-th">Doc Date</th>
                <th className="eway-tab-th">Action</th>
              </tr>
            </thead>
            <tbody className="eway-bill-table-body">
              {eWayBill.map((row, index) => (
                <tr key={index} className="eway-bill-tbody-row">
                  <td className="eway-tab-td">{row.transaction_supply_type}</td>
                  <td className="eway-tab-td">{row.transaction_sub_type}</td>
                  <td className="eway-tab-td">{row.transporter}</td>
                  <td className="eway-tab-td">{row.transporter_gstin}</td>
                  <td className="eway-tab-td">{row.distance}</td>
                  <td className="eway-tab-td">{row.source_address}</td>
                  <td className="eway-tab-td">{row.mode_of_transportation}</td>
                  <td className="eway-tab-td">{row.vehicle_type}</td>
                  <td className="eway-tab-td">{row.vehicle_no}</td>
                  <td className="eway-tab-td">{row.transporter_doc_no}</td>
                  <td className="eway-tab-td">{row.transporter_doc_date}</td>
                  <td className="eway-tab-td">
                    <FontAwesomeIcon icon={faCaretDown} onClick={(e) => handlTableAction(row, e)} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}

        {eWayBill.length === 0 && (
          <div className="eway-bill-no-data-message">
            No sale order found.
          </div>
        )}
      </div>

      {isEWayBill && (
        <div className="show-new-eway-popup">
          <NewEWayBill onClose={closeEWayBill} selectedEWayBill={selectedEWayBill} isCopy={isCopy} ewayBillcreated={handleEWayCreated} />
        </div>
      )}

      {isEWayBillFilter && (
        <div className="show-eway-filter-popup-container" ref={eWayFilterRef}>
          <div className="show-eway-filter-div">
            <span className="show-eway-filter-label">Transporter / GSTIN/ Doc No / Vehicle No.</span>
            <input type="text" className="eway-filter-input-field" />
          </div>
          <div className="show-eway-filter-div">
            <span className="show-eway-filter-label">Transport</span>
            <select>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
          </div>
          <div className="show-eway-filter-div">
            <span className="show-eway-filter-label">Supply Type</span>
            <select>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
          </div>
          <div className="show-eway-filter-div">
            <span className="show-eway-filter-label">Sub Type</span>
            <select>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
          </div>
          <div className="show-eway-filter-date-grp">
            <div className="eway-filter-start-grp">
              <span className="eway-filter-btn-label">Start Date</span>
              <input type="date" className="eway-filter-btn-input" />
            </div>
            <div className="eway-filter-end-grp">
              <span className="eway-filter-btn-label">End Date</span>
              <input type="date" className="eway-filter-btn-input" />
            </div>
          </div>
          <div className="show-eway-filter-button-group">
            <button className="eway-filter-btn">Search</button>
            <button className="eway-filter-btn" onClick={closeEwayButtonPopup}>
              Clear
            </button>
          </div>
        </div>
      )}

      {isEWayBillAction && (
        <div
          className="eway-bill-down-icon-action"
          ref={eWayActionRef}
          style={{
            position: 'absolute',
            top: `${actionDropdownPosition.top}px`,
          }}>
          <ul className="eway-bill-down-icon-action-ul">
            <li className="eway-bill-down-icon-action-li" onClick={() => handleEditEwayBill(selectedEWayBill)}>
              Edit
            </li>
            <li className="eway-bill-down-icon-action-li" onClick={() => handleCopyEwayBill(selectedEWayBill)}>
              Copy
            </li>
            <li className="eway-bill-down-icon-action-li" onClick={() => handleEWayDelete(selectedEWayBill.id)}>
              Delete
            </li>
          </ul>
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default EWayBill;
