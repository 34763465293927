import React, { useEffect, useRef, useState } from "react";
import "./BillPayment.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewBillPayment from "./NewBillPayment";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import PaginationControls from "../../Common/PaginationControls";

function BillPayment() {
  const [openNewBillPayment, setNewBillPayment] = useState(false);
  const [billPayment, setBillPayment] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [billPayActionOption, setBillPayActionOption] = useState(false);
  const [selectedBillPay, setSelectedBillPay] = useState("");
  const [billPayDropdownPosition, setBillPayDropdownPosition] = useState({
    top: 0,
    left: 0,
  });
  const [title, setTitle] = useState();
  const [isUpdated, setIsUpdated] = useState(false);

  const handlNewBillPay = () => {
    setTitle("New Bill Payment");
    setIsUpdated(false);
    setNewBillPayment(true);
  };

  const handleCopyBillPayment = (row) => {
    setTitle("Copy Bill Payment");
    setIsUpdated(false);
    setSelectedBillPay({ ...row, isCopy: true });
    setNewBillPayment(true);
  };

  const closePurchaseBillPay = () => {
    setNewBillPayment(false);
  };

  const fetchBillPayment = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/transactions/payment_transactions?page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setBillPayment(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching purchase bill payment:", error);
    }
  };

  const handleBillPayDelete = async (billPayId) => {
    if (billPayId) {
      try {
        const response = await sendRequest(
          "DELETE",
          `${BASE_URL}/v1/transactions/payment_transactions/${billPayId}?type=receipt&is_purchase=true`
        );
        if (response.status === 200) {
          console.log("Bill payment deleted successfully!");
          fetchBillPayment(currentPage, recordsPerPage);
          setBillPayActionOption(false);
        } else {
          console.log("Failed to delete the bill payment. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting the bill payment:", error);
      }
    }
  };

  useEffect(() => {
    fetchBillPayment(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const handleBillPayActionOption = (billPay, event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setBillPayDropdownPosition({
      top: rect.bottom + window.scrollY,
    });
    setSelectedBillPay(billPay);
    setBillPayActionOption(true);
  };

  const actionBillPayrRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        actionBillPayrRef.current &&
        !actionBillPayrRef.current.contains(event.target)
      ) {
        setBillPayActionOption(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const EditBillpayment = (row) => {
    setNewBillPayment(true);
    setTitle("Edit Bill Payment");
    setIsUpdated(true);
    setSelectedBillPay(row);
  };

  return (
    <>
      <div className="purchase-bill-pay-main-container">
        <div className="purchase-bill-pay-container-first-div">
          <div className="purchase-bill-pay-first-left">
            <span className="all-purchase-bill-pay-show">
              All Bill Payment {totalRecords > 0 ? `(${totalRecords})` : ""}
            </span>
          </div>
          <div className="purchase-bill-pay-first-right">
            <button
              className="purchase-bill-pay-first-right-botton"
              onClick={handlNewBillPay}
            >
              New bill Payment
            </button>
          </div>
        </div>

        <div className="purchase-bill-payment-pagination">
          <EntriesPerPageDropdown
            recordPerPage={recordsPerPage}
            handleRecordPerPageChange={handleRecordPerPageChange}
          />
        </div>

        <div className="purchase-bill-pay-table-group">
          <table className="purchase-bill-pay-table">
            <thead>
              <tr className="purchase-bill-pay-thead-row">
                <th className="purchase-bill-pay-tab-th">Date</th>
                <th className="purchase-bill-pay-tab-th">Bill Payment No</th>
                <th className="purchase-bill-pay-tab-th">Contact/Account</th>
                <th className="purchase-bill-pay-tab-th">Payment Mode</th>
                <th className="purchase-bill-pay-tab-th">Ref.No</th>
                <th className="purchase-bill-pay-tab-th text-end">Amount</th>
                <th className="purchase-bill-pay-tab-th text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {billPayment.map((row, index) => (
                <tr key={index}>
                  <td className="purchase-bill-pay-tab-td">{row.date}</td>
                  <td className="purchase-bill-pay-tab-td">
                    {row.bill_payment_number}
                  </td>
                  <td className="purchase-bill-pay-tab-td">
                    {row.supplier?.supplier_name}
                  </td>
                  <td className="purchase-bill-pay-tab-td">
                    {row.payment_mode}
                  </td>
                  <td className="purchase-bill-pay-tab-td">
                    {row.reference_no}
                  </td>
                  <td className="purchase-bill-pay-tab-td text-end">
                    {row.amount_paid}
                  </td>
                  <td className="purchase-bill-pay-tab-td text-center">
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="purchase-bill-pay-table-body-td"
                      onClick={(e) => handleBillPayActionOption(row, e)}
                    />
                  </td>
                  {billPayActionOption && (
                    <div
                      className="purchase-bill-payment-icon-action"
                      ref={actionBillPayrRef}
                      style={{
                        position: "absolute",
                        top: `${billPayDropdownPosition.top}px`,
                      }}
                    >
                      <ul className="purchase-bill-payment-down-icon-action-ul">
                        <li
                          className="purchase-bill-payment-down-icon-action-li"
                          onClick={() => handleCopyBillPayment(selectedBillPay)}
                        >
                          Copy
                        </li>
                        <li
                          className="purchase-bill-payment-down-icon-action-li"
                          onClick={() => EditBillpayment(row)}
                        >
                          Edit
                        </li>
                        <li
                          className="purchase-bill-payment-down-icon-action-li"
                          onClick={() =>
                            handleBillPayDelete(selectedBillPay.id)
                          }
                        >
                          Delete
                        </li>
                      </ul>
                    </div>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}

        {billPayment.length === 0 && (
          <div className="purchase-bill-pay-no-data-message">
            No bill payment found.
          </div>
        )}
      </div>

      {openNewBillPayment && (
        <div className="new-purchase-bill-pay-popup-container">
          <NewBillPayment
            fetchBillPayment={fetchBillPayment}
            title={title}
            isUpdated={isUpdated}
            onClose={closePurchaseBillPay}
            selectedBillPay={selectedBillPay}
          />
        </div>
      )}

      <Copyrights />
    </>
  );
}

export default BillPayment;