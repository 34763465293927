import React from 'react';
import './EntriesPerPageStyle.css'

const EntriesPerPageDropdown = ({ recordPerPage, handleRecordPerPageChange }) => (
  <div className= 'entry-per-page'>
    <div >
      <select className='form-select' id="entries" value={recordPerPage} onChange={handleRecordPerPageChange}>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
    </div>
    <div >
      <label htmlFor="entries" className='label-per-page'>Entries per page</label>
    </div>
  </div>
);

export default EntriesPerPageDropdown;
