// DashboardHeader.jsx

import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { faFileExcel, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CalendarModal from './calendarModal/CalendarModal'; // Adjust the path as needed
import './dashboardh.css';

const dropdown = ["Today", "This week", "This month", "This year"]
const DashboardHeader = ({totalPrice, cash, online, unpaid}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <div className="container-content">
      <div className="date-selector-container">
        <div>
          <select>
            {dropdown.map((option, index) => (
              <option key={index} value={option.toLowerCase().replace(/\s+/g, '-')}>{option}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="start-date">Start Date</label>
          <DatePicker
            className="date-picker"
            selected={startDate}
            onChange={handleStartDateChange}
            onClick={openModal}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
          <label htmlFor="end-date">End Date</label>
          <DatePicker
            className="date-picker"
            selected={endDate}
            onChange={handleEndDateChange}
            onClick={openModal}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </div>
        <div>
          <button className="action-button">
            <FontAwesomeIcon icon={faFileExcel} style={{ color: 'blue' }} /> Excel Report
          </button>
          <button className="action-button">
            <FontAwesomeIcon icon={faPrint} style={{ color: 'green' }} /> Print
          </button>
        </div>
      </div>

      {/* <br /> */}
      <div>
        {/* <TransactionList startDate={startDate} endDate={endDate}/> */}
      </div>

      <div className="summary-container">
        <div>
          <p>Online</p>
          <h3>₹{online}</h3>
        </div>
        <div>
          <p>Cash</p>
          <h3>₹{cash}</h3>
        </div>
        <div className="summary-item">
          <p>Unpaid</p>
          <h3>₹{unpaid}</h3>
        </div>
        <div className="summary-item">
          <p>Total</p>
          <h3>₹{totalPrice}</h3>
        </div>
      </div>

      <CalendarModal isOpen={modalOpen} onClose={closeModal} />
    </div>
  );
};

export default DashboardHeader;
