import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./Kitchenceff.css";
import ItemStatusCard from "./ItemStatusCard";
import successNotification from "../../Notification/SuccessNotification";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";
class Kitchenceff extends Component {
  state = {
    boardData: {
      lanes: [
        { id: "placed", title: "Placed", cards: [] },
        { id: "priority_list", title: "Priority list", cards: [] },
        { id: "preparation", title: "Preparation", cards: [] },
        { id: "ready_to_serve", title: "Ready to serve", cards: [] },
      ],
    },
    itemStatusData: {
      placed: [],
      priority_list: [],
      preparation: [],
      ready_to_serve: [],
    },
  };

  componentDidMount() {
    this.fetchAllOrders();
    this.fetchAllItemStatus();
  }

  fetchAllOrders = async () => {
    const statuses = [
      "placed",
      "priority_list",
      "preparation",
      "ready_to_serve",
    ];
    try {
      const ordersPromises = statuses.map((status) =>
        sendRequest(
          "GET",
          `${BASE_URL}/v1/restaurant/orders/orders/order_status_display?status=${status}`
        )
      );



      const responses = await Promise.all(ordersPromises);

      const newBoardData = { ...this.state.boardData };
      responses.forEach((response, index) => {
        const orders = response.data;
        const status = statuses[index];
        newBoardData.lanes.find((lane) => lane.id === status).cards =
          orders.map((order) => ({
            id: order.kot_number.toString(),
            title: order.table_name,
            description: order.items
              .map((item) => `${item.item_name} x ${item.quantity}`)
              .join(", "),
            kotNo: order.kot_number,
            date: order.date,
            time: order.time,
          }));
      });

      this.setState({ boardData: newBoardData });
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  fetchAllItemStatus = async () => {
    const statuses = [
      "placed",
      "priority_list",
      "preparation",
      "ready_to_serve",
    ];
    try {
      const itemStatusPromises = statuses.map((status) =>
      sendRequest(
        "GET",
        `${BASE_URL}/v1/restaurant/orders/orders/live_order_items?status=${status}`
      ));

      const responses = await Promise.all(itemStatusPromises);

      const newItemStatusData = { ...this.state.itemStatusData };
      responses.forEach((response, index) => {
        const status = statuses[index];
        newItemStatusData[status] = response.data;
      });

      this.setState({ itemStatusData: newItemStatusData });
    } catch (error) {
      console.error("Error fetching item status:", error);
    }
  };

  onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const sourceLaneIndex = this.state.boardData.lanes.findIndex(
      (lane) => lane.id === source.droppableId
    );
    const destinationLaneIndex = this.state.boardData.lanes.findIndex(
      (lane) => lane.id === destination.droppableId
    );

    const sourceLane = this.state.boardData.lanes[sourceLaneIndex];
    const destinationLane = this.state.boardData.lanes[destinationLaneIndex];

    const [movedCard] = sourceLane.cards.splice(source.index, 1);
    destinationLane.cards.splice(destination.index, 0, movedCard);

    const newBoardData = { ...this.state.boardData };
    newBoardData.lanes[sourceLaneIndex] = sourceLane;
    newBoardData.lanes[destinationLaneIndex] = destinationLane;

    this.setState({ boardData: newBoardData });

    try {
      sendRequest(
        "put",
        `${BASE_URL}/v1/restaurant/orders/orders/update_order_status?id=${movedCard.id}`,{
          status: destination.droppableId,
        }
      );
      await this.fetchAllItemStatus();
      successNotification("Order status updated successfully");
    } catch (error) {
      console.error("Error updating order status:", error);
      alert("Failed to update order status");
      // Revert the state change if the API call fails
      this.setState({ boardData: this.state.boardData });
    }
  };

  render() {
    return (
      <div className="Kitchenceff">
        <div className="Kitchenceff-header">
          <h3>Kitchen Display System</h3>
          <button 
            className="logout-button" 
            onClick={this.props.handleLogout}
            style={{
              position: 'absolute',
              top: '15px',
              right: '16px',
              padding: '8px 16px',
              backgroundColor: '#f44336',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Logout
          </button>
        </div>
        <div className="Kitchenceff-intro col-md-8">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="react-trello">
              {this.state.boardData.lanes.map((lane) => (
                <Droppable droppableId={lane.id} key={lane.id}>
                  {(provided) => (
                    <div
                      className="react-trello-lane"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <div className="react-trello-lane-header">
                        <span className="react-trello-lane-header-title">
                          {lane.title}
                        </span>
                      </div>
                      <div className="react-trello-lane-content">
                        {lane.cards.map((card, index) => (
                          <Draggable
                            key={card.id}
                            draggableId={card.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className={`react-trello-card ${this.getCardClass(
                                  lane.id
                                )}`}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <div className="react-trello-card-info">
                                  <div className="react-trello-sub-card">
                                    <div className="react-trello-card-title">
                                      {card.title}
                                    </div>
                                    <div className="alldetails">
                                      <div>
                                        <strong>KOT No.:</strong> {card.kotNo}
                                      </div>
                                      <div>
                                        <strong>Date:</strong> {card.date}
                                      </div>
                                      <div>
                                        <strong>Time:</strong> {card.time}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="react-trello-card-sub">
                                  <div className="react-trello-card-description">
                                    <span>
                                      {this.formatDescription(card.description)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              ))}
            </div>
          </DragDropContext>
        </div>
        <ItemStatusCard itemStatusData={this.state.itemStatusData} />
      </div>
    );
  }

  getCardClass = (laneId) => {
    switch (laneId) {
      case "placed":
        return "placed";
      case "priority_list":
        return "priority-list";
      case "preparation":
        return "preparation";
      case "ready_to_serve":
        return "ready-to-serve";
      default:
        return "";
    }
  };

  formatDescription = (description) => {
    const items = description.split(", ");
    return (
      <div>
        {items.map((item, index) => (
          <div key={index} className="description-item">
            {item}
          </div>
        ))}
      </div>
    );
  };
}

export default Kitchenceff;
