import React, { useEffect, useRef, useState } from "react";
import "./SaleInvoice.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import NewSaleInvoice from "./NewSaleInvoice";
import Copyrights from "../../Copyrights";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import PaginationControls from "../../Common/PaginationControls";
import DeliveryChallanInvoice from "../CompanySaleInvoices/DeliveryChallanInvoice";

function SaleInvoice() {

  const [visibleInvoiceColumns, setVisibleInvoiceColumns] = useState({
    refNo: true,
    // dueDays: true,
    status: true,
    email: true,
    mobileNumber: true,
    // gstIn: true,
    // saleRep: true,
    buyerOrderNo: true,
    buyerOrderDate: true,
    eInvoiceDetail: true,
  });

  const downActionSaleInvoice = useRef(null);
  const columnSaleInvoice = useRef(null);
  const [isShowSaleInvoiceAction, setShowSaleInvoiceAction] = useState(false);
  const [isShowSaleInvoiceColumn, setShowSaleInvoiceColumn] = useState(false);
  const [isShowNewSaleInvoice, setShowNewSaleInvoice] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const [saleInvoiceCopy, setSaleInvoiceCopy] = useState("");
  const [saleInvoiceUpdated, setSaleInvoiceUpdated] = useState(false);
  const [isSaleInvoicePrint, setSaleInvoicePrint] = useState("");
  const [isOpenInvoicePrint, setOpenInvoiceChallanPrint] = useState(false);
  const [actionDropdownPosition, setActionDropdownPosition] = useState({ top: 0, left: 0 });

  const handleSaleInvoiceAction = (invoice, event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setActionDropdownPosition({
      top: rect.bottom + window.scrollY,
    });
    setSelectedInvoice(invoice)
    setShowSaleInvoiceAction(true);
  }

  const handleSaleInvoiceColumnFilter = () => {
    setShowSaleInvoiceColumn(true);
  }

  const handleCheckboxInvoiceChange = (column) => {
    setVisibleInvoiceColumns((prevState) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const handleApplyInvoiceFilter = () => {
    setShowSaleInvoiceColumn(false);
  };

  const OpenNewSaleInvoicePopup = () => {
    setShowNewSaleInvoice(true);
  }

  const closeNewSaleInvoicePopup = () => {
    setShowNewSaleInvoice(false);
    setSaleInvoiceCopy("")
    setSaleInvoiceUpdated(false);
  }

  useEffect(() => {
    const handleInvoiceActionClickOutside = (event) => {
      if (
        downActionSaleInvoice.current &&
        !downActionSaleInvoice.current.contains(event.target)
      ) {
        setShowSaleInvoiceAction(false);
      }
    };

    if (isShowSaleInvoiceAction) {
      document.addEventListener("mousedown", handleInvoiceActionClickOutside);
    } else {
      document.removeEventListener("mousedown", handleInvoiceActionClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleInvoiceActionClickOutside);
    };
  }, [isShowSaleInvoiceAction]);

  useEffect(() => {
    const handleInvoiceFilterClickOutside = (event) => {
      if (
        columnSaleInvoice.current &&
        !columnSaleInvoice.current.contains(event.target)
      ) {
        setShowSaleInvoiceColumn(false);
      }
    };

    if (isShowSaleInvoiceColumn) {
      document.addEventListener("mousedown", handleInvoiceFilterClickOutside);
    } else {
      document.removeEventListener("mousedown", handleInvoiceFilterClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleInvoiceFilterClickOutside);
    };
  }, [isShowSaleInvoiceColumn]);

  const fetchInvoices = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/transactions/entries?type=invoice&page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setInvoices(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching invoice:", error);
    }
  };

  const handleInvoiceDelete = async (invoiceId) => {
    if (invoiceId) {
      try {
        const response = await sendRequest("DELETE", `${BASE_URL}/v1/transactions/entries/${invoiceId}?type=invoice`);
        if (response.status === 200) {
          console.log("Invoice deleted successfully!");
          fetchInvoices(currentPage, recordsPerPage);
          setShowSaleInvoiceAction(false);
        } else {
          console.log("Failed to delete the Invoice. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting the Invoice:", error);
      }
    }
  };

  const handleSaleInvoiceCopy = async (saleInvoiceCopyId, updated) => {
    if (updated) {
      setSaleInvoiceUpdated(updated);
    }
    if (saleInvoiceCopyId) {
      try {
        const response = await sendRequest("GET", `${BASE_URL}/v1/transactions/entries/${saleInvoiceCopyId}?type=invoice`);
        setSaleInvoiceCopy(response.data.entry);
        setShowNewSaleInvoice(true);
      } catch (error) {
        console.error("Error copy the sale invoice:", error);
        console.log("An error occurred while deleting the copy sale invoice.");
      }
    }
  };

  const handleSaleInvoicePrint = async (saleInvoicePrintId) => {
    if (saleInvoicePrintId) {
      try {
        const response = await sendRequest("GET", `${BASE_URL}/v1/transactions/entries/${saleInvoicePrintId}?type=invoice`);
        setSaleInvoicePrint(response.data.entry);
        setOpenInvoiceChallanPrint(true);
      } catch (error) {
        console.error("Error copy the sale invoice print:", error);
        console.log("An error occurred while deleting the sale invoice print.");
      }
    }
  };

  useEffect(() => {
    fetchInvoices(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const handleNewInvoiceCreated = (value) => {
    setInvoices(prevInvoices => [...prevInvoices, ...value]);
    setTotalRecords(prevTotalRecords => prevTotalRecords + 1);
  }

  const handleSaleInvoiceClose = () => {
    setOpenInvoiceChallanPrint(false);
  }

  return (
    <>
      <div className="sale-invoice-main-container">
        <div className="sale-invoice-container-first-div">
          <div className="sale-invoice-first-left">
            <span className="all-sale-invoice-show">All Invoice ({totalRecords})</span>
          </div>
          <div className="sale-invoice-first-right">
            <button
              className="sale-invoice-first-right-btn"
              onClick={OpenNewSaleInvoicePopup}
            >
              New Invoice
              {/* <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span> */}
            </button>
          </div>
        </div>

        <div className="sale-invoice-container-second-div">
          <div className="sale-invoice-second-div-right">
            <div className="sale-invoice-pagination-header">
              <EntriesPerPageDropdown recordPerPage={recordsPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
            </div>
            <div className="sale-invoice-second-div-part">
              {/* <div className="sale-invoice-second-drop-first">
                <div className="sale-invoice-turn-icon-grp">
                  <span className="sale-invoice-turn-right-icon">
                    <FontAwesomeIcon icon={faArrowsTurnRight} />
                  </span>
                </div> */}
              {/* <span className="sale-invoice-icon-between"></span> */}
              {/* <div className="sale-invoice-down-icon-grp" >
                  <span className="sale-invoice-caret-down-icon">
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </div>
              </div> */}
              {/* <div className="sale-invoice-second-filter" >
                <span className="sale-invoice-filter-icon">
                  <FontAwesomeIcon icon={faFilter} />
                </span>
              </div> */}
              {/* <div
                className="sale-invoice-second-three-dot-group"
                onClick={handleSaleInvoiceColumnFilter}
              >
                <span className="sale-invoice-ellips-icon">
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </span>
              </div> */}
            </div>
          </div>
        </div>

        <div className="sale-invoice-table-group">
          <table className="sale-invoice-table">
            <thead>
              <tr className="sale-invoice-thead-row">
                <th className="sale-invoice-th">Date</th>
                <th className="sale-invoice-th">Invoice No</th>
                <th className="sale-invoice-th">Contact </th>
                {visibleInvoiceColumns.buyerOrderNo && (
                  <th className="sale-invoice-th">Buyer's Order No# </th>
                )}
                {visibleInvoiceColumns.buyerOrderDate && (
                  <th className="sale-invoice-th">Buyer's Order Date </th>
                )}
                {visibleInvoiceColumns.refNo && (
                  <th className="sale-invoice-th">Ref. No</th>
                )}
                {/* {visibleInvoiceColumns.gstIn && (
                  <th className="sale-invoice-th">GSTIN </th>
                )} */}
                {/* {visibleInvoiceColumns.dueDays && (
                  <th className="sale-invoice-th">Due Days</th>
                )} */}
                {visibleInvoiceColumns.status && (
                  <th className="sale-invoice-th">Status</th>
                )}
                {visibleInvoiceColumns.email && (
                  <th className="sale-invoice-th">Email</th>
                )}
                {visibleInvoiceColumns.mobileNumber && (
                  <th className="sale-invoice-th">Mobile Number</th>
                )}
                {/* {visibleInvoiceColumns.saleRep && (
                  <th className="sale-invoice-th">Sales Rep</th>
                )} */}
                <th className="sale-invoice-th">Amount</th>
                {/* <th className="sale-invoice-th">Due Amount</th> */}

                <th className="sale-invoice-th">Action</th>
              </tr>
            </thead>
            <tbody className="sale-invoice-table-body">
              {invoices.map((row, index) => (
                <tr key={index} className="sale-invoice-tbody-row">
                  <td className="sale-invoice-td">{row.date}</td>
                  <td className="sale-invoice-td">{row.bill_number}</td>
                  <td className="sale-invoice-td">{row.customer.customer_name}</td>
                  {visibleInvoiceColumns.buyerOrderNo && (
                    <td className="sale-invoice-td">{row.order_no}</td>
                  )}
                  {visibleInvoiceColumns.buyerOrderDate && (
                    <td className="sale-invoice-td">{row.order_date}</td>
                  )}
                  {visibleInvoiceColumns.refNo && (
                    // <td className="sale-invoice-td">{row.reference_no}</td>
                    <td className="sale-invoice-td">
                      {row.reference_no && Array.isArray(row.reference_no) ? row.reference_no.join(' ') : row.reference_no}
                    </td>
                  )}
                  {/* {visibleInvoiceColumns.gstIn && (
                    <td className="sale-invoice-td">{row.gstIn}</td>
                  )} */}
                  {/* {visibleInvoiceColumns.dueDays && (
                    <td className="sale-invoice-td">{row.due_days}</td>
                  )} */}
                  {visibleInvoiceColumns.status && (
                    <td className="sale-invoice-td">{row.status}</td>
                  )}
                  {visibleInvoiceColumns.email && (
                    <td className="sale-invoice-td">{row.customer.email}</td>
                  )}
                  {visibleInvoiceColumns.mobileNumber && (
                    <td className="sale-invoice-td">{row.customer.mobile_number}</td>
                  )}
                  {/* {visibleInvoiceColumns.saleRep && (
                    <td className="sale-invoice-td">{row.saleRep}</td>
                  )} */}
                  <td className="sale-invoice-td">{row.total_amount}</td>

                  {/* <td className="sale-invoice-td">{row.due_amount}</td> */}
                  <td className="sale-invoice-td">
                    <FontAwesomeIcon icon={faCaretDown} onClick={(e) => handleSaleInvoiceAction(row, e)} className="sale-invoice-action-down" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}

        {invoices.length === 0 && (
          <div className="invoice-no-data-message">
            No Invoices found.
          </div>
        )}

        {isShowSaleInvoiceAction && (
          <div className="sale-invoice-action-popup"
            ref={downActionSaleInvoice}
            style={{
              position: 'absolute',
              top: `${actionDropdownPosition.top}px`,
            }}
          >
            <ul className="sale-invoice-down-icon-action-ul">
              <li className="sale-invoice-down-icon-action-li" onClick={() => handleSaleInvoiceCopy(selectedInvoice.id, true)}>Edit</li>
              <li className="sale-invoice-down-icon-action-li" onClick={() => handleInvoiceDelete(selectedInvoice.id)}>
                Delete
              </li>
              {/* <li className="sale-invoice-down-icon-action-li">Create Credit Note</li>
              <li className="sale-invoice-down-icon-action-li">Cancel</li>
              <li className="sale-invoice-down-icon-action-li">Receipt</li>
              <li className="sale-invoice-down-icon-action-li">Use Credit</li> */}
              <li className="sale-invoice-down-icon-action-li" onClick={() => handleSaleInvoicePrint(selectedInvoice.id)}>Print Preview</li>
              {/* <li className="sale-invoice-down-icon-action-li">Send</li>
              <li className="sale-invoice-down-icon-action-li">Send SMS</li>
              <li className="sale-invoice-down-icon-action-li">Send WhatsApp</li> */}
              <li className="sale-invoice-down-icon-action-li" onClick={() => handleSaleInvoiceCopy(selectedInvoice.id)}>Copy</li>
              {/* <li className="sale-invoice-down-icon-action-li">Create Delivery Challan</li>
              <li className="sale-invoice-down-icon-action-li">Eway Bill</li>
              <li className="sale-invoice-down-icon-action-li">Associate Eway Bill</li> */}
            </ul>
          </div>
        )}

        {isShowSaleInvoiceColumn && (
          <div className="sale-invoice-column-filter-popup" ref={columnSaleInvoice}>
            <form className="sale-invoice-column-filter-form">
              {Object.keys(visibleInvoiceColumns).map((column) => (
                <div key={column} className="sale-invoice--label-input-grp">
                  <label>
                    <input
                      type="checkbox"
                      className="sale-invoice--visible-column-input"
                      checked={visibleInvoiceColumns[column]}
                      onChange={() => handleCheckboxInvoiceChange(column)}
                    />
                    {column.charAt(0).toUpperCase() + column.slice(1)}
                  </label>
                </div>
              ))}
              <button type="button" onClick={handleApplyInvoiceFilter} className="sale-invoice-visible-box-btn">
                Apply
              </button>
            </form>
          </div>
        )}

        {isShowNewSaleInvoice && (
          <div className="open-new-sale-invoice-popup">
            <NewSaleInvoice onClose={closeNewSaleInvoicePopup} saleInvoiceCopy={saleInvoiceCopy} isUpdated={saleInvoiceUpdated} invoiceCreated={handleNewInvoiceCreated} />
          </div>
        )}

        {isOpenInvoicePrint &&
          <div className="open-new-sale-invoice-popup">
            <div className="new-sale-invoice-popup-subcontainer">
              <DeliveryChallanInvoice isSaleInvoicePrint={isSaleInvoicePrint} onClose={handleSaleInvoiceClose} />
            </div>
          </div>
        }
        <Copyrights />
      </div>
    </>
  );
}

export default SaleInvoice;
