// import React, { useState, useEffect } from "react";
// import { BASE_URL } from "../../Url/constants";
// import { getToken } from "../SecureStorage/Token";
// import './SelectDisignations.css';
// import { sendRequest } from "../../ApiRequest";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

// function SelectDesignation({ setIsLoggedIn, onRoleChange, setRoleId }) {
//   const token = getToken();
//   const [roles, setRoles] = useState([]);
//   const [setRolePermission] = useState("");

//   async function fetchData() {
//     const url = `${BASE_URL}/v1/setting/role_permissions`;
//     try {
//       const response = await sendRequest("GET", url, null, token);
//       if (response.status) {
//         setRoles(response.data.data);
//       } else {
//         setIsLoggedIn(false);
//         window.location.reload();
//         throw new Error(response.data.errors[0]);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [setIsLoggedIn, token]);

//   const handleRoleChange = (e) => {
//     const roleId = e.target.value;
//     setRoleId(roleId)
//     fetchData();
//     const role = roles.find((role) => role.id === Number(roleId));
//     onRoleChange(role, role.permissions);
//     setRolePermission(role.permissions);

//   };

//   const filteredRoles = roles.filter(
//     (role) => role.role_name !== "admin" && role.role_name !== "owner"
//   );
//   return (
//     <div className="col-md-6 position-relative">
//       <select
       
//         className="form-control select-input w-25 h-25 text-dark fa-10x"
//         onChange={handleRoleChange}
//       >
//         <option value="">Select Role</option>
//         {filteredRoles.map((role) => (
//           <option key={role.id} value={role.id}>
//             {role.role_name.charAt(0).toUpperCase() + role.role_name.slice(1)}
//           </option>
//         ))}
//       </select>
//       <span
//         className="staff-dropdown-icon"
//         style={{
//           position: "absolute",
//           right: "calc(32% + 0.5rem)",
//           top: "32%",
//           transform: "translateY(-50%)",
//           pointerEvents: "none"
//         }}
//       >
//         <FontAwesomeIcon icon={faChevronDown} />
//       </span>
//     </div>
//   );
// }

// export default SelectDesignation;
// import React, { useState, useEffect } from "react";
// import { BASE_URL } from "../../Url/constants";
// import { getToken } from "../SecureStorage/Token";
// import './SelectDisignations.css';
// import { sendRequest } from "../../ApiRequest";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

// function SelectDesignation({ setIsLoggedIn, onRoleChange, setRoleId }) {
//   const token = getToken();
//   const [roles, setRoles] = useState([]);
//   const [setRolePermission] = useState("");

//   async function fetchData() {
//     const url = `${BASE_URL}/v1/setting/role_permissions`;
//     try {
//       const response = await sendRequest("GET", url, null, token);
//       if (response.status) {
//         setRoles(response.data.data);
//       } else {
//         setIsLoggedIn(false);
//         window.location.reload();
//         throw new Error(response.data.errors[0]);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [setIsLoggedIn, token]);

//   const handleRoleChange = (e) => {
//     const roleId = e.target.value;
//     setRoleId(roleId);
//     fetchData();
//     const role = roles.find((role) => role.id === Number(roleId));
//     onRoleChange(role, role.permissions);
//     setRolePermission(role.permissions);
//   };

//   const filteredRoles = roles.filter(
//     (role) => role.role_name !== "admin" && role.role_name !== "owner"
//   );

//   return (
//     <div className="col-md-8 h-25 ps-3 position-relative">
//       <div className="w-25">
//         <select
//           className="form-control select-input text-dark"
//           onChange={handleRoleChange}
//         >
//           <option value="">Select Role</option>
//           {filteredRoles.map((role) => (
//             <option key={role.id} value={role.id}>
//               {role.role_name.charAt(0).toUpperCase() + role.role_name.slice(1)}
//             </option>
//           ))}
//         </select>
//         <span className="staff-dropdown-icon">
//           <FontAwesomeIcon icon={faChevronDown} />
//         </span>
//       </div>
//     </div>
//   );
// }

// export default SelectDesignation;
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../Url/constants";
import { getToken } from "../SecureStorage/Token";
import './SelectDisignations.css';
import { sendRequest } from "../../ApiRequest";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

function SelectDesignation({ setIsLoggedIn, onRoleChange, setRoleId }) {
  const token = getToken();
  const [roles, setRoles] = useState([]);
  const [setRolePermission] = useState("");

  async function fetchData() {
    const url = `${BASE_URL}/v1/setting/role_permissions`;
    try {
      const response = await sendRequest("GET", url, null, token);
      if (response.status) {
        setRoles(response.data.data);
      } else {
        setIsLoggedIn(false);
        window.location.reload();
        throw new Error(response.data.errors[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [setIsLoggedIn, token]);

  const handleRoleChange = (e) => {
    const roleId = e.target.value;
    setRoleId(roleId);
    fetchData();
    const role = roles.find((role) => role.id === Number(roleId));
    onRoleChange(role, role.permissions);
    setRolePermission(role.permissions);
  };

  const filteredRoles = roles.filter(
    (role) => role.role_name !== "admin" && role.role_name !== "owner"
  );

  return (
    <div className="col-md-8 h-25 ps-3 position-relative">
      <div className="select-content">
        <select
          className="form-control select-input text-dark"
          onChange={handleRoleChange}
        >
          <option value="">Select Role</option>
          {filteredRoles.map((role) => (
            <option key={role.id} value={role.id}>
              {role.role_name.charAt(0).toUpperCase() + role.role_name.slice(1)}
            </option>
          ))}
        </select>
        <span className="staff-dropdown-icon">
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      </div>
    </div>
  );
}

export default SelectDesignation;
