import React from 'react';
import './templatelayout3.css';
// import Watermark from '@uiw/react-watermark';

function TemplateLayout3() {
    return (
        <div className='invoice-template-third'>
            {/* <Watermark
                content="UIW Watermark"
                className="watermark"
            >
                <div className="watermark-content">  
                    <textarea
                        spellCheck={false}
                        defaultValue={"JUSTMINDWORK"}
                        className="watermark-textarea"
                    />
                </div>
            </Watermark> */}
            <div className='invoice-header'>
                <img className="company-logo" alt="Company Logo" src="/companylogo.jpeg" />
                <div className="third-template-company-info">
                    <h2>Company Name</h2>
                    <p>+13649-6589 <i className="fa fa-phone"></i></p>
                    <p>company@gmail.com <i className="fa fa-envelope-o"></i></p>
                    <p>USA <i className="fa fa-location-arrow"></i></p>
                </div>
            </div>
            <div className='third-template-invoice-details'>
                <div className="template-second-customer-datail">
                    <h5>Customer Name </h5>
                    <p><b>Mobile :</b> +1 12345-4569</p>
                    <p><b>Email :</b> customer@gmail.com</p>
                    <p><b>Address :</b> New York, USA</p>
                </div>
                <div className='third-template-invoice-number'>Invoice #12345</div>
            </div>

            <div>
                <table className="table table-bordered invoice-third-table">
                    <thead>
                        <tr>
                            <th>Service</th>
                            <th>Description</th>
                            <th>Rate</th>
                            <th>QTY</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>xyz</td>
                            <td>Provide by IT infrastructure</td>
                            <td>50000</td>
                            <td>2</td>
                            <td>100000</td>
                        </tr>
                        <tr>
                            <td>xyz</td>
                            <td>Provide by IT infrastructure</td>
                            <td>50000</td>
                            <td>2</td>
                            <td>100000</td>
                        </tr>
                        <tr>
                            <td>xyz</td>
                            <td>Provide by IT infrastructure</td>
                            <td>50000</td>
                            <td>2</td>
                            <td>100000</td>
                        </tr>
                        <tr>
                            <td>xyz</td>
                            <td>Provide by IT infrastructure</td>
                            <td>50000</td>
                            <td>2</td>
                            <td>100000</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='third-template-invoice-total'>
                <strong>Total:</strong> 400000
            </div>
            <div className='template-third-footer-container'>
                <div className='row mt-5' >
                    <div className='col-md-9 d-flex justify-content-start'>Date: May 1, 2024</div>
                    <div className='col-md-3 d-flex justify-content-end'>Authority signature</div>
                </div>
                <div className='row'>
                    <div className='template-second-statement mb-4'>Thanks for Shopping.!</div>
                </div>
            </div>
            {/* <div className="watermark">CONFIDENTIAL</div> */}
        </div>
    );
}

export default TemplateLayout3;
