import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./ExpanseBill.css";
import Copyrights from "../Copyrights";
import Search from "../Search/Search.jsx";
import { NotificationContainer } from "react-notifications";
import { getToken } from "../SecureStorage/Token.jsx";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import PaginationControls from "../../components/Common/PaginationControls";
import EntriesPerPageDropdown from "../../components/Common/EntriesPerPageDropdown";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../Url/constants.js";
import { sendRequest } from "../../ApiRequest.js";
import { current_user } from "../../Authentication/AuthenticationContext.jsx";
import ErrorNotification from "../../Notification/ErrorNotification.jsx";
import successNotification from "../../Notification/SuccessNotification.jsx";

const CustomTable = ({ col_names, data }) => {
  // const openFile = (url) => {
  //   window.open(url, "_blank");
  // };
  return (
    <table className="custom-table">
      <thead>
        <tr>
          {col_names.map((col, index) => (
            <th key={index}>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {Object.entries(row).map(([key, cell], cellIndex) => (
              <td key={cellIndex}>
                {key === "View File" && cell ? (
                  <a href={cell} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFile} className="file-icon" />
                  </a>
                ) : (
                  cell
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

function ExpanseBill() {
  const [items, setItems] = useState([]);
  const [user, setUser] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [recordPerPage, setrecordPerPage] = useState(10);

  const [searchQuery, setSearchQuery] = useState("");

  const token = getToken();

  const [totalPages, setTotalPages] = useState(1);

  const [totalRecords, setTotalRecords] = useState(0);

  const endIndex = Math.min(currentPage * recordPerPage, totalRecords);

  const { t } = useTranslation();

  const paginate = async (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);

      await fetchData(pageNumber);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setUser(currentUser);
    };
    fetchUser();
  }, []);

  const fetchData = async (pageNumber = 1) => {
    try {
      let request_url = `${BASE_URL}/v1/setting/expanses?page=${pageNumber}&per_page=${recordPerPage}`;

      if (searchQuery) {
        request_url += `&query=${searchQuery}`;
      }

      const response = await sendRequest("GET", request_url);

      if (response.status === 200) {
        const data = response.data;

        if (Array.isArray(data.data)) {
          setItems(data.data);

          setTotalPages(data.total_pages);

          setTotalRecords(data.total_count);
        } else if (data.data && typeof data.data === "object") {
          setItems([data.data]);
        } else {
          console.error("Unexpected data format:", data);
        }
      }
    } catch (error) {
      console.error("Error fetching expenses data:", error);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchInitialData = async () => {
      try {
        await fetchData();
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching expenses data:", error);
        }
      }
    };

    fetchInitialData();

    return () => {
      isMounted = false;
    };
  }, [token, recordPerPage, searchQuery]);

  const handleAddSalaryExpense = async () => {
    try {
      const response = await sendRequest("POST", `${BASE_URL}/v1/setting/expanses/create_staff_expense`);

      if (response.status === 200) {
        setItems(prevItems => [...prevItems, response.data.data]);
        successNotification("Salary expense added successfully")
      } else {
        ErrorNotification('Unexpected error occurred while adding salary expense');
      }
    } catch (error) {
      ErrorNotification(error.data.errors);
    }
  };
  
  const startIndex = (currentPage - 1) * recordPerPage + 1;

  const handleSearch = (query) => {
    if (query.length >= 2) {
      setSearchQuery(query);
    } else {
      setSearchQuery("");

      setCurrentPage(1);
    }
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);

    setrecordPerPage(perPage);

    setCurrentPage(1);
  };

  const filteredItems = Array.isArray(items)
    ? items.filter((item) =>
        item.name?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <>
      <div className="container-fluid">
        <div className="col d-flex justify-content-between align-items-center">
          <div>
            <h3 className="heading-title">
              {t("All Expenses")} ({items.length})
            </h3>
          </div>
          <div>
          {user && (user.user_detail.role.name === "manager" || user.user_detail.role.name === "owner") && (
            <button
              className="btn btn-primary mt-3 add-salary-expense"
              onClick={handleAddSalaryExpense}
            >
              {t("Add Salary Expense")}
            </button>
          )}
            <Link to="/expanse-data" className="btn btn-primary mt-3">
              <FontAwesomeIcon icon={faPlus} className="plus-icon" />
              <span>{t("Add Expense")}</span>
            </Link>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="pagination-dropdown">
              <div className="pagination-controls">
                <EntriesPerPageDropdown
                  recordPerPage={recordPerPage}
                  handleRecordPerPageChange={handleRecordPerPageChange}
                />
              </div>
              <div
                className="text-right"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
              >
                <Search placeholder={t("Search Expense Bill ...")} />
              </div>
            </div>
          </div>

          <div className="table-view">
            <CustomTable
              col_names={[
                t("S. No."),
                t("Expense Name"),
                t("Description"),
                t("Amount"),
                t("View File"),
                t("Status"),
              ]}
              data={filteredItems.map((item, index) => {
                return {
                  "S. No.": startIndex + index,
                  "Expense Name": item.name,
                  Description: item.description,
                  Amount: item.amount,
                  "View File": item.file,
                  Status: "pending",
                };
              })}
            />
            {filteredItems.length === 0 && searchQuery.length > 1 && (
              <div className="no-report-data-message">
                {t("No match found")}
              </div>
            )}
            {filteredItems.length === 0 && searchQuery.length === 0 && (
              <div className="no-report-data-message">
                {t("No records found")}
              </div>
            )}
          </div>
          {totalRecords >= recordPerPage && (
            <div className="report-pagination-info">
              <div className="report-pagination-container">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                  totalRecords={totalRecords}
                  startIndex={startIndex}
                  endIndex={endIndex}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Copyrights />
      <NotificationContainer />
    </>
  );
}

export default ExpanseBill;
