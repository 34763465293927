import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function BlankTable() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const selectedLanguage = localStorage.getItem('selectedLanguage');

  useEffect(() => {
    if (selectedLanguage) {
      changeLanguage(selectedLanguage);
    }
  }, [selectedLanguage]);

  const changeLanguage = (selectedLanguage) => {
    setLoading(true);
    i18n.changeLanguage(selectedLanguage, () => {
      setLoading(false);
    });
  };

  return (
    <div className="blank-table">
      {loading ? (
        <p>{t("Loading...")}</p>
      ) : (
        <p>{t("Search by Items Name or Items Code")}</p>
      )}
    </div>
  );
}

export default BlankTable;
