import React, { useState } from "react";
import "./quantityedit.css";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import { getToken } from "../../SecureStorage/Token";
import ErrorNotification from "../../../Notification/ErrorNotification";
import successNotification from "../../../Notification/SuccessNotification";

function QuantityEdit({ item, selectedStock, onClose, setItems }) {
  const [quantity, setQuantity] = useState(
    selectedStock ? selectedStock.quantity : (item ? item.quantity : "")
  );
  const [rackLocation, setRackLocation] = useState(
    selectedStock ? selectedStock.rack_no || "" : ""
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const token1 = getToken();

  const handleSubmit = async (e) => {
    e.preventDefault();

   if (!quantity || quantity <= 0) {
      ErrorNotification(`Quantity must be greater than 0`);
      return;
    }

    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    try {
      if (selectedStock) {
        const updateStockQuantity = {
          updated_quantity: quantity,
          rack: rackLocation,
        };
        const response = await sendRequest(
          "PATCH",
          `${BASE_URL}/v1/inventory_managment/items/update_stock_quantity/${selectedStock.id}`,
          updateStockQuantity,
          token1
        );
        if (response.status === 200) {
          setItems(response.data.item);
          successNotification("Stock quantity and location updated Successfully");
          onClose();
        }
      } else if (item) {
        const updateQuantity = { quantity };
        const response = await sendRequest(
          'PATCH',
          `${BASE_URL}/v1/inventory_managment/items/${item.id}`,
          updateQuantity,
          token1
        );
        if (response.status === 200) {
          setItems((prevItems) =>
            prevItems.map((i) =>
              i.id === item.id
                ? { ...i, quantity: response.data.data.quantity }
                : i
            )
          );
          successNotification("Quantity updated Successfully");
          onClose();
        }
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      ErrorNotification("Failed to update quantity");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="qty-edit-container">
      <div className="edit-qty-modal-content">
        <button className="close-btn" onClick={onClose}>
          &#10005;
        </button>
        <form className="qty-edit-form" onSubmit={handleSubmit}>
          <div className="edit-item-qty-form-group">
            <label className="edit-item-form-label">Quantity:</label>
            <input
              id="quantity"
              className="form-control"
              type="number"
              value={quantity}
              onChange={(e) => {
                const value = e.target.value === "" ? "" : Number(e.target.value);
                setQuantity(value);
              }}
              placeholder="Enter Quantity"
            />
          </div>
          {selectedStock && ( // Conditionally render Rack/Location input field
            <div className="edit-item-qty-form-group">
              <label className="edit-item-form-label">Rack/Location:</label>
              <input
                type="text"
                className="form-control"
                value={rackLocation}
                onChange={(e) => setRackLocation(e.target.value)}
                placeholder="Enter Rack/Location"
              />
            </div>
          )}
          <div className="flexbox">
            <button className="update-btn" type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default QuantityEdit;
