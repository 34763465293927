import React, { useEffect, useState } from "react";
import "./NewPurchaseDebitNote.css"
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import CommonSalePopupTable from "../../CompanySales/CommonSalePopupTable/CommonSalePopupTable";
import SaleCommonPopup from "../../CompanySales/SaleCommonPopupHeader/SaleCommonPopupHeader";
import { current_user } from "../../../Authentication/AuthenticationContext";
import successNotification from "../../../Notification/SuccessNotification";
import ErrorNotification from "../../../Notification/ErrorNotification";
import CommonSupplierPopup from "../../Common/CommonSupplierPopup";
import { BsPencilSquare } from "react-icons/bs";

function NewPurchaseDebitNote({ onClose, purchaseDebitNoteCreated, purDebitNoteCopy, isUpdated }) {

  const [searchTerm, setSearchTerm] = useState(purDebitNoteCopy?.supplier?.supplier_name ? purDebitNoteCopy?.supplier?.supplier_name : "");
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddSupplier, setShowAddSupplier] = useState(false);
  const [isShowSupplierPopup, setShowSupplierPopup] = useState(false);
  const [supplierId, setSupplierId] = useState(purDebitNoteCopy?.supplier?.id ? purDebitNoteCopy?.supplier?.id : "");
  const [isEstimateDate, setEstimateDate] = useState(purDebitNoteCopy?.estimate_date ? purDebitNoteCopy?.estimate_date : "");
  const [isOrderNo, setOrderNo] = useState(purDebitNoteCopy?.order_no ? purDebitNoteCopy?.order_no : "");
  const [isOrderDate, setOrderDate] = useState(purDebitNoteCopy?.order_date ? purDebitNoteCopy?.order_date : "");
  const [discount, setDiscount] = useState("");
  const [gstAmount, setGstAmount] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [branchId, setBranchId] = useState("");
  const [supplierDetails, setSupplierDetail] = useState("");

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/suppliers?query=${term}`
        );
        const suppliers = response.data.data;
        if (suppliers.length > 0) {
          setFilteredSuppliers(suppliers);
          setShowDropdown(true);
          setShowAddSupplier(false);
        } else {
          setFilteredSuppliers([]);
          setShowDropdown(true);
          setShowAddSupplier(true);
        }
      } catch (error) {
        setShowDropdown(false);
        setShowAddSupplier(true);
      }
    } else {
      setShowDropdown(false);
      setShowAddSupplier(false);
    }
  };

  const handleSelectSupplier = (supplier) => {
    setSupplierDetail(supplier);
    setSupplierId(supplier.id);
    setSearchTerm(supplier.first_name || supplier.last_name);
    setShowDropdown(false);
  };

  const handleCreateSupplier = () => {
    setShowSupplierPopup(true);
  }

  const handleAddSupplier = () => {
    setShowSupplierPopup(true);
    setShowDropdown(false);
    setShowAddSupplier(false);
  };

  const handleSelectedSupplier = (supplier) => {
    setSupplierId(supplier?.id);
  }

  const updatedSelectedItems = (items) => {
    setSelectedItems(items);
  }

  const handleTotalAmount = (amount) => {
    setTotalAmount(amount);
  }

  const handleSubTotal = (amount) => {
    setSubTotal(amount);
  }

  const handleGstValue = (amount) => {
    setGstAmount(amount);
  }

  const handleDiscountValue = (amount) => {
    setDiscount(amount);
  }

  const closeCommonSupplierPopup = () => {
    setShowSupplierPopup(false);
  }

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setBranchId(currentUser.company.branch_info);
    };
    fetchUser();
  }, []);

  const handleNewPurchaseDebitNote = async () => {
    try {
      const uniqueItems = [];
      const itemIds = new Set();
      selectedItems.forEach(item => {
        if (!itemIds.has(item.id)) {
          itemIds.add(item.id);
          uniqueItems.push({
            item_id: item?.item_id,
            quantity: item?.qty,
            item_total_discount: item?.discountAmount?.toFixed(2),
            item_total_tax: item?.tax?.toFixed(2),
            item_total_amount: item?.saleAmount?.toFixed(2),
            branch_id: branchId.id
          });
        }
      });

      const formData = {
        bill: {
          supplier_id: supplierId,
          estimate_date: isEstimateDate,
          order_no: isOrderNo,
          order_date: isOrderDate,
          bill_items_attributes: uniqueItems,
          total_amount: totalAmount,
          sub_total: subTotal,
          total_gst: gstAmount,
          status: 0,
          total_discount: discount
        }
      }
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/transactions/entries?type=sup_debit_note`,
        formData
      );
      successNotification("Purchase debit note Created!");
      if (purchaseDebitNoteCreated) {
        purchaseDebitNoteCreated(Array.isArray(response.data.entry) ? response.data.entry : [response.data.entry]);
      }
      onClose();
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
    }
  }

  const handlePurEditDebitNote = async (purDebitNoteId) => {
    if (purDebitNoteId) {
      try {
        const uniqueItems = [];
        const itemIds = new Set();
        selectedItems.forEach(item => {
          if (!itemIds.has(item.id)) {
            itemIds.add(item.id);
            uniqueItems.push({
              id: item?.id ? item?.id : null,
              item_id: item?.item_id,
              quantity: item?.qty,
              item_total_discount: item?.discount_amount?.toFixed(2),
              item_total_tax: item?.tax?.toFixed(2),
              item_total_amount: item?.saleAmount?.toFixed(2),
              branch_id: branchId.id
            });
          }
        });
        const formData = {
          bill: {
            supplier_id: supplierId,
            estimate_date: isEstimateDate,
            order_no: isOrderNo,
            order_date: isOrderDate,
            bill_items_attributes: uniqueItems,
            total_amount: totalAmount,
            sub_total: subTotal,
            total_gst: gstAmount,
            status: 0,
            total_discount: discount
          }
        }
        await sendRequest(
          "PUT",
          `${BASE_URL}/v1/transactions/entries/${purDebitNoteId}?type=sup_debit_note`,
          formData
        );
        successNotification("Purchase Debit note updated!");
        onClose();
      }
      catch (error) {
        error.data.errors.map((error) => ErrorNotification(error));
      }
    }
    else {
      console.log("Failed to update purchase debit note")
    }
  }

  return (
    <>
      <div className="new-purchase-debit-note-main-container">
        <SaleCommonPopup onClose={onClose} onSave={handleNewPurchaseDebitNote} isUpdated={isUpdated} onUpdate={() => handlePurEditDebitNote(purDebitNoteCopy.id)} />
        <div className="new-purchase-debit-note-sub-container">
          <div className="new-purchase-debit-note-first-div">
            {/* <div className="new-purchase-debit-note-estimate-no-grp">
              <span className="new-purchase-debit-note-est-no-title">Debit Note No</span>
              <span className="new-purchase-debit-note-est-no">2</span>
            </div> */}
            <div className="new-purchase-debit-note-estimate-date-grp">
              <span className="new-purchase-debit-note-estdate-title">debit Note Date</span>
              {/* <span className="new-purchase-debit-note-estdate-input"> */}
              <input className="form-control new-purchase-debit-note-first-div-input"
                type="date"
                onChange={(e) => setEstimateDate(e.target.value)}
                value={isEstimateDate}
              />
              {/* </span> */}
            </div>
          </div>

          <div className="new-purchase-debit-note-second-div">
            <div className="new-purchase-debit-note-customer-div">
              <span className="new-purchase-debit-note-cust-title">Supplier/Vendor</span>
              <span className="new-purchase-debit-note-cust-search">
                <input
                  className="form-control new-purchase-debit-note-cust-field"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search supplier..."
                />
                {showDropdown && (
                  <ul className="new-purchase-debit-note-customer-dropdown">
                    {filteredSuppliers.length > 0 ? (
                      filteredSuppliers.map((supplier, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelectSupplier(supplier)}
                          className="new-purchase-debit-note-dropdown-item"
                        >
                          {supplier.first_name || supplier.last_name} {/* Assuming customer object has a name field */}
                        </li>
                      ))
                    ) : (
                      <li className="new-purchase-debit-note-cust-dropdown-item">No supplier found</li>
                    )}
                    {showAddSupplier && (
                      <li className="customer-dropdown-item" onClick={handleAddSupplier}>
                        <button className="btn btn-primary" onClick={handleCreateSupplier}>Add Supplier</button>
                      </li>
                    )}
                  </ul>
                )}
              </span>
            </div>

            {supplierId ? (
              <>
                <div className="new-debit-note-second-billing">
                  <div className="new-debit-note-billing-first-div">
                    <span className="new-debit-note-bill-title">Billing Address</span>
                    <BsPencilSquare onClick={handleCreateSupplier} />
                  </div>
                  <span className="new-debit-note-billing-address">
                    {supplierDetails?.addresses && (
                      <>
                        {supplierDetails?.addresses
                          .filter(address => address.address_type === 'billing')
                          .map(billingAddress => (
                            <div key={billingAddress.id}>
                              {billingAddress?.address_line_1},
                              {billingAddress?.address_line_2},
                              {billingAddress?.city},
                              {billingAddress?.state},
                              {billingAddress?.country}
                            </div>
                          ))}
                      </>
                    )}
                  </span>
                </div>

                <div className="new-debit-note-second-shipping">
                  <div className="new-debit-note-billing-first-div">
                    <span className="new-debit-note-shipping-title">Shipping Address</span>
                    <BsPencilSquare onClick={handleCreateSupplier} />
                  </div>
                  <span className="new-debit-note-shipping-address">
                    {supplierDetails?.addresses && (
                      <>
                        {supplierDetails?.addresses
                          .filter(address => address.address_type === 'shipping')
                          .map(shippingAddress => (
                            <div key={shippingAddress.id}>
                              {shippingAddress?.address_line_1},
                              {shippingAddress?.address_line_2},
                              {shippingAddress?.city},
                              {shippingAddress?.state},
                              {shippingAddress?.country}
                            </div>
                          ))}
                      </>
                    )}
                  </span>
                </div>
              </>
            ) : (
              ''
            )}
          </div>

          <div className="new-purchase-debit-note-third-div">
            <div className="new-purchase-debit-note-buyer-order-grp">
              <span className="new-purchase-debit-note-buyer-order-title">Supplier debit Note No</span>
              <input className="form-control new-purchase-debit-note-buyer-order"
                type="number"
                onChange={(e) => setOrderNo(e.target.value)}
                value={isOrderNo}
              />
            </div>
            <div className="new-purchase-debit-note-buyer-order-date-grp">
              <span className="new-purchase-debit-note-buyer-order-date-title">Supplier debit Note Date</span>
              <input className="form-control new-purchase-debit-note-buyer-order-date"
                type="date"
                onChange={(e) => setOrderDate(e.target.value)}
                value={isOrderDate}
              />
            </div>
          </div>

          <div className="new-purchase-debit-note-table">
            <CommonSalePopupTable
              updatedSelectedItems={(items) => updatedSelectedItems(items)}
              totalAmountValue={(totalAmount) => handleTotalAmount(totalAmount)}
              subTotalValue={(subTotal) => handleSubTotal(subTotal)}
              gstValue={(gstValue) => handleGstValue(gstValue)}
              discountValue={(discount) => handleDiscountValue(discount)}
              purDebitNoteCopy={purDebitNoteCopy}
            />
          </div>
        </div>
      </div>

      {isShowSupplierPopup && (
        <div className="new-purchase-debit-note-customer-popup">
          <CommonSupplierPopup
            selectedSupplier={(supplier) => handleSelectedSupplier(supplier)}
            onClose={closeCommonSupplierPopup}
            supplierDetails={supplierDetails}
          />
        </div>
      )}
    </>
  );
}

export default NewPurchaseDebitNote;
