import React, { useEffect, useState } from "react";
import "./CheckBoxPermission.css";
import { getToken } from "../SecureStorage/Token";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";
import ErrorNotification from "../../Notification/ErrorNotification";

function CheckboxPermissions({ permissions }) {
  console.log(permissions)
  const token = getToken();
  const [updatedPermissions, setUpdatedPermissions] = useState([]);
  console.log(updatedPermissions)
  useEffect(() => {
    if (permissions.length === 0) {
      return;
    }

    const rolesPermissions = permissions.permissions;
    setUpdatedPermissions(rolesPermissions);
  }, [permissions]);

  const handleChange = async (permissionId) => {
    const updatedPermissionsCopy = [...updatedPermissions];
    const index = updatedPermissionsCopy.findIndex(permission => permission.id === permissionId);
    if (index !== -1) {
      updatedPermissionsCopy[index] = {
        ...updatedPermissionsCopy[index],
        is_enable: !updatedPermissionsCopy[index].is_enable
      };
      setUpdatedPermissions(updatedPermissionsCopy);

      try {
        const payload = {
          role_id: permissions.id,
          permission_ids: [permissionId],
          is_enable: updatedPermissionsCopy[index].is_enable,
        };

        const response = await sendRequest(
          'POST',
          `${BASE_URL}/v1/setting/role_permissions`,
          payload,
          token
        );

        if (response.status === 200) {
          // console.log("Permissions updated successfully:", response.data.data);
        } else {
          ErrorNotification("Permission not updated");
        }
      } catch (error) {
        ErrorNotification(error.errors)
      }
    }
  };

  if (permissions.length === 0 || updatedPermissions.length === 0) {
    return <div className="error-content">No permissions</div>;
  }

  return (
    <form>
      <div className="check-box-permission">
        <div className="permission-group">
          <div className="row-content">
            {updatedPermissions.map((permission) => (
              <div className="col-content" key={permission.id}>
                <div className="form-checkbox">
                  <input
                    className="form-input"
                    type="checkbox"
                    id={`checkpermission${permission.id}`}
                    checked={permission.is_enable}
                    onChange={() => handleChange(permission.id)}
                  />
                  <label
                    className="form-checkbox-label"
                    htmlFor={`checkpermission${permission.id}`}
                  >
                    {permission.name}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </form>
  );
}

export default CheckboxPermissions;
