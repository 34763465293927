import React, { useState, useEffect } from 'react';
import "../UpdatePlatformItem/UpdatePlatformItem.css"
import CommonCloseButton from '../../../CommonCloseButton/CommonCloseButton';

function UpdatePlatformItem({ item, onClose }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [sortOrder, setSortOrder] = useState(1);
  const [foodType, setFoodType] = useState('Non-vegetarian');
  const [stock, setStock] = useState(-1);
  const [price, setPrice] = useState(0);
  const [setIcon] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [isRecommended, setIsRecommended] = useState(false);

  useEffect(() => {
    if (item) {
      setTitle(item.title);
      setPrice(item.price);
      setIsActive(item.active);
    }
  }, [item]);

  return (
    <div className="update-platform-item-container">
      <div className="update-platform-item-header">
        <span className='update-platform-item-title'>UPDATE ITEM</span>
        <span onClick={onClose}><CommonCloseButton /></span>
      </div>
      <div className='update-plat-summary-grp'>
        <span className='update-plat-head-summary'>This item will update for: All Store</span>
      </div>
      <div className="update-platform-item-sub-container">
        <div className="update-platform-item-div-grp">
          <label className='update-platform-item-label'>Title</label>
          <input className="update-platform-item-input-field" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="update-platform-item-div-grp">
          <label className='update-platform-item-label'>Description</label>
          <textarea  className="update-platform-item-input-field" value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <div className="update-platform-item-div-grp">
          <label className='update-platform-item-label'>Sort Order *</label>
          <input  className="update-platform-item-input-field" type="number" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} />
        </div>
        <div className="update-platform-item-div-grp">
          <label className='update-platform-item-label'>Food Type *</label>
          <select className="update-platform-item-input-field" value={foodType} onChange={(e) => setFoodType(e.target.value)}>
            <option value="vegetarian">Vegetarian</option>
            <option value="non-vegetarian">Non-vegetarian</option>
          </select>
        </div>
        <div className="update-platform-item-div-grp">
          <label className='update-platform-item-label'>Stock</label>
          <input className="update-platform-item-input-field"  type="number" value={stock} onChange={(e) => setStock(e.target.value)} />
        </div>
        <div className="update-platform-item-div-grp">
          <label className='update-platform-item-label'>Price</label>
          <input className="update-platform-item-input-field" type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
        </div>
        <div className="update-platform-item-div-grp">
          <label className='update-platform-item-label'>Icon</label>
          <input className="update-platform-item-input-field" type="file" onChange={(e) => setIcon(e.target.files[0])} />
        </div>
        <div className="update-platform-item-form-group update-platform-item-checkbox">
          <input type="checkbox" checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
          <label className='update-platform-item-check-label'>
            Active
          </label>
        </div>
        <div className="update-platform-item-form-group update-platform-item-checkbox">
        <input type="checkbox" checked={isRecommended} onChange={(e) => setIsRecommended(e.target.checked)} />
        <label className='update-platform-item-check-label'>
          Is Recommended
        </label>
        </div>
      </div>
      <div className="update-platform-item-form-actions">
        <button className="update-platform-footer-buttons" type="submit">Update</button>
        <button className="update-platform-footer-buttons" type="button" onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}

export default UpdatePlatformItem;
