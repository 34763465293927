import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import "./ItemPurchasePrice.css";
import { useParams } from "react-router-dom";
import { getToken } from "../../SecureStorage/Token";
import { useTranslation } from "react-i18next";

const ItemPurchasePrice = ({ onClose, onSave }) => {
  const [purchasePrice, setPurchasePrice] = useState("");
  const [purchaseTaxType, setTaxType] = useState("individualtax");
  const [individualTaxes, setIndividualTaxes] = useState([]);
  const [groupTaxes, setGroupTaxes] = useState([]);
  const [tax, setTax] = useState("");
  const [taxGroup, setTaxGroup] = useState("");
  const [item, setItem] = useState({});
  const { id } = useParams();
  const token1 = getToken();
  const { t } = useTranslation();

  useEffect(() => {
    fetchItem();
  }, [id]);

  useEffect(() => {
    if (item) {
      setPurchasePrice(item.purchase_price || "");
      setTaxType(item.purchase_tax_type || "individualtax");
      setTax(item.purchase_tax_rate_id || "");
      setTaxGroup(item.purchase_tax_group_id || "");
      fetchTaxes(item.purchase_tax_type || "individualtax");
    }
  }, [item]);

  useEffect(() => {
    fetchTaxes(purchaseTaxType);
  }, [purchaseTaxType]);

  const fetchItem = async () => {
    try {
      const response = await sendRequest('GET', `${BASE_URL}/v1/inventory_managment/items/${id}`, null, token1);
      setItem(response.data.data);
    } catch (error) {
      console.error('Error fetching item:', error);
    }
  };

  const fetchTaxes = async (taxType) => {
    try {
      if (taxType === "individualtax") {
        const response = await sendRequest("GET", `${BASE_URL}/v1/taxes/taxes`, null, token1);
        setIndividualTaxes(response.data.data || []);
      } else if (taxType === "grouptax") {
        const response = await sendRequest("GET", `${BASE_URL}/v1/taxes/tax_groups`, null, token1);
        setGroupTaxes(response.data.data || []);
      }
    } catch (error) {
      console.error("Error fetching taxes:", error);
    }
  };

  const handleTaxTypeChange = (event) => {
    setTaxType(event.target.value);
  };

  const handleTaxChange = (event) => {
    setTax(event.target.value);
  };

  const handleTaxGroupChange = (event) => {
    setTaxGroup(event.target.value);
  };

  const handlePurchasePriceChange = (event) => {
    const value = event.target.value;

    if (/^\d*\.?\d*$/.test(value)) {
      setPurchasePrice(value);
    }
  };

  const handleSave = () => {
    const data = {
      purchasePrice,
      purchaseTaxType,
      tax: purchaseTaxType === "individualtax" ? tax : "",
      taxGroup: purchaseTaxType === "grouptax" ? taxGroup : "",
    };
    onSave(data);
    onClose();
  };

  return (
    <div>
      <div className="purchase-item-popup-main">
        <h2>{t("Purchase Price")}</h2>
        <div className="item-purchase-lp">
          <label>{t("Purchase Price")}</label>
          <input
            type="text"
            className="form-control purchase-item-field"
            value={purchasePrice}
            maxLength={10}
            onChange={handlePurchasePriceChange}
          />
        </div>

        <div className="item-purchase-text-type mt-3">
          <label>{t("Tax Type")}</label>
          <div className="item-purchase-radio-grp">
            <div className="purchase-item-radio-group">
              <label className="purchase-item-label">
                {t("Individual")}
                <input
                  type="radio"
                  name="taxType"
                  value="individualtax"
                  checked={purchaseTaxType === "individualtax"}
                  onChange={handleTaxTypeChange}
                />
              </label>
            </div>

            <div className="purchase-item-radio-group">
              <label className="purchase-item-label">
                {t("Group")}
                <input
                  type="radio"
                  name="taxType"
                  value="grouptax"
                  checked={purchaseTaxType === "grouptax"}
                  onChange={handleTaxTypeChange}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="item-purchase-lp mt-4">
          <label className="custom-label">{t("Taxes")}
            <label>
              ({
                purchaseTaxType === 'individualtax' ? 'individual tax' :
                  purchaseTaxType === 'grouptax' ? 'group tax' :
                    purchaseTaxType
              })
            </label>
          </label>

          <div className="">
            <select
              className="form-control purchase-item-tax-select"
              value={purchaseTaxType === "individualtax" ? tax : taxGroup}
              onChange={purchaseTaxType === "individualtax" ? handleTaxChange : handleTaxGroupChange}
            >
              <option value="" disabled>
                {t("Select Tax")}
              </option>
              {purchaseTaxType === "individualtax"
                ? individualTaxes.map((tax) => (
                  <option key={tax.id} value={tax.id}>
                    {tax.title}
                  </option>
                ))
                : groupTaxes.map((taxGroup) => (
                  <option key={taxGroup.id} value={taxGroup.id}>
                    {taxGroup.name}
                  </option>
                ))}
            </select>
            <div className="pur-item-icon">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>

        <div className="purchase-tax-button">
          <button className="btn btn-primary cancel-button-purchase-item" onClick={onClose}>{t("Cancel")}</button>
          <button className="btn btn-primary" onClick={handleSave}>{t("Save")}</button>
        </div>
      </div>
    </div>
  );
};

export default ItemPurchasePrice;
