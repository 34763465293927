import React from 'react';
import './Loader.css'; // Make sure this CSS file is in place

function Loader() {
  return (
    <div className="loader-overlay">
      <div className="loader-spinner">Loading...</div>
    </div>
  );
}

export default Loader;
