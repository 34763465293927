import React, { useEffect, useState } from "react";
import "./NewSaleReceiptInvoice.css"
import SaleCommonPopup from "../SaleCommonPopupHeader/SaleCommonPopupHeader";
import { current_user } from "../../../Authentication/AuthenticationContext";
import CommonCustomerPopup from "../../Common/CommonCustomerPopup";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";
import successNotification from "../../../Notification/SuccessNotification";
import ErrorNotification from "../../../Notification/ErrorNotification";
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

function NewSaleReceiptInvoice({ onClose, saleReceiptCopy, isUpdated, saleReceiptView, saleReceiptCreated }) {

  const [searchTerm, setSearchTerm] = useState(saleReceiptCopy?.customer?.customer_name ? saleReceiptCopy?.customer?.customer_name : "");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [isShowCustomerPopup, setShowCustomerPopup] = useState(false);
  const [branchId, setBranchId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [saleInvoices, setSaleInvoices] = useState([]);
  const [receiptDate, setReceiptDate] = useState(saleReceiptCopy?.date ? saleReceiptCopy?.date : "");
  // const [isBankName, setBankName] = useState(saleReceiptCopy?.paid_from_account ? saleReceiptCopy?.paid_from_account : "");
  const [isPaymentMode, setPaymentMode] = useState(saleReceiptCopy?.payment_mode ? saleReceiptCopy?.payment_mode : "");
  const [isPaidAmount, setPaidAmount] = useState(saleReceiptCopy?.amount_paid ? saleReceiptCopy?.amount_paid : "");
  // const [isAmountBalance, setAmountBalance] = useState(saleReceiptCopy?.amount_balance ? saleReceiptCopy?.amount_balance : "");
  const [isReceiptReference, setReceiptReference] = useState(saleReceiptCopy?.reference_no ? saleReceiptCopy?.reference_no : "");
  const [isEditMode, setIsEditMode] = useState(false);
  const [customerError, setCustomerError] = useState("");
  const [customerDetails, setCustomerDetail] = useState();
  const [BtnDisable, setBtnDisabled] = useState(false);

  useEffect(() => {
    if (saleReceiptCopy && saleReceiptCopy.id) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [saleReceiptCopy]);

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term) {
      try {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/onboarding/customers/customer_search?query=${term}`
        );
        const customers = response.data;
        if (customers.length > 0) {
          setFilteredCustomers(customers);
          setShowDropdown(true);
          setShowAddCustomer(false);
        } else {
          setFilteredCustomers([]);
          setShowDropdown(true);
          setShowAddCustomer(true);
        }
      } catch (error) {
        setShowDropdown(false);
        setShowAddCustomer(true);
      }
    } else {
      setShowDropdown(false);
      setShowAddCustomer(false);
    }
  };

  const handleSelectCustomer = async (customer) => {
    setCustomerDetail(customer);
    setCustomerId(customer.id);
    setSearchTerm(
      customer.first_name && customer.last_name
        ? `${customer.first_name} ${customer.last_name}`
        : customer.first_name || customer.last_name
    );
    setShowDropdown(false);
    try {
      const typeParam = isEditMode ? "&type=view" : "";
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/transactions/payment_transactions/invoice_list?customer_id=${customer.id}${typeParam}`
      );
      console.log("response", response)
      setSaleInvoices(response.data.invoices);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  useEffect(() => {
    if (isEditMode && saleReceiptCopy && saleReceiptCopy.customer) {
      handleSelectCustomer(saleReceiptCopy.customer);
    }
  }, [isEditMode, saleReceiptCopy]);

  const handleCreateCustomer = () => {
    setShowCustomerPopup(true);
  }

  const closeCommonCustomerPopup = () => {
    setShowCustomerPopup(false);
  }

  const handleAddCustomer = () => {
    setShowCustomerPopup(true);
    setShowDropdown(false);
    setShowAddCustomer(false);
  };

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setBranchId(currentUser.company.branch_info);
    };
    fetchUser();
  }, []);

  // const [rows, setRows] = useState([{ deposit: "", reference: "", amount: "" }]);

  // const handleAddRow = () => {
  //   setRows([...rows, { deposit: "", reference: "", amount: "" }]);
  // };

  // const handleDeleteRow = (index) => {
  //   const updatedRows = rows.filter((_, i) => i !== index);
  //   setRows(updatedRows);
  // };

  const handleSaleReceiptCreate = async () => {
    setBtnDisabled(true);
    if (!customerId) {
      setCustomerError("Please select a customer before saving the Receipt.");
      return;
    }
    setCustomerError("");

    try {
      const formData = {
        payment_transaction: {
          customer_id: customerId,
          date: receiptDate,
          // bank_name: isBankName,
          payment_mode: isPaymentMode,
          amount_paid: isPaidAmount,
          // amount_balance: isAmountBalance,
          reference_no: isReceiptReference,
          branchId: branchId.id
        }
      }
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/transactions/payment_transactions?type=sales_receipt`,
        formData
      );
      successNotification("Receipt Created!");
      if (saleReceiptCreated) {
        saleReceiptCreated(Array.isArray(response.data.entry) ? response.data.entry : [response.data.entry]);
      }
      onClose();
    } catch (error) {
      error.data.errors.map((error) => ErrorNotification(error));
    }
  }

  const handleEditSaleReceipt = async (saleReceiptEditId) => {
    setBtnDisabled(true);
    if (!customerId) {
      setCustomerError("Please select a customer before saving the Receipt.");
      return;
    }
    setCustomerError("");

    if (saleReceiptEditId) {
      try {
        const formData = {
          payment_transaction: {
            customer_id: customerId,
            date: receiptDate,
            // bank_name: isBankName,
            payment_mode: isPaymentMode,
            amount_paid: isPaidAmount,
            // amount_balance: isAmountBalance,
            reference_no: isReceiptReference,
            branchId: branchId.id
          }
        }
        await sendRequest(
          "PUT",
          `${BASE_URL}/v1/transactions/payment_transactions/${saleReceiptEditId}?type=sales_receipt`,
          formData
        );
        successNotification("Receipt updated!");
        onClose();
        saleReceiptCreated();
      } catch (error) {
        error.data.errors.map((error) => ErrorNotification(error));
      }
    }
    else {
      console.log("Failed to update Receipt.");
    }
  }

  const handlePaymentMode = (event) => {
    setPaymentMode(event.target.value);
  }

  return (
    <>
      <div className="new-sale-receipt-invoice-main-container">
        <SaleCommonPopup onClose={onClose} onSave={handleSaleReceiptCreate} isUpdated={isUpdated} isView={saleReceiptView} onUpdate={() => handleEditSaleReceipt(saleReceiptCopy.id)}
          BtnDisable={BtnDisable}
        />
        <div className="new-sale-receipt-invoice-sub-container">
          <div className="new-sale-receipt-invoice-first-div">
            {/* <div className="new-sale-receipt-invoice-estimate-no-grp">
              <span className="new-sale-receipt-invoice-est-no-title">Receipt No</span>
              <span className="new-sale-receipt-invoice-est-no">INV24/22-23</span>
            </div> */}
            <div className="new-sale-receipt-invoice-estimate-date-grp">
              <span className="new-sale-receipt-invoice-estdate-title">Receipt Date</span>
              <span className="new-sale-receipt-invoice-estdate-input">
                <input className="form-control new-sale-receipt-invoice-first-div-input"
                  type="date"
                  onChange={(e) => setReceiptDate(e.target.value)}
                  value={receiptDate}
                  disabled={isEditMode}
                />
              </span>
            </div>

            <div className="new-sale-receipt-invoice-estimate-date-grp">
              <div className="new-sale-receipt-invoice-estdate-title">
                <span>Reference No</span>
              </div>
              <input
                type="number"
                onChange={(e) => setReceiptReference(e.target.value)}
                value={isReceiptReference}
                className="form-control new-sale-receipt-invoice-first-div-input"
                disabled={isEditMode}
              />
            </div>
            {/* <div className="new-sale-receipt-invoice-tilldate-grp">
              <span className="new-sale-receipt-invoice-est-tilldate-title">Due Date </span>
              <span className="new-sale-receipt-invoice-est-tilldate-input">
                <input className="form-control new-sale-receipt-invoice-first-div-input" type="date" />
              </span>
            </div> */}
            {/* <div className="new-sale-receipt-invoice-reference-grp">
              <span className="new-sale-receipt-invoice-est-reference-title">Reference </span>
              <span className="new-sale-receipt-invoice-est-reference-input">
                <input className="form-control new-sale-receipt-invoice-first-div-input" />
              </span>
            </div> */}
            {/* <div className="new-sale-receipt-invoice-type-grp">
              <span className="new-sale-receipt-invoice-type-title">Type</span>
              <select className="form-control new-sale-receipt-invoice-first-div-input">
                <option>Regular</option>
                <option>Export with Tax Payment</option>
                <option>Export without Tax Payment(bond/LUT)</option>
                <option>SEZ Supplier with Tax Payment</option>
                <option>SEZ Supplier without Tax Payment</option>
                <option>Bill of Supply</option>
                <option>Deemed Export</option>
              </select>
              <div className="new-sale-receipt-invoice-select-icon">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div> */}
          </div>

          <div className="new-sale-receipt-invoice-second-div">
            <div className="new-sale-receipt-invoice-customer-div">
              <span className="new-sale-receipt-invoice-cust-title">Customer</span>
              <span className="new-sale-receipt-invoice-cust-search">
                <input
                  className="form-control new-sale-receipt-invoice-cust-field"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search customer..."
                  disabled={isEditMode}
                />
                {showDropdown && (
                  <ul className="new-sale-receipt-invoice-customer-dropdown">
                    {filteredCustomers.length > 0 ? (
                      filteredCustomers.map((customer, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelectCustomer(customer)}
                          className="new-sale-receipt-invoice-dropdown-item"
                        >
                          {customer.first_name && customer.last_name
                            ? `${customer.first_name} ${customer.last_name}`
                            : customer.first_name || customer.last_name}
                        </li>
                      ))
                    ) : (
                      <li className="dropdown-item">No customer found</li>
                    )}
                    {showAddCustomer && (
                      <li className="customer-dropdown-item" onClick={handleAddCustomer}>
                        <button className="btn btn-primary" onClick={handleCreateCustomer}>Add Customer</button>
                      </li>
                    )}
                  </ul>
                )}
              </span>
              {customerError && (
                <span className="error-message" style={{ color: 'red', marginTop: '5px' }}>
                  {customerError}
                </span>
              )}
            </div>
            {customerId ? (
              <>
                <div className="new-quote-second-billing">
                  {/* <div className="new-quote-billing-first-div">
                    <span className="new-quote-bill-title">Billing Address</span>
                    <BsPencilSquare onClick={handleCreateCustomer} />
                  </div> */}
                  {/* <span className="new-quote-billing-address">
                    {customerDetails?.addresses
                      .filter(address => address.address_type === 'billing')
                      .map(billingAddress => (
                        <div key={billingAddress.id}>
                          {billingAddress?.address_line_1},
                          {billingAddress?.address_line_2},
                          {billingAddress?.city},
                          {billingAddress?.state},
                          {billingAddress?.country}
                        </div>
                      ))}
                  </span> */}
                </div>

                <div className="new-quote-second-shipping">
                  {/* <div className="new-quote-billing-first-div">
                    <span className="new-quote-shipping-title">Shipping Address</span>
                    <BsPencilSquare onClick={handleCreateCustomer} />
                  </div> */}
                  {/* <span className="new-quote-shipping-address">
                    {customerDetails?.addresses
                      .filter(address => address.address_type === 'shipping')
                      .map(shippingAddress => (
                        <div key={shippingAddress.id}>
                          {shippingAddress?.address_line_1},
                          {shippingAddress?.address_line_2},
                          {shippingAddress?.city},
                          {shippingAddress?.state},
                          {shippingAddress?.country}
                        </div>
                      ))}
                  </span> */}
                </div>
              </>
            ) : (
              ''
            )}
          </div>

          <div className="new-sale-receipt-invoice-input-group">
            {/* <div className="new-sale-receipt-invoice-div"> */}
            {/* <div className="new-sale-receipt-invoice-label-grp">
                <span>
                  Paid Bank Account
                </span>
              </div>
              <input
                type="text"
                onChange={(e) => setBankName(e.target.value)}
                value={isBankName}
              /> */}
            {/* </div> */}
            <div className="new-sale-receipt-invoice-div">
              <div className="new-sale-receipt-invoice-label-grp">
                <span>Payment Mode</span>
              </div>
              <select
                value={isPaymentMode}
                onChange={handlePaymentMode}
                className="form-select"
                disabled={isEditMode}
              >
                <option>online</option>
                <option>cash</option>
                <option>cheque</option>
              </select>
              {/* <div className="sale-receip-invoice-select-icon">
                <FontAwesomeIcon icon={faChevronDown} />
              </div> */}
            </div>
            <div className="new-sale-receipt-invoice-div">
              <div className="new-sale-receipt-invoice-label-grp">
                <span>Paid Amount</span>
              </div>
              <input
                type="number"
                onChange={(e) => setPaidAmount(e.target.value)}
                value={isPaidAmount}
                className="form-control new-sale-invoice-payment"
                disabled={isEditMode}
              />
            </div>
            {/* <div className="new-sale-receipt-invoice-div">
              <div className="new-sale-receipt-invoice-label-grp">
                <span>Amount Balance</span>
              </div>
              <input
                type="number"
                onChange={(e) => setAmountBalance(e.target.value)}
                value={isAmountBalance}
              />
            </div> */}
          </div>

          <div className="new-sale-receipt-table-group">
            <table className="new-sale-receipt-table">
              <thead>
                <tr className="new-sale-receipt-thead-row">
                  <th className="new-sale-receipt-tab-th">Date</th>
                  <th className="new-sale-receipt-tab-th">Bill Number</th>
                  <th className="new-sale-receipt-tab-th">Status</th>
                  <th className="new-sale-receipt-tab-th">Amount</th>
                </tr>
              </thead>
              <tbody>
                {saleInvoices.length > 0 ? (
                  saleInvoices.map((invoice) => (
                    <tr key={invoice.id}>
                      <td className="new-sale-receipt-tab-td">{invoice.created_at}</td>
                      <td className="new-sale-receipt-tab-td">{invoice.bill_number}</td>
                      <td className="new-sale-receipt-tab-td">{invoice.status}</td>
                      <td className="new-sale-receipt-tab-td">{invoice.amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="new-sale-receipt-tab-td">No invoices found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* <div className="sale-receipt-invoice-container">
            <div className="sale-receipt-invoice-middle-div">
              <div className="receipt-invoice-middle-first-div">
                <label>Deposit A/C</label>
              </div>
              <div className="receipt-invoice-middle-second-div">
                <label className="receipt-invoice-middle-refer-div-label">Reference</label>
              </div>
              <div className="receipt-invoice-middle-third-div">
                <label className="receipt-invoice-middle-div-label">Amount Received</label>
              </div>
            </div>

            {rows.map((row, index) => (
              <div className="sale-receipt-invoice-middle-div" key={index}>
                <div className="receipt-invoice-middle-first-div">
                  <select className="form-control receipt-invoice-1-input" value={row.deposit} onChange={(e) => { }}>
                    <option value="Cash">Cash</option>
                    <option value="Bank">Bank</option>
                  </select>
                </div>
                <div className="receipt-invoice-middle-second-div">
                  <select className="form-control receipt-invoice-2-input" value={row.reference} onChange={(e) => { }}>
                    <option value="Cash">Cash</option>
                    <option value="Bank Transfer">Bank Transfer</option>
                  </select>
                  <input type="text" className="form-control receipt-invoice-3-input" placeholder="Reference" value={row.reference} onChange={(e) => { }} />
                </div>
                <div className="receipt-invoice-middle-third-div">
                  <input type="number" className="form-control receipt-invoice-4-input" placeholder="Amount" value={row.amount} onChange={(e) => { }} />
                </div>
                <div className="receipt-invoice-add-row-div">
                  {index === rows.length - 1 && (
                    <button onClick={handleAddRow}>
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  )}
                  {index > 0 && (
                    <button onClick={() => handleDeleteRow(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div> */}

          {/* <div className="new-sale-receipt-invoice-third-div">
            <div className="new-sale-receipt-invoice-note-grp">
              <span className="new-sale-receipt-invoice-note-title">Notes</span>
              <span className="new-sale-receipt-invoice-note-area">
                <textarea className="new-sale-receipt-invoice-note-textarea-input" />
              </span>
            </div>
            <div className="new-sale-receipt-invoice-terms-condition-grp">
              <span className="new-sale-receipt-invoice-term-title">Terms & Conditions</span>
              <span className="new-sale-receipt-invoice-term-textarea">
                <textarea className="new-sale-receipt-invoice-term-textarea-input" />
              </span>
            </div>
            <div className="new-sale-receipt-invoice-bank-grp">
              <span className="new-sale-receipt-invoice-bank-title">Bank</span>
              <span className="new-sale-receipt-invoice-bank-input">
                <input className="form-control new-sale-receipt-invoice-last-input" />
              </span>
            </div>
            <div className="new-sale-receipt-invoice-sales-rep-grp">
              <span className="new-sale-receipt-invoice-sale-rep-title">Sales Rep. </span>
              <span className="new-sale-receipt-invoice-sale-rep-input">
                <input className="form-control new-sale-receipt-invoice-last-input" />
              </span>
            </div>
          </div> */}
        </div>
      </div>

      {isShowCustomerPopup && (
        <div className="new-quote-customer-popup">
          <CommonCustomerPopup
            selectedCustomer={(customer) => handleSelectCustomer(customer)}
            onClose={closeCommonCustomerPopup}
            customerDetails={customerDetails}
          />
        </div>
      )}
    </>
  );
}

export default NewSaleReceiptInvoice;
