import React from "react";
import './receiptfour.css'

function ReceiptFour({ enabledTemplateData = [] }) {
  const getOption = (optionName) => {
    return enabledTemplateData.find((opt) => opt.option_name === optionName) || {};
  };

  const getAlignment = (optionName) => {
    const option = getOption(optionName);
    return option.alignment || 'center'; // Default to 'left' if alignment is not defined
  };

  return (
    <>
      <div className="fouth-receipt-main">
        <div className="fourth-receipt-sub">
          <div className="fourth-logo-header">
            {getOption('company_logo').is_enable && (
              <img className="receipt-four-logo" src="/companylogo.jpeg" alt="logo" />
            )}
          </div>
          <div className="fourth-receipt-header">
            <div className="row">
              {getOption('company_name').is_enable && (
                <span 
                  className="company-title"
                  style={{ textAlign: getAlignment('company_name') }}
                >
                  {getOption('company_name').free_text || "JUSTMINDWORK Private Limited"}
                </span>
              )}
            </div>
            <hr className="fourth-receipt-line" />
            <div className="invoice_details">
              <div className="company-info">
                <div className="company-address">
                  <div>
                    {getOption('address').is_enable && (
                      <div 
                        className="receipt-company-address"
                        style={{ textAlign: getAlignment('address') }}
                      >
                        {getOption('address').free_text || "111J, Satguru Parinay, Indore"}
                      </div>
                    )}
                    {getOption('email').is_enable && (
                      <div 
                        className="receipt-com-mail"
                        style={{ textAlign: getAlignment('email') }}
                      >
                        Email: {getOption('email').free_text || "jmw@gmail.com"}
                      </div>
                    )}
                    {getOption('insta_id').is_enable && (
                      <div 
                        className="receipt-com-mail"
                        style={{ textAlign: getAlignment('insta_id') }}
                      >
                        Insta Id: {getOption('insta_id').free_text || "jmw@insta"}
                      </div>
                    )}
                    {getOption('phone_number').is_enable && (
                      <div 
                        className="receipt-com-mail"
                        style={{ textAlign: getAlignment('phone_number') }}
                      >
                        Phone: {getOption('phone_number').free_text || "00090909090"}
                      </div>
                    )}
                    {getOption('gst_number').is_enable && (
                      <div 
                        className="receipt-com-mail"
                        style={{ textAlign: getAlignment('gst_number') }}
                      >
                        Gst Number: {getOption('gst_number').free_text || "SGST12345678987"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="order-details">
                <div className="order-point">
                  <div className="receipt-date">
                    {getOption('date').is_enable && (
                      `Date: 02/05/2024`
                    )}
                  </div>
                  <div className="receipt-time">
                    {getOption('time').is_enable && (
                      `Time: 01:00`
                    )}
                  </div>
                  <div className="receipt-date">
                    Branch: Indore
                  </div>
                  <div className="receipt-time">
                    {getOption('invoice_number').is_enable && (
                      `Receipt: #L21387`
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr className="fourth-receipt-line" />
            <p>Customer Details:-</p>
            <hr className="fourth-receipt-line" />
          </div>
          <div className="fouth-receiopt-table-grp">
            <table className="receipt-fourth-table">
              <thead className="fourth-receipt-table-heading">
                <tr>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Qty</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Shahi Thali</td>
                  <td>2</td>
                  <td>300</td>
                  <td>600</td>
                </tr>
                <tr>
                  <td>Shahi Thali</td>
                  <td>2</td>
                  <td>300</td>
                  <td>600</td>
                </tr>
                <tr>
                  <td>Shahi Thali</td>
                  <td>2</td>
                  <td>300</td>
                  <td>600</td>
                </tr>
                <tr>
                  <td>Total Amount</td>
                  <td></td>
                  <td></td>
                  <td>660</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Base Amount</td>
                  <td></td>
                  <td>Tax</td>
                </tr>
                <tr>
                  <td></td>
                  <td>600</td>
                  <td></td>
                  <td>60</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Total Included Tax Amount:</td>
                  <td>660</td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
          {getOption('qr_code').is_enable && (
            <div className="qr-code-container">
              <img src="/qr-code-placeholder.png" alt="QR Code" className="qr-code-image" />
            </div>
          )}
          <div className="footer">
            <p className="customer-invoice-msg">THANKS YOU FOR BEING VALUED CUSTOMER</p>

            <div>
              <span className="fourth-receipt-policy">
                {getOption('return_policy').is_enable && (
                  <span style={{ textAlign: getAlignment('return_policy') }}>
                    Return Policy: {getOption('return_policy').free_text || "NO RETURN NO EXCHANGE"}
                  </span>
                )}
              </span>
              <div>
                <span className="receipt-fourth-policy-content">
                  This is an electronic generated receipt so doesn't require any signature
                </span>
              </div>
            </div>

          </div>
          <div className="receip-four-wel-msg mt-3">***Thank you for shopping with us*** </div>
        </div>
      </div>
    </>
  );
}

export default ReceiptFour;