import React from "react";
import "./SaleCommonPopupHeader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseChimney } from "@fortawesome/free-solid-svg-icons";

function SaleCommonPopup(props) {
  return (
    <>
      <div className="sale-common-popup-main-container">
        <div className="sale-common-popup-left-part ms-1">
          <span>{props.title}</span>
          <div className="sale-common-location-group justify-content-start">
            <span className="sale-common-location-symbol">
              <FontAwesomeIcon icon={faHouseChimney} />
            </span>
            <span className="sale-common-location-name">Indore</span>
          </div>
        </div>
        <div className="sale-common-popup-right-part">
          {props.isUpdated ? (
            <div>
              <button
                className="sale-common-header-button"
                onClick={props.onUpdate}
                disabled={props.isSaving}
              >
                {props.isSaving ? "Updating..." : "Update"}
              </button>
            </div>
          ) : (
            <div>
              <button
                className="sale-common-header-button"
                onClick={props.onSave}
                disabled={props.isSaving}
              >
                {props.isSaving ? "Saving..." : "Save"}
              </button>
            </div>
          )}

          <div>
            <button
              className="sale-common-header-button"
              onClick={props.onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SaleCommonPopup;