import React, { useState } from 'react';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CalendarModal.css';

Modal.setAppElement('#root'); // Uncomment this line for screen reader accessibility

function CalendarModal({ isOpen, onClose }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="calendar-modal"
      overlayClassName="calendar-modal-overlay"
    >
      <div className="calendar-container">
        <h2>Select Dates</h2>
        <div className="date-range-selector">
          <div className="date-picker">
            <label htmlFor="start-date">Start Date:</label>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div className="date-picker">
            <label htmlFor="end-date">End Date:</label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
        </div>
        <button onClick={onClose}>Close</button>
      </div>
    </Modal>
  );
}

export default CalendarModal;
