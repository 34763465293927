import React, { useState, useEffect } from 'react';
import "./ItemModifier.css";
import { sendRequest } from '../../../ApiRequest';
import { BASE_URL } from '../../../Url/constants';
import CommonCloseButton from '../CommonCloseButton/CommonCloseButton';
import { useTranslation } from "react-i18next";

function ItemModifier({ selectedModifiers, addModifier, onClose }) {
  const [itemModifier, setitemModifier] = useState([]);
  const [newDescription, setNewDescription] = useState("");
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchItemModifier();
  }, []);
  const fetchItemModifier = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/restaurant/modifiers/item_modifiers`,
        null
      );
      console.log(response);
      setitemModifier(response.data.data);
    } catch (error) {
      console.log("Error fetching item modifier:", error);
    }
  };

  const handleSaveAndSelect = async () => {
    setError(null);
    const params = {
      item_modifier: {
        modifier: newDescription
      }
    };

    try {
      const response = await sendRequest(
        "POST",
        `${BASE_URL}/v1/restaurant/modifiers/item_modifiers`,
        params
      );
      console.log('Item modifier saved:', response);
      fetchItemModifier();
      addModifier([response.data.data]);
      setNewDescription("");
      onClose();
    } catch (error) {
      console.log('Error saving item modifier:', error);
      setError(error.data.errors || 'An error occurred');
    }
  };

  const handleCheckboxChange = async (modifier, modifierId, isArchived) => {
    const params = {
      item_modifier: {
        is_archived: !isArchived
      }
    };

    try {
      const response = await sendRequest(
        "PUT",
        `${BASE_URL}/v1/restaurant/modifiers/item_modifiers/${modifierId}`,
        params
      );
      console.log('Item modifier updated:', response);
      fetchItemModifier();
      addModifier([modifier]);
      onClose();
    } catch (error) {
      console.log('Error updating item modifier:', error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, '');
    setNewDescription(alphabeticValue);
  };

  return (
    <>
      <div className='res-item-modifier'>
        <div className='res-modify-title'>
          <span className='modify-title-heading'>{t("Modifier")}</span>
          <span onClick={onClose}>
            <CommonCloseButton />
          </span>
        </div>

        <div className='res-modify-sub'>
          <div className='res-modify-checkbox-list'>
            <div className='modify-sub-padding'>
              <div className='modify-button-main-style'>
                {itemModifier.map((modifier) => (
                  <div className='item-modifier-button-style' key={modifier.id}>
                    {/* <button
                      className={`item-modifier-invoice-input ${selectedModifiers.includes(modifier) ? 'selected-modifier' : ''}`}
                      onClick={() => handleCheckboxChange(modifier, modifier.id, modifier.is_archived)}
                    > */}
                    <button
                      className={`item-modifier-invoice-input ${selectedModifiers.some(selected => selected.id === modifier.id) ? 'selected-modifier' : ''}`}
                      onClick={() => handleCheckboxChange(modifier, modifier.id, modifier.is_archived)}
                    >
                      {modifier.modifier}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='item-res-modify-sub'>
            {t("New Modifier")}
          </div>
          <span>
            <input
              type="text"
              className='form-control modify-res'
              placeholder='New Modifier'
              maxLength={15}
              value={newDescription}
              onChange={handleInputChange}
            />
          </span>
          {error && <div className='description-error-message'>{error}</div>}
          <div className='collection-res-modify-button'>
            <span className='modifier-buttons-res-save-select'>
              <button className='btn btn-success item-modify-save-select' onClick={handleSaveAndSelect}>
                {t("Save")}
              </button>
            </span>
            <span className='modifier-buttons-res-close'>
              <button className='btn btn-danger item-modify-close' onClick={onClose}>{t("Close")}</button>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemModifier;
