import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCog } from "@fortawesome/free-solid-svg-icons";
import "./item.css";
import { useEffect, useState, useCallback, useRef } from "react"; // Added useCallback and useRef
import { BASE_URL } from "../../Url/constants";
import Copyrights from "../Copyrights";
import Search from "../Search/Search.jsx";
import { NotificationContainer } from "react-notifications";
import CustomTable from "../Common/CustomTable.jsx";
import PaginationControls from "../Common/PaginationControls.jsx";
import { BsEye, BsPencilSquare, BsPrinter } from "react-icons/bs";
import QuantitEdit from "./ItemPopup/QuantityEdit.jsx";
import ItemAdditionalSetting from "./ItemPopup/ItemAdditionalSetting.jsx";
import { useTranslation } from "react-i18next";
import ErrorNotification from "../../Notification/ErrorNotification.jsx";
import { sendRequest } from "../../ApiRequest.js";
import BarcodePrintOption from "./BarcodePrint/BarcodePrintOption.jsx";
import ItemEntriesPerPageDropdown from "./ItemEntriesPerPageDropdown.jsx";
import Loader from "../Common/Loader.jsx"
import { current_user } from "../../Authentication/AuthenticationContext.jsx";
import debounce from 'lodash/debounce'; // Added debounce import
import { useNavigate } from "react-router-dom";

function ItemTable() {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setrecordPerPage] = useState(50);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isQtyEditPopupOpen, setIsQtyEditPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSettingPopupOpen, setIsSettingPopupOpen] = useState(false);
  const [selectedSettingItem, setSelectedSettingItem] = useState(null);
  const [barcodePopupOpen, setBarcodePopupOpen] = useState(false);
  const [userLanguage, setUserLanguage] = useState("en");
  const [errorUnauthorize, setErrorUnauthorize] = useState(false);
  const baseURL = BASE_URL
  const navigate = useNavigate();
  const [isPharmaceutical, setIsPharmaceutical] = useState(false);
  const [expiryFilter, setExpiryFilter] = useState(null);
  const [customDate, setCustomDate] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [sortedItems, setSortedItems] = useState([]);
  const [barcodeTemplate, setBarcodeTemplate] = useState(null);
  const [isCompanydata, setCompanyData] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [expiryFilterDate, setExpiryFilterDate] = useState(null);
  const [expiryFilterType, setExpiryFilterType] = useState(null);


  const startIndex = (currentPage - 1) * recordPerPage + 1;
  const endIndex = Math.min(currentPage * recordPerPage, totalRecords);

  const filterAndSortByExpiry = (items, filterType, customDate = null) => {
    if (!items || !filterType) return items;
  
    const today = new Date();
    let endDate = new Date();
  
    switch (filterType) {
      case '15days':
        endDate.setDate(today.getDate() + 15);
        break;
      case '1month':
        endDate.setMonth(today.getMonth() + 1);
        break;
      case '6months':
        endDate.setMonth(today.getMonth() + 6);
        break;
      case 'custom':
        endDate = new Date(customDate);
        break;
      default:
        return items;
    }

    const filteredItems = items.filter(item => {
      const expiryInfo = getNearestExpiryInfo(item.stocks);
      if (!expiryInfo.date) return false;
      
      const expiryDate = new Date(expiryInfo.date);
      return expiryDate >= today && expiryDate <= endDate;
    });
  
    return filteredItems.sort((a, b) => {
      const aExpiryInfo = getNearestExpiryInfo(a.stocks);
      const bExpiryInfo = getNearestExpiryInfo(b.stocks);
      
      const aDate = new Date(aExpiryInfo.date);
      const bDate = new Date(bExpiryInfo.date);
      
      return aDate - bDate;
    });
  };

  const fetchUser = async () => {
    try {
      const currentUser = await current_user();
      setIsPharmaceutical(currentUser.company.business_type === 'Pharmaceutical');

    } catch (error) {
      console.error('Error fetching user:', error);
      setIsPharmaceutical(false);
    }
  };

  useEffect(() => {
    const initializeUser = async () => {
      await fetchUser();
    };
        initializeUser();
  }, []);

  useEffect(() => {
    fetchItems(currentPage);
  }, [recordPerPage, searchQuery, currentPage]);

  useEffect(() => {
    const language = localStorage.getItem("selectedLanguage") || "en";
    setUserLanguage(language);
  }, []);

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query.length >= 2) {
        setSearchQuery(query);
      } else {
        setSearchQuery("");
        setCurrentPage(1);
      }
    }, 500), // 500ms delay
    []
  );

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setCompanyData(currentUser.company);
    };
    fetchUser();
  }, []);

  const fetchItems = async (pageNumber) => {
    setLoading(true);
    try {
      let request_url = `${BASE_URL}/v1/inventory_managment/items?page=${pageNumber}&per_page=${recordPerPage}`;
      if (searchQuery) {
        request_url += `&query=${searchQuery}`;
      }

      const response = await sendRequest('GET', request_url);
      const data = await response.data;

      setItems(data.data);
      setTotalPages(Math.ceil(data.total_count / recordPerPage));
      if (searchQuery) {
        setTotalRecords(data.total_filtered_count);
      } else {
        setTotalRecords(data.total_count);
      }
    } catch (error) {
      if (error && error.data && error.data.errors) {
        if (error.data.errors.length === 49) {
          // setErrorUnauthorize(true); // Handle unauthorized case
        } else {
          error.data.errors.forEach((err) => ErrorNotification(err)); // Handle other errors
        }
      } else {
        ErrorNotification('Unknown error occurred', error); // In case the error structure is different
      }
    } finally {
      setLoading(false);
    }
  };


  const handleCloseItemModel = () => {
    setErrorUnauthorize(false);
    navigate("/dashboard");
  }
  const getNearestExpiryInfo = (stocks) => {
    if (!stocks || stocks.length === 0) return { date: null, quantity: 0 };
    
    const today = new Date();
    // Filter stocks that have expiry dates and are not expired
    const validStocks = stocks.filter(stock => 
      stock.expiry_date && 
      new Date(stock.expiry_date) > today && 
      stock.quantity > 0
    );
    
    if (validStocks.length === 0) return { date: null, quantity: 0 };
    
    // Sort by expiry date and get the nearest one
    const nearestStock = validStocks.reduce((nearest, current) => {
      if (!nearest) return current;
      return new Date(current.expiry_date) < new Date(nearest.expiry_date) 
        ? current 
        : nearest;
    });
    
    return {
      date: nearestStock.expiry_date,
      quantity: nearestStock.quantity
    };
  };
  const fetchBarcodeTemplate = async () => {
    try {
      const response = await sendRequest('GET', `${BASE_URL}/v1/onboarding/companies/show_barcode_temp`);
      if (response.data.default_template) {
        setBarcodeTemplate(response.data.default_template.html_code);
      }
    } catch (error) {
      ErrorNotification('Error fetching barcode template:', error);
    }
  };

  useEffect(() => {
    if (!barcodeTemplate) {
      fetchBarcodeTemplate();
    }
  }, []);

  const filterItemsByExpiry = (items) => {
    if (!expiryFilter) return items;
    
    const today = new Date();
    let filterDate = new Date();
    
    switch (expiryFilter) {
      case '15days':
        filterDate.setDate(today.getDate() + 15);
        break;
      case '1month':
        filterDate.setMonth(today.getMonth() + 1);
        break;
      case '6months':
        filterDate.setMonth(today.getMonth() + 6);
        break;
      case 'custom':
        filterDate = customDate;
        break;
      default:
        return items;
    }
    return items.sort((a, b) => {
      const aExpiry = getNearestExpiryInfo(a.stocks).date;
      const bExpiry = getNearestExpiryInfo(b.stocks).date;
      return new Date(aExpiry) - new Date(bExpiry);
    });
  };
  const getTableColumns = () => {
    let columns = [
      'S. No.',
      'Item Name',
      'Item Code',
      'Sales Price',
      'Purchase Price',
      "Sales Discount",
      'Quantity',
      'Tax',
      'Tax Type',
      "Discount Price",
      'Unit',
      'Tax Inclusive',
      'Category',
      'Item Image',
      'Actions'
    ];

    // Add expiry date column for pharmaceutical items
    if (isPharmaceutical) {
      columns.splice(6, 0, 'Expiry Date');
    } else {
      // Add sales discount and discount price columns for non-pharmaceutical items
      columns.splice(6, 0, 'Sales Discount', 'Discount Price');
    }

    return columns;
  };

  const handleSort = (columnName, filterType, customDate = null) => {
    if (columnName === 'Expiry Date') {
      setExpiryFilterType(filterType);
      setExpiryFilterDate(customDate);
      const sortedItems = filterAndSortByExpiry(items, filterType, customDate);
      setSortedItems(sortedItems);
    }
  };
  
  useEffect(() => {
    setSortedItems(items);
  }, [items]);

  const handleSearch = (query) => {
    debouncedSearch(query);
  };

  const openImagePopup = (image) => {
    setSelectedImage(image);
    const fullImageURL = image.startsWith("http") ? image : `${baseURL}${image}`;
    setSelectedImage(fullImageURL);
  };

  const closeImagePopup = () => {
    setSelectedImage(null);
  };

  const openQtyEditPopup = (item) => {
    setSelectedItem(item);
    setIsQtyEditPopupOpen(true);
  };

  const closeQtyEditPopup = () => {
    setIsQtyEditPopupOpen(false);
    setSelectedItem(null);
  };

  const openSettingPopup = (item) => {
    setSelectedSettingItem(item);
    setIsSettingPopupOpen(true);
  };

  const closeSettingPopup = () => {
    setIsSettingPopupOpen(false);
    setSelectedSettingItem(null);
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setrecordPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getShortTaxType = (taxType) => {
    const taxTypeMap = {
      fixed: "fix",
      percentage: "per",
    };
    return taxTypeMap[taxType] || taxType;
  };

  // const handlePrintBarcodes = (quantity) => {
  //   if (quantity > selectedItem.quantity) {
  //     ErrorNotification(`barcode Quantity should not greater than ${selectedItem.quantity}`);
  //     return;
  //   }
  //   const printWindow = window.open("");

  //   printWindow.document.write(`
  //     <html>
  //       <head>
  //         <title>Print Barcodes</title>
  //         <style>
  //           @media print {
  //             body * {
  //               visibility: hidden;
  //             }
  //             #printable-bill, #printable-bill * {
  //               visibility: visible;
  //             }
  //             #printable-bill {
  //               position: absolute;
  //               left: 8mm;
  //               top: 5;
  //               right: 1mm;
  //               width: calc(100% - 2mm);
  //             }
  //           }
  //           .sticker {
  //             width: 60mm;
  //             height: 30mm;
  //             border: 1px solid #000;
  //             page-break-inside: avoid;
  //             padding: 1mm;
  //             box-sizing: border-box;
  //             display: flex;
  //             flex-direction: column;
  //             justify-content: center;
  //             margin-top: 1.5mm;
  //             margin-right: 1.5mm;
  //             margin-bottom: 1mm;
  //             margin-left: -8mm;
  //           }

  //           .left-section, .right-section {
  //             width: 100%;
  //             text-align: center;
  //           }

  //           .left-section {
  //             display: flex;
  //             justify-content: space-between;
  //           }

  //           .barcode-image img {
  //             width: 100%;
  //             height: 7mm;
  //             margin-top: 1mm;
  //             margin-bottom: 1mm;
  //           }

  //           .barcode-number, .price-font-size {
  //             font-size: 16px;
  //             max-width: 100%;
  //             text-align: center;
  //           }

  //           .barcode-number {
  //             margin-bottom: 2mm;
  //             font-weight: bold;
  //           }

  //           .price-font-size {
  //             display: flex;
  //             flex-flow: column;
  //           }

  //           .item-sales-price-value {
  //             font-weight: bold;
  //           }

  //           .item-sale-price-symbol{
  //             width: 5mm;
  //           }
  //         </style>
  //       </head>
  //       <body>
  //         <div id="printable-bill">
  //           ${Array(quantity).fill('').map(() => `
  //             <div class="sticker">
  //               <div class="left-section">
  //                 <div class="price-font-size">
  //                   <span class="item-sale-price-symbol">MRP</span>
  //                   <span class="item-sales-price-value">${selectedItem?.sales_price ? `${selectedItem.sales_price} ₹` : ""}</span>
  //                 </div>
  //                 <div> ${selectedItem?.cat_name ? selectedItem?.cat_name : ""}</div>
  //               </div>
  //               <div class="right-section barcode-image">
  //                 <img src="${BASE_URL}${selectedItem?.bar_code_url}" alt="Barcode" />
  //                 <div class="barcode-number">${selectedItem?.bar_code}</div>
  //               </div>
  //             </div>
  //           `).join('')}
  //         </div>
  //       </body>
  //     </html>
  //   `);
  //   printWindow.document.close();
  //   printWindow.onload = () => {
  //     printWindow.print();
  //     printWindow.onafterprint = () => {
  //       printWindow.close();
  //     };
  //   };
  // };
  const handlePrintBarcodes = (quantity, template) => {
    if (quantity > selectedItem.quantity) {
      ErrorNotification(`Barcode quantity should not be greater than ${selectedItem.quantity}`);
      return;
    }
    console.log("item--------------", selectedItem)
    const printWindow = window.open("");

    // Extract template styles
    const styleContent = barcodeTemplate.match(/<style>([\s\S]*?)<\/style>/)?.[1] || '';
    const templateContent = barcodeTemplate.replace(/<style>[\s\S]*?<\/style>/, '');
    console.log("css............", styleContent)
    console.log("templates............", templateContent)
    console.log("items------->", selectedItem)


    // Additional print-specific styles
    const additionalStyles = `
  @page {
  size: 58mm 40mm portrait;
  margin: 0;
  }
  body {
  margin: 0;
  padding: 0;
  width: 58mm;
  }
  #printable-content {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;    
  margin: 0 auto;         
  width: 58mm;            
  height: 100%;           
  box-sizing: border-box; 
}
`;

    const company_name = isCompanydata?.name;
    const company_gst = isCompanydata?.gstin;
    const company_address = isCompanydata.branch_info.address
    debugger
    // Generate templates
    const templates = Array(quantity).fill('').map(() => {
      return templateContent.replace(/{([^}]+)}/g, (match, key) => {

        const replacements = {
          gstin_no: selectedItem?.gstin || '',
          store_address: selectedItem?.address || '',
          item_name: selectedItem?.item_name || '',
          unit: selectedItem?.unit || '',
          sales_price: selectedItem?.sales_price ? `₹${selectedItem.sales_price}` : '',
          purchase_price: selectedItem?.purchase_price ? `₹${selectedItem.purchase_price.toFixed(2)}` : '',
          mgp_price: selectedItem?.mgp_price ? `₹${selectedItem.mgp_price}` : '',
          barcode_url: selectedItem?.bar_code_url ? `${BASE_URL}${selectedItem.bar_code_url}` : '',
          barcode: selectedItem?.bar_code || '',
          fssai_no: selectedItem?.fssai || '',
          packaging_date: selectedItem?.packaging_date || '',
          company_name: company_name || '',
          company_address: company_address || '',
        };
        return replacements[key] || '';
      });
    }).join('');

    printWindow.document.write(`
<!DOCTYPE html>
<html>
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Print Barcodes</title>
<style>
${additionalStyles}
${styleContent}
</style>
</head>
<body>
<div id="printable-content">
${templates}
</div>
<script>
window.onload = () => {
window.print();
window.onafterprint = () => window.close();
};
</script>
</body>
</html>
`);
    printWindow.document.close();
  };
  const openBarcodePrint = (item) => {
    setSelectedItem(item);
    setBarcodePopupOpen(true);
  }

  const handleCloseBarcodePopup = () => {
    setBarcodePopupOpen(false);
  }

  const handleselectedTemplate = (template) => {
    if (template) {
      setBarcodeTemplate(template);
    }
  };

  return (
    <>
      <div className="item-table-content">
        {loading && <Loader />}

        <div className="row mt-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <h3 className="heading-title mb-0">
                {t("All Items")} ({totalRecords})
              </h3>
            </div>
            <div>
              <Link
                to="/item"
                className="btn btn-primary mb-3"
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                <span>{t("Add Item")}</span>
              </Link>
            </div>
          </div>

          <div className="pagination-dropdown">
            <div className="pagination-controls">
              <ItemEntriesPerPageDropdown
                recordPerPage={recordPerPage}
                handleRecordPerPageChange={handleRecordPerPageChange}
              />
            </div>
            <div
              className="text-right search-adjust"
              value={searchQuery}
            >
              <Search 
                placeholder={"Search Items ..."} 
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="table-view" style={{minHeight: "33em"}}>
          <CustomTable
            col_names={getTableColumns()}
            data={filterItemsByExpiry(sortedItems).map((item, index) => {
              let translatedName;
              try {
                translatedName = JSON.parse(item.translated_name);
              } catch {
                translatedName = {};
              }

              const baseData = {
                "S. No.": startIndex + index,
                "Item Name":
                  userLanguage === "hi" &&
                  item.translated_name &&
                  translatedName.hi
                    ? translatedName.hi
                    : item.item_name,
                "Item Code": item.item_code,
                "Sales Price": `₹${item.sales_price}`,
                "Purchase Price": item?.purchase_price ? `₹${item?.purchase_price?.toFixed(2)}` : `₹0.00`, 
                Quantity: (
                  <div>
                    <span className="qty-item-value">{item.quantity}</span>
                    {item.quantity !== -1 && (
                      <span className="Qty-edit-icon">
                        <BsPencilSquare onClick={() => openQtyEditPopup(item)} />
                      </span>
                    )}
                  </div>
                ),
                "Tax": (
                  <div>
                    {item.tax_amount} 
                    {item.tax_amount !== 0 && item.tax_type !== "Group" && item?.tax_info?.tax_type && ( 
                      <span className="tax-item">
                        ({getShortTaxType(item.tax_info.tax_type)})
                      </span> 
                    )}
                  </div>
                ),
                "Tax Type": item.tax_type,
                "Unit": item.unit_name,
                "Tax Inclusive": (
                  <span className={item.is_tax_inclusive ? "check-symbol" : "cross-symbol"}>
                    {item.is_tax_inclusive ? "✓" : "x"}
                  </span>
                ),
                "Category": item.cat_name,
                "Item Image": item.item_url ? 
                  <Link onClick={() => openImagePopup(item.item_url)}>{t("View Image")}</Link> 
                  : null,
                "Actions": (
                  <div className="icons">
                    <FontAwesomeIcon
                      icon={faCog}
                      className="setting-icon"
                      onClick={() => openSettingPopup(item)}
                      title="Settings"
                    />
                    <Link to={`/item-details/${item.id}`} title="View Details">
                      <BsEye className="item-eye-icon" />
                    </Link>
                    <Link to={`/item-update/${item.id}`} title="Edit Item">
                      <BsPencilSquare className="edit-item-attributes" />
                    </Link>
                    <BsPrinter 
                      className="barcode-print-icon" 
                      onClick={() => openBarcodePrint(item)} 
                      title="Print Barcode" 
                    />
                  </div>
                ),
              };

              // Add pharmaceutical-specific or non-pharmaceutical fields
              if (isPharmaceutical) {
                const { date: expiryDate, quantity: expiryQuantity } = getNearestExpiryInfo(item.stocks);
                baseData['Expiry Date'] = expiryDate ? (
                  <div className="expiry-info">
                    {new Date(expiryDate).toLocaleDateString()}
                    <span className="expiry-quantity" style={{ color: 'red' }}>
                      ({expiryQuantity})
                    </span>
                  </div>
                ) : '';
              } else {
                baseData['Sales Discount'] = item.sales_discount;
                baseData['Discount Price'] = item.discount_price;
              }

              return baseData;
            })}
            onSort={handleSort}
            sortConfig={sortConfig}
            isPharmaceutical={isPharmaceutical}
          />
          {items.length === 0 && searchQuery.length > 1 && (
            <div className="no-report-data-message">{t("No match found")}</div>
          )}
          {items.length === 0 && searchQuery.length === 0 && (
            <div className="no-report-data-message">{t("No records found")}</div>
          )}
        </div>

        {totalRecords >= recordPerPage && (
          <div>
            <div className="report-pagination-info">
              <div className="report-pagination-container">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                  totalRecords={totalRecords}
                  startIndex={startIndex}
                  endIndex={endIndex}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedImage && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="close-sign">
              <span className="image-close" onClick={closeImagePopup}>
                &times;
              </span>
            </div>
            <div>
              <img src={selectedImage} alt="Item" className="modal-image" />
            </div>
          </div>
        </div>
      )}

      {isQtyEditPopupOpen && (
        <div className="popup-overlay">
          <QuantitEdit
            className="add-close"
            item={selectedItem}
            onClose={closeQtyEditPopup}
            setItems={setItems}
          />
        </div>
      )}

      {barcodePopupOpen && (
        <div className="popup-overlay">
          <BarcodePrintOption
            onClose={handleCloseBarcodePopup}
            selectedQuantity={handlePrintBarcodes}
            selectedItem={selectedItem}
            TemplateToPrint={handleselectedTemplate}
          />
        </div>
      )}

      {isSettingPopupOpen && (
        <div className="item-add-popup">
          <div className="add-setting-close-sign">
            <ItemAdditionalSetting
              item={selectedSettingItem}
              closeSettingPopup={closeSettingPopup}
              type={"individual"}
            />
            <span className="add-close" onClick={closeSettingPopup}>
              &times;
            </span>
          </div>
        </div>
      )}
      <Copyrights />
      <NotificationContainer />
    </>
  );
}

export default ItemTable;