import React, { useState } from "react";
import "./ItemStatusCard.css";
import { useTranslation } from "react-i18next";


const ItemStatusCard = ({ itemStatusData }) => {
  const { t} = useTranslation();

  const [currentTab, setCurrentTab] = useState("placed");

  const handleChangeTab = (status) => {
    setCurrentTab(status);
  };

  const getTabTitle = (status) => {
    switch (status) {
      case "placed": return "New Order";
      case "preparation": return "Processed";
      case "ready_to_serve": return "Ready";
      default: return status;
    }
  };

  return (
    <div className="item-status-card col-md-4">
      <div className="item-status-header">{t("Item Status")}</div>
      <div className="item-status-tabs">
        {["placed", "preparation", "ready_to_serve"].map(status => (
          <div 
            key={status}
            className={`item-status-tab ${currentTab === status ? "active" : ""}`} 
            onClick={() => handleChangeTab(status)}
          >
            {getTabTitle(status)}
          </div>
        ))}
      </div>
      <div className="item-status-content">
        <div className="item-status-row header">
          <span>{t("Item Name")}</span>
          <span>{t("Quantity")}</span>
        </div>
        {itemStatusData[currentTab] && itemStatusData[currentTab].map((item, index) => (
          <div className="item-status-row" key={index}>
            <span>{item.item_name}</span>
            <span>{item.total_quantity}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemStatusCard;