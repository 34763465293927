import React from 'react';
import './ToggleButton.css';

const ToggleButton = ({ id, checked, onChange }) => {
  return (
    <div className="toggle-button">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        className="toggle-button__input"
      />
      <label htmlFor={id} className="toggle-button__label">
        <span className="toggle-button__switch"></span>
      </label>
    </div>
  );
};

export default ToggleButton;