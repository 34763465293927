import React, { useEffect, useRef, useState } from "react";
import "./CreditNote.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import NewCreditNote from "./NewCreditNote";
import Copyrights from "../../Copyrights";
import PaginationControls from "../../Common/PaginationControls";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import { BASE_URL } from "../../../Url/constants";
import { sendRequest } from "../../../ApiRequest";

function CreditNote() {

  const [visibleCreditColumns, setVisibleCreditColumns] = useState({
    refNo: true,
    // dueDays: true,
    status: true,
  });

  const [isShowCreditColumnFilter, setShowCreditColumnFilter] = useState(false);
  const [tempVisibleColumns, setTempVisibleColumns] = useState({ ...visibleCreditColumns });
  const [showCreditIconAction, setShowCreditDownIconAction] = useState(false);
  const [isOpenCreditPopup, setOpenCreditPopup] = useState(false);
  const [creditNote, setCreditNote] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedCreditNote, setSelectedCreditNote] = useState("");
  const [saleCreditNoteCopy, setSaleCreditNoteCopy] = useState("");
  const [saleCreditNoteUpdated ,setSaleCreditNoteUpdated] = useState(false);
  const [actionDropdownPosition, setActionDropdownPosition] = useState({ top: 0, left: 0 });


  const handleCreditColumnFilter = () => {
    setShowCreditColumnFilter(true);
  }

  const handleCheckboxCreditChange = (column) => {
    setTempVisibleColumns((prevState) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const handleApplyCreditFilter = () => {
    setVisibleCreditColumns(tempVisibleColumns)
    setShowCreditColumnFilter(false);
  }

  const handleCreditDownIconAction = (creditNote, event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setActionDropdownPosition({
      top: rect.bottom + window.scrollY,
    });
    setSelectedCreditNote(creditNote)
    setShowCreditDownIconAction(true);
  }

  const handleCreditNote = () => {
    setOpenCreditPopup(true);
  }

  const closeCreditNotePopup = () => {
    setOpenCreditPopup(false);
    setSaleCreditNoteCopy("");
    setSaleCreditNoteUpdated(false);
  }

  const columnCreditFilterRef = useRef(null);
  const columnCreditActionFilterRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnCreditFilterRef.current &&
        !columnCreditFilterRef.current.contains(event.target)
      ) {
        setShowCreditColumnFilter(false);
      }
    };

    if (isShowCreditColumnFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isShowCreditColumnFilter]);


  useEffect(() => {
    const handleDownClickOutside = (event) => {
      if (
        columnCreditActionFilterRef.current &&
        !columnCreditActionFilterRef.current.contains(event.target)
      ) {
        setShowCreditDownIconAction(false);
      }
    };
    if (showCreditIconAction) {
      document.addEventListener("mousedown", handleDownClickOutside);
    } else {
      document.removeEventListener("mousedown", handleDownClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleDownClickOutside);
    };
  }, [showCreditIconAction]);

  const fetchCreditNote = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/transactions/entries?type=credit_note&page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setCreditNote(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching credit note:", error);
    }
  };

  const handleCreditNoteDelete = async (creditId) => {
    if (creditId) {
      try {
        const response = await sendRequest("DELETE", `${BASE_URL}/v1/transactions/entries/${creditId}?type=credit_note`);
        if (response.status === 200) {
          console.log("Credit Note deleted successfully!");
          fetchCreditNote(currentPage, recordsPerPage);
          setShowCreditDownIconAction(false);
        } else {
          console.log("Failed to delete the credit note. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting the credit note:", error);
      }
    }
  };

  const handleSaleCreditCopy = async (saleCreditNoteCopyId, updated) => {
    if (updated) {
      setSaleCreditNoteUpdated(updated);
    }
    if (saleCreditNoteCopyId) {
      try {
        const response = await sendRequest("GET", `${BASE_URL}/v1/transactions/entries/${saleCreditNoteCopyId}?type=credit_note`);
        setSaleCreditNoteCopy(response.data.entry);
        setOpenCreditPopup(true);
      } catch (error) {
        console.error("Error copy the sale credit note:", error);
        console.log("An error occurred while deleting the copy sale credit note.");
      }
    }
  };

  useEffect(() => {
    fetchCreditNote(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const handleCreditNoteCreated = (value) => {
    setCreditNote(prevCreditNotes => [...prevCreditNotes, ...value]);
    setTotalRecords(prevTotalRecords => prevTotalRecords + 1);
  }

  return (
    <>
      <div className="credit-note-main-container">
        <div className="credit-note-container-first-div">
          <div className="credit-note-first-left">
            <span className="all-credit-note-show">All Credit Note({totalRecords})</span>
          </div>
          <div className="credit-note-first-right">
            <button
              className="credit-note-first-right-btn"
              onClick={handleCreditNote}
            >
              New Credit Note
              {/* <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span> */}
            </button>
          </div>
        </div>

        <div className="credit-note-container-second-div">
          <div className="credeit-note-second-right-part">
            <EntriesPerPageDropdown recordPerPage={recordsPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
          </div>
          <div className="credit-note-second-div-sub-part">
            {/* <div className="credit-note-second-drop-first">
              <div className="credit-note-turn-icon-grp">
                <span className="credit-note-turn-right-icon">
                  <FontAwesomeIcon icon={faArrowsTurnRight} />
                </span>
              </div> */}
              {/* <span className="credit-note-icon-between"></span> */}
              {/* <div className="credit-note-down-icon-grp" >
                <span className="credit-note-caret-down-icon">
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </div>
            </div> */}
            {/* <div className="credit-note-second-filter" >
              <span className="credit-note-filter-icon">
                <FontAwesomeIcon icon={faFilter} />
              </span>
            </div> */}
            <div
              className="credit-note-second-three-dot-group"
              onClick={handleCreditColumnFilter}
            >
              <span className="credit-note-ellips-icon">
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </span>
            </div>
          </div>
        </div>

        <div className="credit-note-table-group">
          <table className="credit-note-table">
            <thead>
              <tr className="credit-note-thead-row">
                <th className="credit-note-th">Date</th>
                <th className="credit-note-th">Credit Note No</th>
                <th className="credit-note-th">Contact</th>
                {visibleCreditColumns.status && (
                  <th className="credit-note-th">Status</th>
                )}
                {visibleCreditColumns.refNo && (
                  <th className="credit-note-th">Ref.No</th>
                )}
                {/* {visibleCreditColumns.dueDays && (
                  <th className="credit-note-th">DueDays</th>
                )} */}
                <th className="credit-note-th">Amount</th>
                <th className="credit-note-th">Action</th>
              </tr>
            </thead>
            <tbody className="credit-note-table-body">
              {creditNote.map((row, index) => (
                <tr key={index} className="credit-note-tbody-row">
                  <td className="credit-note-td">{row.date}</td>
                  <td className="credit-note-td">{row.bill_number}</td>
                  <td className="credit-note-td">{row.customer.customer_name}</td>
                  {visibleCreditColumns.status && (
                    <td className="credit-note-td">{row.status}</td>
                  )}
                  {visibleCreditColumns.refNo && (
                    <td className="credit-note-td">{row.reference_no}</td>
                  )}
                  {/* {visibleCreditColumns.dueDays && (
                    <td className="credit-note-td">{row.due_days}</td>
                  )} */}
                   <td className="credit-note-td">{row.total_amount}</td>
                  <td className="credit-note-td">
                    <FontAwesomeIcon icon={faCaretDown} className="credit-action-down-icon"
                      onClick={(e) => handleCreditDownIconAction(row, e)} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalRecords > recordsPerPage && (
          <div className="report-pagination-info">
            <div className="report-pagination-container">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                totalRecords={totalRecords}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>
          </div>
        )}

        {creditNote.length === 0 && (
          <div className="credit-note-no-data-message">
            No quotations found.
          </div>
        )}

        {isShowCreditColumnFilter && (
          <div className="credit-note-column-filter-popup" ref={columnCreditFilterRef}>
            <form className="credit-note-column-filter-form">
              {Object.keys(tempVisibleColumns).map((column) => (
                <div key={column} className="credit-note--label-input-grp">
                  <label>
                    <input
                      type="checkbox"
                      className="credit-note--visible-column-input"
                      checked={tempVisibleColumns[column]}
                      onChange={() => handleCheckboxCreditChange(column)}
                    />
                    {column.charAt(0).toUpperCase() + column.slice(1)}
                  </label>
                </div>
              ))}
              <button type="button" onClick={handleApplyCreditFilter} className="credit-note-visible-box-btn">
                Apply
              </button>
            </form>
          </div>
        )}

        {showCreditIconAction && (
          <div className="credit-note-down-icon-action" 
          ref={columnCreditActionFilterRef}
          style={{
            position: 'absolute',
            top: `${actionDropdownPosition.top}px`,
          }}
          >
            <ul className="credit-note-down-icon-action-ul">
              <li className="credit-note-down-icon-action-li" onClick={() => handleSaleCreditCopy(selectedCreditNote.id, true)}>Edit</li>
              <li className="credit-note-down-icon-action-li" onClick={() => handleCreditNoteDelete(selectedCreditNote.id)}>
                Delete
              </li>
              {/* <li className="credit-note-down-icon-action-li">Print</li>
              <li className="credit-note-down-icon-action-li">Send</li>
              <li className="credit-note-down-icon-action-li">Send SMS</li>
              <li className="credit-note-down-icon-action-li">Send WhatsApp</li> */}
              <li className="credit-note-down-icon-action-li" onClick={() => handleSaleCreditCopy(selectedCreditNote.id)}>Copy</li>
            </ul>
          </div>
        )}

        {isOpenCreditPopup && (
          <div className="new-credit-note-popup-container">
            <NewCreditNote onClose={closeCreditNotePopup} saleCreditNoteCopy={saleCreditNoteCopy} isUpdated={saleCreditNoteUpdated} creditNoteCreated={handleCreditNoteCreated} />
          </div>
        )}
        <Copyrights />
      </div>
    </>
  );
}

export default CreditNote;
