import React, { useEffect, useState } from 'react';
import { useTransliteration } from '../TransliterationContext';

const TransliterateInput = ({ placeholder, className, onChange, originalValue }) => {
  const { setText } = useTransliteration(); 
  const [displayValue, setDisplayValue] = useState(originalValue);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    setText(originalValue);
    fetchTranslations(originalValue); // Fetch suggestions on mount
  }, [originalValue, setText]);

  const fetchTranslations = async (text) => {
    try {
      const response = await fetch(
        `https://inputtools.google.com/request?text=${text}&itc=hi-t-i0-und&num=4&cp=0&cs=1&ie=utf-8&oe=utf-8&app=demopage`
      );
      const data = await response.json();

      if (data[0] === "SUCCESS") {
        const transliterations = data[1][0][1];
        setSuggestions(transliterations);
        setShowSuggestions(true); // Show suggestions if they exist
      } else {
        setSuggestions([]);
        setShowSuggestions(false); // Hide suggestions if there are none
      }
    } catch (error) {
      console.error("Error fetching transliterations:", error);
      setSuggestions([]);
      setShowSuggestions(false); // Hide suggestions on error
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setText(value);
    onChange(value);
    setDisplayValue(value);
    fetchTranslations(value); // Fetch new suggestions on input change
  };

  const handleSuggestionClick = (suggestion) => {
    setDisplayValue(suggestion);
    onChange(suggestion);
    setShowSuggestions(false);
    setSuggestions([]);
  };
  return (
    <>
      <input
        value={displayValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={className}
      />

      {showSuggestions && suggestions.length > 1 && (
        <ul className="suggestion-list">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              className="suggestion-item"
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default TransliterateInput;
