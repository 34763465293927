import React, { useEffect, useState } from "react";
import "./NewPurchaseBill.css";
import SelectGuest from "../../Restaurant/SelectGuest/SelectGuest";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import CommonSalePopupTable from "../../CompanySales/CommonSalePopupTable/CommonSalePopupTable";
import SaleCommonPopup from "../../CompanySales/SaleCommonPopupHeader/SaleCommonPopupHeader";
import { handleSearchSupplier } from "../../../services/supplierService";
import { fetchBillData } from "../../../services/fetchBillsService";
import CommonItemTable from "../../Common/CommonItemTable";
import successNotification from "../../../Notification/SuccessNotification";
import ErrorNotification from "../../../Notification/ErrorNotification";

function NewPurchaseBill(props) {
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [showAddSupplier, setShowAddSupplier] = useState(false);
  const [isShowCustomerPopup, setShowCustomerPopup] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [references, setReferences] = useState([]);
  const [selectedReferences, setSelectedReferences] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedBills, setSelectedBills] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const [subTotal, setSubTotal] = useState("");

  const [billData, setBillData] = useState({
    billNumber: "",
    billDate: "",
    dueDate: "",
    purchaseOrderNo: "",
    type: "",
    supplierInvoiceNo: "",
    supplierInvoiceDate: "",
  });

  useEffect(() => {
    if (props.isUpdated) {
      setBillData({
        billNumber: props.editedRowData.bill_number || "",
        billDate: props.editedRowData.date || "",
        dueDate: props.editedRowData.valid_date || "",
        purchaseOrderNo: props.editedRowData.order_no || "",
        type: props.editedRowData.type || "Regular",
        supplierInvoiceNo: props.editedRowData.order_no || "",
        supplierInvoiceDate: props.editedRowData.order_date || "",
      });
      setSelectedSupplier(props.editedRowData.supplier?.supplier_name || "");
      if (props.editedRowData.items) {
        setSelectedItems(props.editedRowData.items);
      }
    } else if (props.isCopy && props.editedRowData) {
      setBillData({
        billNumber: "",
        billDate: new Date().toISOString().split("T")[0],
        dueDate: props.editedRowData.valid_date || "",
        purchaseOrderNo: props.editedRowData.order_no || "",
        type: props.editedRowData.type || "Regular",
        supplierInvoiceNo: "",
        supplierInvoiceDate: new Date().toISOString().split("T")[0],
      });
      setSelectedSupplier(props.editedRowData.supplier?.supplier_name || "");

      if (props.editedRowData.items) {
        setSelectedItems(props.editedRowData.items);
      }
    } else if (props.isCreated) {
      setBillData({
        billNumber: rowData.bill_number || props.rowData?.bill_number || "",
        billDate: rowData.estimate_date || props.rowData?.estimate_date || "",
        dueDate: rowData.valid_date || props.rowData?.valid_date || "",
        purchaseOrderNo: rowData.order_no || props.rowData?.order_no || "",
        type: rowData.type || "Regular",
        supplierInvoiceNo: "",
        supplierInvoiceDate:
          rowData.order_date || props.rowData?.order_date || "",
      });
      setSelectedSupplier(
        rowData.supplier?.supplier_name ||
          props.rowData?.supplier?.supplier_name ||
          ""
      );
      setSelectedItems(
        props.rowData?.bill_items ? props.rowData?.bill_items : ""
      );
    }
  }, [
    props.isUpdated,
    props.isCopy,
    props.editedRowData,
    rowData,
    props.rowData,
  ]);

  console.log("props.rowData==>>", props.rowData);

  const handleSearch = async (e) => {
    const searchQuery = e.target.value;
    setQuery(searchQuery);

    if (searchQuery) {
      try {
        const suppliersData = await handleSearchSupplier(searchQuery);
        setSuppliers(suppliersData);
        setShowSearchDropdown(true);
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    } else {
      setShowSearchDropdown(false);
    }
  };

  const handleSearchReferences = async (query) => {
    setQuery(query);
    if (query.length < 2) return;
    let type = 'purchase_order'
    const references = await fetchBillData(query, type);
    setShowDropdown(true);
    setReferences(references);
  };

  const handleSelectSupplier = (supplier) => {
    setSelectedSupplier(
      supplier.first_name || supplier.last_name || supplier.name
    );
    setShowDropdown(false);
  };

  const handleCreateSupplier = () => {
    setShowCustomerPopup(true);
  };

  const handleAddSupplier = () => {
    setShowAddSupplier(true);
    setShowDropdown(false);
  };

  function handleSelectReference(reference) {
    setRowData(reference);
    setSelectedReferences(reference.bill_number);
    setSelectedBills((prevBills) => [...prevBills, reference]);
    setShowDropdown(false);
  }

  const headers = [
    { key: "id", label: "S. No" },
    { key: "item_name", label: "Item Name" },
    { key: "hsn", label: "HSN" },
    { key: "tax", label: "Tax" },
    { key: "qty", label: "Quantity" },
    { key: "unit", label: "Unit" },
    props.type === "purchase_bill"
      ? { key: "purchasePrice", label: "Purchase Price" }
      : { key: "salesPrice", label: "Sales Price" },
    { key: "discount", label: "Discount" },
    { key: "amount", label: "Amount" },
    { key: "actions", label: "Action" },
  ];

  const handleSelectPurchaseBill = (items) => {
    setSelectedItems(items);
  };

  const handlePurchaseBill = async () => {
    if (selectedItems.length === 0) return;
    const branchId =
      props.isUpdated || props.isCopy
        ? props.editedRowData.branch.id
        : selectedBills[0]?.branch.id;
    const supplierId =
      props.isUpdated || props.isCopy
        ? props.editedRowData.supplier.id
        : selectedBills[0]?.supplier.id;
    const purchaseDate =
      props.isUpdated || props.isCopy
        ? props.editedRowData.estimate_date
        : selectedBills[0].estimate_date;
    const orderDate =
      props.isUpdated || props.isCopy
        ? props.editedRowData.order_date
        : selectedBills[0].orderDate;
    const orderNo =
      props.isUpdated || props.isCopy
        ? props.editedRowData.order_no
        : selectedBills[0].orderNo;
    const validDate =
      props.isUpdated || props.isCopy
        ? props.editedRowData.valid_date
        : selectedBills[0].valid_date;
    try {
      const uniqueItems = [];
      const itemIds = new Set();

      selectedItems.forEach((item) => {
        if (item && !itemIds.has(item.id)) {
          itemIds.add(item.id);
          uniqueItems.push({
            item_id: item?.item_id,
            quantity: item?.qty,
            item_total_discount: item?.discountAmount
              ? item.discountAmount.toFixed(2)
              : "0.00",
            item_total_tax: item?.tax
              ? parseFloat(item.tax).toFixed(2)
              : "0.00",
            item_sub_total_amount: item?.purchasePrice
              ? (item.purchasePrice * item.qty).toFixed(2)
              : "0.00",
            item_total_amount: item?.purchasePrice
              ? (item.purchasePrice * item.qty).toFixed(2)
              : "0.00",
            branch_id: branchId ? branchId : null,
            id: item?.existingItemId,
          });
        }
      });

      const discount = uniqueItems
        .reduce(
          (sum, item) => sum + parseFloat(item.item_total_discount || 0),
          0
        )
        .toFixed(2);

      const formData = {
        bill: {
          supplier_id: supplierId || 1,
          estimate_date: purchaseDate,
          valid_date: validDate,
          reference_no: Array.isArray(selectedReferences)
            ? selectedReferences
            : [selectedReferences],
          order_no: orderNo,
          order_date: orderDate,
          bill_items_attributes: uniqueItems,
          total_amount: totalAmount - discount,
          sub_total: subTotal,
          total_gst: 0,
          total_discount: discount,
          due_days: "",
          due_amount: "",
        },
      };
      let pOId = 0;
      if (props.isUpdated) {
        pOId = props.editedRowData.id;
      }
      const requestType = props.isCopy
        ? "POST"
        : props.isUpdated
        ? "PUT"
        : "POST";
      const url = `${BASE_URL}/v1/transactions/entries${
        props.isUpdated && !props.isCopy ? `/${props.editedRowData.id}` : ""
      }?type=purchase_bill`;

      await sendRequest(requestType, url, formData);
      props.fetchPurchaseBills();
      successNotification(
        props.isCopy
          ? "Purchase Bill Copied!"
          : props.isUpdated
          ? "Purchase Bill Updated!"
          : "Purchase Bill Created!"
      );
      props.onClose();
    } catch (error) {
      console.error("Error in handlePurchaseOrder:", error);
      if (error.data && error.data.errors) {
        error.data.errors.forEach((error) => ErrorNotification(error));
      } else {
        ErrorNotification("An unexpected error occurred.");
      }
    }
  };

  const handleTotalAmount = (amount) => {
    setTotalAmount(amount);
  };

  const handleSubTotal = (amount) => {
    setSubTotal(amount);
  };

  return (
    <>
      <div className="new-purchase-bill-main-container">
        <SaleCommonPopup
          title={props.title}
          isUpdated={props.isUpdated}
          onClose={props.onClose}
          onSave={handlePurchaseBill}
          onUpdate={handlePurchaseBill}
        />
        <div className="new-purchase-bill-sub-container">
          <div className="new-purchase-bill-first-div">
            <div className="new-purchase-bill-estimate-no-grp">
              <span className="new-purchase-bill-est-no-title">
                Purchase Bill No
              </span>
              <span className="new-purchase-bill-est-no">
                {billData.billNumber || ''}
              </span>
            </div>
            <div className="new-purchase-bill-estimate-date-grp">
              <span className="new-purchase-bill-estdate-title">Bill Date</span>
              <span className="new-purchase-bill-estdate-input">
                <input
                  className="form-control new-purchase-bill-first-div-input"
                  type="date"
                  value={billData.billDate || ''}
                  onChange={(e) =>
                    setBillData({ ...billData, billDate: e.target.value })
                  }
                />
              </span>
            </div>
            <div className="new-purchase-bill-tilldate-grp">
              <span className="new-purchase-bill-est-tilldate-title">
                Due Date
              </span>
              <span className="new-purchase-bill-est-tilldate-input">
                <input
                  className="form-control new-purchase-bill-first-div-input"
                  type="date"
                  value={billData.dueDate || ''}
                  onChange={(e) =>
                    setBillData({ ...billData, dueDate: e.target.value })
                  }
                />
              </span>
            </div>
            <div className="new-purchase-bill-reference-grp">
              <span className="new-purchase-bill-est-reference-title">
                Purchase Order #
              </span>
              <span className="new-purchase-bill-est-reference-input">
                <input
                  className="form-control new-purchase-bill-first-div-input"
                  value={query}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleSearchReferences(value);
                  }}
                  placeholder="Search References"
                />
              </span>

              {showDropdown && references.length > 0 && (
                <ul className="reference-dropdown">
                  {references.map((reference, index) => (
                    <li
                      key={index}
                      onClick={() => handleSelectReference(reference)}
                      className="reference-dropdown-item"
                    >
                      {reference.bill_number}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="new-purchase-bill-reference-grp">
              <span className="new-purchase-bill-est-reference-title">
                Type
              </span>
              <span className="new-purchase-bill-est-reference-input">
                <select
                  value={billData.type}
                  onChange={(e) =>
                    setBillData({ ...billData, type: e.target.value })
                  }
                >
                  <option>Regular</option>
                  <option>Import with Tax Payment</option>
                  <option>Import without Tax Payment</option>
                  <option>Purchase From SEZ with Tax Payment</option>
                </select>
              </span>
            </div>
          </div>

          <div className="new-purchase-bill-second-div">
            <div className="new-purchase-bill-customer-div">
              <span className="new-purchase-bill-cust-title">
                Supplier/Vendor
              </span>
              <span className="new-purchase-bill-cust-search">
                <input
                  className="form-control new-purchase-bill-cust-field"
                  type="text"
                  value={selectedSupplier}
                  onChange={handleSearch}
                  placeholder="Search Supplier"
                />
                {showSearchDropdown && (
                  <ul className="new-purchase-bill-customer-dropdown">
                    {suppliers.length > 0 ? (
                      suppliers.map((supplier, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelectSupplier(supplier)}
                          className="new-purchase-bill-dropdown-item"
                        >
                          {supplier.name ||
                            `${supplier.first_name} ${supplier.last_name}`}
                        </li>
                      ))
                    ) : (
                      <li
                        className="customer-dropdown-item"
                        onClick={handleAddSupplier}
                      >
                        <button
                          className="btn btn-primary"
                          onClick={handleCreateSupplier}
                        >
                          Add Supplier
                        </button>
                      </li>
                    )}
                  </ul>
                )}
              </span>
            </div>
            <div className="new-purchase-bill-buyer-order-grp">
              <span className="new-purchase-bill-buyer-order-title">
                Supplier Invoice No
              </span>
              <input
                className="form-control new-purchase-bill-buyer-order"
                value={billData.supplierInvoiceNo}
                onChange={(e) =>
                  setBillData({
                    ...billData,
                    supplierInvoiceNo: e.target.value,
                  })
                }
              />
            </div>
            <div className="new-purchase-bill-buyer-order-date-grp">
              <span className="new-purchase-bill-buyer-order-date-title">
                Supplier Invoice Date
              </span>
              <input
                className="form-control new-purchase-bill-buyer-order-date"
                type="date"
                value={billData.supplierInvoiceDate}
                onChange={(e) =>
                  setBillData({
                    ...billData,
                    supplierInvoiceDate: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="new-purchase-bill-table">
            <CommonItemTable
              headers={headers}
              type={props.type}
              subTotalValue={(amount) => handleSubTotal(amount)}
              totalValue={(amount) => handleTotalAmount(amount)}
              editedRowData={
                props.isUpdated
                  ? props.editedRowData
                  : props.isCreatedPO
                  ? props.editedRowData
                  : props.isOrderUpdated
                  ? props.purchaseOrderCopy
                  : props.isCreatedPB
                  ? props.rowData
                  : props.isCreated
                  ? rowData
                  : selectedBills
              }
              initialItems={selectedItems}
              onFormDataChange={(items) => handleSelectPurchaseBill(items)}
            />
          </div>
        </div>
      </div>

      {/* {isShowCustomerPopup && (
        <div className="new-purchase-bill-customer-popup">
          <div className="new-purchase-bill-sub-customer-container">
            <SelectGuest onClose={props.onClose} />
          </div>
        </div>
      )} */}
    </>
  );
}

export default NewPurchaseBill;