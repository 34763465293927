import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendRequest } from "../../../../ApiRequest";
import { BASE_URL } from "../../../../Url/constants";
import ErrorNotification from "../../../../Notification/ErrorNotification";
import successNotification from "../../../../Notification/SuccessNotification";
import { NotificationContainer } from "react-notifications";
import { useTranslation } from "react-i18next";


const AddSupplier = ({ id, onClose, onAddSupplier }) => {
  const [supplierData, setSupplierData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    supp_company: "",
    com_address: "",
  });

  const navigate = useNavigate();
  const MAX_LENGTH = 60;
  const { t } = useTranslation();


  const handleSupplierChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= MAX_LENGTH) {
      setSupplierData({ ...supplierData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const supplier_params = {
      supplier: {
        first_name: supplierData.first_name,
        last_name: supplierData.last_name,
        email: supplierData.email,
        phone_number: supplierData.phone_number,
        supp_company: supplierData.supp_company,
        com_address: supplierData.com_address,
      },
    };

    try {
      const response = await sendRequest("POST", `${BASE_URL}/v1/onboarding/suppliers`, supplier_params);
      if (response.status === 200) {
        successNotification("Supplier created successfully");
        onAddSupplier(response.data.data);
        onClose();
        navigate(`/transaction-details/${id}`);
      }
    } catch (error) {
      ErrorNotification(error.data.errors[0]);
    }
  };

  return (
    <div className="container-fluid mt-4">
      <h3 className="mb-2">Add Supplier</h3>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-4 mb-2">
            <div className="form-group">
              <label htmlFor="first_name">{t("First Name")}</label>
              <input
                type="text"
                className="form-control"
                id="first_name"
                name="first_name"
                placeholder={t("First Name")}
                value={supplierData.first_name}
                onChange={handleSupplierChange}
              />
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="form-group">
              <label htmlFor="last_name">{t("Last Name")}</label>
              <input
                type="text"
                className="form-control"
                id="last_name"
                name="last_name"
                placeholder={t("Last Name")}
                value={supplierData.last_name}
                onChange={handleSupplierChange}
              />
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="form-group">
              <label htmlFor="email">{t("Email")}</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder={t("Email")}
                value={supplierData.email}
                onChange={handleSupplierChange}
              />
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="form-group">
              <label htmlFor="phone_number">{t("Phone Number")}</label>
              <input
                type="text"
                className="form-control"
                id="phone_number"
                name="phone_number"
                placeholder={t("Phone Number")}
                value={supplierData.phone_number}
                onChange={handleSupplierChange}
              />
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="form-group">
              <label htmlFor="supp_company">{t("Company Name")}</label>
              <input
                type="text"
                className="form-control"
                id="supp_company"
                name="supp_company"
                placeholder={t("Company Name")}
                value={supplierData.supp_company}
                onChange={handleSupplierChange}
              />
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="form-group">
              <label htmlFor="com_address">{t("Company Address")}</label>
              <input
                type="text"
                className="form-control"
                id="com_address"
                name="com_address"
                placeholder={t("Company Address")}
                value={supplierData.com_address}
                onChange={handleSupplierChange}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-1 gap-2">
          <button type="button" className="btn btn-primary" onClick={onClose}>Cancel</button>
          <button type="submit" className="btn btn-primary">Save</button>
        </div>
      </form>
      <NotificationContainer />
    </div>
  );
};

export default AddSupplier;
