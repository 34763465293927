import React, { useState } from "react";
import { getToken } from './SecureStorage/Token';
import { BASE_URL } from "../Url/constants";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import './CreateCustomer.css';
import { useNavigate } from "react-router-dom";
import { sendRequest } from "../ApiRequest";

const CustomerCollapse = () => {

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [customerData, setCustomerData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: ""
  });
  
  const [formErrors, setFormErrors] = useState({});

  const navigate = useNavigate();

  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    let error = '';

    switch (name) {
      case 'first_name':
      case 'last_name':
        newValue = value.replace(/[^A-Za-z]/g, '').slice(0, 64);
        break;
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value.length > 128) {
          newValue = value.slice(0, 128);
        }
        if (value && (!emailRegex.test(value) || value.length > 128)) {
          error = "Please enter a valid email address (up to 128 characters)";
        }
        break;
      case 'phone':
        newValue = value.replace(/\D/g, '').slice(0, 10);
        if (newValue.length > 0 && !/^[6-9]\d{9}$/.test(newValue)) {
          error = "Please enter a valid 10-digit phone number";
        } else if (newValue.length === 0) {
          error = "Phone number is required";
        }
        break;
      default:
        break;
    }

    setCustomerData(prev => ({ ...prev, [name]: newValue }));
    setFormErrors(prev => ({ ...prev, [name]: error }));
  };

  const token1 = getToken();
  const token = { headers: { Authorization: `${token1}` }};

  const handleSubmit = async (e) => {
    setIsButtonDisabled(true);
    e.preventDefault();

    const newErrors = {};
    if (customerData.phone.trim() === '') {
      newErrors.phone = "Phone number is required";
    }

    setFormErrors(prevErrors => ({...prevErrors, ...newErrors}));

    if (Object.values({...formErrors, ...newErrors}).some(error => error !== '')) {
      return;
    }

    const customer_params = {
      customer: customerData
    }

    try {
      const response = await sendRequest('POST', `${BASE_URL}/v1/onboarding/customers`, customer_params, token1);
      if (response.status === 200) {
        navigate("/customers");
        setIsButtonDisabled(false);
      }
    } catch (error) {
      ErrorNotification(error.data.errors[0]);
      setIsButtonDisabled(false);
    };
  };

  const handleCancel = () => {
    navigate('/customers');
  };

  return (
    <>
      <div className="content">
        <div className="col-md-12 margin-btm"><h3 className="title">Add Customer</h3></div>
        <div className="create-company">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <input 
                  type="text" 
                  className={`form-control ${formErrors.first_name ? 'is-invalid' : ''}`}
                  placeholder="First Name" 
                  name="first_name" 
                  value={customerData.first_name} 
                  onChange={handleCustomerChange} 
                />
                {formErrors.first_name && <div className="invalid-feedback">{formErrors.first_name}</div>}
              </div>
              <div className="col-md-6">
                <input 
                  type="text" 
                  className={`form-control ${formErrors.last_name ? 'is-invalid' : ''}`}
                  placeholder="Last Name" 
                  name="last_name" 
                  value={customerData.last_name} 
                  onChange={handleCustomerChange} 
                />
                {formErrors.last_name && <div className="invalid-feedback">{formErrors.last_name}</div>}
              </div>
              <div className="col-md-6">
                <input 
                  type="text" 
                  className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                  placeholder="Email Id" 
                  name="email" 
                  value={customerData.email} 
                  onChange={handleCustomerChange} 
                />
                {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
              </div>
              <div className="col-md-6">
                <input 
                  type="text" 
                  className={`form-control ${formErrors.phone ? 'is-invalid' : ''}`}
                  placeholder="Phone No" 
                  name="phone" 
                  value={customerData.phone} 
                  onChange={handleCustomerChange} 
                  required
                />
                {formErrors.phone && <div className="invalid-feedback">{formErrors.phone}</div>}
              </div>
              <div className="col-md-12">
                <button className="btn btn-primary save-btn-1" disabled={isButtonDisabled} type="submit">Save</button>
                <button className="btn btn-primary" type="button" onClick={handleCancel}>Cancel</button>
              </div>
            </div>
          </form>
          <NotificationContainer/>
        </div>
      </div>
    </>
  );
}

export default CustomerCollapse;
