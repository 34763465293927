
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BackButton.css';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1); // Navigate to the previous page in the browser history
  };

  return (
    <button className="back-button" onClick={handleBackButtonClick}>
      <i className="fa fa-arrow-left"></i>
    </button>
  );
};

export default BackButton;