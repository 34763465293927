import React, { useState, useRef, useEffect } from 'react';
import { CalendarContainer } from 'react-datepicker';
import DatePicker from 'react-datepicker';
import { FaSort, FaCalendar } from 'react-icons/fa';
import "../Items/item.css"

// Custom header component for expiry date column
const ExpiryDateHeader = ({ onSortChange, customDate, setCustomDate }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        setShowCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);     

  return (
    <div className="expiry-header-container" ref={dropdownRef}>
      <div 
        className="expiry-header-trigger"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <span>Expiry Date</span>
        <FaSort />
      </div>
      
      {showDropdown && (
        <div className="expiry-dropdown">
          <div className="expiry-dropdown-content">
            <button 
              className="dropdown-item"
              onClick={() => {
                onSortChange('Expiry Date', '15days');
                setShowDropdown(false);
              }}
            >
              Next 15 Days
            </button>
            <button 
              className="dropdown-item"
              onClick={() => {
                onSortChange('Expiry Date', '1month');
                setShowDropdown(false);
              }}
            >
              Next 1 Month
            </button>
            <button 
              className="dropdown-item"
              onClick={() => {
                onSortChange('Expiry Date', '6months');
                setShowDropdown(false);
              }}
            >
              Next 6 Months
            </button>
            <button 
              className="dropdown-item custom-date"
              onClick={() => setShowCalendar(!showCalendar)}
            >
              <span>Custom Date</span>
              <FaCalendar />
            </button>
          </div>
          
          {showCalendar && (
            <div className="calendar-container">
              <DatePicker
                selected={customDate}
                onChange={(date) => {
                  onSortChange('Expiry Date', 'custom', date);
                  setShowCalendar(false);
                  setShowDropdown(false);
                }}
                minDate={new Date()}
                inline
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const CustomTable = ({ col_names, data, onSort, isPharmaceutical, customDate, setCustomDate }) => {
  return (
    <table className="table table-striped w-full border-collapse">
      <thead className="sticky-header">
        <tr>
          {col_names.map((col, index) => (
            <th key={index} className="p-3 text-left border-b border-gray-200 bg-gray-50">
              {isPharmaceutical && col === 'Expiry Date' ? (
                <ExpiryDateHeader
                  onSortChange={onSort}
                  customDate={customDate}
                  setCustomDate={setCustomDate}
                />
              ) : (
                <div className="flex items-center">
                  {col}
                </div>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr 
            key={rowIndex}
            className="hover:bg-gray-50"
          >
            {col_names.map((col, colIndex) => (
              <td key={colIndex} className="p-3 border-b border-gray-200">
                {row[typeof col === 'object' ? col.name : col]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CustomTable;