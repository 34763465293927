import React, { useState } from 'react';
import './AddPurchase.css';
import { sendRequest } from '../../../ApiRequest';
import { BASE_URL } from '../../../Url/constants';
import successNotification from '../../../Notification/SuccessNotification';
import ErrorNotification from '../../../Notification/ErrorNotification';

const StockPayment = ({ stockData, onClose, setPaymentUpdated, supplierList }) => {
  const [isPartialPayment, setIsPartialPayment] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(stockData.due_amount);
  const [dueAmount, setDueAmount] = useState(stockData.due_amount);
  const [supplierId, setSupplierId] = useState(stockData?.supplier_details?.supplier_id || "");
  const [errorMessage, setErrorMessage] = useState('');

  const [transactionId, setTransactionId] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const handleTransactionId = (e) => {
    setTransactionId(e.target.value);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleToggleChange = () => {
    setIsPartialPayment(!isPartialPayment);
    if (!isPartialPayment) {
      setPaymentAmount('');
    } else {
      setPaymentAmount(stockData.due_amount);
    }
  };

  const handlePaymentAmountChange = (e) => {
    const amount = parseFloat(e.target.value) || 0;

    if (amount <= stockData.due_amount) {
      setPaymentAmount(amount);
      setDueAmount(stockData.due_amount - amount);
      setErrorMessage('');
    } else {
      setErrorMessage(`Amount cannot exceed ₹${stockData.due_amount}`);
    }
  };

  const handlePayNow = async () => {
    if (!supplierId) {
      ErrorNotification("Please select Supplier");
      return;
    } else if (paymentAmount > stockData.due_amount) {
      setErrorMessage(`Amount cannot exceed ₹${stockData.due_amount}`);
      return;
    }

    const convertFileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    };

    let fileBase64 = null;

    if (selectedFile) {
      try {
        fileBase64 = await convertFileToBase64(selectedFile);
      } catch (error) {
        ErrorNotification("File conversion failed");
        return;
      }
    }

    const params = {
      paid_amount: paymentAmount,
      supplier_id: supplierId,
      transaction_id: transactionId,
      file: fileBase64,
    };

    if (stockData.due_amount === stockData.purchase_amount) {
      params.is_paid = true;
    } else {
      params.is_partialy_paid = true;
    }
    const response = await sendRequest("PUT", `${BASE_URL}/v1/inventory_managment/purchase_stocks/${stockData.id}`, params);
    if (response.status === 200) {
      successNotification("Payment updated");
      setPaymentUpdated(true);
      onClose();
    } else {
      ErrorNotification("Payment update failed");
    }
  };

  return (
    <div className="stock-payment-popup-overlay">
      <div className="stock-payment-popup-content">
        <div className="stock-payment-popup-header">
          <span className='stock-pay-head'>Payment for {stockData.purchase_unique_id}</span>
          <button onClick={onClose} className="stock-payment-popup-close-button">&times;</button>
        </div>
        <div className="stock-payment-popup-body">
          <div className="stock-payment-input-select-grp">
            <select
              className="form-select stock-form-select"
              value={supplierId}
              onChange={(e) => {
                setSupplierId(e.target.value);
              }}
            >
              <option value="">Select Supplier</option>
              {supplierList.map((supplier) => (
                <option key={supplier.id} value={supplier.id}>
                  {`${supplier.first_name} ${supplier.last_name}`}
                </option>
              ))}
            </select>
          </div>
          <p>Total Purchase Amount: (₹) <b>{stockData.purchase_amount}</b></p>
          <p>Due Amount: (₹) <b>{dueAmount}</b></p>
          <div className="purchase-toggle-div">
            <label className="toggle-container">
              <input
                type="checkbox"
                checked={isPartialPayment}
                onChange={handleToggleChange}
              />
              <span className="toggle-slider"></span>
            </label>
            <span className="purchase-toggle-label">
              Pay Partial Amount
            </span>
          </div>

          <div className='stock-payment-input-box'>
            <label>
              {isPartialPayment ? "Enter Payment Amount:" : "Amount:"}
              <input
                type="number"
                value={paymentAmount}
                className='form-control stock-input-field'
                onChange={handlePaymentAmountChange}
                disabled={!isPartialPayment}
              />
            </label>
            {errorMessage && (
              <p className="error-message" style={{ color: 'red' }}>
                {errorMessage}
              </p>
            )}
          </div>

          <div className='stock-payment-input-box'>
            <label>
              Transaction ID:
              <input
                type="text"
                value={transactionId}
                className='form-control stock-input-field'
                onChange={handleTransactionId}
              />
            </label>
          </div>

          <div className='stock-payment-input-box'>
            <label>
              Upload File:
              <input
                type="file"
                className='form-control stock-input-field'
                onChange={handleFileChange}
              />
            </label>
          </div>

          <button onClick={handlePayNow} className="btn btn-primary mt-2">
            Pay Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default StockPayment;

