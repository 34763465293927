import "./ItemUpdate.css";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getToken } from "../SecureStorage/Token";
import { faChevronDown, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendRequest } from "../../ApiRequest";
import { BASE_URL } from "../../Url/constants";
import successNotification from "../../Notification/SuccessNotification";
import Copyrights from "../Copyrights";
import ItemPurchasePrice from "./ItemPopup/ItemPurchasePrice";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { BsPencilSquare, BsTextIndentRight } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import ErrorNotification from "../../Notification/ErrorNotification";

function ItemUpdate() {
  const token1 = getToken();
  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [hsn, setHsn] = useState("");
  const [salesPrice, setSalesPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [updatedQuantity, setUpdatedQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [units, setUnits] = useState([]);
  const [wholesalePrice, setWholesalePrice] = useState("");
  // const [itemImage, setItemImage] = useState(null);
  const [itemImageName, setItemImageName] = useState(null);
  const [itemImageFile, setItemImageFile] = useState(null);
  const [discountType, setDiscountType] = useState("Fixed");
  const [salesDiscount, setSalesDiscount] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [taxType, setTaxType] = useState("Individual");
  const [individualTaxes, setIndividualTaxes] = useState([]);
  const [groupTaxes, setGroupTaxes] = useState([]);
  const [tax, setTax] = useState([]);
  const [taxGroup, setTaxGroup] = useState([]);
  const [isTaxInclusive, setIsTaxInclusive] = useState(false);
  const [purchaseEditPricePopup, setPurchaseEditPricePopup] = useState(false);
  const [purchaseEditTax, setEditPurchaseTax] = useState([]);
  const [purchaseEditTaxGroup, setEditPurchaseTaxGroup] = useState([])
  const [purchaseTaxType, setEditPurchaseTaxType] = useState("individualtax");
  const [settingConfig, setSettingConfig] = useState([]);
  const [setting, setSetting] = useState({})
  const [isVisible, setIsVisible] = useState(true);
  const [discountError, setDiscountError] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [purchasePrice, setPurchasePrice] = useState("");


  const { t } = useTranslation();

  const getSettings = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/setting/settings/item_setting_options?type=global`,
        null,
        token1
      );
      console.log(response.data.data);
      setSetting(response.data.data);
    } catch (error) {
      console.error("Error fetching options config:", error.response || error.message);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchUnits();
    getSettings();
    handleTaxTypeChange({ target: { value: "Individual" } });
    handleTaxTypeChange({ target: { value: "Group" } });
    fetchItem();
  }, [])

  useEffect(() => {
    if (Array.isArray(setting)) {
      setSettingConfig(setting);
    } else if (setting && Array.isArray(setting.data)) {
      // Add this condition to handle the case when setting is an object with a data array
      setSettingConfig(setting.data);
    } else {
      console.error("setting is not an array:", setting);
      setSettingConfig([]);
    }
  }, [setting]);

  let isWholesalePriceEnabled = "";
  let isUnitEnabled = "";
  let isCategoryEnabled = "";
  let isPurchaseEnable = "";
  let isItemTaxEnable = "";
  let isItemDiscountEnable = "";

  if (Array.isArray(settingConfig)) {
    const wholesaleSetting = settingConfig.find(
      (config) => config.name === "wholesale_price"
    );
    isWholesalePriceEnabled = wholesaleSetting?.item_setting_config?.status;

    const unitSetting = settingConfig.find(
      (config) => config.name === "item_unit"
    );
    isUnitEnabled = unitSetting?.item_setting_config?.status;

    const categorySetting = settingConfig.find(
      (config) => config.name === "item_category"
    );
    isCategoryEnabled = categorySetting?.item_setting_config?.status;
    const purchaseSetting = settingConfig.find(
      (config) => config.name === "party_wise_item_rate"
    );
    isPurchaseEnable = purchaseSetting?.item_setting_config?.status;

    const itemTaxSetting = settingConfig.find(
      (config) => config.name === "item_wise_tax"
    );
    isItemTaxEnable = itemTaxSetting?.item_setting_config?.status;

    const itemDiscountSetting = settingConfig.find(
      (config) => config.name === "item_wise_discount"
    );
    isItemDiscountEnable = itemDiscountSetting?.item_setting_config?.status;
  }

  useEffect(() => {
    if (item?.category_id) setCategoryValue(item.category_id);
    if (item?.item_name) setItemName(item.item_name);
    if (item?.item_code) setItemCode(item.item_code);
    if (item?.hsn) setHsn(item.hsn);
    if (item?.sales_price) setSalesPrice(item.sales_price);
    if (item?.purchase_price) setPurchasePrice(item.purchase_price);
    if (item?.quantity) setQuantity(item.quantity);
    if (item?.unit_id) setUnit(item.unit_id);
    if (item?.wholesale_price) setWholesalePrice(item.wholesale_price);
    if (item?.discount_type) setDiscountType(item.discount_type);
    if (item?.sales_discount) setSalesDiscount(item.sales_discount);
    if (item?.discount_price) setDiscountPrice(item.discount_price);
    if (item?.tax_type) setTaxType(item.tax_type);
    if (item?.tax_rate_id) setTax(item.tax_rate_id);
    if (item?.tax_group_id) setTaxGroup(item.tax_group_id);
    if (item?.is_tax_inclusive !== undefined) setIsTaxInclusive(item.is_tax_inclusive);
    // if (item?.image_name) setItemImage(item.image_name);
    if (item?.image_name) setItemImageName(item.image_name);
    if (item?.purchase_tax_type) setEditPurchaseTaxType(item.purchase_tax_type);
    if (item?.purchase_tax_rate_id) setEditPurchaseTax(item.purchase_tax_rate_id);
    if (item?.purchase_tax_group_id) setEditPurchaseTaxGroup(item.purchase_tax_group_id);
    if (item?.is_visible !== undefined) setIsVisible(item.is_visible);
    if (item?.bar_code) setBarcode(item.bar_code);
  }, [item]);

  useEffect(() => {
    calculateDiscountPrice();
  }, [salesPrice, salesDiscount, discountType]);

  const calculateDiscountPrice = () => {
    if (salesPrice && salesDiscount && discountType) {
      let calculatedDiscount = 0;
      if (discountType === "Fixed") {
        calculatedDiscount = parseFloat(salesDiscount);
        if (calculatedDiscount > parseFloat(salesPrice)) {
          setDiscountError(true);
          setDiscountPrice(0);
        } else {
          setDiscountError(false);
          setDiscountPrice(parseFloat(salesPrice) - calculatedDiscount);
        }
      } else if (discountType === "Percentage") {
        if (parseFloat(salesDiscount) > 100) {
          setDiscountError(true);
          setDiscountPrice(0);
        } else {
          setDiscountError(false);
          calculatedDiscount =
            (parseFloat(salesPrice) * parseFloat(salesDiscount)) / 100;
          setDiscountPrice(parseFloat(salesPrice) - calculatedDiscount);
        }
      }
    }
  };

  const handlePurchasePriceSave = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    if (value.length <= 10) {
      setPurchasePrice(value);
    }
  };

  const fetchItem = async () => {
    try {
      const response = await sendRequest('GET', `${BASE_URL}/v1/inventory_managment/items/${id}`, null, token1);
      setItem(response.data.data);
    } catch (error) {
      console.error('Error fetching item:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/inventory_managment/categories`,
        null,
        token1
      );
      setCategories(response.data.data);
    } catch (error) {
      console.log("Error fetching categories:", error);
    }
  };

  const fetchUnits = async () => {
    try {
      const response = await sendRequest(
        "GET",
        `${BASE_URL}/v1/inventory_managment/units`,
        null,
        token1
      );
      console.log(response);
      setUnits(response.data.data);
    } catch (error) {
      console.log("Error fetching units:", error);
      setUnits([]);
    }
  };

  const handleCancel = () => {
    navigate("/inventory");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isSubmitting) {
      return;
    }
    if (discountError) {
      ErrorNotification(
        discountError
          ? discountType === "Percentage"
            ? "Discount percentage cannot be greater than 100%."
            : "Discount cannot be greater than sales price."
          : "Fields are required."
      );
      return;
    }
    if (quantity && (quantity <= 0)) {
      ErrorNotification(`quantity must be greater than 0`);
      return;
    }
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("item[item_name]", itemName);
    formData.append("item[item_code]", itemCode);
    formData.append("item[hsn]", hsn);
    formData.append("item[sales_price]", salesPrice);
    formData.append("item[purchase_price]", purchasePrice);
    formData.append("item[sales_discount]", salesDiscount);
    formData.append("item[is_tax_inclusive]", isTaxInclusive);
    formData.append("item[unit_id]", unit);
    if (updatedQuantity) {
      formData.append("item[quantity]", updatedQuantity);
    }
    formData.append("item[discount_type]", discountType);
    formData.append("item[tax_type]", taxType);
    formData.append("item[wholesale_price]", wholesalePrice);
    formData.append("item[discount_price]", discountPrice);
    if (taxType === "Individual") {
      formData.append("item[tax_rate_id]", tax);
    } else {
      formData.append("item[tax_group_id]", taxGroup);
    }
    if (itemImageFile) {
      formData.append("item[item_image]", itemImageFile);
    }
    formData.append("item[category_id]", categoryValue);
    formData.append("item[purchase_tax_type]", purchaseTaxType);
    formData.append("item[purchase_tax_rate_id]", purchaseEditTax);
    formData.append("item[purchase_tax_group_id]", purchaseEditTaxGroup);
    formData.append("item[is_visible]", isVisible);
    formData.append("item[bar_code]", barcode);

    try {
      const response = await sendRequest(
        "PUT",
        `${BASE_URL}/v1/inventory_managment/items/purchase_item_update?id=${id}`,
        formData,
        token1
      );
      console.log("Update response:", response);
      successNotification("Item Updated!");
      navigate("/inventory");
    } catch (error) {
      console.error("Error updating item:", error);
      ErrorNotification("Failed to update item. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCategoryChange = (event) => {
    setCategoryValue(event.target.value);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleDiscountTypeChange = (event) => {
    const newDiscountType = event.target.value;
    setDiscountType(newDiscountType);

    if (newDiscountType === "Percentage" && parseFloat(salesDiscount) > 100) {
      setSalesDiscount("");
      setDiscountError(false);
    }

    calculateDiscountPrice();
  };

  const handleTaxTypeChange = async (event) => {
    const selectedTaxType = event.target.value;
    setTaxType(selectedTaxType);
    try {
      if (selectedTaxType === "Individual") {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/taxes/taxes`,
          null,
          token1
        );
        console.log(response);
        setIndividualTaxes(response?.data.data || []);
      } else if (selectedTaxType === "Group") {
        const response = await sendRequest(
          "GET",
          `${BASE_URL}/v1/taxes/tax_groups`,
          null,
          token1
        );
        console.log(response);
        setGroupTaxes(response?.data.data || []);
      }
    } catch (error) {
      console.error("Error fetching taxes:", error);
    }
  };

  const handleTaxchange = (event) => {
    setTax(event.target.value);
  };

  const handletaxGroupchange = (event) => {
    setTaxGroup(event.target.value);
  };

  const handleSalesPriceChange = (event) => {
    setSalesPrice(event.target.value);
  };

  const handleSalesDiscount = (event) => {
    const newDiscount = event.target.value;
    if (discountType === "Percentage" && parseFloat(newDiscount) > 100) {
      setDiscountError(true);
    } else if (
      discountType === "Fixed" &&
      parseFloat(newDiscount) > parseFloat(salesPrice)
    ) {
      setDiscountError(true);
    } else {
      setDiscountError(false);
    }
    setSalesDiscount(newDiscount);
  };

  const handleItemNameChange = (event) => {
    setItemName(event.target.value);
  };

  const handleVisibleChange = (event) => {
    setIsVisible(!event.target.checked);
  };

  const handleItemCodeChange = (event) => {
    setItemCode(event.target.value);
  };

  const handleHsnChange = (event) => {
    setHsn(event.target.value);
  };

  // const handlePurchasePriceChange = (event) => {
  //   setPurchasePrice(event.target.value);
  // };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
    setUpdatedQuantity(event.target.value);
  };

  const handleWholesalePriceChange = (event) => {
    setWholesalePrice(event.target.value);
  };

  // const handleImageChange = (event) => {
  //   setItemImage(event.target.files[0]);
  // };

  const handleItemImageChange = (event) => {
    const file = event.target.files[0];
    setItemImageFile(file);
    setItemImageName(file ? file.name : null);
  };

  const handleDiscountPrice = (event) => {
    setDiscountPrice(event.target.value);
  };

  const handleIsTaxInclusiveChange = (event) => {
    setIsTaxInclusive(event.target.checked);
  };

  const handleEditShowPurchase = () => {
    setPurchaseEditPricePopup(true);
  }

  const handlePurchaseEditClosepopup = () => {
    setPurchaseEditPricePopup(false);
  }

  const handleEditPurchasePriceSave = (data) => {
    const { purchasePrice, tax, taxGroup, purchaseTaxType } = data;
    setEditPurchaseTaxGroup(taxGroup);
    setEditPurchaseTax(tax);
    setPurchasePrice(purchasePrice);
    setEditPurchaseTaxType(purchaseTaxType);
  };

  const isSalesPriceLess = salesPrice !== '' && parseFloat(salesPrice) < purchasePrice;
  return (
    <>
      <Tooltip id="my-tooltip" />
      <div className="item-update-main-content">
        <form onSubmit={handleSubmit}>
          <div className="create-item">
            <div className="row">
              <div className="col-md-4">
                <label>{t("Item Name")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Item Name*"
                  value={itemName}
                  maxLength={15}
                  onChange={handleItemNameChange}
                />
              </div>

              <div className="col-md-4">
                <label>{t("Item Code")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Item Code"
                  value={itemCode}
                  maxLength={15}
                  onChange={handleItemCodeChange}
                />
              </div>

              <div className="col-md-4">
                <label>{t("Hsn No")}.</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="HSN Number"
                  value={hsn}
                  maxLength={8}
                  onChange={handleHsnChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <label>{t("Category")}</label>
                <div className="custom-select">
                  <select
                    className="form-select"
                    value={categoryValue}
                    onChange={handleCategoryChange}
                    disabled={!isCategoryEnabled}
                    {...(!isCategoryEnabled && {
                      "data-tooltip-id": "my-tooltip",
                      "data-tooltip-content": "Please enable this from item settings",
                    })}
                  >
                    <option value="" disabled>
                      {t("Select Category")}
                    </option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  {/* <div className="item-update-select-icon">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div> */}
                </div>
              </div>
              <div className="col-md-4">
                <label>{t("Unit")}</label>
                <div className="iteme-update-custom-select">
                  <select
                    className="form-select"
                    value={unit}
                    onChange={handleUnitChange}
                    // disabled
                    disabled={!isUnitEnabled}
                    {...(!isUnitEnabled && {
                      "data-tooltip-id": "my-tooltip",
                      "data-tooltip-content":
                        "Please enable this from item settings",
                    })}
                  >
                    <option value="" disabled>
                      {t("Select Unit")}*
                    </option>
                    {units.map((unit) => (
                      <option key={unit.id} value={unit.id}>
                        {unit.unit_name}
                      </option>
                    ))}
                  </select>
                  {/* <div className="select-icon">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div> */}
                </div>
              </div>
              <div className="col-sm-4 mt-2">
                <label>{t("Item Image")}</label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={handleItemImageChange}
                />
                {itemImageName && <p className="update-item-img-name">{t("Selected Image")}: {itemImageName}</p>}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-3">
                <label>
                  Purchase Price
                  <span className="update-item-tax-per-unit">(per unit)</span>
                </label>
                <input
                  type="text"
                  className="form-control readonly-input"
                  placeholder="Purchase Price"
                  value={purchasePrice}
                  onChange={handlePurchasePriceSave}
                />

                {/* {isPurchaseEnable ? (
                  <Link onClick={handleEditShowPurchase}>
                    <span className="edit-purchase-icon">
                      <BsPencilSquare />
                    </span>
                    <span className="edit-purchase-item">{t("Edit Purchase")}</span>
                  </Link>
                ) : (
                  <p>{t("Please enable purchase price from item settings")}</p>
                )} */}

              </div>
              <div className="col-md-3">
                <label>{t("Wholesale price")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Wholesale Price"
                  value={wholesalePrice}
                  onChange={handleWholesalePriceChange}
                  disabled={!isWholesalePriceEnabled}
                  {...(!isWholesalePriceEnabled && {
                    "data-tooltip-id": "my-tooltip",
                    "data-tooltip-content":
                      "Please enable this from item settings",
                  })}
                />
              </div>
              <div className="col-md-3">
                <label>
                  {t("sales price")}
                  <span className="tax-per-unit">(per unit)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Sales Price*"
                  value={salesPrice}
                  onChange={handleSalesPriceChange}
                />
                {isSalesPriceLess && (
                  <div className="price-warning">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="warning-icon" />
                    {t("Sales price is less than purchase price")}
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <label>{t("Quantity")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Quantity*"
                  value={quantity}
                  onChange={handleQuantityChange}
                />
              </div>
            </div>

            <div className="row mt-3 align-items-center">
              <div className="col-md-3">
                <div className="radio-group d-flex">
                  <label className="item-label">
                    <input
                      type="radio"
                      name="group1"
                      value="Fixed"
                      checked={discountType === "Fixed"}
                      onChange={handleDiscountTypeChange}
                    />
                    <span className="custom-radio"></span>
                    {t("Fixed")}
                  </label>
                </div>
              </div>

              <div className="col-md-3">
                <div className="radio-group d-flex">
                  <label className="item-label ml-3">
                    <input
                      type="radio"
                      name="group1"
                      value="Percentage"
                      checked={discountType === "Percentage"}
                      onChange={handleDiscountTypeChange}
                    />
                    <span className="custom-radio"></span>
                    {t("Percent")}(%)
                  </label>
                </div>
              </div>

              <div className="col-md-3">
                <label>
                  {t("Sales Discount")}
                  <span className="tax-per-unit">({discountType})</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Sales Discount"
                  value={salesDiscount}
                  onChange={handleSalesDiscount}
                  disabled={!isItemDiscountEnable}
                  {...(!isItemDiscountEnable && {
                    "data-tooltip-id": "my-tooltip",
                    "data-tooltip-content":
                      "Please enable this from item settings",
                  })}                  
                />
                { discountError && (
                  <div className="error-message">
                    {discountType === "Percentage"
                      ? "Discount percentage cannot be greater than 100%."
                      : "Discount cannot be greater than sales price."}
                  </div>
                )}
              </div>

              <div className="col-md-3">
                <label>
                  {t("Discounted Price")}
                  <span className="tax-per-unit">(per unit)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Discount Price"
                  value={discountPrice}
                  onChange={handleDiscountPrice}
                  disabled={!isItemDiscountEnable}
                  {...(!isItemDiscountEnable && {
                    "data-tooltip-id": "my-tooltip",
                    "data-tooltip-content":
                      "Please enable this from item settings",
                  })}
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-3">
                <div className="radio-group d-flex">
                  <label className="item-label">
                    <input
                      type="radio"
                      name="taxType"
                      className="tax-radio-color"
                      value="Individual"
                      checked={taxType === "Individual"}
                      onChange={handleTaxTypeChange}
                    />
                    <span className="custom-radio"></span>
                    {t("Individual Tax")}
                  </label>
                </div>
              </div>

              <div className="col-md-3">
                <div className="radio-group d-flex">
                  <label className="item-label ml-3">
                    <input
                      type="radio"
                      name="taxType"
                      value="Group"
                      checked={taxType === "Group"}
                      onChange={handleTaxTypeChange}
                    />
                    <span className="custom-radio"></span>
                    {t("Group Tax")}
                  </label>
                </div>
              </div>

              <div className="col-md-3">
                <label>
                  {t("Taxes")}
                  <span className="tax-per-unit">({taxType})</span>
                </label>
                <div className="custom-select">
                  <select
                    name="tax"
                    className="form-select"
                    value={taxType === "Individual" ? tax : taxGroup}
                    onChange={
                      taxType === "Individual"
                        ? handleTaxchange
                        : handletaxGroupchange
                    }
                    disabled={!isItemTaxEnable}
                    {...(!isItemTaxEnable && {
                      "data-tooltip-id": "my-tooltip",
                      "data-tooltip-content":
                        "Please enable this from item settings",
                    })}
                  >
                    <option value="" selected disabled>
                      {t("Select Tax")}
                    </option>
                    {taxType === "Individual"
                      ? individualTaxes.map((tax) => (
                        <option key={tax.id} value={tax.id}>
                          {tax.title}
                        </option>
                      ))
                      : groupTaxes.map((taxGroup) => (
                        <option key={taxGroup.id} value={taxGroup.id}>
                          {taxGroup.name}
                        </option>
                      ))}
                  </select>
                  {/* <div className="select-icon">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div> */}
                </div>
              </div>

              <div className="col-md-3 mt-3">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isTaxInclusive}
                    onChange={handleIsTaxInclusiveChange}
                    disabled={!isItemTaxEnable}
                    {...(!isItemTaxEnable && {
                      "data-tooltip-id": "my-tooltip",
                      "data-tooltip-content":
                        "Please enable this from item settings",
                    })}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="toggle-label" style={{ marginLeft: '3%' }}>
                  {t("Tax Inclusive")}
                </span>
              </div>
              <div className="">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={!isVisible}
                    onChange={handleVisibleChange}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="toggle-label">
                  {t("Not Saleable")}
                </span>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label>{t("Barcode")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Barcode"
                    readOnly={item.bar_code_url !== null && item.bar_code_url !== undefined}
                    value={barcode}
                    onChange={(event) => setBarcode(event.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* {purchaseEditPricePopup && (
            <div className="purchase-item-popup-overlay">
              <ItemPurchasePrice
                onClose={handlePurchaseEditClosepopup}
                onSave={(data) => handleEditPurchasePriceSave(data)}
              />
            </div>
          )} */}
          <div className="col-md-12 text-right item-update-button-group">
            <button 
              className="btn btn-primary" 
              type="submit" 
              disabled={isSubmitting}
            >
              {isSubmitting ? t("Updating...") : t("Update")}
            </button>
            <button 
              className="btn btn-primary button-spacing" 
              onClick={handleCancel}
              disabled={isSubmitting}
            >
              {t("Cancel")}
            </button>
          </div>
        </form>
      </div>
      <Copyrights />
    </>
  )
}

export default ItemUpdate;
