import React, {useState, useEffect} from "react";
import "./DeliveryPerson.css"
import CommonCloseButton from "../CommonCloseButton/CommonCloseButton";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import { useTranslation } from "react-i18next";

function DeliveryPerson({onClose, onSelectBiker}) {
  const[isBiker, setBiker] = useState([])
  const { t} = useTranslation();

  useEffect(() => {
    fetchBiker();
  }, []);  

  const fetchBiker = async () => {
    const response = await sendRequest(
    "GET",
    `${BASE_URL}/v1/staff_management/staffs`,
      null
    );
    setBiker(response.data.data);
  };

  const handleBikerSelect = (biker) => {
    onSelectBiker(biker);
  };

  return (
    <>
      <div className='delivery-person-main-container'>
        <div className='delivery-person-title'>
          <span className='delivery-person-title-heading'>{t("Delivery Person")}</span>
          <span onClick={onClose}>
            <CommonCloseButton />
          </span>
        </div>
        <div className="delivery-person-sub-container">
          <div className="delivery-person-group-title">
            <span className="delivery-biker-title">
              {t("Select Biker")}
            </span>
          </div>
          <div className="biker-group">
            <div className="collection-biker-list">
              {isBiker.map((biker) => (
                <div className='biker-person-button-style' key={biker.id}>
                  <button className="biker-person-input" onClick={() => handleBikerSelect(biker)}>
                    {biker.first_name} {biker.last_name}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeliveryPerson;
