import React from "react";

function Copyrights() {
  return (
    <>
      <div className="copyrights"><h5>All copyrights reserved <a href="https://justmindwork.com/">JMW</a> 2024</h5></div>
    </>
  );
}

export default Copyrights;