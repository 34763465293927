import React from 'react';
import { Link } from 'react-router-dom';

const CustomMemoTable = ({ col_names, data }) => {
    console.log("tabledata", data)
  const renderCell = (rowData, columnName) => {
    const cellData = rowData[columnName];

    if (typeof cellData === 'object' && cellData !== null) {
      if (cellData.type === 'button') {
        return (
          <button 
            className="btn btn-primary btn-sm"
            onClick={cellData.onClick}
          >
            {cellData.label}
          </button>
        );
      }
      if (cellData.type === 'link') {
        return (
          <Link 
            to={cellData.to}
            className="btn btn-info btn-sm"
          >
            {cellData.label}
          </Link>
        );
      }
      if (cellData.type === 'image') {
        return (
          <a href={cellData.url} target="_blank" rel="noopener noreferrer">
            <img
              src={cellData.url}
              alt={cellData.alt || "Image"}
              style={{ maxWidth: '100px', maxHeight: '100px', cursor: 'pointer' }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://via.placeholder.com/100";
              }}
            />
          </a>
        );
      }
    }

    // For 'File' column, keep the existing image handling
    if (columnName === 'File' && rowData[columnName]) {
      return (
        <a href={rowData[columnName]} target="_blank" rel="noopener noreferrer">
          <img
            src={rowData[columnName]}
            alt="Transaction File"
            style={{ maxWidth: '100px', maxHeight: '100px', cursor: 'pointer' }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://via.placeholder.com/100";
            }}
          />
        </a>
      );
    }

    return cellData;
  };

  return (
    <table className="table table-striped">
      <thead className='sticky-header'>
        <tr>
          {col_names.map((columnName, index) => (
            <th key={index} scope="col">{columnName}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((rowData, rowIndex) => (
          <tr key={rowIndex}>
            {col_names.map((columnName, colIndex) => (
              <td key={colIndex}>
                {renderCell(rowData, columnName)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CustomMemoTable;