import React, { useState, useEffect } from "react";
import "./template_setting.css";
import "react-multi-carousel/lib/styles.css";
import { FaCog } from "react-icons/fa";
import { IoMdClose } from 'react-icons/io';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ReceiptFirst from "../Receipt/ReceiptFirst/ReceiptFirst";
import ReceiptSecond from "../Receipt/ReceiptSecond/ReceiptSecond";
import ReceiptThird from "../Receipt/ReceiptThird/ReceiptThird";
import ReceiptFour from "../Receipt/ReceiptFour/ReceiptFour";
import { BASE_URL, SUCCESS_MESSAGE, UPDATE_ERROR_MSG } from "../../Url/constants";
import { getToken } from "../SecureStorage/Token";
import ErrorNotification from "../../Notification/ErrorNotification";
import { sendRequest } from "../../ApiRequest";
import SuccessNotification from "../../Notification/SuccessNotification";
import { NotificationContainer } from "react-notifications";
import Modal from "react-modal";
import Copyrights from "../Copyrights";
import { useTranslation } from "react-i18next";

function TemplateSettingView() {
  const [selectedItem, setSelectedItem] = useState(0);
  const [templateConfigData, setTemplateConfigData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentEditingOption, setCurrentEditingOption] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    getDefaultTemplate();
  }, []);

  const getDefaultTemplate = async () => {
    const response = await sendRequest('GET', `${BASE_URL}/v1/template/templates/get_default_template`);
    if (response.status === 200) {
      setSelectedItem(response?.data?.data?.id - 1);
      handleTemplateChange(response?.data?.data?.id);
    }
  };

  const handleTemplateChange = async (templateId) => {
    try {
      const url = `${BASE_URL}/v1/template/templates/${templateId}`;
      const response = await sendRequest("GET", url);
    
      if (response.status === 200) {
        const templateOptions = response.data.data.template_options;
    
        const templateIds = templateOptions.map((option) => option.id);
    
        const configUrl = `${BASE_URL}/v1/template/templates/get_template_configs?template_id=${templateId}&template_option_id=${templateIds}`;
        const configResponse = await sendRequest("GET", configUrl);
    
        if (configResponse.status === 200 && configResponse.data.data.length > 0) {
          const updatedTemplateOptions = templateOptions.map((option) => {
            const matchingConfig = configResponse.data.data.find(
              (config) => config.template_option_id === option.id
            );
    
            return {
              ...option,
              template_config: matchingConfig || {},
              alignment: (matchingConfig && matchingConfig.alignment) || 'center',
            };
          });
    
          setTemplateConfigData(updatedTemplateOptions);
        } else {
          console.error("No configuration data found.");
          ErrorNotification("Failed to load template configuration.");
        }
      }
    } catch (error) {
      console.error("Error loading template configuration:", error);
      ErrorNotification("Failed to load template configuration");
    }
  };

  const handleItemClick = async (index) => {
    if (index !== selectedItem) {
      setSelectedItem(index);
      await handleTemplateChange(index + 1);
    }
  };

  const handleCheckboxChange = (index) => {
    setTemplateConfigData(prevState => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        template_config: {
          ...newState[index].template_config,
          is_enable: !newState[index].template_config?.is_enable,
        },
      };
      return newState;
    });
  };

  const openModal = (option) => {
    setCurrentEditingOption(option);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentEditingOption(null);
  };

  const handleTextChange = (text) => {
    setCurrentEditingOption(prevOption => ({
      ...prevOption,
      template_config: {
        ...prevOption.template_config,
        free_text: text,
      },
    }));
  };

  const handleAlignmentChange = (alignment) => {
    setCurrentEditingOption(prevOption => ({
      ...prevOption,
      template_config: {
        ...prevOption.template_config,
        alignment: alignment,
      },
    }));
  };

  const handleSavePopup = () => {
    setTemplateConfigData(prevState =>
      prevState.map(option =>
        option.id === currentEditingOption.id
          ? {
              ...option,
              template_config: {
                ...option.template_config,
                ...currentEditingOption.template_config,
              },
            }
          : option
      )
    );
    closeModal();
  };

  const handleSave = async () => {
    const data = {
      template_config_data: templateConfigData.map(option => ({
        template_option_id: option.id,
        free_text: option.template_config?.free_text,
        is_enable: option.template_config?.is_enable,
        alignment: option.template_config?.alignment,
      })),
    };

    try {
      const response = await sendRequest('PUT', `${BASE_URL}/v1/template/templates/${selectedItem + 1}`, data);
      if (response.status === 200) {
        SuccessNotification(SUCCESS_MESSAGE('updated', 'template'));
      } else {
        ErrorNotification(UPDATE_ERROR_MSG('template'));
      }
    } catch (error) {
      ErrorNotification(UPDATE_ERROR_MSG('template'));
    }
  };

  const enabledTemplateData = templateConfigData
    .filter(option => option?.template_config?.is_enable)
    .map(option => ({
      option_name: option.option_name,
      free_text: option.template_config.free_text,
      is_enable: option.template_config?.is_enable,
      alignment: option.template_config?.alignment,
    }));

  return (
    <>
      <div className="template-main-content">
        <div className="template-setting-box-container">
          <div className="inner-box-1">
            {[1, 2, 3, 4].map((item, index) => (
              <div
                key={index}
                onClick={() => handleItemClick(index)}
                className={`temp-img-align mt-3 ${selectedItem === index ? "selected" : ""}`}
              >
                <img
                  src={`/receipt${item}.png`}
                  alt={`demoImage${item}`}
                  className="box-temp-setting-image"
                />
                {selectedItem === index && (
                  <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                )}
              </div>
            ))}
          </div>

          {selectedItem !== null && (
            <div className="template-main-sub-content">
              <div className="inner-box-2">
                <div className="template-setting-title">
                  {t("Template Setting")}
                </div>
                <div className="setting-template-figure mt-3">
                  <div className="temp-setting-options-container">
                    {templateConfigData.map((option, index) => (
                      <div key={option.id} className="temp-option-item">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={option.template_config?.is_enable}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <label className="label-space">
                          {option.option_name.replace(/_/g, " ")}
                        </label>
                        <FaCog
                          className="settings-icon"
                          onClick={() => openModal(option)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-12 mt-10 text-right">
                  <button className="btn btn-primary save-btn-i" onClick={handleSave}>
                    {t("Save")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {selectedItem !== null && (
          <div className="right-content">
            <div className="right-content-inner">
              {selectedItem === 0 && (<ReceiptFirst enabledTemplateData={enabledTemplateData} templateConfig={templateConfigData} />)}
              {selectedItem === 1 && (<ReceiptSecond enabledTemplateData={enabledTemplateData} templateConfig={templateConfigData} />)}
              {selectedItem === 2 && (<ReceiptThird enabledTemplateData={enabledTemplateData} templateConfig={templateConfigData} />)}
              {selectedItem === 3 && (<ReceiptFour enabledTemplateData={enabledTemplateData} templateConfig={templateConfigData} />)}
            </div>
          </div>
        )}
        
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Option Settings"
          className="custom-modal"
          overlayClassName="custom-overlay"
        >
          <div className="modal-header">
            <h2>{currentEditingOption?.option_name.replace(/_/g, " ")} Settings</h2>
            <IoMdClose className="close-icon" onClick={closeModal} />
          </div>
          <div className="modal-content">
            <div className="form-group">
              <label>Alignment:</label>
              <select
                value={currentEditingOption?.template_config?.alignment || "center"}
                onChange={(e) => handleAlignmentChange(e.target.value)}
              >
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
              </select>
            </div>
            <div className="form-group">
              <label>Custom Text:</label>
              <input
                type="text"
                value={currentEditingOption?.template_config?.free_text || ""}
                onChange={(e) => handleTextChange(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button onClick={handleSavePopup}>Save</button>
            <button onClick={closeModal}>Cancel</button>
          </div>
        </Modal>

        <NotificationContainer />
      </div>
      <Copyrights />
    </>
  );
}

export default TemplateSettingView;