import React, { useState } from "react";
import { getToken } from './SecureStorage/Token';
import { BASE_URL, UPDATE_ERROR_MSG } from "../Url/constants";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import './CreateCustomer.css';
import { validateEmail, validatePhone } from "../Url/validationUtils";
import Header from "./Header";
import { useNavigate, useLocation } from "react-router-dom";
import { sendRequest } from "../ApiRequest";

const UpdateCustomerForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const customer = location.state;
  const [updateCustomerData, setupdateCustomerData] = useState({
    id: customer?.id,
    first_name: customer?.first_name || '',
    last_name: customer?.last_name || '',
    email: customer?.email || '',
    phone: customer?.phone || ''
  });

  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e) => {
    setupdateCustomerData({ ...updateCustomerData, [e.target.name]: e.target.value });
  };

  const token1 = getToken();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    const errors = [];

    if (!updateCustomerData.email && !updateCustomerData.phone) {
      errors.push("Either Email or Phone No is required.");
    } else {
      if (updateCustomerData.email) {
        const emailValidationResult = validateEmail(updateCustomerData.email);
        if (emailValidationResult) {
          errors.push(emailValidationResult);
        }
      }

      if (updateCustomerData.phone) {
        const phoneValidationResult = validatePhone(updateCustomerData.phone);
        if (phoneValidationResult) {
          errors.push(phoneValidationResult);
        }
      }
    }

    if (errors.length > 0) {
      errors.forEach(errorMessage => {
        ErrorNotification(errorMessage);
      });
      return;
    }

    const params = {
      customer: {
        first_name: updateCustomerData.first_name,
        last_name: updateCustomerData.last_name,
        email: updateCustomerData.email,
        phone: updateCustomerData.phone
      }
    }
    await sendRequest('PUT', `${BASE_URL}/v1/onboarding/customers/${updateCustomerData.id}`, params, token1)
      .then(response => {
        if (response.status === 200) {
          navigate("/customers");
        }
      })
      .catch(error => {
        const msg = UPDATE_ERROR_MSG('customer');
        ErrorNotification(msg);
      });
  };

  const handleCancelform = () => {
    navigate('/customers');
  };

  return (
    <>
      <div className="content">
        <div className="col-md-12 margin-btm"><h3 className="title">Edit Customer</h3></div>
        <div className="create-company">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  value={updateCustomerData.first_name}
                  onChange={handleInputChange}
                  placeholder="First Name"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="last_name"
                  value={updateCustomerData.last_name}
                  onChange={handleInputChange}
                  placeholder="Last Name"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control ${submitted && !updateCustomerData.email && !updateCustomerData.phone && "required-field"}`}
                  name="email"
                  value={updateCustomerData.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control ${submitted && !updateCustomerData.email && !updateCustomerData.phone && "required-field"}`}
                  name="phone"
                  value={updateCustomerData.phone}
                  onChange={handleInputChange}
                  placeholder="Phone No"
                />
              </div>
              <div className="col-md-12">
                <button className="btn btn-primary save-btn-1" type="submit">Update</button>
                <button className="btn btn-primary" type="button" onClick={handleCancelform}>Cancel</button>
              </div>
            </div>
          </form>
          <NotificationContainer />
        </div>
      </div>
    </>
  );
}

export default UpdateCustomerForm;
