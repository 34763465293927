import React, { useEffect, useState } from "react";
import "./NewPurchaseOrder.css";
import SaleCommonPopup from "../../CompanySales/SaleCommonPopupHeader/SaleCommonPopupHeader";
import CommonItemTable from "../../Common/CommonItemTable";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import successNotification from "../../../Notification/SuccessNotification";
import ErrorNotification from "../../../Notification/ErrorNotification";
import {
  handleSearchSupplier,
  SupplierDetails,
} from "../../../services/supplierService";
import CommonSupplierPopup from "../../Common/CommonSupplierPopup";
import { current_user } from "../../../Authentication/AuthenticationContext";
import { fetchBillData } from "../../../services/fetchBillsService";

function NewPurchaseOrder(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [reference, setReference] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [selectedBills, setSelectedBills] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedItems, setSelectedItems] = useState();
  const [isShowSupplierPopup, setShowSupplierPopup] = useState(false);
  const [isSupplierUpdated, setIsSupplierUpdated] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");
  const [subTotal, setSubTotal] = useState("");
  const [supplierId, setSupplierId] = useState();

  const [orderNo, setOrderNo] = useState("");
  const [estimateDate, setEstimateDate] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [validTillDate, setValidTillDate] = useState();
  const [branchId, setBranchId] = useState();

  useEffect(() => {
    if (props.isUpdated) {
      setEstimateDate(props.editedRowData.estimate_date || "");
      setValidTillDate(props.editedRowData.valid_date || "");
      setReference(props.editedRowData.bill_number || "");
      setSelectedSupplier(props.editedRowData.supplier.supplier_name || "");
      setOrderNo(props.editedRowData.order_no || "");
      setOrderDate(props.editedRowData.order_date || "");
      setSelectedItems(props.editedRowData.bill_items_attributes || []);
    } else if (props.isOrderUpdated && props.purchaseOrderCopy) {
      setEstimateDate(props.purchaseOrderCopy.estimate_date || "");
      setValidTillDate(props.purchaseOrderCopy.valid_date || "");
      setReference(props.purchaseOrderCopy.bill_number || "");
      setSelectedSupplier(props.purchaseOrderCopy.supplier || "");
      setOrderNo(props.purchaseOrderCopy.order_no || "");
      setOrderDate(props.purchaseOrderCopy.order_date || "");
      setSelectedItems(props.purchaseOrderCopy.bill_items_attributes || []);
    } else if (props.isCreatedPO) {
      setEstimateDate(props.editedRowData.estimate_date || "");
      setValidTillDate(props.editedRowData.valid_date || "");
      setReference(props.editedRowData.bill_number || "");
      setSelectedSupplier(props.editedRowData.supplier.supplier_name || "");
      setOrderNo(props.editedRowData.order_no || "");
      setOrderDate(props.editedRowData.order_date || "");
      setSelectedItems(props.editedRowData.bill_items_attributes || []);
    } else if (props.isCreated) {
      resetForm();
    }
  }, [
    props.editedRowData,
    props.isCreated,
    props.isUpdated,
    props.purchaseOrderCopy,
    props.isOrderUpdated,
  ]);

  const resetForm = () => {
    setEstimateDate("");
    setValidTillDate("");
    setReference("");
    setSelectedSupplier();
    setOrderNo("");
    setOrderDate("");
    setSelectedItems([]);
  };

  const handleSearch = async (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (searchTerm.length >= 2) {
      const suppliers = await handleSearchSupplier(query);
      setSuppliers(suppliers);
      setShowDropDown(true);
    } else {
      setShowDropDown(false);
    }
  };

  const handleSelectSupplier = (supplier) => {
    setSelectedSupplier(supplier);
    setSupplierId(supplier.id);
    setSearchTerm(`${supplier?.first_name} ${supplier?.last_name}`);
    setShowDropDown(false);
  };

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await current_user();
      setBranchId(currentUser.company.branch_info);
    };
    fetchUser();
  }, []);

  const handleSearchReferences = async (e) => {
    const searchText = e.target.value;
    setReference(searchText);
    if (searchText.length < 2) return;
    let type = 'purchase_quotation'
    const references = await fetchBillData(searchText, type);
    setShowDropDown(true);
    setSearchResults(references);
  };

  const handleSelectReference = (ref) => {
    setSelectedBills((prevBills) => {
      if (prevBills.some((bill) => bill.id === ref.id)) {
        const updatedBills = prevBills.filter((bill) => bill.id !== ref.id);
        setSelectedSupplier(
          updatedBills.length > 0 ? updatedBills[0].supplier.supplier_name : ""
        );
        return updatedBills;
      } else {
        const updatedBills = [...prevBills, ref];
        setSelectedSupplier(ref.supplier.supplier_name);
        return updatedBills;
      }
    });

    setReference("");
    setShowDropDown(false);
  };

  const headers = [
    { key: "id", label: "S. No" },
    { key: "item_name", label: "Item Name" },
    { key: "hsn", label: "HSN" },
    { key: "tax", label: "Tax" },
    { key: "qty", label: "Quantity" },
    { key: "unit", label: "Unit" },
    {
      key: "price",
      label:
        props.type === "purchase_quotation" || props.type === "purchase_order"
          ? "Purchase Price"
          : "Sales Price",
    },
    { key: "discount", label: "Discount" },
    { key: "amount", label: "Amount" },
    { key: "actions", label: "Action" },
  ];

  const handlePurchaseOrder = async () => {
    if (isSaving) return;

    if (selectedItems.length === 0) return;
    const branch_id =
      selectedBills[0]?.branch?.id ||
      props.purchaseOrderCopy?.branch?.id ||
      branchId.id;
    const estimate_date =
      selectedBills[0]?.estimate_date ||
      props.purchaseOrderCopy?.estimate_date ||
      estimateDate;
    const valid_date =
      selectedBills[0]?.valid_date ||
      props.purchaseOrderCopy?.valid_date ||
      validTillDate;
    const supplier_id =
      selectedBills[0]?.supplier?.id ||
      props.purchaseOrderCopy?.supplier?.id ||
      props.editedRowData?.supplier.id;
    const order_no =
      selectedBills[0]?.order_no ||
      props.purchaseOrderCopy?.order_no ||
      orderNo;
    const order_date =
      selectedBills[0]?.order_date ||
      props.purchaseOrderCopy?.order_date ||
      orderDate;

    if (!supplier_id) {
      ErrorNotification("Please select supplier");
      return;
    }

    try {
      const uniqueItems = selectedItems.map((item) => ({
        item_id: item.item_id,
        quantity: item.qty,
        item_total_discount: item.discountAmount
          ? item.discountAmount.toFixed(2)
          : "0.00",
        item_total_tax: item.tax ? parseFloat(item.tax).toFixed(2) : "0.00",
        item_sub_total_amount: item.purchasePrice
          ? (item.purchasePrice * item.qty).toFixed(2)
          : "0.00",
        item_total_amount: item.purchasePrice
          ? (item.purchasePrice * item.qty).toFixed(2)
          : "0.00",
        branch_id: branch_id,
        id: item.existingItemId,
      }));

      if (!uniqueItems) {
        ErrorNotification("Please select Items");
        return;
      }
      const discount = uniqueItems
        .reduce(
          (sum, item) => sum + parseFloat(item.item_total_discount || 0),
          0
        )
        .toFixed(2);

      const formattedEstimateDate = estimate_date
        ? new Date(estimate_date).toISOString().split("T")[0]
        : null;
      
      const formattedValidDate = valid_date
        ? new Date(valid_date).toISOString().split("T")[0]
        : null;
      
      const formattedOrderDate = order_date
        ? new Date(order_date).toISOString().split("T")[0]
        : null;      

      const formData = {
        bill: {
          supplier_id: supplier_id || 1,
          estimate_date: formattedEstimateDate || "",
          valid_date: formattedValidDate || "",
          reference_no: Array.isArray(reference) ? reference : [reference],
          order_no: order_no,
          order_date: formattedOrderDate,
          bill_items_attributes: uniqueItems,
          total_amount: totalAmount - discount,
          sub_total: subTotal,
          total_gst: 0,
          total_discount: discount,
          due_days: "",
          due_amount: totalAmount,
        },
      };
      setIsSaving(true);
      const pOId = props.isUpdated
        ? props.editedRowData.id || selectedBills[0].id
        : 0;
      const requestType = pOId ? "PUT" : "POST";
      const url = `${BASE_URL}/v1/transactions/entries${
        pOId ? `/${pOId}` : ""
      }?type=purchase_order`;

      const response = await sendRequest(requestType, url, formData);
      if (response.status === 200) {
        successNotification(
          pOId ? "Purchase Order Updated!" : "Purchase Order Created!"
        );

        if (props.type !== "purchase_order") {
          props.fetchPurchaseOrders();
        }

        if (props.type === "purchase_order") {
          props.fetchPurchaseOrders();
        }
        props.onClose();
      }
    } catch (error) {
      console.error("Error in handlePurchaseOrder:", error);
      if (error.data && error.data.errors) {
        error.data.errors.forEach((err) => ErrorNotification(err));
      } else {
        ErrorNotification("An unexpected error occurred.");
      }
    }
  };

  const handleTotalAmount = (amount) => {
    setTotalAmount(amount);
  };

  const handleSubTotal = (amount) => {
    setSubTotal(amount);
  };

  const handleSelectOrder = (items) => {
    setSelectedItems(items);
  };

  const handleCreateSupplier = () => {
    setIsSupplierUpdated(false);
    setShowSupplierPopup(true);
  };

  const handleEditSupplier = () => {
    setIsSupplierUpdated(true);
    setShowSupplierPopup(true);
  };

  const closeCommonSupplierPopup = () => {
    setShowSupplierPopup(false);
  };

  const handleClose = () => {
    setIsSaving(false);
    props.onClose();
  };

  return (
    <div className="new-purchase-order-main-container">
      <SaleCommonPopup
        isSaving={isSaving}
        title={props.title}
        isUpdated={props.isUpdated}
        onClose={() => {
          handleClose();
          resetForm();
        }}
        onUpdate={() => handlePurchaseOrder()}
        onSave={() => handlePurchaseOrder()}
      />

      <div className="new-purchase-order-sub-container">
        <div className="po_table ms-2">
          <div className="po_table_row pq_table_header">
            <div className="pq_table_th">Purchase Order No</div>
            <div className="pq_table_th">Purchase Order Date</div>
            <div className="pq_table_th">Expected Arrival</div>
            <div className="pq_table_th">Reference#</div>
          </div>
          <div className="po_table_row">
            <div className="pq_table_td">
              PO-{selectedBills[0]?.order_no || ""}
            </div>
            <div className="pq_table_td">
              <input
                className="form-control"
                type="date"
                value={selectedBills[0]?.estimate_date || estimateDate}
                onChange={(e) => setEstimateDate(e.target.value)}
              />
            </div>
            <div className="pq_table_td">
              <input
                className="form-control"
                type="date"
                value={selectedBills[0]?.valid_date || validTillDate}
                onChange={(e) => setValidTillDate(e.target.value)}
              />
            </div>
            {/* <div className="pq_table_td">
              <input
                className="form-control"
                value={reference}
                onChange={handleReferenceChange}
                placeholder="Search by reference"
              />
              {showDropDown && searchResults.length > 0 && (
                <div className="reference-dropdown overflow-y-scroll h-25 bg-danger">
                  {searchResults.map((ref) => (
                    <div key={ref.id} className="reference-dropdown-item">
                      <input
                        type="checkbox"
                        checked={selectedBills.some(
                          (bill) => bill.id === ref.id
                        )}
                        onChange={() => handleSelectReference(ref)}
                      />
                      {ref.bill_number}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="selected-references">
              {selectedBills.map((bill) => (
                <div key={bill.id} className="selected-reference-item">
                  {bill.bill_number}
                </div>
              ))}
            </div> */}
            <div className="pq_table_td">
              <div className="selected-references-container">
                <input
                  className="form-control flex-grow-1"
                  value={reference}
                  onChange={handleSearchReferences}
                  placeholder="Search by reference"
                />
                {selectedBills.map((bill) => (
                  <div key={bill.id} className="selected-reference-tag">
                    <span>{bill.bill_number}</span>
                    <button onClick={() => handleSelectReference(bill)}>
                      ×
                    </button>
                  </div>
                ))}
              </div>

              {showDropDown && searchResults.length > 0 && (
                <div className="reference-dropdown">
                  {searchResults.map((ref) => (
                    <div key={ref.id} className="reference-dropdown-item">
                      <input
                        type="checkbox"
                        checked={selectedBills.some(
                          (bill) => bill.id === ref.id
                        )}
                        onChange={() => handleSelectReference(ref)}
                        className="me-2"
                      />
                      {ref.bill_number}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Selected References Section */}
            {/* <div className="selected-references mt-3">
              {selectedBills.map((bill) => (
                <div
                  key={bill.id}
                  className="selected-reference-item d-flex align-items-center justify-content-between bg-secondary text-white p-2 rounded mb-2"
                >
                  <span>{bill.bill_number}</span>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleSelectReference(bill)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div> */}
          </div>
        </div>

        <div className="po_table ms-2">
          <div className="po_table_row pq_table_header">
            <div className="pq_table_th">Supplier/Vendor</div>
            <div className="pq_table_th">Supplier Order No</div>
            <div className="pq_table_th">Supplier Order Date</div>
            <div className="pq_table_th"></div>
          </div>
          <div className="po_table_row">
            <div className="pq_table_td">
              <input
                className="form-control"
                type="text"
                value={
                  selectedBills[0]?.supplier?.supplier_name ||
                  searchTerm ||
                  selectedSupplier
                }
                onChange={handleSearch}
                placeholder="Search supplier"
              />
              {showDropDown && (
                <ul className="list-group bg-light">
                  {suppliers.length > 0 ? (
                    suppliers.map((supplier, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelectSupplier(supplier)}
                        className="new-purchase-quote-dropdown-item"
                      >
                        {supplier.first_name} {supplier.last_name}
                      </li>
                    ))
                  ) : (
                    <li className="customer-dropdown-item">
                      <button
                        className="btn btn-primary"
                        onClick={handleCreateSupplier}
                      >
                        Add Supplier
                      </button>
                    </li>
                  )}
                </ul>
              )}
            </div>
            <div className="pq_table_td">
              <input
                className="form-control"
                placeholder="Order No"
                value={selectedBills[0]?.order_no || orderNo}
                onChange={(e) => setOrderNo(e.target.value)}
              />
            </div>
            <div className="pq_table_td">
              <input
                className="form-control"
                type="date"
                value={selectedBills[0]?.order_date || orderDate}
                onChange={(e) => setOrderDate(e.target.value)}
              />
            </div>
            <div className="pq_table_td"></div>
          </div>
        </div>
        <div className="ms-3 w-50">
          <SupplierDetails
            supplierId={supplierId}
            supplierDetails={selectedSupplier}
            handleEditSupplier={handleEditSupplier}
          />
        </div>

        <div className="new-purchase-order-table">
          <CommonItemTable
            headers={headers}
            type={props.type}
            isCreated={props.isCreatedPO}
            subTotalValue={(amount) => handleSubTotal(amount)}
            totalValue={(amount) => handleTotalAmount(amount)}
            onFormDataChange={(items) => handleSelectOrder(items)}
            editedRowData={
              props.isCreatedPO
                ? props.editedRowData
                : props.isUpdated
                ? props.editedRowData
                : props.isOrderUpdated
                ? props.purchaseOrderCopy
                : selectedBills
            }
            initialItems={selectedItems}
          />
        </div>
      </div>
      {isShowSupplierPopup && (
        <div className="purchase-quote-supplier-popup">
          <CommonSupplierPopup
            isSupplierUpdated={isSupplierUpdated}
            supplierDetails={selectedSupplier}
            onClose={closeCommonSupplierPopup}
          />
        </div>
      )}
    </div>
  );
}

export default NewPurchaseOrder;