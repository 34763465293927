import React from "react";
import "./CommonToggleButton.css"

function CommonToggleButton(props) {
  return (
    <>
      <div className="common-tax-wise-toggle-wrapper">
        <label className="common-tax-wise-toggle-container">
          <input
            type="checkbox"
            checked={props.isBilling}
            onChange={props.onToggleChange}
          />
          <span className="common-tax-wise-toggle-slider"></span>
        </label>
        <span className="common-tax-wise-toggle-label">
          {props.toggleLabel || "Item Wise Tax"}
        </span>
      </div>
    </>
  );
}
export default CommonToggleButton;
