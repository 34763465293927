import React, { useEffect, useRef, useState } from "react";
import "./PurchaseReceipt.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import Copyrights from "../../Copyrights";
import NewPurchaseReceipt from "./NewPurchaseReceipt";
import { sendRequest } from "../../../ApiRequest";
import { BASE_URL } from "../../../Url/constants";
import EntriesPerPageDropdown from "../../Common/EntriesPerPageDropdown";
import PaginationControls from "../../Common/PaginationControls";
import successNotification from "../../../Notification/SuccessNotification";

function PurchaseReceipt() {
  const [openNewPurchaseReceipt, setNewPurchaseReceipt] = useState();
  const [purchaseSaleReceipt, setPurchaseSaleReceipt] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [purReceiptDownAction, setPurReceiptDownAction] = useState(false);
  const [purSelectedReceipt, setPurSelectedReceipt] = useState("");
  const [purchaseReceiptCopy, setPurchaseReceiptCopy] = useState("");
  const [purchaseReceiptUpdated, setPurchaseReceiptUpdated] = useState(false);
  const [actionDropdownPosition, setActionDropdownPosition] = useState({ top: 0, left: 0 });

  const handleNewPurchaseReceipt = () => {
    setNewPurchaseReceipt(true);
  }

  const closeNewPurchaseReceipt = () => {
    setNewPurchaseReceipt(false);
  }

  const fetchPurchaseReceipt = async (pageNumber = 1, perPage = 10, query = "") => {
    try {
      let request_url = `${BASE_URL}/v1/transactions/entries?type=goods_receipt&page=${pageNumber}&per_page=${perPage}`;
      if (query.length > 2) {
        request_url += `&query=${query}`;
      }
      const response = await sendRequest("GET", request_url);
      setPurchaseSaleReceipt(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / perPage));
      setTotalRecords(response.data.total_count);
    } catch (error) {
      console.error("Error fetching sale receipt:", error);
    }
  };

  useEffect(() => {
    fetchPurchaseReceipt(currentPage, recordsPerPage);
  }, [currentPage, recordsPerPage]);

  useEffect(() => {
    const handleDownClickOutside = (event) => {
      if (
        columnPurchaseReceipActionRef.current &&
        !columnPurchaseReceipActionRef.current.contains(event.target)
      ) {
        setPurReceiptDownAction(false);
      }
    };
    if (purReceiptDownAction) {
      document.addEventListener("mousedown", handleDownClickOutside);
    } else {
      document.removeEventListener("mousedown", handleDownClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleDownClickOutside);
    };
  }, [purReceiptDownAction]);

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value, 10);
    setRecordsPerPage(perPage);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * recordsPerPage + 1;
  const endIndex = Math.min(currentPage * recordsPerPage, totalRecords);

  const handlePurchaseReceiptCreated = (value) => {
    if (!value) return;
    setPurchaseSaleReceipt(prevPurchaseReceipt => [...prevPurchaseReceipt, ...value]);
    setTotalRecords(prevTotalRecords => prevTotalRecords + 1);
  }

  const handlePurchaseReceiptDelete = async (purReceiptId) => {
    if (purReceiptId) {
      try {
        const response = await sendRequest("DELETE", `${BASE_URL}/v1/transactions/entries/${purReceiptId}?type=goods_receipt`);
        if (response.status === 200) {
          successNotification("Purchase receipt deleted successfully!");
          fetchPurchaseReceipt(currentPage, recordsPerPage);
          setPurReceiptDownAction(false);
        } else {
          console.log("Failed to delete the purchase receipt. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting the purchase receipt:", error);
      }
    }
  };

  const handlePurchaseReceipCopy = async (purchaseReceipCopyId, updated) => {
    if (updated) {
      setPurchaseReceiptUpdated(updated);
    }
    if (purchaseReceipCopyId) {
      try {
        const response = await sendRequest("GET", `${BASE_URL}/v1/transactions/entries/${purchaseReceipCopyId}?type=goods_receipt`);
        setPurchaseReceiptCopy(response.data.entry);
        setNewPurchaseReceipt(true);
      } catch (error) {
        console.error("Error copy the sale credit note:", error);
        console.log("An error occurred while deleting the copy sale credit note.");
      }
    }
  };

  const handlePurchaseReceiptDownAction = (receipt, event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setActionDropdownPosition({
      top: rect.bottom + window.scrollY,
    });
    setPurSelectedReceipt(receipt)
    setPurReceiptDownAction(true);
  }

  const columnPurchaseReceipActionRef = useRef(null);

  return (
    <>
      <div className="purchase-receipt-main-container">
        <div className="purchase-receipt-container-first-div">
          <div className="purchase-receipt-first-left">
            <span className="all-purchase-receipt-show">All Receipt({totalRecords})</span>
          </div>
          <div className="purchase-receipt-first-right">
            <button
              className="purchase-receipt-first-right-botton"
              onClick={handleNewPurchaseReceipt}
            >
              New Receipt
              {/* <span>
                <FontAwesomeIcon icon={faCaretDown} />
              </span> */}
            </button>
          </div>
        </div>

        <div className="purchase-receipt-second-container">
          <EntriesPerPageDropdown recordPerPage={recordsPerPage} handleRecordPerPageChange={handleRecordPerPageChange} />
        </div>

        <div className="purchase-receipt-table-group">
          <table className="purchase-receipt-table">
            <thead>
              <tr className="purchase-receipt-thead-row">
                <th className="purchase-receipt-tab-th">Date</th>
                <th className="purchase-receipt-tab-th">Receipt No</th>
                <th className="purchase-receipt-tab-th">Contact</th>
                <th className="purchase-receipt-tab-th">Supplier Delivery Challan No</th>
                <th className="purchase-receipt-tab-th">Supplier Delivery Challan Date</th>
                <th className="purchase-receipt-tab-th">Ref.No</th>
                {/* <th className="purchase-receipt-tab-th">Due Days</th> */}
                <th className="purchase-receipt-tab-th">Status</th>
                <th className="purchase-receipt-tab-th">Amount</th>
                <th className="purchase-receipt-tab-th">Action</th>
              </tr>
            </thead>
            <tbody>
              {purchaseSaleReceipt.map((row, index) => (
                <tr key={index}>
                  <td className="purchase-receipt-tab-td">{row.date}</td>
                  <td className="purchase-receipt-tab-td">{row.bill_number}</td>
                  <td className="purchase-receipt-tab-td">{row.customer?.customer_name}</td>
                  <td className="purchase-receipt-tab-td">{row.order_no}</td>
                  <td className="purchase-receipt-tab-td">{row.order_date}</td>
                  <td className="purchase-receipt-tab-td">{row.reference_no}</td>
                  {/* <td className="purchase-receipt-tab-td"></td> */}
                  <td className="purchase-receipt-tab-td">{row.status}</td>
                  <td className="purchase-receipt-tab-td">{row.total_amount}</td>
                  <td className="purchase-receipt-tab-td">
                    <FontAwesomeIcon icon={faCaretDown} className="purchase-receipt-table-body-td"
                      onClick={(e) => handlePurchaseReceiptDownAction(row, e)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {totalRecords > recordsPerPage && (
            <div className="report-pagination-info">
              <div className="report-pagination-container">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                  totalRecords={totalRecords}
                  startIndex={startIndex}
                  endIndex={endIndex}
                />
              </div>
            </div>
          )}

          {purchaseSaleReceipt.length === 0 && (
            <div className="receipt-no-data-message">
              No purchase receipt found.
            </div>
          )}
        </div>
      </div>

      {openNewPurchaseReceipt && (
        <div className="new-purchase-receipt-popup-container">
          <NewPurchaseReceipt 
            onClose={closeNewPurchaseReceipt} 
            purchaseReceiptCreated={handlePurchaseReceiptCreated} 
            purchaseReceiptCopy={purchaseReceiptCopy}
            isUpdated={purchaseReceiptUpdated}
          />
        </div>
      )}

      {purReceiptDownAction && (
        <div
          ref={columnPurchaseReceipActionRef}
          style={{
            position: 'absolute',
            top: `${actionDropdownPosition.top}px`,
          }}
          className="purchase-receipt-down-icon-action"
        >
          <ul className="purchase-receipt-down-icon-action-ul">
            <li className="purchase-receipt-down-icon-action-li" 
              onClick={() => handlePurchaseReceipCopy(purSelectedReceipt.id, true)}
            >
              Edit
            </li>
            <li className="purchase-receipt-down-icon-action-li"
              onClick={() => handlePurchaseReceipCopy(purSelectedReceipt.id)}
            >
              Copy
            </li>
            <li className="purchase-receipt-down-icon-action-li"
            onClick={() => handlePurchaseReceiptDelete(purSelectedReceipt.id)}>
              Delete
            </li>
          </ul>
        </div>
      )}
      <Copyrights />
    </>
  );
}

export default PurchaseReceipt;
