import React, { useState, useEffect, useRef } from "react";
import "./ItemSearchForRestourent.css";
import { BASE_URL } from "../../Url/constants";
import ErrorNotification from "../../Notification/ErrorNotification";
import { sendRequest } from "../../ApiRequest";
import { useTranslation } from "react-i18next";

const ItemSearchForRestourent = ({ label, id, handleChange }) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const inputRef = useRef(null);
  const { t} = useTranslation();
    

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleInputChange = async (e) => {
    const { value } = e.target;
    setQuery(value);

    if (value.length >= 2) {
      try {
        const response = await sendRequest('GET', `${BASE_URL}/v1/inventory_managment/items?query=${value}&is_billing=true`);
        const data = response.data;
        const fetchedOptions = data.data;
        setOptions(fetchedOptions);
        setIsOpen(true);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    } else {
      setOptions([]);
      setIsOpen(false);
    }
  };

  // const selectOption = (option) => {
  //   handleChange(option);
  //   setQuery("");
  //   setIsOpen(false);
  // };
  const selectOption = (option) => {
    if (option.quantity < 1) {
      ErrorNotification(`${option.item_name} - ${option.item_code} is out of stock.`);
    } else {
      handleChange(option);
      setQuery("");
      setIsOpen(false);
    }
  };

 

  return (
    <div className="searchable-dropdown" ref={inputRef}>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder={t("Search by Items Name or Items Code")}
        className="form-control"
      />
      {isOpen && (
        <div className="dropdown-container" style={{ maxHeight: "100px", overflowY: "auto" }}>
          {options.map((option) => (
            <div
              key={option.id}
              className="dropdown-option"
              onClick={() => selectOption(option)}
            >
              {option.item_name} - {option.item_code}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ItemSearchForRestourent;

